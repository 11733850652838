import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { AttachFileOutlined, GetApp } from "@material-ui/icons";
import HeaderInfoTitle from "./HeaderInfoTitle";

const AttachIcon = styled(AttachFileOutlined)`
  color: #787486;
  margin-right: 8px;
`;
const Attachments = styled.div`
  margin-top: 8px;
  padding: 0 3px;
  width: 100%;
  height: 110px;
  border-radius: 8px;
  display: flex;
  background-color: #d9d9d940;
`;
const Attachment = styled.div`
  padding: 0 3px;
  margin: 6px 3px;
  width: 116px;
  height: 96px;
  border-radius: 8px;
  background-color: white;
  :hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const DownloadIcon = styled(GetApp)`
  color: #787486;
  margin-right: 8px;
`;
const TicketDetailAttachments = ({ attachments }) => {
  return (
    <Box mt={1}>
      <Box display="flex" alignItems="center">
        <AttachIcon />
        <HeaderInfoTitle>Anexos</HeaderInfoTitle>
      </Box>

      <Attachments>
        {attachments.map(attachment => (
          <Attachment onClick={() => window.open(attachment.url, "_blank")}>
            <DownloadIcon />
            <span>{attachment.name}</span>
          </Attachment>
        ))}
      </Attachments>
    </Box>
  );
};

TicketDetailAttachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      id: PropTypes.number
    })
  ).isRequired
};

export default TicketDetailAttachments;
