import React from "react";
import PropTypes from "prop-types";
import { MenuItem, Select as MuiSelect } from "@material-ui/core";
import styled from "styled-components";

const Select = styled(MuiSelect)`
  margin: 0;
  color: #7a7a7a;
  .MuiSelect-root {
    width: 172px;
  }

  .MuiSelect-icon {
    color: #7a7a7a;
  }
`;

const SelectCampus = ({ campuses, selected, onChangeCampus }) => {
  const campusesOptions = campuses || [];

  return (
    <Select value={selected} onChange={onChangeCampus} disableUnderline>
      {campusesOptions.map(campus => (
        <MenuItem value={campus.id} key={campus.id}>
          {campus.name}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectCampus.propTypes = {
  campuses: PropTypes.shape([]).isRequired,
  selected: PropTypes.string,
  onChangeCampus: PropTypes.func.isRequired
};

SelectCampus.defaultProps = {
  selected: ""
};

export default SelectCampus;
