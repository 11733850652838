import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import Dialog from "../../../waybee-ui/Dialog";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";

const DialogInfo = ({ open, onClose }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth closeButton>
      <DialogTitle onClose={onClose}>Informações</DialogTitle>

      <DialogContent>
        <Box mt={1} mb={3}>
          <Typography variant="body1">
            Valide as informações de um cadastro pendente.
            <br />
            <br />
            <b>Cadastro no app: </b>
            Informações digitadas pelo usuário ao fazer o cadastro no
            aplicativo.
            <br />
            <b>Cadastro no colégio: </b>
            Informações encontradas na base de dados no colégio.
            <br />
            <b>Aprovar cadastro: </b>
            Libera o acesso ao usuário e acesso as informações dos alunos
            vinculados.
            <br />
            <b>Reprovar cadastro: </b>
            Bloqueia o acesso do usuário até ele refazer o cadastro com os dados
            corretos.
            <br />
            <br />
            <b>OBS: </b>
            Os textos em vermelho são os dados que divergem no cadastro.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DialogInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DialogInfo;
