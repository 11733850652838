import DOMPurify from "dompurify";

const sanitize = dirtyHtml => {
  return DOMPurify.sanitize(dirtyHtml, {
    ADD_TAGS: ["iframe"],
    ADD_ATR: ["allow", "allowfullscreen", "frameborder", "scrolling"] // or //or ALLOWED_ATR
  });
};

export default {
  sanitize
};
