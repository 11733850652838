import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LinearProgressMui from "@material-ui/core/LinearProgress";
import {
  BarChart as BarChartIcon,
  CloudDownload as DownloadIcon
} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import ModalVoters from "./ModalVoters";
import Loading from "../../../components/Loading";
import Button from "../../../waybee-ui/Button";
import CommunicationService from "../../../services/CommunicationService";
import { Can } from "../../../acl/can";

const SurveyQuestion = styled.p`
  color: #000;
  margin: 40px 0 30px;
  font-size: 20px;
  font-weight: 400;
`;

const LinearProgress = styled(LinearProgressMui)`
  height: 10px;
  border-radius: 5px;
`;

const ViewVotes = styled.p`
  text-align: right;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.primary.main};
  &:hover {
    cursor: pointer;
  }
`;

const VotePercent = styled.p`
  text-align: center;
`;

const VoteContainer = styled.div`
  margin: 26px;
`;

const NoVote = styled.p`
  margin: 12px 26px 0 0;
  text-align: right;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
`;

const DownloadButton = styled(Button)`
  margin-left: auto;

  p {
    margin: 0 0 0 15px;
  }
`;
class GroupSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openVoterModal: false,
      selectedOption: undefined
    };
  }

  getReport = async () => {
    const { communicationId } = this.props;
    const report = await CommunicationService.getSurveyReport(communicationId);
    const url = window.URL.createObjectURL(new Blob([report.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "yourfilename.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  render() {
    const { survey, loading } = this.props;
    const { openVoterModal, selectedOption } = this.state;

    if (!loading && !survey) return null;

    return (
      <Group>
        <Heading level={2} icon={BarChartIcon} gutterBottom>
          Resultados da Enquete
          <Can I="use module" a="surveyReport">
            <DownloadButton color="secondary" onClick={() => this.getReport()}>
              <DownloadIcon />
              <p>Baixar Dados</p>
            </DownloadButton>
          </Can>
        </Heading>

        {loading ? (
          <Loading />
        ) : (
          <>
            <SurveyQuestion>{survey.title}</SurveyQuestion>
            {survey.options.map(option => (
              <VoteContainer>
                <Grid justify="space-between" container>
                  <Grid xs={5} item>
                    <p>{option.question}</p>
                  </Grid>
                  <Grid xs={2} item>
                    <VotePercent>
                      {option.statistic}
                      % de votos
                    </VotePercent>
                  </Grid>
                  <Grid xs={5} item>
                    {!!option.statistic && (
                      <ViewVotes
                        onClick={() =>
                          this.setState({
                            openVoterModal: true,
                            selectedOption: option
                          })
                        }
                      >
                        Visualizar Votos
                      </ViewVotes>
                    )}
                  </Grid>
                </Grid>
                <LinearProgress
                  variant="determinate"
                  value={option.statistic}
                />
              </VoteContainer>
            ))}
            <NoVote>
              Porcentagem de pessoas que ainda não votaram:
              {` ${survey.statistic}`}
              %
            </NoVote>
          </>
        )}
        <ModalVoters
          onClose={() => this.setState({ openVoterModal: false })}
          open={openVoterModal}
          option={selectedOption}
        />
      </Group>
    );
  }
}

GroupSurvey.defaultProps = {
  loading: false
};

GroupSurvey.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  survey: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  communicationId: PropTypes.number.isRequired
};

export default GroupSurvey;
