import React from "react";
import PropType from "prop-types";
import styled from "styled-components";
import { Icon as IconMui, Box, IconButton } from "@material-ui/core";

const Container = styled.div`
  padding: 30px 20px;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.primary.dark};
  margin: 0;
`;

const HeaderButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.color.primary.dark};
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(IconMui)`
  color: ${({ variant, theme }) =>
    variant === "filled" ? "white" : theme.color.primary.dark};
`;

const ChatColumnHeader = ({ title, icon, onClickIcon, variant, children }) => {
  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Title>{title}</Title>

        {icon && variant === "filled" && (
          <HeaderButton onClick={onClickIcon}>
            <Icon fontSize="small" variant={variant}>
              {icon}
            </Icon>
          </HeaderButton>
        )}

        {icon && variant === "outline" && (
          <IconButton size="small" onClick={onClickIcon}>
            <Icon>{icon}</Icon>
          </IconButton>
        )}
      </Box>
      {children}
    </Container>
  );
};

ChatColumnHeader.defaultProps = {
  icon: "",
  onClickIcon: () => {},
  children: null,
  variant: "filled"
};

ChatColumnHeader.propTypes = {
  title: PropType.string.isRequired,
  icon: PropType.string,
  onClickIcon: PropType.func,
  variant: PropType.oneOf(["filled", "outline"]),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropType.any // Ejected prop
};

export default ChatColumnHeader;
