import React, { Fragment } from "react";
import PropTypes from "prop-types";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import { Box as Container, Typography } from "@material-ui/core";
import styled from "styled-components";

const Box = styled(Container)`
  display: flex;
  cursor: pointer;
`;
const Attachments = ({ attachments }) => {
  return (
    <Fragment>
      {attachments.map(attachment => {
        return (
          <Box mb={1} onClick={() => window.open(attachment.url, "_system")}>
            <AttachmentIcon fontSize="small" color="primary" />
            <Typography color="primary">{attachment.name}</Typography>
          </Box>
        );
      })}
    </Fragment>
  );
};

export default Attachments;

Attachments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  attachments: PropTypes.array.isRequired
};
