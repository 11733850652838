import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { MoreHoriz } from "@material-ui/icons";
import PropTypes from "prop-types";
import { Container, ButtonText, ButtonContainer } from "./styles";

const MoreButton = ({ actions }) => {
  const [targetElement, setTargetElement] = useState(null);

  const handleShowSubmenu = event => {
    event.stopPropagation();
    setTargetElement(event.currentTarget);
  };

  const handleCloseSubmenu = event => {
    event.stopPropagation();
    setTargetElement(null);
  };

  const handleClick = (event, action) => {
    event.stopPropagation();
    if (action) {
      action();
    }
  };

  const open = Boolean(targetElement);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button aria-describedby={id} onClick={handleShowSubmenu}>
        <MoreHoriz />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={targetElement}
        onClose={handleCloseSubmenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Container>
          {actions.map(btn => (
            <ButtonContainer>
              <Button
                startIcon={<btn.icon />}
                variant={btn.variant || "contained"}
                color={btn.color || "primary"}
                onClick={event => handleClick(event, btn.action)}
              >
                <ButtonText>{btn.text}</ButtonText>
              </Button>
            </ButtonContainer>
          ))}
        </Container>
      </Popover>
    </div>
  );
};

MoreButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array.isRequired
};

export default MoreButton;
