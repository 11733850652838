import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Button, Table as MuiTable, TableBody } from "@material-ui/core";
import {
  AddCircleOutline,
  Announcement as AnnouncementIcon
} from "@material-ui/icons";
import { withAlert } from "react-alert";
import moment from "@moment";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import NotificationList from "./NotificationList";
import ConfirmDialog from "../../../waybee-ui/Dialog/ConfirmDialog";
import NewNotificationDialog from "./NewNotificationDialog";
import NotificationService from "../../../services/NotificationService";
import TableListItem from "../../../waybee-ui/TableList/TableListItem";
import Loading from "../../../components/Loading";

const AddNotificationButton = styled(Button)`
  height: 80px;
  margin: 0 -30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  text-transform: none;
  font-size: 16px;
  color: #34657f;

  p {
    margin: 0 0 0 14px;
  }
`;

const Table = styled(MuiTable)`
  width: calc(100% + 60px);
  max-height: calc(100vh - 430px);
  margin: 0 -30px -20px;
  border: solid #f2f2f2;
  border-width: 2px 0 2px 0;
`;

const GroupNotification = ({
  notifications,
  communication,
  reload,
  alert,
  loading
}) => {
  // Modals: NEW_NOTIFICATION, EDIT_NOTIFICATION, NOTIFICATION_CANCELED, NOTIFICATION_EDITED, CONFIRM_EDIT, CONFIRM_CANCEL
  const [usedModal, setUsedModal] = useState("");
  const [selectedNotification, setSelectedNotification] = useState(undefined);

  const saveNotifications = async (notification, action) => {
    if (action === "NEW_NOTIFICATION") {
      try {
        await NotificationService.saveNotification(
          notification,
          communication.id
        );
        setUsedModal("");
        alert.show("A notificação foi salva com sucesso!", {
          title: "Notificação Salva",
          onClose: reload
        });
      } catch (e) {
        console.error(e);
        const message = e.isAxiosError
          ? e.response.data.error.message
          : "Ocorreu um erro ao salvar a notificação!";
        alert.show(message, {
          title: "Erro ao salvar"
        });
      }
    } else {
      const notificationData = {
        ...selectedNotification,
        ...notification
      };
      setSelectedNotification(notificationData);
      setUsedModal("CONFIRM_EDIT");
    }
  };

  const editNotification = async () => {
    try {
      await NotificationService.editNotifications(
        selectedNotification,
        communication.id
      );
      setUsedModal("");
      alert.show("As alterações foram salvas com sucesso!", {
        title: "Notificação Editada",
        onClose: reload
      });
    } catch (e) {
      console.error(e);
      const message = e.isAxiosError
        ? e.response.data.error.message
        : "Ocorreu um erro um erro ao salvar as alterações da notificação.";
      alert.show(message, { title: "Erro ao salvar alterações" });
    }
  };

  const cancelNotification = async () => {
    try {
      const editedNotification = {
        ...selectedNotification,
        status: "CANCELED"
      };

      await NotificationService.editNotifications(
        editedNotification,
        communication.id
      );
      setUsedModal("");
      alert.show("A notificação foi cancelada com sucesso!", {
        title: "Notificação Cancelada",
        onClose: reload
      });
    } catch (e) {
      console.error(e);
      const message = e.isAxiosError
        ? e.response.data.error.message
        : "Ocorreu um erro um erro ao salvar as alterações da notificação.";
      alert.show(message, {
        title: "Erro ao cancelar"
      });
    }
  };

  if (communication.status !== "sent" && !communication.sendNotification)
    return null;

  return (
    <Group>
      <Box>
        <Heading level={2} icon={AnnouncementIcon} gutterBottom>
          Notificações da Comunicação
        </Heading>

        {loading ? (
          <Loading />
        ) : (
          <>
            {communication.status === "sent" && !communication.deleted_at && (
              <AddNotificationButton
                component="div"
                onClick={() => {
                  setSelectedNotification(undefined);
                  setUsedModal("NEW_NOTIFICATION");
                }}
              >
                <AddCircleOutline />
                <p>Nova Notificação</p>
              </AddNotificationButton>
            )}

            {communication.status !== "sent" && communication.sendNotification && (
              <Table aria-label="simple table">
                <TableBody>
                  <TableListItem
                    key={1}
                    statusText="Notificação Agendada"
                    statusIcon="access_time"
                    statusColor="rgba(0,0,0,.5)"
                    item={{
                      title: communication.title,
                      info: moment(communication.schedule).format(
                        "[Agendada para] DD/MM/YYYY [às] HH:mm"
                      )
                    }}
                  />
                </TableBody>
              </Table>
            )}

            <NotificationList
              notifications={notifications}
              onSelectItem={item => {
                setSelectedNotification(item);
                setUsedModal("EDIT_NOTIFICATION");
              }}
            />
          </>
        )}

        <NewNotificationDialog
          open={
            usedModal === "NEW_NOTIFICATION" ||
            usedModal === "EDIT_NOTIFICATION"
          }
          communication={communication}
          onClose={() => {
            setUsedModal("");
          }}
          onSubmit={saveNotifications}
          onCancel={() => setUsedModal("CONFIRM_CANCEL")}
          notification={selectedNotification}
          action={usedModal}
        />

        <ConfirmDialog
          title="Notificação Agendada"
          message="Tem certeza que deseja salvar alterações nessa notificação?"
          onClose={() => {
            setUsedModal("");
          }}
          onCancel={() => {
            setUsedModal("EDIT_NOTIFICATION");
          }}
          onConfirm={editNotification}
          confirmBtnText="Salvar Alterações"
          cancelBtnText="Voltar"
          open={usedModal === "CONFIRM_EDIT"}
        />

        <ConfirmDialog
          title="Notificação Agendada"
          message="Tem certeza que deseja cancelar essa notificação?"
          onClose={() => {
            setUsedModal("");
          }}
          onCancel={() => {
            setUsedModal("EDIT_NOTIFICATION");
          }}
          onConfirm={cancelNotification}
          confirmBtnText="Cancelar Notificação"
          confirmBtnColor="#ec0000"
          cancelBtnText="Voltar"
          open={usedModal === "CONFIRM_CANCEL"}
        />
      </Box>
    </Group>
  );
};

GroupNotification.defaultProps = {
  loading: false
};

GroupNotification.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  notifications: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  communication: PropTypes.array.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  reload: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default withAlert()(GroupNotification);
