import { createTheme } from "@material-ui/core/styles";

const headingStyle = {
  lineHeight: 1.33,
  fontWeight: 500,
  fontStyle: "normal",
  fontStretch: "normal",
  letterSpacing: "normal"
};

export default theme =>
  createTheme({
    spacing: 10,
    palette: {
      background: {
        default: "#f2f2f2"
      },
      primary: {
        light: theme.color.primary.light,
        main: theme.color.primary.main,
        dark: theme.color.primary.dark,
        contrastText: "#fff"
      },
      secondary: {
        light: theme.color.secondary.light,
        main: theme.color.secondary.main,
        dark: theme.color.secondary.dark,
        contrastText: "#fff"
      },
      error: {
        light: theme.color.error.light,
        main: theme.color.error.main,
        dark: theme.color.error.dark,
        contrastText: "#fff"
      },
      default: {
        light: theme.color.default.light,
        main: theme.color.default.main,
        dark: theme.color.default.dark,
        contrastText: "#fff"
      }
    },
    typography: {
      allVariants: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
      },
      h1: {
        ...headingStyle,
        ...{
          fontSize: "24px",
          fontWeight: 600,
          color: theme.color.primary.dark
        }
      },
      h2: {
        ...headingStyle,
        ...{
          fontSize: "24px",
          color: theme.color.primary.dark
        }
      },
      h3: {
        ...headingStyle,
        ...{
          fontSize: "22px",
          color: theme.color.primary.dark
        }
      },
      h4: {
        ...headingStyle,
        ...{
          fontSize: "20px",
          color: theme.color.primary.dark
        }
      },
      h5: {
        ...headingStyle,
        ...{
          fontSize: "18px",
          color: theme.color.primary.dark
        }
      },
      h6: {
        fontSize: "22px",
        color: "#000000",
        fontWeight: 300,
        lineHeight: "1.7em"
      },
      body1: {
        marginBottom: "4px",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25em",
        letterSpacing: "normal",
        color: "#000000"
      },
      body2: {
        marginBottom: "4px",
        fontSize: 14,
        lineHeight: "1.33em",
        color: "#000000"
      },
      subtitle1: {
        marginBottom: "4px",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.56em",
        color: theme.color.secondary.dark
      },
      caption: {
        fontSize: 14,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        marginBottom: "8px",
        display: "inline-block",
        lineHeight: 1.29,
        letterSpacing: "normal",
        color: "#727272"
      }
    },
    overrides: {
      MuiTypography: {
        gutterBottom: {
          marginBottom: "20px"
        }
      },
      MuiSvgIcon: {
        fontSizeLarge: {
          fontSize: "2.3875rem"
        }
      },
      MuiFormLabel: {
        root: {
          color: "#727272",
          borderBottomColor: "#727272",
          marginBottom: "20px"
        }
      },
      MuiInput: {
        underline: {
          "&::before:hover": {
            borderBottom: "solid 2px #727272"
          }
        }
      },
      MuiFormControl: {
        marginDense: {
          marginTop: "-30px"
        }
      },
      MuiFormControlLabel: {
        label: {
          color: "#727272",
          marginBottom: 0
        }
      },
      MuiFormHelperText: {
        root: {
          marginBottom: "-4px"
        }
      },
      MuiChip: {
        root: {
          margin: "3px"
        }
      },
      assets: {
        home: theme.images.homeAsset,
        profileHeader: theme.images.profileHeaderAsset,
        loginBackground: theme.images.loginBackground,
        logo: theme.images.logo
      }
    }
  });
