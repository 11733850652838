import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover as MuiPopover } from "@material-ui/core";
import styled from "styled-components";

const Popover = styled(MuiPopover)`
  pointer-events: none;
  p {
    margin: 10px 20px;
  }
`;

const PopoverLabel = ({ label, popoverContent }) => {
  const [open, setOpen] = useState(false);
  const [activePopoverEl, setActivePopoverEl] = useState();

  const handlePopoverOpen = el => {
    setOpen(true);
    setActivePopoverEl(el);
  };

  const handlePopoverClose = () => {
    setOpen(false);
    setActivePopoverEl(null);
  };

  return (
    <>
      <div
        onMouseEnter={e => handlePopoverOpen(e.target)}
        onMouseLeave={() => handlePopoverClose()}
      >
        {label}
      </div>
      {!!popoverContent && (
        <Popover
          open={open}
          anchorEl={activePopoverEl}
          anchorOrigin={{
            vertical: -10,
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          onClose={() => handlePopoverClose()}
          disableRestoreFocus
        >
          {popoverContent}
        </Popover>
      )}
    </>
  );
};

PopoverLabel.defaultProps = {
  popoverContent: null
};

PopoverLabel.propTypes = {
  label: PropTypes.string.isRequired,
  popoverContent: PropTypes.string
};

export default PopoverLabel;
