import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const HeaderName = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #787486;
  ${({ highlight }) =>
    highlight
      ? css`
          text-decoration: underline;

          :hover {
            cursor: pointer;
            color: #00303b;
          }
        `
      : ""}
`;

HeaderName.defaultProps = {
  highlight: false
};

HeaderName.propTypes = {
  highlight: PropTypes.bool
};

export default HeaderName;
