import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, FormLabel, Grid, TextField } from "@material-ui/core";
import { withAlert } from "react-alert";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  HowToReg as HowToRegIcon
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from "../../waybee-ui/Divider";
import SchoolGroupService from "../../services/SchoolGroupService";
import Group from "../../waybee-ui/Group";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import ModalReceiversSelector from "../../views/ActivityForm/ModalReceiversSelector";

class PublicSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coursesAndClasses: [],
      openStudentSelector: false,
      schoolYears: [],
      value: {
        studentCourses: [],
        studentClasses: [],
        schoolYear: []
      },
      destinationFilter: {
        students: {
          courses: [],
          classes: [],
          excluded: []
        }
      }
    };
  }

  componentDidMount() {
    this.getYearFilter();
  }

  componentDidUpdate(prevProps) {
    const { resetFlag } = this.props;
    if (prevProps.resetFlag !== resetFlag) {
      this.reset();
    }
  }

  reset = () => {
    const { value } = this.props;
    if (value) {
      this.setState({
        value: {
          studentCourses: value.students.courses.map(course => course.CODIGO),
          studentClasses: value.students.classes.map(classe => classe.CODIGO)
        },
        destinationFilter: {
          ...value
        }
      });
    }
  };

  getYearFilter = async () => {
    const res = await SchoolGroupService.getFilters();
    this.setState({ schoolYears: res.schoolYear });
  };

  getCoursesAndClasses = async year => {
    try {
      const coursesAndClasses = await SchoolGroupService.getCoursesAndClasses(
        year
      );
      this.setState({ coursesAndClasses });
    } catch (e) {
      const { alert } = this.props;
      alert.show("Falha ao obter dados da atividade", { title: "Erro" });
    }
  };

  changeStudentSelect = (type, ids) => {
    if (type === "excludedDestinationIds") {
      this.changeValue({ excludedDestinationIds: ids.split(",") });
    }
  };

  changeValue = val => {
    const { onChange } = this.props;
    const { destinationFilter, coursesAndClasses } = this.state;
    let { courses } = destinationFilter.students;

    if (val.schoolYear) {
      this.getCoursesAndClasses(val.schoolYear);
    }

    if (val.studentCourses)
      courses = coursesAndClasses.filter(course =>
        val.studentCourses.includes(course.CODIGO)
      );

    let { classes } = destinationFilter.students;
    if (val.studentClasses)
      classes = coursesAndClasses
        .filter(course => val.studentCourses.includes(course.CODIGO))
        .map(course => course.VW_TURMA)
        .flat()
        .filter(classe => val.studentClasses.includes(classe.CODIGO));

    const newDestinationFilter = {
      students: {
        courses,
        classes,
        years: val.schoolYear,
        excluded:
          val.excludedDestinationIds || destinationFilter.students.excluded
      }
    };

    this.setState({
      destinationFilter: newDestinationFilter,
      value: {
        studentCourses: courses.map(course => course.CODIGO),
        studentClasses: classes.map(classe => classe.CODIGO),
        schoolYear: val.schoolYear
      }
    });
    onChange(newDestinationFilter);
  };

  getClassesByCourses = courses => {
    const { coursesAndClasses } = this.state;

    return courses
      ? coursesAndClasses
          .filter(course => courses.includes(course.CODIGO))
          .map(course => course.VW_TURMA)
          .flat()
      : [];
  };

  render() {
    const {
      coursesAndClasses,
      destinationFilter,
      openStudentSelector,
      schoolYears,
      value
    } = this.state;
    const { description, disabled } = this.props;

    const courses = coursesAndClasses.map(course => course.CODIGO);
    const classes = this.getClassesByCourses(value.studentCourses);

    return (
      <>
        <Box py={0.8}>
          <Grid container spacing={5}>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <FormLabel>{description}</FormLabel>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                onChange={(event, schoolYear) => {
                  this.changeValue({
                    ...value,
                    schoolYear
                  });
                }}
                name="shcoolYear"
                id="schoolYear"
                options={schoolYears}
                value={value.schoolYear}
                renderInput={params => (
                  <TextField {...params} label="Ano" margin="dense" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="studentCourses"
                name="studentCourses"
                disabled={disabled}
                value={value.studentCourses}
                onChange={(event, studentCourses) => {
                  this.changeValue({
                    ...value,
                    studentCourses
                  });
                }}
                options={courses}
                getOptionLabel={option => {
                  const foundCourse = coursesAndClasses.find(
                    course => course.CODIGO === option
                  );
                  if (foundCourse) return foundCourse.NOME;
                  return null;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Cursos"
                    margin="dense"
                    fullWidth
                  />
                )}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {
                      coursesAndClasses.find(course => course.CODIGO === option)
                        .NOME
                    }
                  </React.Fragment>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="studentClasses"
                name="studentClasses"
                options={classes.map(classe => classe.CODIGO)}
                disabled={disabled}
                getOptionLabel={option => {
                  const foundClass = classes.find(
                    classe => classe.CODIGO === option
                  );
                  if (foundClass) return foundClass.NOME;
                  return null;
                }}
                onChange={(event, studentClasses) => {
                  this.changeValue({
                    ...value,
                    studentClasses
                  });
                }}
                value={value.studentClasses}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Turmas"
                    margin="dense"
                    fullWidth
                  />
                )}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {classes.find(classe => classe.CODIGO === option).NOME}
                  </React.Fragment>
                )}
              />
            </Grid>
          </Grid>
        </Box>

        {!disabled && (
          <>
            <Divider />
            <Group.GroupFooter>
              <ButtonBlock
                startIcon={<HowToRegIcon />}
                disabled={!destinationFilter.students.courses.length}
                onClick={() => this.setState({ openStudentSelector: true })}
              >
                Gerenciar destinatários
              </ButtonBlock>
            </Group.GroupFooter>
          </>
        )}

        <ModalReceiversSelector
          isOpen={openStudentSelector}
          onClose={() => this.setState({ openStudentSelector: false })}
          studentClasses={destinationFilter.students.classes}
          studentCourses={destinationFilter.students.courses}
          years={value.schoolYear}
          excludedIds={destinationFilter.students.excluded.join(",")}
          setSubscribers={this.changeStudentSelect}
        />
      </>
    );
  }
}

PublicSelector.defaultProps = {
  value: null,
  disabled: false
};

PublicSelector.propTypes = {
  resetFlag: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  disabled: PropTypes.bool,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(PublicSelector);
