import styled from "styled-components";

import { IconButton as MuiIconButton } from "@material-ui/core";

const IconButton = styled(MuiIconButton)`
  color: inherit;
  margin-right: 6px;
  margin-left: -10px;
`;

export default IconButton;
