import React from "react";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

const apiKey = "adc3507dcc2aec06700b8a98759a6256";
const releaseStage = process.env.REACT_APP_ENV;
const appVersion = process.env.REACT_APP_VERSION;

const bugsnagBeforeSend = function bugsnagBeforeSend(report) {
  if (releaseStage === "development") report.ignore();

  const localStorageData = {};
  report.updateMetaData("localStorage", localStorageData);
};

const bugsnagClient = bugsnag({
  releaseStage,
  apiKey,
  appVersion,
  beforeSend: bugsnagBeforeSend
});

const ErrorBoundary = (() => {
  bugsnagClient.use(bugsnagReact, React);
  return bugsnagClient.getPlugin("react");
})();

const notifyError = (error, additionalInfo) => {
  const localStorageData = localStorage;
  bugsnagClient.metaData = { localStorage: localStorageData, additionalInfo };
  bugsnagClient.user = {};
  const errorObject = error instanceof Error ? error : new Error(error);
  bugsnagClient.notify(errorObject);
};

const bugsnagService = {
  ErrorBoundary,
  notifyError
};

export default bugsnagService;
