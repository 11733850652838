import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  AttachFile as AttachIcon,
  InsertEmoticon as EmojiIcon,
  Send as SendIcon
} from "@material-ui/icons";
import {
  CircularProgress,
  IconButton,
  Button,
  Avatar as MuiAvatar,
  TextField as MuiTextField
} from "@material-ui/core";
import { Picker } from "emoji-mart";
import { withAlert } from "react-alert";
import Compressor from "compressorjs";
import Autocomplete from "@material-ui/lab/Autocomplete";

const MessageInput = styled.div`
  margin: 20px 12px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  height: 100%;
  border-radius: 4px;
`;
const EmojiPicker = styled.div`
  display: inline;
  position: relative;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
  }
`;
const TextField = styled(MuiTextField)`
  border: none;
  width: 100%;
  background: none;
  flex-grow: 1;
  margin: 0;
  box-shadow: 0 0 0 0;
  outline: 0;
  resize: none;

  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
`;
const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;
const SendButton = styled(Button)`
  background-color: #00303b;
  color: white;
  min-width: unset;
  width: 30px;
  height: 30px;
  svg {
    font-size: 20px;
  }
`;
const Avatar = styled(MuiAvatar)`
  width: 28px;
  height: 28px;
  border: 1px solid white;
  margin-right: 12px;
`;
const ShortcutOption = styled.p`
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #787486;
  span {
    font-weight: 600;
    font-size: 14px;
  }
`;

function TicketMessageField({
  onSend,
  value,
  loading,
  shortcuts,
  onChange,
  onSendFile
}) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [openShortcuts, setOpenShortcuts] = useState(false);

  const hidePicker = useCallback(e => {
    if (e.target.classList.contains("emoji-picker")) return;
    if (e.target.closest(".emoji-picker")) return;
    setShowEmojiPicker(false);
  }, []);

  useEffect(() => {
    document.addEventListener("click", hidePicker);
    return () => {
      document.removeEventListener("click", hidePicker);
    };
  }, [hidePicker]);

  const onSelectEmoji = emojiObj => {
    const newText = (value || "") + emojiObj.native;
    setShowEmojiPicker(false);
    onChange(newText);
  };

  const minimizeImage = file => {
    return new Promise((resolve, reject) => {
      return new Compressor(file, {
        convertSize: 0,
        quality: 0.5,
        maxWidth: 1024,
        maxHeight: 1024,
        success: resultFile => resolve(resultFile),
        error: () => reject()
      });
    });
  };

  const handleChangeFile = async e => {
    const file = e.target.files[0];

    if (!file) return;
    if (
      file.type.split("/")[0] === "image" &&
      file.type.split("/")[1] !== "gif"
    ) {
      minimizeImage(file)
        .then(minimizedFile => onSendFile(minimizedFile))
        .catch(err => {
          console.error(err);
          alert.show(`Erro ao enviar imagem`, { title: "Erro" });
        });
    } else {
      try {
        const sizeInMb = file.size / 1024 / 1024;
        if (sizeInMb > 30) {
          alert.show(`Arquivo não pode ser maior que 30Mb`, { title: "Erro" });
          return;
        }

        onSendFile(file);
      } catch (err) {
        console.error(err);
        alert.show(`Erro ao enviar arquivo`, { title: "Erro" });
      }
    }
  };

  const handleKeyPress = event => {
    if (
      !event.shiftKey &&
      event.key === "Enter" &&
      !loading &&
      !openShortcuts
    ) {
      event.preventDefault();
      onSend(value);
    }
  };

  const handleChangeMessage = event => {
    const { value: val } = event.target;
    onChange(val);

    if (val && val[0] === "/") {
      setOpenShortcuts(true);
    } else if (!val || val[0] !== "/") {
      setOpenShortcuts(false);
    }
  };

  const handleChangeShortcut = shortcut => {
    onChange(shortcut.message);
    setOpenShortcuts(false);
  };

  const translationObj = {
    search: "Buscar",
    clear: "Apagar",
    notfound: "Emoji não encontrado",
    categories: {
      search: "Resultados da busca",
      recent: "Usados frequentemente",
      people: "Carinhas e pessoas",
      nature: "Animais e natureza",
      foods: "Comidas e bebidas",
      activity: "Atividades",
      places: "Viagem e lugares",
      objects: "Objetos",
      symbols: "Símbolos",
      flags: "Bandeiras"
    },
    categorieslabel: "Categorias dos emojis"
  };

  return (
    <form
      onSubmit={e => {
        onSend(value);
        e.preventDefault();
      }}
    >
      <div>
        <MessageInput>
          <Avatar src={localStorage.getItem("userPhoto")} />

          <Autocomplete
            inputValue={value}
            freeSolo
            disableClearable
            options={shortcuts}
            open={openShortcuts}
            getOptionLabel={option => option.message}
            filterOptions={(options, { inputValue }) =>
              options.filter(option => option.shortcut.includes(inputValue))
            }
            renderOption={option => (
              <ShortcutOption>
                <span>{option.shortcut}</span>
                {` - ${option.message}`}
              </ShortcutOption>
            )}
            fullWidth
            onChange={(e, val) => handleChangeShortcut(val)}
            renderInput={params => (
              <TextField
                {...params}
                multiline
                maxRows={4}
                onKeyDown={handleKeyPress}
                placeholder="Comente algo sobre a tarefa.."
                onChange={handleChangeMessage}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />

          <IconButton size="small" disabled={loading}>
            <AttachIcon style={{ color: "rgba(0,0,0,.5)" }} />
            <FileInput type="file" onChange={handleChangeFile} />
          </IconButton>

          <EmojiPicker className="emoji-picker">
            {showEmojiPicker && (
              <Picker
                onSelect={onSelectEmoji}
                showPreview={false}
                showSkinTones={false}
                i18n={translationObj}
                style={{ position: "absolute", bottom: 0, right: 0, zIndex: 1 }}
              />
            )}
            <IconButton
              size="small"
              disabled={loading}
              onClick={() => setShowEmojiPicker(true)}
            >
              <EmojiIcon style={{ color: "rgba(0,0,0,.5)" }} />
            </IconButton>
          </EmojiPicker>

          {loading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <SendButton type="submit">
              <SendIcon />
            </SendButton>
          )}
        </MessageInput>
      </div>
    </form>
  );
}

TicketMessageField.defaultProps = {
  value: "",
  loading: false
};

TicketMessageField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onSendFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  shortcuts: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default withAlert()(TicketMessageField);
