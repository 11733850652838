import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import AppService from "../services/AppService";

const loadResources = async () => {
  // eslint-disable-next-line no-return-await
  return await AppService.getI18n()
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
    });
};

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      loadResources().then(response => {
        callback(null, {
          data: response,
          status: 200
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500
      });
    }
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    debug: true,
    load: "languageOnly",
    fallbackLng: "pt",
    keySeparator: ".",
    react: {
      useSuspense: false
    }
  });

export default i18n;
