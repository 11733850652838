import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Dialog as MuiDialog, Fab } from "@material-ui/core";
import {
  Computer as ComputerIcon,
  Smartphone as SmartphoneIcon
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

const MOBILE_WIDTH = "360px";
const DESKTOP_WIDTH = "750px";
const HEIGHT = "658px";

const Dialog = styled(MuiDialog)`
  .container-root-dialog {
    top: -22px !important;
    bottom: -22px !important;
    background-color: #0d0;
    overflow: visible;
  }

  .mobile {
    width: ${MOBILE_WIDTH};
    height: ${HEIGHT};
    margin: 145px 0 0 !important;
    overflow: visible !important;
    align-self: flex-start;
    vertical-align: top;
    transition: width 1s;
  }

  .desktop {
    width: ${DESKTOP_WIDTH};
    height: ${HEIGHT};
    margin: 160px 0 0 !important;
    overflow: visible !important;
    align-self: flex-start;
    vertical-align: top;
    transition: width 1s;
  }
`;

const CloseButton = styled(Fab)`
  background-color: #ec0000;
  color: #fff;
  right: -20px;
  top: -20px;
  position: absolute;

  :hover {
    background-color: #bc0000;
  }
`;

const Content = styled.div`
  width: 250px;
`;

const PreviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const PreviewButton = styled(Button)`
  color: ${props => (props.active ? "#018675" : "#CCCCCC")};
`;

const ButtonText = styled.span`
  padding-left: 10px;
  text-transform: none;
`;

const PreviewContent = styled.iframe`
  transition: width 1s;
  border: none;
`;

function DialogNotification(props) {
  const { open, onClose, content } = props;
  const [device, setDevice] = useState("desktop");
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.style.width =
        device === "mobile" ? MOBILE_WIDTH : DESKTOP_WIDTH;
    }
  }, [device]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="body"
      maxWidth="1000px"
      classes={{
        paper: device,
        root: "container-root-dialog"
      }}
    >
      <PreviewHeader>
        <CloseButton size="small" aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <PreviewButton
          active={device === "desktop"}
          onClick={() => {
            setDevice("desktop");
          }}
        >
          <ComputerIcon />
          <ButtonText>Desktop</ButtonText>
        </PreviewButton>
        <PreviewButton
          active={device === "mobile"}
          onClick={() => {
            setDevice("mobile");
          }}
        >
          <SmartphoneIcon />
          <ButtonText>Celular</ButtonText>
        </PreviewButton>
      </PreviewHeader>
      <Content>
        <PreviewContent
          ref={iframeRef}
          srcDoc={content}
          title="preview"
          width={device === "mobile" ? MOBILE_WIDTH : DESKTOP_WIDTH}
          height={HEIGHT}
        />
      </Content>
    </Dialog>
  );
}

DialogNotification.defaultProps = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  content: ""
};

DialogNotification.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  content: PropTypes.string
};

export default DialogNotification;
