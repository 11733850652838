import React, { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { DebounceInput } from "react-debounce-input";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  Grid,
  InputAdornment,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TextField,
  Typography,
  withTheme
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  AccessTime as AccessTimeIcon,
  People as PeopleIcon,
  Search,
  Edit,
  GroupWork as GroupIcon,
  HighlightOff as HighlightOffIcon,
  Send,
  Schedule
} from "@material-ui/icons";
import moment from "@moment";
import { withAlert } from "react-alert";
import { AbilityContext } from "../../../acl/can";
import MoreButton from "../../../components/MoreButton";
import ActivitiesService, { enums } from "../../../services/ActivitiesService";
import HoverPopover from "../../../components/HoverPopover";
import CoursesInfoPopover from "../../../components/CoursesInfoPopover";
import ConfirmDialog from "../../../waybee-ui/Dialog/ConfirmDialog";
import SelectScheduleDialog from "../../../components/SelectScheduleDialog";

const Table = styled(MuiTable)`
  width: calc(100% + 60px);
  max-height: calc(100vh - 430px);
  margin: 0 -30px -20px;
  border: solid #f2f2f2;
  border-width: 2px 0 2px 0;
`;
const TableRow = styled(MuiTableRow)`
  cursor: pointer;
`;
const TableCell = styled(MuiTableCell)`
  padding: 20px 30px;
  border-bottom: solid #f2f2f2 2px;
  max-width: 280px;
  min-width: 110px;
  color: #727272;

  &.title {
    h5 {
      margin: 0;
      font-size: 22px;
      font-weight: 300;
      color: #000;
      line-height: 1.15em;
      margin-bottom: 4px;
    }

    span {
      font-size: 16px;
    }
  }

  &.type {
    font-size: 16px;
  }

  &.qtde {
    * {
      color: #727272;
    }

    font-size: 18px;
  }

  &.status {
    svg {
      color: ${props => props.color};
      font-size: 28px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      padding-right: 30px;
      color: ${props => props.color};
    }
  }

  &.more {
    svg {
      color: ${props => props.color};
      font-size: 28px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      padding-right: 30px;
      color: ${props => props.color};
    }
  }
`;
const GroupWork = styled(GroupIcon)`
  color: #727272;

  &:hover {
    color: rgba(114, 114, 114, 0.5);
  }
`;

const ActivityList = ({
  data,
  filters,
  setFilter,
  onSelectData,
  alert,
  theme
}) => {
  const ability = useContext(AbilityContext);
  const history = useHistory();

  const [dataSelectedId, setDataSelectedId] = useState(null);

  const [openSendData, setOpenSendData] = useState(false);
  const [sendSelectId, setSendSelectId] = useState(null);

  const [openSchedule, setOpenSchedule] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [scheduleSelectId, setScheduleSelectId] = useState(null);

  let searchTimeout = null;
  const onChangeDate = (date, field) => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setFilter(field, date);
    }, 600);
  };

  const handleEdit = useCallback(
    id => () => {
      setDataSelectedId(id);
    },
    []
  );
  const handleSend = useCallback(
    id => () => {
      setOpenSendData(true);
      setSendSelectId(id);
    },
    []
  );
  const handleSchedule = useCallback(
    id => () => {
      setScheduleSelectId(id);
      setOpenSchedule(true);
    },
    []
  );

  const refresh = () => {
    history.go(0);
  };

  const sendActivity = async id => {
    if (id) {
      await ActivitiesService.send(id);
    }
    setOpenSendData(false);
    alert.show("Atividade enviada com sucesso!", {
      title: "Atividade Enviada",
      onClose: refresh
    });
  };

  const scheduleActivity = async id => {
    if (id) {
      await ActivitiesService.schedule(id, schedule);
    }
    setOpenSchedule(false);
    alert.show("Atividade agendada com sucesso!", {
      title: "Atividade Agendada",
      onClose: refresh
    });
  };

  if (dataSelectedId) {
    return <Redirect to={`/activity/${dataSelectedId}/edit`} />;
  }

  const activityActions = activity => {
    const actions = [];

    if (ability.can("edit", "activity")) {
      actions.push({
        text: "Editar atividade",
        icon: Edit,
        color: "secondary",
        variant: "contained",
        action: handleEdit(activity.id)
      });
    }
    if (ability.can("create", "activity")) {
      if (activity.status === enums.status.OPEN) {
        actions.push({
          text: "Enviar Agora",
          icon: Send,
          color: "primary",
          variant: "contained",
          action: handleSend(activity.id)
        });
        actions.push({
          text: "Agendar Envio",
          icon: Schedule,
          color: "primary",
          variant: "contained",
          action: handleSchedule(activity.id)
        });
      }
    }

    return actions;
  };

  return (
    <Box>
      <Box pb={4} pt={2}>
        <form noValidate autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
            <Grid container spacing={2} alignContent="space-between">
              <Grid item md={6}>
                <DebounceInput
                  minLength={3}
                  debounceTimeout={400}
                  element={TextField}
                  value={filters.searchText}
                  onChange={e => setFilter("searchText", e.target.value)}
                  label="Filtrar"
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="action" />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Data inicial"
                  value={filters.initialDate}
                  onChange={date => onChangeDate(date, "initialDate")}
                  KeyboardButtonProps={{
                    "aria-label": "Selecione a data inicial"
                  }}
                  fullWidth
                  invalidDateMessage="Data inválida"
                />
              </Grid>
              <Grid item md={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Data final"
                  value={filters.finalDate}
                  onChange={date => onChangeDate(date, "finalDate")}
                  KeyboardButtonProps={{
                    "aria-label": "Selecione a data final"
                  }}
                  fullWidth
                  invalidDateMessage="Data inválida"
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      </Box>
      <Table aria-label="simple table">
        <TableBody>
          {data.map(dataItem => {
            const { destinationFilter } = dataItem;
            const studentsClassInfo = () => {
              if (destinationFilter) {
                const { students } = destinationFilter;
                return [{ students }];
              }
              return undefined;
            };
            let statusText;
            let statusIcon;
            let statusColor;
            switch (dataItem.status) {
              case enums.status.OPEN:
                statusText = "Em aberto";
                statusIcon = <RadioButtonUncheckedIcon />;
                statusColor = "#727272";
                break;
              case enums.status.SENT:
                statusText = "Enviado";
                statusIcon = <CheckCircleOutlineIcon />;
                statusColor = theme.palette.primary.main;
                break;
              case enums.status.SCHEDULED:
                statusText = "Agendado";
                statusIcon = <AccessTimeIcon />;
                statusColor = theme.palette.secondary.main;
                break;
              default:
            }

            if (dataItem.deleted_at) {
              statusText = "Deletado";
              statusIcon = <HighlightOffIcon />;
              statusColor = theme.palette.error.main;
            }

            return (
              <TableRow
                key={dataItem.id}
                hover
                onClick={() =>
                  ability.can("view", "activity") && onSelectData(dataItem)
                }
              >
                <TableCell className="title" align="left">
                  <h5>{dataItem.title}</h5>
                  <span>
                    {dataItem.status === enums.status.SCHEDULED
                      ? moment(dataItem.schedule).format(
                          "[Agendado para ]DD/MM/YYYY[ às ]HH:mm"
                        )
                      : moment(dataItem.createdAt).format(
                          "DD/MM/YYYY[ às ]HH:mm"
                        )}
                  </span>
                </TableCell>
                <TableCell className="qtde" align="center">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body1">
                        {destinationFilter
                          ? destinationFilter.students.courses.length
                          : 0}
                      </Typography>
                    </Grid>

                    <Grid item>
                      {destinationFilter ? (
                        <HoverPopover
                          id={dataItem.id}
                          value={
                            <CoursesInfoPopover groups={studentsClassInfo()} />
                          }
                        >
                          <GroupWork fontSize="large" color="action" />
                        </HoverPopover>
                      ) : (
                        <GroupWork fontSize="large" color="action" />
                      )}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className="qtde" align="center">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body1">
                        {dataItem.subscribers ? dataItem.subscribers.length : 0}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <PeopleIcon fontSize="large" color="action" />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className="status" align="right" color={statusColor}>
                  <Grid
                    container
                    direction="row-reverse"
                    alignItems="center"
                    justify="right"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body1">{statusText}</Typography>
                    </Grid>
                    <Grid item>{statusIcon}</Grid>
                  </Grid>
                </TableCell>
                <TableCell className="more" align="center" color={statusColor}>
                  <Grid
                    container
                    direction="row-reverse"
                    alignItems="center"
                    justify="right"
                    spacing={1}
                  >
                    {dataItem.status === enums.status.OPEN && (
                      <Grid item>
                        <MoreButton actions={activityActions(dataItem)} />
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ConfirmDialog
        title="Enviar atividade"
        message="Tem certeza que deseja enviar essa atividade agora?"
        onClose={() => setOpenSendData(false)}
        onCancel={() => setOpenSendData(false)}
        onConfirm={() => sendActivity(sendSelectId)}
        confirmBtnText="Enviar"
        cancelBtnText="Voltar"
        open={openSendData}
      />
      <SelectScheduleDialog
        open={openSchedule}
        onClose={() => setOpenSchedule(false)}
        value={schedule}
        onChange={value => setSchedule(value)}
        onConfirm={() => scheduleActivity(scheduleSelectId)}
        title="Agendamento de Atividade"
        message="Escolha a data e hora para a atividade ser enviada"
      />
    </Box>
  );
};

ActivityList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  filters: PropTypes.shape({
    searchText: PropTypes.string.isRequired,
    initialDate: PropTypes.instanceOf(Date),
    finalDate: PropTypes.instanceOf(Date)
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // Injected by withTheme Hoc
  onSelectData: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // Injected by withAlert hov
};

export default withAlert()(withTheme(ActivityList));
