import axios from "axios";
import UserStore from "./UserStore";

const $apiUrl = process.env.REACT_APP_API_URL;
const appIdentifier =
  process.env.REACT_APP_PACKAGE_NAME || "com.waybee.apps.communication";

const urlApi = {
  value: `${$apiUrl}api/`
};

const api = axios.create({
  baseURL: urlApi.value,
  responseType: "json",
  headers: { "Content-Type": "application/json" },
  timeout: 40000
});

api.interceptors.request.use(config => {
  const accessToken = UserStore.getToken();
  const campusIdentifier = UserStore.getUserCampusId();

  config.headers["app-identifier"] = appIdentifier; // eslint-disable-line no-param-reassign

  if (campusIdentifier) {
    config.headers["campus-identifier"] = campusIdentifier; // eslint-disable-line no-param-reassign
  }

  if (accessToken) {
    config.headers.Authorization = accessToken; // eslint-disable-line no-param-reassign
  }

  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Executa redirecionamento a não ser que o erro 401 venha de uma tentativa de login.
    if (error && error.response && error.response.status === 401) {
      const { responseURL } = error.response.request;
      if (!responseURL.includes("Authenticate")) {
        UserStore.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export { api };
export default urlApi;
