import { api } from "../utils/api";
import UserStore from "../utils/UserStore";

const getCoursesAndClasses = async year => {
  const schoolId = UserStore.getUserSchoolId();
  const response = await api.get("SchoolGroups/getCoursesAndClasses", {
    params: {
      schoolId,
      year,
      additionalInfo: { serie: true }
    }
  });
  return response.data.response;
};

const getDestinationUsers = async (
  courseIds,
  classesIds,
  filter,
  nameFilter
) => {
  const schoolId = UserStore.getUserSchoolId();
  const courses = courseIds.join();
  const classes = classesIds.join();
  let series = [];
  if (filter && filter.series && filter.series.length)
    series = filter.series.map(serie => serie.CODIGO);

  const response = await api.get("SchoolGroups/getDestinationUsers", {
    params: { schoolId, courses, classes, series, filter, nameFilter }
  });
  const data = response.data.response;
  return data;
};

const getDestinationEmployees = async (courseIds, classesIds, typesIds) => {
  const schoolId = UserStore.getUserSchoolId();
  const courses = courseIds.join();
  const classes = classesIds.join();
  const types = typesIds.join();
  const res = await api.get("SchoolGroups/getDestinationEmployees", {
    params: { schoolId, courses, classes, types }
  });

  return res.data;
};

const getFilters = async () => {
  const res = await api.get("SchoolGroups/availableStudentFilters");
  return res.data && res.data.response;
};

const getEmployees = async (filter = {}) => {
  const schoolId = UserStore.getUserSchoolId();
  const response = await api.get("SchoolGroups/getEmployees", {
    params: { schoolId, filter }
  });
  const data = response.data.response;
  return data;
};

const getEmployeesByGroupIds = async (groupIds = []) => {
  if (!groupIds.length) {
    throw new Error("Lista de grupos inválida!");
  }
  const schoolId = UserStore.getUserSchoolId();

  const response = await api.get("SchoolGroups/getEmployeesByGroupIds", {
    params: { schoolId, groupIds }
  });

  return response.data;
};

const getEmployeesGroups = async (filter = {}) => {
  const schoolId = UserStore.getUserSchoolId();
  const response = await api.get("SchoolGroups/getEmployeesGroups", {
    params: { schoolId, filter }
  });
  const data = response.data.response;
  return data;
};

export default {
  getCoursesAndClasses,
  getDestinationUsers,
  getFilters,
  getDestinationEmployees,
  getEmployees,
  getEmployeesGroups,
  getEmployeesByGroupIds
};
