import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { Description as DescriptionIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import Attachments from "../../../components/Attachments";

const GroupContent = ({ data }) => {
  return (
    <Group>
      <Box minHeight={380}>
        <Heading level={2} icon={DescriptionIcon} gutterBottom>
          Conteúdo
        </Heading>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="caption">Título</Typography>
            <Typography variant="body1" gutterBottom>
              {data.title}
            </Typography>
          </Grid>
        </Grid>

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: data.bodyMessage }}
          gutterBottom
        />

        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="caption">Lição de casa</Typography>
          </Grid>
        </Grid>

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: data.bodyHomeActivity }}
          gutterBottom
        />

        <Grid>
          {data.attachments.length !== 0 && (
            <Fragment>
              <Typography variant="caption">Anexos</Typography>
              <Attachments attachments={data.attachments} />
            </Fragment>
          )}
        </Grid>
      </Box>
    </Group>
  );
};

GroupContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
};

export default GroupContent;
