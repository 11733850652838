import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button as MuiButton
} from "@material-ui/core";
import styled from "styled-components";
import SchoolGroupService from "../../services/SchoolGroupService";
import Button from "../../waybee-ui/Button";
import ReceiversSelector from "./components/ReceiversSelector";
import MuiCollapseGroup from "../../waybee-ui/CollapseGroup/CollapseGroup";

import UserStore from "../../utils/UserStore";

const myAccess = UserStore.getAccess();

const SelectAllButton = styled(MuiButton)`
  text-decoration: underline;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const CollapseGroup = styled(MuiCollapseGroup)`
  margin: 0 -20px;
`;

class ModalReceiversSelector extends Component {
  state = {
    students: [],
    employees: [],
    initialStudents: [],
    initialEmployees: [],
    isLoading: false
  };

  loadStudents = async () => {
    this.setState({ isLoading: true });
    const {
      studentCourses,
      studentClasses,
      employeeClasses,
      employeeCourses,
      employeeTypes,
      excludedIds,
      years
    } = this.props;

    const studentCourseIds = studentCourses.map(e => e.CODIGO);
    const studentClassIds = studentClasses.map(e => e.CODIGO);
    let filteredStudents = [];
    const filter = years.length ? { years } : {};
    if (studentCourseIds.length) {
      const students = await SchoolGroupService.getDestinationUsers(
        studentCourseIds,
        studentClassIds,
        filter
      );
      let excludedIdsList = [];
      if (excludedIds) excludedIdsList = excludedIds.split(",");
      filteredStudents = students.map(student => {
        const { students: currentStudents } = this.state;
        const existentStudentId = currentStudents.findIndex(
          e => e.id === student.id
        );

        let checked = true;
        excludedIdsList.forEach(id => {
          if (parseFloat(id) === student.id) {
            checked = false;
          }
        });

        if (existentStudentId >= 0) {
          currentStudents[existentStudentId].checked = checked;
          return currentStudents[existentStudentId];
        }

        return {
          photoProfile: student.photoProfile,
          name: student.name,
          id: student.id,
          checked
        };
      });
    }

    const employeeCoursesIds = employeeCourses.map(e => e.CODIGO);
    const employeeClassIds = employeeClasses.map(e => e.CODIGO);
    const employeeTypesIds = employeeTypes.map(e => e.CODIGO);
    let filteredEmployees = [];
    if (employeeCoursesIds.length || employeeTypesIds.length) {
      const employees = await SchoolGroupService.getDestinationEmployees(
        employeeCoursesIds,
        employeeClassIds,
        employeeTypesIds
      );
      filteredEmployees = employees.map(employee => {
        const { employees: currentEmployees } = this.state;
        const existentEmployeeId = currentEmployees.findIndex(
          e => e.id === employee.id
        );
        if (existentEmployeeId >= 0) {
          return currentEmployees[existentEmployeeId];
        }
        return {
          photoProfile: employee.photoProfile,
          name: employee.name,
          id: employee.id,
          checked: true
        };
      });
    }

    this.setState({
      students: filteredStudents,
      employees: filteredEmployees,
      initialStudents: this.cloneStudents(filteredStudents),
      initialEmployees: this.cloneStudents(filteredEmployees),
      isLoading: false
    });
  };

  handleOpen = () => {
    this.loadStudents();
  };

  handleClose = () => {
    const { initialStudents, initialEmployees } = this.state;
    const { onClose } = this.props;
    onClose();
    this.setState({
      students: this.cloneStudents(initialStudents),
      employees: this.cloneStudents(initialEmployees)
    });
  };

  handleSubmit = () => {
    const { setSubscribers, onClose } = this.props;
    const { students, employees } = this.state;
    setSubscribers("studentSubscribers", students);
    setSubscribers("employeeSubscribers", employees);

    const allSubscribers = students.concat(employees);

    const excludedDestinationIds = allSubscribers
      .filter(e => e.checked === false)
      .map(e => e.id)
      .toString();

    setSubscribers("excludedDestinationIds", excludedDestinationIds);

    onClose();
  };

  cloneStudents = students => {
    return JSON.parse(JSON.stringify(students));
  };

  handleToggle = (id, checked, type) => {
    this.setState(prevState => {
      const stateStudents = prevState[type];
      const indexStudent = stateStudents.findIndex(
        student => student.id === id
      );
      stateStudents[indexStudent].checked = checked;
      return { [type]: stateStudents };
    });
  };

  selectAll = (users, type, cheked) => {
    const newUsers = users.map(user => {
      const filteredUser = { ...user };
      filteredUser.checked = cheked;
      return filteredUser;
    });

    this.setState({ [type]: newUsers });
  };

  render() {
    const { students, employees, isLoading } = this.state;
    const { isOpen } = this.props;

    const countCheckedStudents = students.filter(e => e.checked).length;
    const countCheckedEmployees = employees.filter(e => e.checked).length;
    const countCheckedUser = countCheckedStudents + countCheckedEmployees;

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        onEnter={this.handleOpen}
        maxWidth="xl"
      >
        <DialogTitle onClose={this.handleClose}>
          Gerenciar destinatários
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Desmarque os remetentes de cada grupo que NÃO receberão esta
              comunicação
            </Typography>
          </Box>
          <Box py={2}>
            {students.length > 0 && (
              <>
                <CollapseGroup divider={false} fontSize={16} title="Alunos">
                  <ReceiversSelector
                    users={students}
                    onToggleStudent={(id, check) =>
                      this.handleToggle(id, check, "students")
                    }
                    isLoading={isLoading}
                  />
                </CollapseGroup>
                <Box textAlign="right" mt={1}>
                  <SelectAllButton
                    onClick={() =>
                      this.selectAll(
                        students,
                        "students",
                        countCheckedStudents < students.length
                      )
                    }
                  >
                    {countCheckedStudents === students.length
                      ? "Desmarcar Todos"
                      : "Marcar Todos"}
                  </SelectAllButton>
                </Box>
              </>
            )}

            {employees.length > 0 && (
              <>
                <CollapseGroup
                  divider={false}
                  fontSize={16}
                  title="Professores/Coordenadores"
                >
                  <ReceiversSelector
                    users={employees}
                    disabled={!myAccess.classDiary}
                    onToggleStudent={(id, check) =>
                      this.handleToggle(id, check, "employees")
                    }
                    isLoading={isLoading}
                  />
                </CollapseGroup>
                {myAccess.classDiary && (
                  <Box textAlign="right" mt={1}>
                    <SelectAllButton
                      onClick={() =>
                        this.selectAll(
                          employees,
                          "employees",
                          countCheckedEmployees < employees.length
                        )
                      }
                    >
                      {countCheckedEmployees === employees.length
                        ? "Desmarcar Todos"
                        : "Marcar Todos"}
                    </SelectAllButton>
                  </Box>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleClose}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleSubmit}
            color="primary"
            disabled={countCheckedUser === 0}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalReceiversSelector.defaultProps = {
  excludedIds: "",
  employeeClasses: [],
  employeeCourses: [],
  employeeTypes: [],
  years: []
};

ModalReceiversSelector.propTypes = {
  studentClasses: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ).isRequired,
  studentCourses: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ).isRequired,
  employeeClasses: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ),
  employeeCourses: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ),
  employeeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ),
  years: PropTypes.arrayOf(),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSubscribers: PropTypes.func.isRequired,
  excludedIds: PropTypes.string
};

export default ModalReceiversSelector;
