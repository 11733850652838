import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import WuiButton from "../../../waybee-ui/Button";
import Dialog from "../../../waybee-ui/Dialog";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import DialogActions from "../../../waybee-ui/Dialog/DialogActions";

const Button = styled(WuiButton)`
  min-width: 300px;
`;

const DialogEdit = ({ open, onClose, onSend, user }) => {
  const { register, getValues } = useForm({
    defaultValues: {
      ...user
    }
  });

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth closeButton>
      <DialogTitle onClose={onClose}>Editar detalhes</DialogTitle>

      <DialogContent>
        <Box mt={1} mb={3}>
          <Typography variant="subtitle1">
            Altere os detalhes do cadastro do responsável e clique em Salvar.
          </Typography>
        </Box>

        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField {...register("name")} label="Nome no app" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("email")}
                label="E-mail cadastrado no app"
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={6}> */}
            {/*  <TextField */}
            {/*    {...register("documentNumber")} */}
            {/*    label="CPF" */}
            {/*    fullWidth */}
            {/*  /> */}
            {/* </Grid> */}
            <Grid item xs={6}>
              <TextField
                {...register("phone")}
                label="Número de contato"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          autoFocus
          onClick={() => onSend(getValues())}
          color="primary"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogEdit.defaultProps = {
  user: {}
};

DialogEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    documentNumber: PropTypes.string,
    phone: PropTypes.string
  })
};

export default DialogEdit;
