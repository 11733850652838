import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import AccessionStatus from "./AccessionStatus";
import UserItem from "./UserItem";

const Description = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 20px;

  && {
    margin-top: 6px;
  }
`;

const AccessionStudentItem = ({ user, photoSize, hasStatus, onClick }) => {
  return (
    <UserItem
      key={user.id}
      onClick={onClick}
      name={user.name}
      description={user.description}
      photoSize={photoSize}
      photoUrl={user.photo}
      info={`RA: ${user.id}`}
      rightInfo={
        hasStatus ? (
          <AccessionStatus status={user.status} />
        ) : (
          <Grid item container justifyContent="flex-end" xs={3}>
            <Description>{user.period}</Description>
          </Grid>
        )
      }
    />
  );
};

AccessionStudentItem.defaultProps = {
  onClick: null,
  photoSize: 70,
  hasStatus: false
};

AccessionStudentItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    period: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func,
  photoSize: PropTypes.number,
  hasStatus: PropTypes.bool
};

export default AccessionStudentItem;
