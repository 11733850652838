import React from "react";
import PropTypes from "prop-types";
import { withTheme, Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import RoundedAvatar from "../waybee-ui/RoundedAvatar";
import AccessionStatus from "./AccessionStatus";

const Row = styled(Grid)`
  padding: 18px 30px;
  border-top: 2px solid #f2f2f2;

  &:last-of-type {
    border-bottom: 2px solid #f2f2f2;
  }

  ${({ hasClick }) =>
    hasClick &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${({ color }) => color};
      }
    `}
`;

const User = styled(Grid)`
  display: flex;
  align-items: center;
`;
const UserInfos = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;

  p {
    margin: 0;
  }
`;
const UserName = styled.p`
  color: black;
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
`;
const UserBond = styled.p`
  color: ${({ color }) => color};
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;

  && {
    margin-top: 6px;
  }
`;
const UserBondName = styled.span`
  color: rgba(0, 0, 0, 0.5);
`;

const Dependents = styled(Grid)`
  display: flex;
  flex-direction: column;
`;
const DependentInfo = styled.div`
  margin: 12px 0;

  p {
    margin: 0;
  }
`;
const DependentDescription = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 20px;

  && {
    margin-top: 6px;
  }
`;

const AccessionResponsibleItem = ({
  user,
  photoSize,
  onClick,
  hideDependents,
  theme
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  const dependents = user.dependents || [];

  return (
    <Row
      key={user.id}
      hasClick={!!onClick}
      color={theme.palette.primary.light}
      onClick={handleClick}
      container
    >
      <User item xs={hideDependents ? 9 : 5}>
        <RoundedAvatar size={photoSize} icon="person" imageSrc={user.photo}>
          {user.name}
        </RoundedAvatar>
        <UserInfos>
          <UserName>{user.name}</UserName>
          {dependents[0] !== null &&
            dependents.map(
              dependent =>
                dependent.bond && (
                  <UserBond
                    key={dependent.id}
                    color={theme.palette.primary.main}
                  >
                    {dependent.bond}
                    <UserBondName>
                      (
                      {dependent.name}
                      )
                    </UserBondName>
                  </UserBond>
                )
            )}
        </UserInfos>
      </User>
      {!hideDependents && (
        <Dependents item xs={4}>
          {dependents[0] !== null &&
            dependents.map(dependent => (
              <DependentInfo key={dependent.id}>
                <UserName>{dependent.name}</UserName>
                <DependentDescription>
                  {dependent.description}
                </DependentDescription>
              </DependentInfo>
            ))}
        </Dependents>
      )}
      <AccessionStatus status={user.status} />
    </Row>
  );
};

AccessionResponsibleItem.defaultProps = {
  onClick: null,
  hideDependents: false,
  photoSize: 70
};

AccessionResponsibleItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    status: PropTypes.string,
    dependents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        bond: PropTypes.string,
        description: PropTypes.string
      })
    )
  }).isRequired,
  onClick: PropTypes.func,
  photoSize: PropTypes.number,
  hideDependents: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // inject by withTheme
};

export default withTheme(AccessionResponsibleItem);
