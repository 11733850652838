import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button as MuiButton,
  FormLabel,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import SchoolGroupService from "../../../services/SchoolGroupService";
import Button from "../../../waybee-ui/Button";
import ReceiversSelector from "./ReceiversSelector";
import MuiCollapseGroup from "../../../waybee-ui/CollapseGroup/CollapseGroup";
import ReceiversList from "./ReceiversList";

const SelectAllButton = styled(MuiButton)`
  text-decoration: underline;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const CollapseGroup = styled(MuiCollapseGroup)`
  margin: 0 -20px;
`;

const LimitHeight = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

class DestinationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      initialUsers: [],
      isLoading: false,
      searchedUsers: [],
      helperText: ""
    };
  }

  getDestinationUser = (excludedIdsList, users) => {
    return users.map(user => {
      let checked = true;
      excludedIdsList.forEach(id => {
        if (parseFloat(id) === user.id) {
          checked = false;
        }
      });
      return {
        photoProfile: user.photoProfile,
        studentId: user.studentId,
        name: user.name,
        id: user.id,
        checked
      };
    });
  };

  loadUsers = async () => {
    this.setState({ isLoading: true });
    const { simpleFilter, advancedFilter } = this.props;
    const {
      destinationType,
      employeeClasses,
      employeeCourses,
      employeeTypes,
      studentCourses,
      studentClasses,
      excludedIds
    } = simpleFilter;

    const studentCourseIds = studentCourses.map(e => e.CODIGO);
    const studentClassIds = studentClasses.map(e => e.CODIGO);

    const employeeCourseIds = employeeCourses.map(e => e.CODIGO);
    const employeeClassIds = employeeClasses.map(e => e.CODIGO);
    const employeeTypesIds = employeeTypes.map(e => e.CODIGO);

    let destinationUser = [];
    let filteredUsers = [];
    if (destinationType === "employees" && employeeCourseIds.length) {
      destinationUser = await SchoolGroupService.getDestinationEmployees(
        employeeCourseIds,
        employeeClassIds,
        employeeTypesIds
      );
    }
    if (destinationType !== "employees" && studentCourseIds.length) {
      destinationUser = await SchoolGroupService.getDestinationUsers(
        studentCourseIds,
        studentClassIds,
        advancedFilter
      );
    }

    let excludedIdsList = [];
    if (excludedIds) excludedIdsList = excludedIds.split(",");
    filteredUsers = this.getDestinationUser(excludedIdsList, destinationUser);

    this.setState({
      users: filteredUsers,
      initialUsers: this.cloneUsers(filteredUsers),
      isLoading: false
    });
  };

  handleOpen = () => {
    this.loadUsers();
  };

  handleClose = () => {
    const { initialUsers } = this.state;
    const { onClose } = this.props;
    onClose();
    this.searchByName("");
    this.setState({
      users: this.cloneUsers(initialUsers),
      searchedUsers: []
    });
  };

  handleSubmit = () => {
    const { setSubscribers, onClose, isConfirm, onConfirm } = this.props;

    if (isConfirm) return onConfirm();

    const { users } = this.state;
    setSubscribers("studentSubscribers", users);

    const excludedDestinationIds = users
      .filter(e => e.checked === false)
      .map(e => e.id)
      .toString();

    setSubscribers("excludedDestinationIds", excludedDestinationIds);

    return onClose();
  };

  cloneUsers = users => {
    return JSON.parse(JSON.stringify(users));
  };

  handleToggle = (id, checked) => {
    this.setState(prevState => {
      const stateUsers = prevState.users;
      const indexUser = stateUsers.findIndex(user => user.id === id);
      stateUsers[indexUser].checked = checked;
      return { users: stateUsers };
    });
  };

  selectAll = (users, cheked) => {
    const newUsers = users.map(user => {
      const filteredUser = { ...user };
      filteredUser.checked = cheked;
      return filteredUser;
    });

    this.setState({ users: newUsers });
  };

  searchByName = value => {
    const { users } = this.state;
    const filterValue = value.toLowerCase();
    const searchedUsers = users.filter(user => {
      const userName = user.name.toLowerCase();
      const userRa = user.studentId || "";
      this.setState({ helperText: "" });

      return userName.includes(filterValue) || userRa.includes(filterValue);
    });

    if (searchedUsers.length === 0)
      this.setState({ helperText: "Nenhum usuário encontrado" });

    this.setState({ searchedUsers });
  };

  renderUsers = checkedUser => {
    const { searchedUsers, users } = this.state;
    const { isConfirm } = this.props;

    if (searchedUsers.length > 0) return searchedUsers;
    if (isConfirm) return checkedUser;

    return users;
  };

  render() {
    const { users, isLoading, helperText } = this.state;
    const {
      isOpen,
      alert,
      isConfirm,
      confirmBtnText,
      simpleFilter,
      isResend
    } = this.props;

    const unCheckedUser = users.filter(e => !e.checked);
    const checkedUser = users.filter(e => e.checked);
    const countCheckedUsers = users.filter(e => e.checked).length;
    const countCheckedUser = countCheckedUsers;

    const title = isConfirm ? "Confirmar " : "Gerenciar ";
    const destinations =
      simpleFilter.destinationType === "employees" ? "Funcionários" : "Alunos";

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        onEnter={this.handleOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle onClose={this.handleClose}>
          {isConfirm && isResend ? "Novos " : title}
          {' '}
          destinatários
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            {alert}
            {!isConfirm && (
              <Typography variant="subtitle1">
                Desmarque os remetentes de cada grupo que NÃO receberão esta
                comunicação
              </Typography>
            )}
            {isResend && (
              <Typography variant="subtitle1">
                Lista de destinatários de cada grupo que vão receber essa cópia
              </Typography>
            )}
          </Box>
          <TextField
            label="Filtrar por Nome/RA"
            onChange={e => this.searchByName(e.target.value)}
            helperText={helperText}
          />
          <Box py={2}>
            {isLoading && <CircularProgress />}

            {!isLoading && users.length > 0 && (
              <>
                <CollapseGroup
                  startOpened
                  divider={false}
                  fontSize={16}
                  title={isResend ? "Destinatários  " : destinations}
                >
                  <LimitHeight>
                    <ReceiversSelector
                      isResend={isResend}
                      users={this.renderUsers(checkedUser)}
                      disabled={isConfirm}
                      onToggleUser={(id, check) => this.handleToggle(id, check)}
                      isLoading={isLoading}
                    />
                  </LimitHeight>
                </CollapseGroup>
                {!isConfirm && (
                  <Box textAlign="right" mt={1}>
                    <SelectAllButton
                      onClick={() =>
                        this.selectAll(users, countCheckedUsers < users.length)
                      }
                    >
                      {countCheckedUsers === users.length
                        ? "Desmarcar Todos"
                        : "Marcar Todos"}
                    </SelectAllButton>
                  </Box>
                )}

                {isConfirm && unCheckedUser.length > 0 && (
                  <Box my={1} mb={3} mt={2}>
                    <FormLabel>
                      Destinatários retirados da comunicação
                    </FormLabel>
                    <CollapseGroup
                      divider={false}
                      fontSize={16}
                      title={isResend ? "Destinatários" : destinations}
                    >
                      <ReceiversList users={unCheckedUser} />
                    </CollapseGroup>
                  </Box>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleClose}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleSubmit}
            color="primary"
            disabled={countCheckedUser === 0}
          >
            {confirmBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DestinationModal.defaultProps = {
  advancedFilter: {},
  alert: "",
  isConfirm: false,
  onConfirm: null,
  isResend: false,
  confirmBtnText: "Salvar"
};

DestinationModal.propTypes = {
  simpleFilter: PropTypes.shape({
    studentClasses: PropTypes.arrayOf(
      PropTypes.shape({
        CODIGO: PropTypes.string
      })
    ).isRequired,
    studentCourses: PropTypes.arrayOf(
      PropTypes.shape({
        CODIGO: PropTypes.string
      })
    ).isRequired,
    employeeTypes: PropTypes.arrayOf(
      PropTypes.shape({
        CODIGO: PropTypes.string
      })
    ).isRequired,
    employeeClasses: PropTypes.arrayOf(
      PropTypes.shape({
        CODIGO: PropTypes.string
      })
    ).isRequired,
    employeeCourses: PropTypes.arrayOf(
      PropTypes.shape({
        CODIGO: PropTypes.string
      })
    ).isRequired,
    excludedIds: PropTypes.string,
    destinationType: PropTypes.string
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSubscribers: PropTypes.func.isRequired,
  advancedFilter: PropTypes.shape({}),
  alert: PropTypes.func,
  isConfirm: PropTypes.bool,
  onConfirm: PropTypes.func,
  isResend: PropTypes.bool,
  confirmBtnText: PropTypes.string
};

export default DestinationModal;
