import React, { Component } from "react";
import PropTypes from "prop-types";
import MaterialActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Send as SendIcon,
  StarBorder as StarBorderIcon
} from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import moment from "@moment";
import { theme } from "../../../../../assets/theme/StyledTheme";

const styles = {
  actionButton: {
    width: "50%",
    height: "100%",
    color: "#AAA",
    fontSize: "14px",
    fontWeight: 400,
    paddingLeft: "6px",
    paddingRight: "2px",
    maxWidth: "calc(100% - 42px)",
    textAlign: "left",
    lineHeight: "1.5em"
  },
  activeActionButton: {
    color: theme.color.primary.dark
  },
  ActionIcons: {
    fontSize: "24px",
    marginTop: "-3px",
    marginLeft: 0
  },
  extraButton: {
    backgroundColor: theme.color.primary.main,
    color: "#FFF",
    borderRadius: "20px"
  },
  extraButtonContainer: {
    padding: "0 40px 12px"
  },
  defaultFooter: {
    minHeight: 36.5
  }
};

class DialogActions extends Component {
  formatDateHeader = date => {
    const headerDateFormat = "DD/MM/YYYY - [às] HH:mm";
    return moment(date).format(headerDateFormat);
  };

  render() {
    const {
      type,
      hadInterest,
      confirmedPresence,
      onClickInterest,
      onClickPresence,
      onClickMessage,
      chatIsEnabled
    } = this.props;

    return (
      <div>
        {chatIsEnabled && (
          <div>
            <Divider />
            <MaterialActions classes={{ root: "card-dialog-actions" }}>
              <Button
                variant="contained"
                className="send-button"
                label="Enviar Mensagem"
                fullWidth
                icon={<SendIcon color="#018675" />}
                onClick={onClickMessage}
              />
            </MaterialActions>
          </div>
        )}
        {type === 2 && (
          <div>
            <Divider />
            <MaterialActions classes={{ root: "card-dialog-actions" }}>
              <Button
                className={`action-button${hadInterest}` ? " active" : ""}
                onClick={onClickInterest}
                startIcon={<StarBorderIcon fontSize="large" />}
                style={{
                  ...styles.actionButton,
                  ...(hadInterest ? styles.activeActionButton : {})
                }}
                labelStyle={styles.actionButtonText}
              >
                Tenho Interesse
              </Button>
              <Button
                className={`action-button${confirmedPresence}` ? " active" : ""}
                onClick={onClickPresence}
                startIcon={<CheckCircleOutlineIcon fontSize="large" />}
                style={{
                  ...styles.actionButton,
                  ...(confirmedPresence ? styles.activeActionButton : {})
                }}
                labelStyle={styles.actionButtonText}
              >
                Confirmar Presença
              </Button>
            </MaterialActions>
          </div>
        )}
        {!chatIsEnabled && type !== 2 && (
          <div>
            <Divider />
            <div style={styles.defaultFooter} />
          </div>
        )}
      </div>
    );
  }
}

DialogActions.propTypes = {
  chatIsEnabled: PropTypes.bool.isRequired,
  confirmedPresence: PropTypes.bool.isRequired,
  hadInterest: PropTypes.bool.isRequired,
  onClickInterest: PropTypes.func.isRequired,
  onClickMessage: PropTypes.func.isRequired,
  onClickPresence: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired
};

export default DialogActions;
