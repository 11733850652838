import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
// services
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { AddCircleOutline, History as HistoryIcon } from "@material-ui/icons";
import styled from "styled-components";
import _ from "lodash";
import ActivitiesService from "../../services/ActivitiesService";
import Group from "../../waybee-ui/Group";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import Heading from "../../waybee-ui/Heading/Heading";
import { AbilityContext, Can } from "../../acl/can";
import ActivityList from "./components/ActivityList";

const ListMessage = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  text-align: center;
`;

class ActivitiesDashboard extends Component {
  state = {
    data: [],
    filteredData: [],
    dataLoading: false,
    hasAllData: false,
    dataCount: 0,
    filters: {
      searchText: "",
      initialDate: null,
      finalDate: null
    },
    dataSelected: null,
    noAccessError: false
  };

  componentDidMount() {
    const { context } = this;

    if (context.can("list", "activity")) {
      const { data } = this.state;

      if (!data.length) {
        this.getData().then(newData =>
          this.setState(() => ({ data: newData }))
        );
      }

      window.addEventListener("scroll", this.scrollEvent);

      this.setDataCount();
    }

    const { setTitle } = this.props;
    setTitle("Central de Atividades");
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEvent);
  }

  setFilter(field, value) {
    const { filters } = this.state;
    filters[field] = value;
    this.setState({ filters, filteredData: [], hasAllData: false }, () => {
      if (this.hasAnyFilter()) {
        this.getData().then(newData =>
          this.setState(() => ({ filteredData: newData }))
        );
      }
    });
  }

  getData = (countToSkip = 0) => {
    const { filters } = this.state;
    const { openAlert } = this.props;

    this.setState({ dataLoading: true });
    return ActivitiesService.getAllData(
      countToSkip,
      filters.searchText,
      filters.initialDate,
      filters.finalDate
    )
      .catch(e => {
        const error = _.get(e, "response.data.error");
        if (error && error.name === "no_class_access") {
          this.setState({ noAccessError: true });
        } else {
          openAlert({
            status: "error",
            message: e.message
          });
        }
        return [];
      })
      .finally(() => this.setState({ dataLoading: false }));
  };

  getMoreData() {
    const { data, filteredData, hasAllData, dataLoading } = this.state;
    if (hasAllData) return;
    if (dataLoading) {
      setTimeout(() => {
        this.getMoreData();
      }, 500);
      return;
    }

    this.getData(this.hasAnyFilter() ? filteredData.length : data.length).then(
      newData => {
        this.setState(prevState => {
          const typeData = this.hasAnyFilter() ? "filteredData" : "data";
          return {
            hasAllData: newData.length === 0,
            [typeData]: prevState[typeData].concat(newData)
          };
        });
      }
    );
  }

  setDataCount() {
    ActivitiesService.getAllDataCount().then(count =>
      this.setState({ dataCount: count })
    );
  }

  scrollEvent = () => {
    const { dataLoading } = this.state;
    const container = document.querySelector("html");
    if (
      !dataLoading &&
      container.scrollTop + window.innerHeight >= container.offsetHeight - 100
    ) {
      this.getMoreData();
    }
  };

  hasAnyFilter() {
    const { filters } = this.state;
    const { searchText, initialDate, finalDate } = filters;
    return !!(searchText.trim() || initialDate || finalDate);
  }

  selectData(data) {
    this.setState({ dataSelected: data });
  }

  render() {
    const {
      data,
      filteredData,
      dataLoading,
      filters,
      dataCount,
      dataSelected,
      noAccessError
    } = this.state;

    if (dataSelected) {
      return <Redirect to={`/activity/${dataSelected.id}`} />;
    }

    return (
      <Grid container spacing={2}>
        <Can I="create" a="activity">
          <Grid item xs={12}>
            <Group p={0}>
              <ButtonBlock
                startIcon={<AddCircleOutline />}
                component={Link}
                to="/activity"
              >
                Nova atividade
              </ButtonBlock>
            </Group>
          </Grid>
        </Can>
        <Can I="list" a="activity">
          <Grid item xs={12}>
            <Group>
              <Box>
                <Heading level={2} icon={HistoryIcon} gutterBottom>
                  {`Histórico de Atividades (${dataCount})`}
                </Heading>

                <ActivityList
                  data={this.hasAnyFilter() ? filteredData : data}
                  filters={filters}
                  setFilter={(field, value) => this.setFilter(field, value)}
                  onSelectData={(...props) => this.selectData(...props)}
                />

                {noAccessError && (
                  <ListMessage>
                    Não podemos exibir nenhuma atividade pois não foram
                    encontrado vínculos deste usuário com nenhuma turma. Entre
                    em contato com um administrador do sistema para solicitar as
                    permissões necessárias
                  </ListMessage>
                )}

                {dataLoading && (
                  <Box p={6} textAlign="center">
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Group>
          </Grid>
        </Can>
      </Grid>
    );
  }
}

ActivitiesDashboard.contextType = AbilityContext;

ActivitiesDashboard.defaultProps = {
  openAlert: null
};

ActivitiesDashboard.propTypes = {
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.func
};

export default ActivitiesDashboard;
