import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button as MuiButton
} from "@material-ui/core";
import styled from "styled-components";
import SchoolGroupService from "../../services/SchoolGroupService";
import Button from "../../waybee-ui/Button";
import ReceiversSelector from "./components/ReceiversSelector";
import MuiCollapseGroup from "../../waybee-ui/CollapseGroup/CollapseGroup";

const SelectAllButton = styled(MuiButton)`
  text-decoration: underline;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const CollapseGroup = styled(MuiCollapseGroup)`
  margin: 0 -20px;
`;

class ModalReceiversSelector extends Component {
  state = {
    employees: [],
    initialEmployees: [],
    isLoading: false
  };

  loadEmployees = async () => {
    this.setState({ isLoading: true });
    const { employeeGroups } = this.props;

    let filteredEmployees = [];
    if (employeeGroups.length) {
      const employeeGroupIds = _.map(employeeGroups, group => group.CODIGO);

      const employees = await SchoolGroupService.getEmployeesByGroupIds(
        employeeGroupIds
      );

      filteredEmployees = employees.map(employee => {
        const { employees: currentEmployees } = this.state;
        const existentEmployeeId = currentEmployees.findIndex(
          e => e.id === employee.CODIGO
        );
        if (existentEmployeeId >= 0) {
          return currentEmployees[existentEmployeeId];
        }

        return {
          name: employee.NOME_COMPLETO,
          id: employee.CODIGO,
          checked: true
        };
      });
    }

    this.setState({
      employees: filteredEmployees,
      initialEmployees: this.cloneObject(filteredEmployees),
      isLoading: false
    });
  };

  handleOpen = () => {
    this.loadEmployees();
  };

  handleClose = () => {
    const { initialEmployees } = this.state;
    const { onClose } = this.props;
    onClose();
    this.setState({
      employees: this.cloneObject(initialEmployees)
    });
  };

  handleSubmit = () => {
    const { setSubscribers, onClose } = this.props;
    const { employees } = this.state;
    setSubscribers("employeeSubscribers", employees);
    onClose();
  };

  cloneObject = obj => {
    return JSON.parse(JSON.stringify(obj));
  };

  handleToggle = (id, checked, type) => {
    this.setState(prevState => {
      const stateEmployees = prevState[type];
      const indexEmployee = stateEmployees.findIndex(
        employee => employee.id === id
      );
      stateEmployees[indexEmployee].checked = checked;
      return { [type]: stateEmployees };
    });
  };

  selectAll = (users, type, cheked) => {
    const newUsers = users.map(user => {
      const filteredUser = { ...user };
      filteredUser.checked = cheked;
      return filteredUser;
    });

    this.setState({ [type]: newUsers });
  };

  render() {
    const { employees, isLoading } = this.state;
    const { isOpen } = this.props;

    const countCheckedEmployees = employees.filter(e => e.checked).length;

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        onEnter={this.handleOpen}
        maxWidth="xl"
      >
        <DialogTitle onClose={this.handleClose}>
          Gerenciar destinatários
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Desmarque os remetentes de cada grupo que NÃO receberão esta
              comunicação
            </Typography>
          </Box>
          <Box py={2}>
            {employees.length > 0 && (
              <>
                <CollapseGroup
                  divider={false}
                  fontSize={16}
                  title="Contribuidores"
                >
                  <ReceiversSelector
                    users={employees}
                    onToggle={(id, check) =>
                      this.handleToggle(id, check, "employees")
                    }
                    isLoading={isLoading}
                  />
                </CollapseGroup>
                <Box textAlign="right" mt={1}>
                  <SelectAllButton
                    onClick={() =>
                      this.selectAll(
                        employees,
                        "employees",
                        countCheckedEmployees < employees.length
                      )
                    }
                  >
                    {countCheckedEmployees === employees.length
                      ? "Desmarcar Todos"
                      : "Marcar Todos"}
                  </SelectAllButton>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleClose}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleSubmit}
            color="primary"
            disabled={countCheckedEmployees === 0}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalReceiversSelector.propTypes = {
  employeeGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSubscribers: PropTypes.func.isRequired
};

export default ModalReceiversSelector;
