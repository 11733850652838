import React, { Component } from "react";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import moment from "@moment";
import { theme } from "../../../../../assets/theme/StyledTheme";

const styles = {
  header: {
    fontFamily: "Roboto, Helvetica, sans-serif",
    padding: "14px 13px",
    height: "90px",
    boxSizing: "border-box"
  },
  icon: {
    position: "relative",
    width: "42px",
    height: "42px",
    boxSizing: "border-box",
    borderRadius: "50%",
    backgroundColor: "#E4E4E4",
    border: "1px solid #E4E4E4",
    margin: "3px 12px 10px 0",
    float: "left",
    overflow: "hidden"
  },
  schoolPhoto: {
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto"
  },
  title: {
    display: "flex",
    padding: "2px 0 0",
    maxWidth: "calc(100% - 52px)",
    height: 38,
    flexDirection: "column",
    justifyContent: "space-around"
  },
  titleText: {
    verticalAlign: "middle",
    fontSize: 16,
    fontWeight: 500,
    color: theme.color.secondary.dark,
    margin: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
  },
  date: {
    maxWidth: "calc(100% - 52px)",
    paddingTop: 4
  },
  dateText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#7f7f7f"
  }
};

class Header extends Component {
  formatDateHeader = date => {
    const headerDateFormat = "DD/MM/YYYY - [às] HH:mm";
    return moment(date).format(headerDateFormat);
  };

  render() {
    const { createdAt, schoolIcon, title } = this.props;

    const dateHeader = this.formatDateHeader(createdAt);

    return (
      <div>
        <header style={styles.header}>
          <div style={styles.icon}>
            <img alt="" style={styles.schoolPhoto} src={schoolIcon} />
          </div>
          <div style={styles.title}>
            <h3 style={styles.titleText}>{title}</h3>
          </div>
          <div style={styles.date}>
            <span style={styles.dateText}>{dateHeader}</span>
          </div>
        </header>
        <Divider />
      </div>
    );
  }
}

Header.propTypes = {
  createdAt: PropTypes.string.isRequired,
  schoolIcon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Header;
