import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  Details as DetailsIcon,
  People as PeopleIcon
} from "@material-ui/icons";
import moment from "@moment";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import Avatar from "../../../waybee-ui/Avatar";
import { enums } from "../../../services/CampaignsService";

const GroupDetails = ({ campaign, author, subscriberCount }) => {
  let statusText = "";
  switch (campaign.status) {
    case enums.status.OPEN:
      statusText = "Em aberto";
      break;
    case enums.status.SENT:
      statusText = "Enviado";
      break;
    case enums.status.SCHEDULED:
      statusText = "Agendado";
      break;
    default:
  }

  const sentHour =
    campaign.sentAt || campaign.schedule
      ? moment(campaign.sentAt || campaign.schedule).format("HH:mm")
      : "";

  const sentDate =
    campaign.sentAt || campaign.schedule
      ? moment(campaign.sentAt || campaign.schedule).format(
          "DD[ de ]MMMM[ de ]YYYY"
        )
      : "";

  return (
    <Group>
      <Box minHeight={380}>
        <Heading level={2} icon={DetailsIcon} gutterBottom>
          Detalhes
        </Heading>

        <Typography variant="caption">Data de envio</Typography>
        <Typography variant="body1">{sentDate}</Typography>
        <Typography variant="body1" gutterBottom>
          {sentHour}
        </Typography>

        <Typography variant="caption">Data de criação</Typography>
        <Typography variant="body1">
          {moment(campaign.createdAt).format("DD[ de ]MMMM[ de ]YYYY")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {moment(campaign.createdAt).format("HH:mm")}
        </Typography>

        <Typography variant="caption">Emissor</Typography>

        <Box display="flex">
          <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
            <Typography variant="body1" gutterBottom>
              {author.name}
            </Typography>
          </Avatar>
        </Box>

        {campaign.createdByExternalUser && (
          <Fragment>
            <Typography variant="caption">Criado por</Typography>
            <Box display="flex">
              <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
                <Typography variant="body1" gutterBottom>
                  {campaign.createdByExternalUser}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        {campaign.updatedByExternalUser && (
          <Fragment>
            <Typography variant="caption">Atualizado por</Typography>
            <Box display="flex">
              <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
                <Typography variant="body1" gutterBottom>
                  {campaign.updatedByExternalUser}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        <Typography variant="caption">Status</Typography>
        <Typography variant="body1" gutterBottom>
          {statusText}
        </Typography>

        <Typography variant="caption">Quantidade de destinatários</Typography>

        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <PeopleIcon fontSize="large" color="action" />
          </Grid>
          <Grid item>
            <Typography variant="body1">{subscriberCount}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Group>
  );
};

GroupDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  author: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  campaign: PropTypes.object.isRequired,
  subscriberCount: PropTypes.number.isRequired
};

export default GroupDetails;
