let ws = null;
const onMessageFunctions = [];

const open = async (url, token) => {
  return new Promise(resolve => {
    ws = new WebSocket(url);

    ws.onopen = () => {
      // Before requesting any method / subscription you have to send a connect message
      // https://docs.rocket.chat/api/realtime-api
      ws.send(
        JSON.stringify({
          msg: "connect",
          version: "1",
          support: ["1"]
        })
      );
      // Login user on RocketChat
      ws.send(
        JSON.stringify({
          msg: "method",
          method: "login",
          id: "1",
          params: [{ resume: token }]
        })
      );

      resolve();
    };

    // Handle received messages from rocket chat
    ws.onmessage = event => {
      const message = JSON.parse(event.data);

      // Keep connection alive - the server will send you "ping" and you must respond with "pong" otherwise the server will close the connection
      // https://docs.rocket.chat/api/realtime-api
      if (message.msg === "ping")
        return ws.send(JSON.stringify({ msg: "pong" }));

      onMessageFunctions.map(func => func(event));

      return event;
    };
  });
};

const close = () => {
  ws.close();
};

const sendLoadHistory = roomId => {
  // Load messages history from a room
  // https://docs.rocket.chat/api/realtime-api/method-calls/load-history
  // todo: understand why the rocketchat client uses HTTP for this method (any ws limitations ?)
  // todo: messages pagination
  ws.send(
    JSON.stringify({
      msg: "method",
      method: "loadHistory",
      id: "1",
      params: [
        roomId,
        null, // The NEWEST message timestamp date (or null) to only retrieve messages before this time. - this is used to do pagination
        1000, // The message quantity
        null // the date of the last time the client got data for the room
      ]
    })
  );
};

const sendSubRoom = roomId => {
  // Subscribes in a rooms to listen for its messages
  ws.send(
    JSON.stringify({
      msg: "sub",
      name: "stream-room-messages",
      id: "10",
      params: [roomId, false]
    })
  );
};

const sendUnsubRoom = () => {
  // Subscribes in a rooms to listen for its messages
  ws.send(
    JSON.stringify({
      msg: "unsub",
      id: "10"
    })
  );
};

const sendSubUser = userId => {
  // Subscription used to listen for room's unread messages counts
  ws.send(
    JSON.stringify({
      msg: "sub",
      name: "stream-notify-user",
      id: "101",
      params: [`${userId}/notification`]
    })
  );
};

const addOnMessageFunc = func => {
  if (!onMessageFunctions.includes(func)) onMessageFunctions.push(func);
};

export default {
  ws,
  open,
  close,
  sendLoadHistory,
  sendSubRoom,
  sendUnsubRoom,
  sendSubUser,
  addOnMessageFunc
};
