import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core/";
import styled from "styled-components";
import IconHeading from "./IconHeading";

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const getTypeByLevel = level => {
  switch (level) {
    case 1:
      return "h1";
    case 2:
      return "h2";
    case 3:
      return "h3";
    case 4:
      return "h4";
    case 5:
      return "h5";
    case 6:
      return "h6";
    default:
      return "span";
  }
};

const Heading = ({ children, level, icon, gutterBottom }) => {
  return (
    <Typography variant={getTypeByLevel(level)} gutterBottom={gutterBottom}>
      <Content>
        {icon && <IconHeading icon={icon} />}
        {children}
      </Content>
    </Typography>
  );
};

Heading.propTypes = {
  level: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object, // React object component
  gutterBottom: PropTypes.bool
};

Heading.defaultProps = {
  icon: null,
  gutterBottom: false
};

export default Heading;
