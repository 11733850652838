import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// Material-ui
import { Container, Hidden, withTheme } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import Heading from "../Heading";
import AppHeader from "./AppHeader";
import Sidebar from "../Sidebar";

const sidebarSize = 280;

const Content = styled.main`
  flex-grow: 1;
  padding: 20px;
  @media (min-width: ${({ breakpoints }) => breakpoints.values.lg}px) {
    margin-left: ${sidebarSize}px;
  }
`;

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
  }

  handleSidebarToggle = () => {
    const { mobileOpen } = this.state;
    this.setState({ mobileOpen: !mobileOpen });
  };

  render() {
    const { mobileOpen } = this.state;
    const {
      activeTitle,
      listMenu,
      children: content,
      userPhoto,
      userName,
      theme
    } = this.props;

    return (
      <div>
        <Sidebar
          size={sidebarSize}
          mobileOpen={mobileOpen}
          onClose={this.handleSidebarToggle}
          userPhoto={userPhoto}
          userName={userName}
        >
          {listMenu}
        </Sidebar>
        <AppHeader sidebarSize={sidebarSize}>
          <Hidden lgUp implementation="css">
            <AppHeader.IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleSidebarToggle}
            >
              <MenuIcon />
            </AppHeader.IconButton>
          </Hidden>
          <Heading level={1}>{activeTitle}</Heading>
        </AppHeader>
        <Content breakpoints={theme.breakpoints}>
          <Container maxWidth="lg" style={{ padding: 0 }}>
            {content}
          </Container>
        </Content>
      </div>
    );
  }
}

Toolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  listMenu: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  userPhoto: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  activeTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(Toolbar);
