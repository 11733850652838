import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Icon = styled.span`
  min-width: 29px;
  font-size: 1.2em;
  margin: -0.2em 21px -0.2em 0;
`;

const IconHeading = ({ icon }) => {
  return <Icon as={icon} />;
};

IconHeading.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object // React object component
};

IconHeading.defaultProps = {
  icon: null
};

export default IconHeading;
