import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  FormHelperText
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons";
import { withTranslation } from "react-i18next";

import Heading from "../../waybee-ui/Heading";
import Button from "../../waybee-ui/Button";

const Form = Styled.form`
  width: 100%;
  margin-top: 1rem;
`;

const LoginButton = Styled(Button)`
  max-width: 300px;
  margin: 50px auto 0 auto;
  display: block;
`;

function FormLogin(props) {
  const {
    email,
    password,
    handleChange,
    openForgotPassword,
    onSubmit,
    loading,
    errors,
    t
  } = props;

  const [values, setValues] = React.useState({
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <Fragment>
      <Heading level={1} icon={ExitToAppIcon}>
        Acesso à Plataforma Waybee
      </Heading>
      <Form
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <TextField
          InputLabelProps={{ required: false }}
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("login.login", "Login")}
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleChange("email")}
          error={!!errors.email}
          helperText={errors.email}
        />
        <FormControl fullWidth margin="normal" error={!!errors.password}>
          <InputLabel htmlFor="password">{t("login.pass", "Senha")}</InputLabel>
          <Input
            margin="normal"
            required
            label="Senha"
            name="password"
            id="password"
            type={values.showPassword ? "text" : "password"}
            value={password}
            onChange={handleChange("password")}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onMouseDown={handleClickShowPassword}
                >
                  {values.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )}
          />
          <FormHelperText>{errors.password}</FormHelperText>
        </FormControl>
        <Grid container alignItems="flex-start" justify="flex-end">
          <Button color="secondary" size="lg" onClick={openForgotPassword}>
            Esqueceu a senha?
          </Button>
        </Grid>
        {loading ? (
          <Box py={4} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <LoginButton
            onClick={() => {}}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Entrar
          </LoginButton>
        )}
      </Form>
    </Fragment>
  );
}

FormLogin.propTypes = {
  email: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  openForgotPassword: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(FormLogin);
