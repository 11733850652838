import { api } from "../utils/api";

const getAcl = async () => {
  try {
    const response = await api.get("WaybeeUsers/getAcl");
    return response;
  } catch (e) {
    console.error(e);
  }

  return [];
};

const getRoles = async () => {
  const res = await api.get("AclWaybee/role");
  return res.data;
};

const getPermissions = async () => {
  const res = await api.get("AclWaybee/permission");
  return res.data;
};

const saveRole = async data => {
  const res = await api.post("AclWaybee/role", data);
  return res.data;
};

const getCommUserRoles = async () => {
  const res = await api.get("AclWaybee/role/commUser");
  return res.data;
};

const updateCommUserRoles = async values => {
  const res = await api.post("AclWaybee/role/commUser", values);
  return res.data;
};

const AclService = {
  getAcl,
  getRoles,
  getPermissions,
  saveRole,
  getCommUserRoles,
  updateCommUserRoles
};

export default AclService;
