import React from "react";
import styled from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";

const CourseOverview = styled.p`
  color: rgb(114, 114, 114);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.3;

  span {
    display: block;
  }
`;

const CoursesInfoPopover = ({ groups }) => {
  const studentsDetails = [];
  let classesName = [];
  let hasNoClasses = false;
  let coursesName = "";

  const validateClassCode = (courseDetails, classDetails) => {
    if (hasNoClasses && courseDetails === coursesName) studentsDetails.pop();
    coursesName = courseDetails;
    classesName.push(classDetails);
    return studentsDetails.push({
      courses: courseDetails,
      classes: classDetails
    });
  };

  const validateClassName = (courseDetails, classDetails) => {
    studentsDetails.pop();
    classesName.push(classDetails);
    return studentsDetails.push({
      courses: courseDetails,
      classes: classesName
    });
  };

  const validateCourseName = courseDetails => {
    hasNoClasses = true;
    coursesName = courseDetails;
    classesName = [];
    return studentsDetails.push({ courses: courseDetails });
  };

  groups.map(group => {
    const {
      students: {
        courses,
        classes,
        grades,
        years,
        enrollmentTypes,
        enrollmentSituation
      }
    } = group;

    courses.map(course => {
      if (classes.length > 0) {
        return classes.map(studentClass => {
          if (course.CODIGO === studentClass.CODIGO_CURSO) {
            if (coursesName === course.NOME)
              return validateClassName(course.NOME, studentClass.NOME);
            return validateClassCode(course.NOME, studentClass.NOME);
          }
          if (coursesName !== course.NOME)
            return validateCourseName(course.NOME);
          return undefined;
        });
      }
      return studentsDetails.push({ courses: course.NOME });
    });

    if (enrollmentTypes)
      enrollmentTypes.map(enrollment =>
        studentsDetails.push({
          enrollmentTypes: enrollment.NOME_TIPO_MATRICULA
        })
      );
    if (enrollmentSituation)
      enrollmentSituation.map(enrollment =>
        studentsDetails.push({
          enrollmentSituation: enrollment.NOME_STATUS_MATRICULA
        })
      );
    if (years) years.map(year => studentsDetails.push({ years: year }));
    if (grades)
      grades.map(grade => studentsDetails.push({ grades: grade.NOME }));
    return undefined;
  });

  return (
    <div>
      <CourseOverview key={Math.random()}>
        <span>
          {_.find(studentsDetails, "courses")
            ? `Curso: ${_.find(studentsDetails, "courses").courses}`
            : ""}
        </span>
        <span>
          {_.find(studentsDetails, "classes")
            ? `Turma: ${_.find(studentsDetails, "classes").classes}`
            : "Turma: Todas as turmas"}
        </span>
        <span>
          {_.find(studentsDetails, "enrollmentTypes")
            ? `Tipo de Matrícula: ${
                _.find(studentsDetails, "enrollmentTypes").enrollmentTypes
              }`
            : ""}
        </span>
        <span>
          {_.find(studentsDetails, "enrollmentSituation")
            ? `Situação de Matrícula: ${
                _.find(studentsDetails, "enrollmentSituation")
                  .enrollmentSituation
              }`
            : ""}
        </span>
        <span>
          {_.find(studentsDetails, "years")
            ? `Ano: ${_.find(studentsDetails, "years").years}`
            : ""}
        </span>
        <span>
          {_.find(studentsDetails, "grades")
            ? `Série: ${_.find(studentsDetails, "grades").grades}`
            : ""}
        </span>
      </CourseOverview>
    </div>
  );
};

CoursesInfoPopover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  groups: PropTypes.array.isRequired
};

export default CoursesInfoPopover;
