import React, { Component } from "react";
import { withAlert } from "react-alert";
import PropTypes from "prop-types";
import Board, { moveCard, changeCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { AvatarGroup } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import {
  Avatar,
  Select,
  FormControl,
  MenuItem,
  TextField,
  InputAdornment,
  withTheme
} from "@material-ui/core";
import { DebounceInput } from "react-debounce-input";
import _ from "lodash";
import Loading from "../../components/Loading";
import TicketsService from "../../services/TicketsService";
import TicketDetail from "./components/TicketDetail";
import TicketCard from "./components/TicketCard";
import BackButton from "../../components/BackButton";
import HoverPopover from "../../components/HoverPopover";

const Kanban = styled.div`
  .react-kanban-column {
    width: 354px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ColumnHeaderBadge = styled.span`
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  display: block;
  border-radius: 8px;
`;

const ColumnHeaderTitle = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding: 6px;
  margin: 0;
  color: #00303b;
`;

const ColumnHeaderCounter = styled.p`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #e0e0e0;
  text-align: center;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #625f6d;
`;

const ColumnHeaderStatus = styled.span`
  flex-grow: 1;
  width: 100%;
  height: 3px;
  background-color: ${({ color }) => color};
  margin-top: 22px;
  margin-bottom: 28px;
`;

const AvatarSelect = styled(Avatar)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Option = styled(MenuItem)`
  padding: 10px 15px;
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
`;
const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  .MuiInputBase-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SelectChannel = styled(Select)`
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 8px;
  margin-left: 20px;

  .MuiSelect-root {
    padding: 0 32px 0 0;
  }
`;

const UserAvatar = styled(Avatar)`
  cursor: pointer;
`;

const AvatarBox = styled.div`
  border-radius: 100%;
  border: ${props => (props.color ? `2px solid ${props.color}` : "none")};
`;

class Tickets extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { channelId }
      }
    } = props;
    this.state = {
      board: null,
      loading: true,
      loadKanban: false,
      openDetails: false,
      channels: [],
      ticket: null,
      status: [],
      // eslint-disable-next-line no-restricted-globals
      selectedChannel: isNaN(channelId) ? channelId : parseInt(channelId, 10),
      initialBoard: [],
      attendees: [],
      filteredUser: [],
      isAttendee: false
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Canais de Atendimento");
    this.getTickets();
    this.getChannels();
    this.getAttendees();
  }

  getAttendees = async () => {
    const {
      match: {
        params: { channelId }
      }
    } = this.props;

    const attendees = await TicketsService.getAttendees(channelId);

    const userId = parseInt(localStorage.getItem("userId"), 10);
    const isAttendee = attendees.some(attendee => attendee.id === userId);

    this.setState({ attendees, isAttendee });
  };

  getChannels = async () => {
    const channels = await TicketsService.getChannels();
    this.setState({ channels });
  };

  getTickets = async () => {
    const {
      setTitle,
      match: {
        params: { channelId }
      }
    } = this.props;
    this.setState({ loading: true });

    const ticketData = await TicketsService.getTickets(channelId);
    setTitle(`Canais de Atendimento > ${ticketData.title}`);

    const statusList = ticketData.board.columns.map(({ id, title, color }) => ({
      id,
      title,
      color
    }));

    this.setState({
      initialBoard: ticketData.board,
      board: ticketData.board,
      loading: false,
      status: statusList
    });
  };

  filterByText = search => {
    const { initialBoard } = this.state;
    this.setState({ loadKanban: true });

    if (!search) {
      return this.setState({
        board: initialBoard,
        loadKanban: false
      });
    }

    const normalizeText = text => {
      return text
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    };

    const normalizedSearch = normalizeText(search);

    const filteredBoard = {
      columns: initialBoard.columns.map(column => ({
        ...column,
        cards: column.cards.filter(card => {
          const normalizedCard = normalizeText(card.title);

          return normalizedCard.includes(normalizedSearch);
        })
      }))
    };

    return this.setState({
      board: filteredBoard,
      loadKanban: false
    });
  };

  filterByAvatar = user => {
    const { initialBoard, filteredUser } = this.state;
    this.setState({ loadKanban: true });

    let users = filteredUser;

    if (users.includes(user.id)) {
      users = _.remove(users, userId => userId !== user.id);
      this.setState({ filteredUser: users });
    } else {
      users.push(user.id);
      this.setState({ filteredUser: users });
    }

    if (!users.length) {
      this.setState({ board: initialBoard }, () => {
        this.setState({ loadKanban: false });
      });
    } else {
      const filteredBoard = {
        columns: initialBoard.columns.map(column => {
          return {
            ...column,
            cards: column.cards.filter(card => users.includes(card.attendeeId))
          };
        })
      };

      this.setState({ board: filteredBoard }, () => {
        this.setState({ loadKanban: false });
      });
    }
  };

  setAttendee = async (ticketId, user) => {
    const { board } = this.state;

    const userId = user ? user.id : null;
    await TicketsService.setAttendee(ticketId, userId);

    let attendee = {};
    if (user) {
      attendee = {
        name: user.name,
        photoProfile: user.photoProfile
      };
    } else {
      attendee = {
        name: localStorage.getItem("userName"),
        photoProfile: localStorage.getItem("userPhoto")
      };
    }

    const updatedBoard = changeCard(board, ticketId, { attendee });
    this.setState({ board: updatedBoard });
  };

  changeStatus = async (ticketId, source, destination) => {
    const { board, isAttendee } = this.state;
    const { alert } = this.props;

    if (!isAttendee) {
      alert.show(`Apenas atendentes desse canal podem alterar o status.`, {
        title: "Permissão negada"
      });
      return;
    }

    TicketsService.changeStatus(ticketId, destination.toColumnId);

    let sourcePosition = 0;
    if (!source.fromPosition) {
      sourcePosition = board.columns
        .find(column => column.id === source.fromColumnId)
        .cards.findIndex(card => card.id === ticketId);
    }

    const updateBoard = moveCard(
      board,
      { fromPosition: sourcePosition, ...source },
      { toPosition: 0, ...destination }
    );
    this.setState({ board: updateBoard, initialBoard: updateBoard });
  };

  openDetails = async ticketId => {
    const ticket = await TicketsService.getTicket(ticketId);
    this.setState({ openDetails: true, ticket });
  };

  changeChannel = e => {
    const { history } = this.props;

    this.setState({ selectedChannel: e.target.value }, () => {
      this.getTickets();
    });
    history.replace(`/ticket-channels/${e.target.value}`);
  };

  render() {
    const {
      board,
      loading,
      openDetails,
      channels,
      selectedChannel,
      ticket,
      status,
      loadKanban,
      attendees,
      filteredUser,
      isAttendee
    } = this.state;

    const {
      theme,
      match: {
        params: { channelId }
      }
    } = this.props;

    if (loading) return <Loading />;

    const getOption = channel => {
      if (channel) {
        return (
          <Option value={channel.id}>
            <AvatarSelect src={channel.photoAvatar} />
            {channel.title}
          </Option>
        );
      }
      return null;
    };

    const myTicketsOption = {
      id: 0,
      photoAvatar: localStorage.getItem("userPhoto"),
      title: "Meus atendimentos"
    };

    const unassignedTickets = {
      id: "unassignedTickets",
      photoAvatar: "",
      title: "Não atribuidos"
    };
    return (
      <Kanban>
        <FormControl variant="outlined" fullWidth>
          <FilterHeader>
            <FilterContainer>
              <BackButton to="/ticket-channels" />
              <SelectChannel
                onChange={e => this.changeChannel(e)}
                renderValue={value => {
                  const foundChannel = channels.find(
                    channel => channel.id === value
                  );
                  let sideOption;
                  if (!foundChannel) {
                    if (value === myTicketsOption.id)
                      sideOption = myTicketsOption;
                    if (value === unassignedTickets.id)
                      sideOption = unassignedTickets;
                  }
                  return getOption(foundChannel || sideOption);
                }}
                value={selectedChannel}
              >
                {getOption(unassignedTickets)}
                {getOption(myTicketsOption)}
                {channels.map(channel => getOption(channel))}
              </SelectChannel>
              {channelId !== "0" && (
                <AvatarGroup max={4}>
                  {attendees.map(attendee => (
                    <AvatarBox
                      color={
                        filteredUser.includes(attendee.id)
                          ? theme.palette.primary.main
                          : undefined
                      }
                    >
                      <HoverPopover
                        externalClasses="MuiAvatar-root MuiAvatarGroup-avatar"
                        id={attendee.id}
                        value={attendee.name}
                      >
                        <UserAvatar
                          alt={attendee.name}
                          src={attendee.photoProfile}
                          onClick={() => this.filterByAvatar(attendee)}
                          sx={{ cursor: "pointer" }}
                        />
                      </HoverPopover>
                    </AvatarBox>
                  ))}
                </AvatarGroup>
              )}
            </FilterContainer>
            <FilterContainer>
              <DebounceInput
                debounceTimeout={400}
                element={TextField}
                onChange={e => this.filterByText(e.target.value)}
                placeholder="Pesquisar Tarefa"
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="action" />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </FilterContainer>
          </FilterHeader>
        </FormControl>

        {loadKanban ? (
          <Loading />
        ) : (
          <Board
            disableColumnDrag
            onCardDragEnd={({ id: cardId }, source, destination) =>
              this.changeStatus(cardId, source, destination)
            }
            renderColumnHeader={({ title, color, cards }) => (
              <ColumnHeader>
                <ColumnHeaderBadge color={color} />
                <ColumnHeaderTitle>{title}</ColumnHeaderTitle>
                <ColumnHeaderCounter>{cards.length}</ColumnHeaderCounter>
                <ColumnHeaderStatus color={color} />
              </ColumnHeader>
            )}
            renderCard={cardData => (
              <TicketCard
                selectedChannel={selectedChannel}
                ticket={cardData}
                setAttendee={this.setAttendee}
                openDetails={this.openDetails}
                disableActions={!isAttendee}
              />
            )}
          >
            {board}
          </Board>
        )}

        <TicketDetail
          open={openDetails}
          onClose={() => this.setState({ openDetails: false })}
          ticket={ticket}
          status={status}
          employees={attendees}
          setAttendee={this.setAttendee}
          changeStatus={this.changeStatus}
          disableActions={!isAttendee}
        />
      </Kanban>
    );
  }
}

Tickets.propTypes = {
  setTitle: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      channelId: PropTypes.string
    })
  }).isRequired, // inject by withRouter
  theme: PropTypes.objectOf().isRequired,
  history: PropTypes.objectOf().isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // injected by withAlert
};

export default withAlert()(withTheme(withRouter(Tickets)));
