import React, { Component } from "react";
import {
  Box,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import styled from "styled-components";
import { withAlert } from "react-alert";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import Group from "../../waybee-ui/Group";
import TicketChannelFormDetail from "./components/TicketChannelFormDetail";
import Button from "../../waybee-ui/Button";
import TicketChannelFormDestinations from "./components/TicketChannelFormDestinations";
import TicketChannelFormAutoMessages from "./components/TicketChannelFormAutoMessages";
import TicketsService from "../../services/TicketsService";
import SchoolGroupService from "../../services/SchoolGroupService";
import BackButton from "../../components/BackButton";

const steps = ["Detalhes", "Segmentação", "Respostas"];

const TicketForm = styled(Box)`
  height: calc(100vh - 300px);
`;

const FormContainer = styled(Grid)`
  height: 90%;
  margin-top: 8px;
  overflow: auto;
`;

const LoadingWrapper = styled.div`
  text-align: center;
`;

class TicketChannelForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      autoMessages: [],
      categories: [],
      filters: {
        schoolYear: []
      },
      loading: true,
      employees: [],
      formValues: {
        title: "",
        description: "",
        ticketCategoryId: {},
        destinationFilter: {
          students: {
            courses: [],
            classes: [],
            grades: [],
            years: [`${new Date().getFullYear()}`]
          },
          employees: {
            attendees: [],
            supervisors: []
          },
          years: [`${new Date().getFullYear()}`]
        },
        photoAvatar: "",
        autoMessages: []
      },
      status: [],
      yearFilterEnable: true
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Canais de Atendimento");
    this.getFilters();
    this.getEmployees();
    this.getCategory();
    this.getTicketStatus();
    this.getEnableYearFilter();
    this.getChannelInfo().then(() => this.setState({ loading: false }));
  }

  getFilters = async () => {
    const { alert } = this.props;
    try {
      const res = await SchoolGroupService.getFilters();
      this.setState({ filters: res });
    } catch (e) {
      alert.show("Erro ao listar filtros", {
        title: "Ocorreu um erro"
      });
    }
  };

  getEmployees = async () => {
    const { alert } = this.props;
    try {
      const employees = await TicketsService.getEmployees();
      const employeesList = employees.map(employee => {
        return { id: employee.id, name: employee.name };
      });

      this.setState({ employees: employeesList });
    } catch (e) {
      alert.show("Erro ao criar listar funcionários", {
        title: "Ocorreu um erro"
      });
    }
  };

  getCategory = async () => {
    const { alert } = this.props;
    try {
      const categories = await TicketsService.getCategories();
      this.setState({ categories });
    } catch (e) {
      alert.show("Erro ao listar categorias", {
        title: "Ocorreu um erro"
      });
    }
  };

  getTicketStatus = async () => {
    const { alert } = this.props;
    try {
      const status = await TicketsService.getStatus();
      this.setState({ status });
    } catch (e) {
      alert.show("Erro ao listar status", {
        title: "Ocorreu um erro"
      });
    }
  };

  getEnableYearFilter = async () => {
    const { alert } = this.props;
    try {
      const yearFilterEnable = await TicketsService.getEnableYearField();
      this.setState({ yearFilterEnable });
    } catch (e) {
      alert.show("Erro ao pegar configurações de ano letivo", {
        title: "Ocorreu um erro"
      });
    }
  };

  handleSkip = action => {
    const { activeStep } = this.state;
    if (action === "skip") {
      const newStep = activeStep + 1;
      this.setState({ activeStep: newStep });
    } else {
      const newStep = activeStep - 1;
      this.setState({ activeStep: newStep });
    }
  };

  addAutoMessage = (message, statusId) => {
    const { autoMessages } = this.state;
    if (statusId) {
      autoMessages.push({ ticketStatusId: statusId, message });
    } else {
      autoMessages.push({ message });
    }
  };

  redirect = id => {
    const { history } = this.props;
    if (id) {
      history.push(`/ticket-channels/${id}`);
    } else {
      history.push("/ticket-channels");
    }
  };

  createChannel = async (values, categoryId) => {
    const {
      match: {
        params: { channelId }
      },
      alert
    } = this.props;

    const data = values;
    data.ticketCategoryId = categoryId.id;

    if (!channelId) {
      try {
        return await TicketsService.createChannel(values).then(res => {
          alert.show("As informações do canal foram salvas com sucesso", {
            title: "Sucesso",
            onClose: () => this.redirect(res.data.id)
          });
        });
      } catch (e) {
        alert.show("Erro um erro ao criar canal de atendimento ", {
          title: "Ocorreu um erro"
        });
      }
    }

    if (channelId) {
      return this.updateChannel(data);
    }

    return [];
  };

  updateChannel = async values => {
    const {
      match: {
        params: { channelId }
      },
      alert
    } = this.props;

    // const data = values;
    // data.ticketCategoryId = values.ticketCategoryId.id;

    try {
      return await TicketsService.editChannel(channelId, values).then(() => {
        alert.show("As informações do canal foram salvas com sucesso", {
          title: "Sucesso",
          onClose: this.redirect
        });
      });
    } catch (e) {
      alert.show("Erro um erro ao editar o canal de atendimento ", {
        title: "Ocorreu um erro"
      });
    }
    return [];
  };

  getChannelInfo = async () => {
    const {
      match: {
        params: { channelId }
      }
    } = this.props;

    if (channelId) {
      const formValues = await TicketsService.getChannelInfo(channelId);

      if (formValues) {
        const { categories } = this.state;
        formValues.ticketCategoryId = _.find(
          categories,
          category => category.id === formValues.ticketCategoryId
        );
      }

      this.setState({ formValues, loading: false });
    }
  };

  render() {
    const {
      activeStep,
      filters,
      loading,
      employees,
      categories,
      formValues,
      status,
      yearFilterEnable
    } = this.state;

    const {
      match: {
        params: { channelId }
      }
    } = this.props;

    if (loading) {
      return (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BackButton to="/ticket-channels" hasConfirm />
          <Group>
            <TicketForm>
              <Formik
                validationSchema={Yup.object().shape({
                  title: Yup.string().required(),
                  ticketCategoryId: Yup.object().shape({}),
                  photoAvatar: Yup.string().nullable(),
                  description: Yup.string().nullable(),
                  // eslint-disable-next-line react/forbid-prop-types
                  classes: Yup.array(),
                  courses: Yup.array().when("public", {
                    is: false,
                    then: Yup.array().min(1, "Selecione ao menos 1 curso")
                  }),
                  // eslint-disable-next-line react/forbid-prop-types
                  grades: Yup.array(),
                  // eslint-disable-next-line react/forbid-prop-types
                  supervisors: Yup.array(),
                  // eslint-disable-next-line react/forbid-prop-types
                  attendees: Yup.array(),
                  // eslint-disable-next-line react/forbid-prop-types
                  year: Yup.array(),
                  // eslint-disable-next-line react/forbid-prop-types
                  autoMessages: Yup.array()
                })}
                initialValues={formValues}
                onSubmit={() => {
                  this.createChannel(formValues);
                }}
              >
                {({ values, errors, handleChange, touched, setFieldValue }) => (
                  <>
                    <Stepper activeStep={activeStep}>
                      {steps.map(step => (
                        <Step key={step}>
                          <StepLabel>{step}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <Divider />
                    <FormContainer
                      container
                      spacing={2}
                      justifyContent="center"
                      rowSpacing={2}
                      alignContent="space-around"
                    >
                      {activeStep === 0 && (
                        <TicketChannelFormDetail
                          errors={errors}
                          values={values}
                          categories={categories}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )}
                      {activeStep === 1 && (
                        <TicketChannelFormDestinations
                          yearFilterEnabled={yearFilterEnable}
                          filters={filters}
                          employees={employees}
                          errors={errors}
                          values={values}
                          touched={touched}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )}
                      {activeStep === 2 && (
                        <TicketChannelFormAutoMessages
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          status={status}
                          addAutoMessage={this.addAutoMessage}
                          channelId={channelId}
                          setValue={value =>
                            setFieldValue("autoMessages", value)
                          }
                        />
                      )}
                      <Grid container justifyContent="space-evenly">
                        <Button
                          style={{ padding: "0 30px" }}
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            activeStep !== 0
                              ? this.handleSkip("back")
                              : this.redirect()
                          }
                        >
                          Voltar
                        </Button>

                        {activeStep !== steps.length - 1 ? (
                          <Button
                            onClick={() => this.handleSkip("skip", values)}
                            color="primary"
                            variant="contained"
                          >
                            Continuar
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              const { ticketCategoryId } = values;
                              this.createChannel(values, ticketCategoryId);
                              this.setState({ formValues: values });
                            }}
                          >
                            Salvar
                          </Button>
                        )}
                      </Grid>
                    </FormContainer>
                  </>
                )}
              </Formik>
            </TicketForm>
          </Group>
        </Grid>
      </Grid>
    );
  }
}

TicketChannelForm.defaultProps = {
  match: {
    params: {}
  }
};

TicketChannelForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf
  }),
  history: PropTypes.objectOf().isRequired
};

export default withAlert()(withRouter(TicketChannelForm));
