import React from "react";
import PropTypes from "prop-types";
import {
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon
} from "@material-ui/core";
import styled from "styled-components";

const Container = styled(MuiListItem)`
  height: 70px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: solid 1px #f2f2f2;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  color: ${({ theme }) => theme.color.primary.dark};
  min-width: 52px;
  svg {
    width: 1.2em;
    height: 1.2em;
  }
`;

const ListItemText = styled.span`
  color: #7e7e7e;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
`;

const ListItem = props => {
  const { icon, children } = props;
  return (
    <Container button {...props}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
    </Container>
  );
};

ListItem.propTypes = {
  ...MuiListItem.propTypes,
  icon: PropTypes.node
};

ListItem.defaultProps = {
  icon: null
};

export default ListItem;
