import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const LoadContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
`;

const Backdrop = () => {
  return (
    <LoadContainer>
      <CircularProgress color="inherit" size={50} />
    </LoadContainer>
  );
};

export default Backdrop;
