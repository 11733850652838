import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "@moment";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { History as HistoryIcon, ExpandMore } from "@material-ui/icons";
import styled from "styled-components";
import Group from "../../waybee-ui/Group";
import Heading from "../../waybee-ui/Heading";
import HoverPopover from "../HoverPopover";
import Loading from "../Loading";

const MainContainer = styled(Grid)`
  margin: 5px 0;
`;

const DropDown = styled(Accordion)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-shadow: none;
`;

const DetailsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
`;

const SubtitleBefore = styled(Typography)`
  color: #00b297;

  &:before {
    content: "antigo: ";
  }
`;

const SubtitleAfter = styled(Typography)`
  color: #00b297;

  &:before {
    content: "novo: ";
  }
`;

const DropdownDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 0;
`;

const DropdownSummary = styled(AccordionSummary)`
  padding: 0;

  &expandIconWrapper {
    padding: 0;
  }
`;

const Subtitle = styled(Typography)`
  color: #00b297;
`;

const DetailText = styled(Typography)`
  margin: 0 0 0 5px;
  word-break: break-word;
  white-space: normal;
`;

const PopoverText = styled.p`
  color: rgb(114, 114, 114);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.4;
  margin: 0 0 0 5px;
  word-break: break-word;
`;

const PopoverContent = styled(Typography)`
  cursor: pointer;
  word-break: break-word;
  line-break: normal;

  :hover {
    opacity: 0.8;
  }
`;

const TitleDescription = styled(Typography)`
  margin: 0 0 0 10px;
`;

const ItemDetail = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

const ItemContainerLeft = styled(Grid)`
  padding: 0 32px 0 0;
`;
const ItemContainerRight = styled(Grid)`
  padding: 0 0 0 16px;
`;

const GroupHistory = ({ audits, loading }) => {
  const [viewDetails, setViewDetails] = useState(false);

  const handleDropdownChange = panel => (event, isExpanded) => {
    setViewDetails(isExpanded ? panel : false);
  };

  if (!loading && (!audits || (audits && !audits.length))) return null;

  return (
    <Group>
      <Heading level={2} icon={HistoryIcon} gutterBottom>
        Histórico de alterações na comunicação
      </Heading>

      {loading ? (
        <Loading />
      ) : (
        <Grid container justify="space-between">
          {audits
            .map(audit => {
              if (!audit) {
                return undefined;
              }
              const { alteredValues } = audit;

              return (
                <MainContainer item xs={12} container key={audit.id}>
                  <DropDown
                    expanded={viewDetails === `panel${audit.id}`}
                    onChange={handleDropdownChange(`panel${audit.id}`)}
                  >
                    <DropdownSummary expandIcon={<ExpandMore />}>
                      <Grid
                        container
                        xs={4}
                        direction="column"
                        alignItems="flex-start"
                        justify="flex-start"
                      >
                        <DetailText>{`Alterações na comunicação (${alteredValues.length})`}</DetailText>
                      </Grid>
                      <Grid
                        container
                        xs={4}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >
                        <DetailText variant="caption">
                          Reponsável pela alteração:
                          <Subtitle variant="inherit">{` ${audit.userId}`}</Subtitle>
                        </DetailText>
                      </Grid>
                      <Grid
                        container
                        xs={4}
                        direction="column"
                        alignItems="flex-end"
                        justify="flex-end"
                      >
                        <DetailText variant="caption">
                          {moment(audit.createdAt).format(
                            "[Realizada dia] DD/MM/YYYY [às] HH:mm"
                          )}
                        </DetailText>
                      </Grid>
                    </DropdownSummary>
                    <DropdownDetails>
                      {alteredValues.map((data, j) => {
                        const detailTitle = data.name || "-";
                        let detailOld = "-";
                        let detailNew = "-";
                        const regex = /<[^>]*>?/gm;

                        switch (data.type) {
                          case "attachments":
                          case "number":
                          case "string":
                            detailOld = data.old;
                            detailNew = data.new;
                            break;
                          case "html":
                            detailOld = data.old.replace(regex, "");
                            detailNew = data.new.replace(regex, "");
                            break;
                          case "date":
                            detailOld = !data.old
                              ? ""
                              : moment(data.old).format(
                                  "DD/MM/YYYY [às] HH:mm"
                                );

                            detailNew = !data.new
                              ? ""
                              : moment(data.new).format(
                                  "DD/MM/YYYY [às] HH:mm"
                                );
                            break;
                          case "boolean":
                            detailOld = data.old ? "Sim" : "Não";
                            detailNew = data.new ? "Sim" : "Não";
                            break;
                          default:
                            return "";
                        }

                        const limitRange = 125;
                        const handleText = text => {
                          if (text.length > limitRange) {
                            return (
                              detailNew.substring(0, limitRange) +
                              "...mais".bold()
                            );
                          }
                          return text;
                        };

                        return (
                          <DetailsContainer container>
                            {viewDetails && (
                              <ItemDetail item xs={12} container>
                                <Grid
                                  item
                                  xs={4}
                                  container
                                  justify="flex-start"
                                  alignContent="center"
                                >
                                  <DetailText>{j + 1}</DetailText>
                                  <TitleDescription variant="caption">
                                    {detailTitle}
                                  </TitleDescription>
                                </Grid>
                                <ItemContainerLeft
                                  item
                                  xs={4}
                                  container
                                  justify="center"
                                >
                                  <SubtitleBefore variant="caption" />
                                  {detailOld &&
                                    (detailOld.length > limitRange ? (
                                      <HoverPopover
                                        id={audit.id}
                                        value={(
                                          <PopoverText
                                            variant="caption"
                                            dangerouslySetInnerHTML={{
                                              __html: detailOld
                                            }}
                                          />
                                        )}
                                      >
                                        <PopoverContent
                                          variant="caption"
                                          dangerouslySetInnerHTML={{
                                            __html: handleText(detailOld)
                                          }}
                                        />
                                      </HoverPopover>
                                    ) : (
                                      <DetailText
                                        variant="caption"
                                        dangerouslySetInnerHTML={{
                                          __html: handleText(detailOld)
                                        }}
                                      />
                                    ))}
                                </ItemContainerLeft>
                                <ItemContainerRight
                                  item
                                  xs={4}
                                  container
                                  justify="center"
                                >
                                  <SubtitleAfter variant="caption" />
                                  {detailNew &&
                                    (detailNew.length > limitRange ? (
                                      <HoverPopover
                                        id={audit.id}
                                        value={(
                                          <PopoverText
                                            variant="caption"
                                            dangerouslySetInnerHTML={{
                                              __html: detailNew
                                            }}
                                          />
                                        )}
                                      >
                                        <PopoverContent
                                          variant="caption"
                                          dangerouslySetInnerHTML={{
                                            __html: handleText(detailNew)
                                          }}
                                        />
                                      </HoverPopover>
                                    ) : (
                                      <DetailText
                                        variant="caption"
                                        dangerouslySetInnerHTML={{
                                          __html: handleText(detailNew)
                                        }}
                                      />
                                    ))}
                                </ItemContainerRight>
                              </ItemDetail>
                            )}
                          </DetailsContainer>
                        );
                      })}
                    </DropdownDetails>
                  </DropDown>
                </MainContainer>
              );
            })
            .reverse()}
        </Grid>
      )}
    </Group>
  );
};

GroupHistory.defaultProps = {
  loading: false
};

GroupHistory.propTypes = {
  audits: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      id: PropTypes.number,
      model: PropTypes.string,
      modelId: PropTypes.string,
      alteredValues: PropTypes.arrayOf
    })
  ).isRequired,
  loading: PropTypes.bool
};

export default GroupHistory;
