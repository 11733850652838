import styled from "styled-components";
import PropTypes from "prop-types";

const Color = styled.div`
  background-color: ${({ color }) => color};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.primary.main};
`;

Color.propTypes = {
  color: PropTypes.string.isRequired
};

export default Color;
