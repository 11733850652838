import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withAlert } from "react-alert";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Details as DetailsIcon,
  Group as GroupIcon
} from "@material-ui/icons";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import _ from "lodash";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import ChatColumnHeader from "./ChatColumnHeader";
import { Can } from "../../../acl/can";
// import Divider from "../../../waybee-ui/Divider";
import Button from "../../../waybee-ui/Button";
import Heading from "../../../waybee-ui/Heading";
import Group from "../../../waybee-ui/Group";
import ScrollColumnBody from "./ScrollColumnBody";
import SchoolGroupService from "../../../services/SchoolGroupService";
import BugsnagService from "../../../services/BugsnagService";
import HighlightText from "../../../waybee-ui/HighlightText";
// import ButtonBlock from "../../../waybee-ui/ButtonBlock";
import ModalReceiversSelector from "../../CommunicationForm/ModalReceiversSelector";
import ImageInput from "../../../components/ImageInput";
import ChatService from "../../../services/ChatService";
import FilterField from "../../CommunicationForm/components/FilterField";

const Header = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.background.default};
`;
const HeaderDescription = styled.p`
  margin-top: 30px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
`;
const FormDetails = styled(Grid)`
  margin-top: 30px;
`;
const FormGroup = styled(Grid)`
  border-top: 12px solid ${({ theme }) => theme.color.background.default};
`;
const FormDescription = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
`;
const FormFooter = styled.div`
  background-color: ${({ theme }) => theme.color.background.default};
  padding: 20px 0;
`;

class NewChannelColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChannel: null,
      coursesAndClasses: [],
      employees: [],
      studentSelectorIsOpen: false,
      loadingChannel: false,
      loadingUpload: false,
      loadingSubmit: false,
      loadingRemove: false,
      filters: {
        schoolYear: []
      }
    };
  }

  componentDidMount() {
    const { selectedChannel } = this.props;

    this.getEmployees();
    this.getFilters();
    if (selectedChannel) this.getChannel(selectedChannel);
  }

  componentDidUpdate(prevProps) {
    const { selectedChannel: selectedChannelId } = this.props;
    if (selectedChannelId && prevProps.selectedChannel !== selectedChannelId)
      this.getChannel(selectedChannelId);
  }

  getChannel = async id => {
    this.setState({ loadingChannel: true });
    const channel = await ChatService.getChannelById(id);
    await this.getCoursesAndClasses(
      channel.destinationFilter.students.years[0]
    );
    this.setState({ selectedChannel: channel, loadingChannel: false });
  };

  getCoursesAndClasses = async year => {
    try {
      const coursesAndClasses = await SchoolGroupService.getCoursesAndClasses(
        year
      );
      this.setState({ coursesAndClasses });
    } catch (e) {
      BugsnagService.notifyError(e);
    }
  };

  getEmployees = async () => {
    const { alert } = this.props;
    try {
      const employees = await ChatService.getEmployees();
      this.setState({
        employees: employees.map(employee => ({
          id: employee.id,
          name: employee.name
        }))
      });
    } catch (e) {
      alert.show(`Erro ao criar canal.`, { title: "Erro" });
    }
  };

  getFilters = async () => {
    const res = await SchoolGroupService.getFilters();
    await this.getCoursesAndClasses(res.schoolYear[0]);
    this.setState({ filters: res });
  };

  getClassesByCourses = courses => {
    const { coursesAndClasses } = this.state;

    return _.flatMap(courses, course => {
      const found = _.find(coursesAndClasses, { CODIGO: course.CODIGO });
      return found ? found.VW_TURMA : [];
    });
  };

  getSeriesByCourses = courses => {
    return courses ? courses.map(course => course.VW_SERIES).flat() : [];
  };

  onUpload = async file => {
    this.setState({ loadingUpload: true });
    const url = await ChatService.uploadChannelPhoto(file);
    this.setState({ loadingUpload: false });
    return url;
  };

  onSave = async values => {
    const { alert, selectedChannel: selectedChannelId } = this.props;
    this.setState({ loadingSubmit: true });

    try {
      await ChatService.createChannel(values, selectedChannelId);
      alert.show(`Canal salvo com sucesso`, { title: "Sucesso" });

      const refreshEvent = new Event("refresh");
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      console.error(e);
      const resError = _.get(e, "response.data.error", null);
      alert.show(resError ? resError.message : `Erro ao salvar canal.`, {
        title: "Erro"
      });
    } finally {
      this.setState({ loadingSubmit: false });
    }
  };

  onDelete = async () => {
    const { alert, selectedChannel: selectedChannelId } = this.props;
    this.setState({ loadingRemove: true });

    try {
      await ChatService.deleteChannel(selectedChannelId);
      alert.show(`Canal removido com sucesso`, { title: "Sucesso" });

      const refreshEvent = new Event("refresh");
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      console.error(e);
      const resError = _.get(e, "response.data.error", null);
      alert.show(resError ? resError.message : `Erro ao remover canal.`, {
        title: "Erro"
      });
    } finally {
      this.setState({ loadingRemove: false });
    }
  };

  render() {
    const { onClose } = this.props;
    const {
      coursesAndClasses,
      studentSelectorIsOpen,
      loadingUpload,
      loadingSubmit,
      selectedChannel,
      loadingChannel,
      loadingRemove,
      employees,
      filters
    } = this.state;

    const { schoolYear: schoolYears } = filters;

    const initialValues = {
      name: "",
      photo: "",
      description: "",
      public: false,
      allowGroup: true,
      studentCourses: [],
      studentClasses: [],
      studentSeries: [],
      employees: [],
      schoolYears: [schoolYears[0]]
    };
    if (selectedChannel) {
      initialValues.schoolYears =
        selectedChannel.destinationFilter.students.years;
      initialValues.name = selectedChannel.name;
      initialValues.photo = selectedChannel.photoAvatar;
      initialValues.description = selectedChannel.description;
      initialValues.public = selectedChannel.public;
      initialValues.allowGroup = selectedChannel.allowGroup;

      const studentFilters = _.get(
        selectedChannel,
        "destinationFilter.students",
        null
      );
      if (studentFilters) {
        initialValues.studentCourses = studentFilters.courses || [];
        initialValues.studentClasses = studentFilters.classes || [];
        initialValues.studentSeries = studentFilters.grades || [];
        initialValues.employees = studentFilters.employees || [];
      }

      const employeeFilter = _.get(
        selectedChannel,
        "destinationFilter.employees",
        null
      );

      if (employeeFilter) initialValues.employees = employeeFilter;
    }

    return (
      <>
        <Header id="channel-form-header">
          <ChatColumnHeader
            title={selectedChannel ? "Editar Canal" : "Novo Canal"}
            icon="close"
            onClickIcon={onClose}
            variant="outline"
          >
            <HeaderDescription>
              Para criar um novo canal, preencha as fichas com atenção e depois
              salve. O canal será criado e entrará para lista de canais. Caso
              queira editar um canal já criado, clique no canal e no ícone em
              azul. Dentro da edição de canal, ele também pode ser excluído.
            </HeaderDescription>
            <FormHelperText>* Campos Obrigatórios</FormHelperText>
          </ChatColumnHeader>
        </Header>

        <ScrollColumnBody
          subtractIds={["channel-form-header"]}
          bodyId="channel-form-body"
        >
          <Formik
            validateOnBlur
            validateOnChange={false}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required(),
              photo: Yup.string().nullable(),
              description: Yup.string().nullable(),
              public: Yup.boolean(),
              allowGroup: Yup.boolean(),
              // eslint-disable-next-line react/forbid-prop-types
              studentClasses: Yup.array(),
              // eslint-disable-next-line react/forbid-prop-types
              studentSeries: Yup.array(),
              studentCourses: Yup.array().when("public", {
                is: false,
                then: Yup.array().min(1, "Selecione ao menos 1 curso")
              }),
              // eslint-disable-next-line react/forbid-prop-types
              employees: Yup.array()
            })}
            onSubmit={this.onSave}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              validateForm,
              getFieldMeta,
              isValid
            }) => {
              const isInvalid = () => {
                let valid = true;
                const touchedKeys = Object.keys(touched);
                touchedKeys.forEach(fieldName => {
                  if (touched[fieldName] && getFieldMeta(fieldName).error)
                    valid = false;
                });

                return !valid;
              };

              return (
                <>
                  <Grid container>
                    {loadingChannel ? (
                      <Grid item xs={12}>
                        <Group>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box display="flex" alignItems="center" mb={2}>
                                <Skeleton
                                  variant="circle"
                                  width={32}
                                  height={32}
                                />
                                <Box pl={2}>
                                  <Skeleton variant="text" width={150} />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Box
                                pr={3}
                                display="flex"
                                alignItems="center"
                                height="100%"
                              >
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={32}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Box display="flex" alignItems="center">
                                <Skeleton
                                  variant="circle"
                                  width={64}
                                  height={64}
                                />
                                <Box flexGrow={1} pl={1}>
                                  <Skeleton variant="text" />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box pt={2}>
                                <Skeleton variant="text" height={64} />
                              </Box>
                            </Grid>
                          </Grid>
                        </Group>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Group>
                            <Heading level={2} icon={DetailsIcon}>
                              Detalhes do canal
                            </Heading>

                            <FormDetails container>
                              <Grid item xs={12} sm={6}>
                                <Box pr={3}>
                                  <Field
                                    id="name"
                                    component={TextField}
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Nome *"
                                    fullWidth
                                    error={touched.name && errors.name}
                                    helperText={touched.name && errors.name}
                                    inputProps={{ maxLength: "32" }}
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <ImageInput
                                  imageUrl={values.photo}
                                  label="Carregar imagem do canal"
                                  loading={loadingUpload}
                                  onChange={async file => {
                                    setFieldValue(
                                      "photo",
                                      URL.createObjectURL(file)
                                    );
                                    setFieldValue(
                                      "photoUrl",
                                      await this.onUpload(file)
                                    );
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Box mt={5}>
                                  <FormDescription>
                                    Descrição do Canal
                                  </FormDescription>
                                  <Field
                                    id="description"
                                    component={TextField}
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    multiline
                                    rows={5}
                                    variant="outlined"
                                    placeholder="Descreva o canal e qual será sua função…"
                                    error={
                                      errors.description && touched.description
                                    }
                                    helperText={
                                      touched.description && errors.description
                                    }
                                  />
                                </Box>
                              </Grid>
                            </FormDetails>
                          </Group>
                        </Grid>

                        <FormGroup item xs={12}>
                          <Group>
                            <Heading level={2} icon={GroupIcon}>
                              Público do canal
                            </Heading>

                            <Box mt={1.5}>
                              <Grid container spacing={5}>
                                <Grid item xs={6}>
                                  <Tooltip
                                    title="Canal liberado para todos os alunos"
                                    placement="top"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled={selectedChannel}
                                          checked={values.public}
                                          onChange={(event, publicVal) => {
                                            setFieldValue("studentCourses", []);
                                            setFieldValue("studentClasses", []);
                                            setFieldValue("studentSeries", []);
                                            handleChange(event, publicVal);
                                          }}
                                          onBlur={handleBlur}
                                          id="public"
                                          name="public"
                                          color="primary"
                                        />
                                      }
                                      label="Canal Público "
                                    />
                                  </Tooltip>
                                  <Typography variant="subtitle2" gutterBottom>
                                    (Não é possível modificar esta opção após a
                                    criação do canal)
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>

                            {!values.public && (
                              <Box py={4}>
                                <Grid container spacing={5}>
                                  <>
                                    <Grid item xs={12}>
                                      <Box mb={2} mt={-2}>
                                        <FormLabel component="legend">
                                          Especifique os{" "}
                                          <HighlightText>
                                            cursos e/ou turmas
                                          </HighlightText>
                                          :
                                        </FormLabel>
                                      </Box>
                                    </Grid>

                                    <Grid item sm={6}>
                                      <FilterField
                                        label="Ano Letivo"
                                        onChange={(event, yearsValues) => {
                                          setFieldValue(
                                            "schoolYears",
                                            yearsValues
                                          );
                                          this.getCoursesAndClasses(
                                            yearsValues
                                          );
                                        }}
                                        error={
                                          errors.schoolYears &&
                                          touched.schoolYears
                                        }
                                        helperText={
                                          touched.schoolYears &&
                                          errors.schoolYears
                                        }
                                        name="schoolYears"
                                        options={schoolYears}
                                        value={values.schoolYears}
                                      />
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Autocomplete
                                        multiple
                                        id="studentCourses"
                                        name="studentCourses"
                                        value={values.studentCourses}
                                        onBlur={handleBlur}
                                        onChange={(event, coursesValues) => {
                                          setFieldValue(
                                            "studentCourses",
                                            coursesValues
                                          );

                                          const filteredClasses = this.getClassesByCourses(
                                            coursesValues
                                          ).filter(
                                            classItem =>
                                              values.studentClasses.filter(
                                                i =>
                                                  i.CODIGO === classItem.CODIGO
                                              ).length
                                          );
                                          setFieldValue(
                                            "studentClasses",
                                            filteredClasses
                                          );

                                          handleChange(event, coursesValues);
                                        }}
                                        options={coursesAndClasses}
                                        getOptionLabel={option =>
                                          option && option.NOME
                                            ? option.NOME
                                            : "-"
                                        }
                                        getOptionSelected={(option, value) =>
                                          option.CODIGO === value.CODIGO
                                        }
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            label="Cursos *"
                                            margin="dense"
                                            fullWidth
                                            error={
                                              errors.studentCourses &&
                                              touched.studentCourses
                                            }
                                            helperText={
                                              touched.studentCourses &&
                                              errors.studentCourses
                                            }
                                          />
                                        )}
                                        disableCloseOnSelect
                                        renderOption={(
                                          option,
                                          { selected }
                                        ) => (
                                          <React.Fragment>
                                            <Checkbox
                                              color="primary"
                                              icon={
                                                <CheckBoxOutlineBlankIcon />
                                              }
                                              checkedIcon={<CheckBoxIcon />}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                            />
                                            {option && option.NOME
                                              ? option.NOME
                                              : "-"}
                                          </React.Fragment>
                                        )}
                                      />
                                    </Grid>
                                    {!!values.studentCourses.length && (
                                      <Grid item xs={6}>
                                        <Autocomplete
                                          multiple
                                          id="studentSeries"
                                          name="studentSeries"
                                          options={this.getSeriesByCourses(
                                            values.studentCourses
                                          )}
                                          getOptionLabel={option =>
                                            option ? option.NOME || "-" : "-"
                                          }
                                          getOptionSelected={(option, value) =>
                                            option.CODIGO === value.CODIGO
                                          }
                                          onChange={(event, seriesValues) => {
                                            setFieldValue(
                                              "studentSeries",
                                              seriesValues
                                            );
                                          }}
                                          value={values.studentSeries}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label="Séries"
                                              margin="dense"
                                              fullWidth
                                              error={
                                                errors.studentSeries &&
                                                touched.studentSeries
                                              }
                                              helperText={
                                                touched.studentSeries &&
                                                errors.studentSeries
                                              }
                                            />
                                          )}
                                          disableCloseOnSelect
                                          renderOption={(
                                            option,
                                            { selected }
                                          ) => (
                                            <React.Fragment>
                                              <Checkbox
                                                color="primary"
                                                icon={
                                                  <CheckBoxOutlineBlankIcon />
                                                }
                                                checkedIcon={<CheckBoxIcon />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option
                                                ? option.NOME || "-"
                                                : "-"}
                                            </React.Fragment>
                                          )}
                                        />
                                      </Grid>
                                    )}
                                    {!!values.studentCourses.length && (
                                      <Grid item xs={6}>
                                        <Autocomplete
                                          multiple
                                          id="studentClasses"
                                          name="studentClasses"
                                          options={this.getClassesByCourses(
                                            values.studentCourses
                                          )}
                                          getOptionLabel={option =>
                                            option ? option.NOME || "-" : "-"
                                          }
                                          getOptionSelected={(option, value) =>
                                            option.CODIGO === value.CODIGO
                                          }
                                          onChange={(event, classesValues) => {
                                            setFieldValue(
                                              "studentClasses",
                                              classesValues
                                            );
                                          }}
                                          value={values.studentClasses}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label="Turmas"
                                              margin="dense"
                                              fullWidth
                                              error={
                                                errors.studentClasses &&
                                                touched.studentClasses
                                              }
                                              helperText={
                                                touched.studentClasses &&
                                                errors.studentClasses
                                              }
                                            />
                                          )}
                                          disableCloseOnSelect
                                          renderOption={(
                                            option,
                                            { selected }
                                          ) => (
                                            <React.Fragment>
                                              <Checkbox
                                                color="primary"
                                                icon={
                                                  <CheckBoxOutlineBlankIcon />
                                                }
                                                checkedIcon={<CheckBoxIcon />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option
                                                ? option.NOME || "-"
                                                : "-"}
                                            </React.Fragment>
                                          )}
                                        />
                                      </Grid>
                                    )}
                                  </>
                                </Grid>
                              </Box>
                            )}

                            {/* <Divider /> */}
                            {/* <Group.GroupFooter> */}
                            {/*  <ButtonBlock */}
                            {/*    startIcon={<HowToRegIcon />} */}
                            {/*    disabled={ */}
                            {/*      !values.studentCourses.length */}
                            {/*    } */}
                            {/*    onClick={() => */}
                            {/*      this.setState({ studentSelectorIsOpen: true }) */}
                            {/*    } */}
                            {/*  > */}
                            {/*    Gerenciar público */}
                            {/*  </ButtonBlock> */}
                            {/* </Group.GroupFooter> */}
                          </Group>
                        </FormGroup>

                        <FormGroup item xs={12}>
                          <Group>
                            <Heading level={2} icon={GroupIcon}>
                              Profissionais responsáveis pelo canal
                            </Heading>

                            <Box py={4}>
                              <Grid container spacing={5}>
                                <Grid item xs={12}>
                                  <Box mb={5} mt={-2}>
                                    <FormLabel component="legend">
                                      Especifique os{" "}
                                      <HighlightText>
                                        funcionários
                                      </HighlightText>
                                      :
                                    </FormLabel>
                                  </Box>
                                  <Autocomplete
                                    multiple
                                    id="employees"
                                    name="employees"
                                    value={values.employees}
                                    onChange={(event, employeesValues) => {
                                      setFieldValue(
                                        "employees",
                                        employeesValues
                                      );
                                      handleChange(event);
                                    }}
                                    options={employees}
                                    getOptionLabel={option => option.name}
                                    getOptionSelected={(option, value) =>
                                      option.id === value.id
                                    }
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        label="Funcionários"
                                        margin="dense"
                                        fullWidth
                                        error={
                                          errors.employees && touched.employees
                                        }
                                        helperText={
                                          touched.employees && errors.employees
                                        }
                                      />
                                    )}
                                    disableCloseOnSelect
                                    renderOption={(option, { selected }) => {
                                      return (
                                        <React.Fragment>
                                          <Checkbox
                                            color="primary"
                                            icon={<CheckBoxOutlineBlankIcon />}
                                            checkedIcon={<CheckBoxIcon />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.name}
                                        </React.Fragment>
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Group>
                        </FormGroup>
                      </>
                    )}

                    <ModalReceiversSelector
                      isOpen={studentSelectorIsOpen}
                      onClose={() =>
                        this.setState({ studentSelectorIsOpen: false })
                      }
                      studentClasses={values.studentClasses}
                      studentCourses={values.studentCourses}
                      setSubscribers={(type, subscribers) =>
                        setFieldValue(type, subscribers)
                      }
                    />
                  </Grid>

                  {!loadingChannel && (
                    <FormFooter>
                      <Grid container justify="space-around">
                        {selectedChannel && (
                          <Can I="delete" a="channel">
                            <Grid item xs={12} sm={5}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="error"
                                disabled={loadingRemove || loadingSubmit}
                                fullWidth
                                onClick={this.onDelete}
                              >
                                {loadingRemove && (
                                  <CircularProgress size={20} />
                                )}
                                Excluir
                              </Button>
                            </Grid>
                          </Can>
                        )}

                        <Grid item xs={12} sm={5}>
                          {isInvalid() && (
                            <FormHelperText error>
                              Formulário inválido
                            </FormHelperText>
                          )}
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={
                              loadingRemove ||
                              loadingSubmit ||
                              loadingUpload ||
                              isInvalid()
                            }
                            fullWidth
                            onClick={() => {
                              validateForm().then(() => {
                                if (isValid) handleSubmit();
                              });
                            }}
                          >
                            {loadingSubmit && <CircularProgress size={20} />}
                            <Box ml={0.5}>Salvar</Box>
                          </Button>
                        </Grid>
                      </Grid>
                    </FormFooter>
                  )}
                </>
              );
            }}
          </Formik>
        </ScrollColumnBody>
      </>
    );
  }
}

NewChannelColumn.defaultProps = {
  selectedChannel: null
};

NewChannelColumn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedChannel: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(NewChannelColumn);
