import React from "react";
import PropTypes from "prop-types";
import { withTheme, Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import RoundedAvatar from "../waybee-ui/RoundedAvatar";

const Row = styled(Grid)`
  padding: 18px 30px;
  border-top: 2px solid #f2f2f2;

  &:last-of-type {
    border-bottom: 2px solid #f2f2f2;
  }

  ${({ hasClick }) =>
    hasClick &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${({ color }) => color};
      }
    `}
`;

const User = styled(Grid)`
  display: flex;
  align-items: center;
`;
const UserInfos = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;

  p {
    margin: 0;
  }
`;
const UserName = styled.p`
  color: black;
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
`;
const UserRegister = styled.p`
  color: ${({ hasRightInfo, color }) =>
    hasRightInfo ? color : "rgba(0, 0, 0, 0.5)"};
  font-size: 16px;
  line-height: 20px;

  && {
    margin-top: 6px;
  }
`;

const Description = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 20px;

  && {
    margin-top: 6px;
  }
`;

const UserItem = ({
  photoUrl,
  name,
  info,
  description,
  photoSize,
  rightInfo,
  onClick,
  theme
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Row
      hasClick={!!onClick}
      color={theme.palette.primary.light}
      onClick={handleClick}
      alignItems="center"
      container
    >
      <User item xs={5}>
        <RoundedAvatar size={photoSize} icon="person" imageSrc={photoUrl}>
          {name}
        </RoundedAvatar>
        <UserInfos>
          <UserName>{name}</UserName>
          {info && (
            <UserRegister
              color={theme.palette.primary.main}
              hasRightInfo={!!rightInfo}
            >
              {info}
            </UserRegister>
          )}
        </UserInfos>
      </User>

      <Grid item xs={4}>
        <Description>{description}</Description>
      </Grid>

      {rightInfo}
    </Row>
  );
};

UserItem.defaultProps = {
  onClick: null,
  photoSize: 70,
  photoUrl: "",
  info: "",
  description: "",
  rightInfo: null
};

UserItem.propTypes = {
  name: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
  info: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  photoSize: PropTypes.number,
  rightInfo: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // inject by withTheme
};

export default withTheme(UserItem);
