import { api } from "../utils/api";

const getGraphicData = async () => {
  const graphData = [
    { name: "20/09/2021", responsible: 60, students: 50, family: 30 },
    { name: "21/09/2021", responsible: 70 },
    { name: "22/09/2021" },
    { name: "23/09/2021" },
    { name: "24/09/2021", responsible: 80, students: 40 },
    { name: "25/09/2021" },
    { name: "26/09/2021", responsible: 80, students: 40, family: 30 }
  ];

  await new Promise(resolve => setTimeout(resolve, 1000));

  return graphData;
};

const getAccessionData = async () => {
  const accessionData = {
    responsibles: {
      value: 80,
      additionalValue: 20
    },
    students: {
      value: 40,
      additionalValue: 0
    },
    family: {
      value: 30
    }
  };
  await new Promise(resolve => setTimeout(resolve, 1000));

  return accessionData;
};

const getResponsibles = async (page, filter) => {
  const res = await api.get("/SchoolManagements/guardians", {
    params: {
      page,
      itemsPerPage: 20,
      filter
    }
  });
  return res.data;
};

const getStudents = async (page, filter) => {
  const res = await api.get("/SchoolManagements/students", {
    params: {
      page,
      itemsPerPage: 20,
      filter
    }
  });
  return res.data;
};

const getStudent = async ra => {
  const res = await api.get(`/SchoolManagements/student/${ra}`);
  return res.data;
};

const getResponsibleDetails = async userId => {
  const res = await api.get(`/SchoolManagements/guardians/${userId}`);
  return res.data;
};

const editUser = async (userId, data) => {
  const res = await api.post(`/SchoolManagements/guardians/${userId}`, {
    ...data
  });
  return res.data;
};

const changeStatus = async (userId, status) => {
  const res = await api.post(`/SchoolManagements/guardians/${userId}/status`, {
    status
  });
  return res.data;
};

const sendConfirmationEmail = async userId => {
  const res = await api.post("WaybeeUsers/sendConfirmationEmail", { userId });
  return res.data;
};

const sendPasswordEmail = async userId => {
  const res = await api.post("WaybeeUsers/requestPasswordReset", { userId });
  return res.data;
};

const getEmailLink = async userId => {
  const res = await api.get("WaybeeUsers/getConfirmationEmailLink", {
    params: { userId }
  });
  return res.data;
};

const getPasswordLink = async userId => {
  const res = await api.get("WaybeeUsers/getPasswordResetLink", {
    params: { userId }
  });
  return res.data;
};

const getSchoolYears = async () => {
  const res = await api.get("SchoolManagements/schoolYears");
  return res.data;
};

const syncStudent = async (studentId, campusId, module) => {
  const res = await api.post(`WaybeeUsers/student/${studentId}/sync`, [
    {
      job: module,
      payload: { campusId }
    }
  ]);
  return res.data;
};

const registerResponsible = async (campus, module) => {
  const res = await api.post(`WaybeeUsers/responsible/register`, {
    job: module,
    payload: { campus }
  });
  return res.data;
};

const createStudentUser = async studentId => {
  const res = await api.post(`WaybeeUsers/student/${studentId}/create`);
  return res.data;
};

const getGroupsByUser = async userId => {
  const res = await api.get(`UserGroupUsers/group/${userId}`);
  return res.data;
};

const getStatus = async () => {
  return [
    {
      id: "USER_NOT_REGISTERED",
      name: "Contato não cadastrado"
    },
    {
      id: "EMAIL_NOT_ACTIVATED",
      name: "E-mail não ativado"
    },
    {
      id: "PENDING_REGISTER",
      name: "Cadastro pendente de aprovação"
    },
    {
      id: "CANCELED_REGISTER",
      name: "Cadastro recusado"
    },
    {
      id: "COMPLETE",
      name: "Cadastro completo"
    },
    {
      id: "DELETED",
      name: "Usuário deletado"
    },
    {
      id: "INACTIVE",
      name: "Usuário inativo"
    }
  ];
};

const getInstallationDetails = async userId => {
  const res = await api.get(`WaybeeUsers/${userId}/installation`);
  return res.data;
};

export default {
  getGraphicData,
  getAccessionData,
  getResponsibles,
  getStudents,
  getStudent,
  getResponsibleDetails,
  editUser,
  changeStatus,
  sendConfirmationEmail,
  sendPasswordEmail,
  getEmailLink,
  getPasswordLink,
  getSchoolYears,
  syncStudent,
  registerResponsible,
  getStatus,
  createStudentUser,
  getGroupsByUser,
  getInstallationDetails
};
