import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@material-ui/core";
import styled from "styled-components";

const TipText = styled.p`
  color: ${({ theme }) => theme.color.primary.main};
`;

const AttachmentsList = ({ attachments, onDelete }) => {
  const copyToClipboard = str => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <>
      {attachments.map(attachment => {
        return (
          <Chip
            onClick={() => copyToClipboard(attachment.file.url)}
            key={attachment.id}
            label={attachment.file.name}
            onDelete={() => onDelete(attachment)}
          />
        );
      })}
      <TipText>* Clique no item para copiar a url do arquivo</TipText>
    </>
  );
};

AttachmentsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  attachments: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default AttachmentsList;
