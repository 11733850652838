import {
  SET_CAMPUSES_VALUE,
  SET_ACCESSION_RESPONSIBLE,
  SET_ACCESSION_STUDENT,
  LOAD_THEME
} from "./actionTypes";

const createAction = type => {
  return value => ({
    type,
    payload: value
  });
};

export const setCampuses = value => ({
  type: SET_CAMPUSES_VALUE,
  campuses: value
});
export const setAccessionResponsible = createAction(SET_ACCESSION_RESPONSIBLE);
export const setAccessionStudent = createAction(SET_ACCESSION_STUDENT);
export const loadTheme = createAction(LOAD_THEME);
