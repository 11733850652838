import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar as MuiAvatar, Box, Grid, Typography } from "@material-ui/core";
import {
  BorderColor,
  DeleteOutlined,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../waybee-ui/Dialog/ConfirmDialog";
import { Can } from "../../../acl/can";
import ActionButton from "./ActionButton";

const ChannelItemContainer = styled(Grid)`
  background-color: #ffffff;
  border-radius: 16px;
  margin: 4px 0;
  min-height: 84px;
  :hover {
    cursor: pointer;
    background-color: #e3e3e3;
  }
`;
const ChannelActions = styled(Grid)`
  display: flex;
  justify-content: center;
  z-index: 1;
`;
const Avatar = styled(MuiAvatar)`
  width: 60px;
  height: 60px;
`;
const ChannelTitle = styled.p`
  color: #0d062d;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  text-align: center;
`;
const ChannelItemDescription = styled(Typography)`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #787486;
  text-align: justify;
`;
const ChannelItemCategory = styled(ChannelItemDescription)`
  text-align: center;
`;

const ChannelInfoContainer = styled(Grid)`
  padding-left: 18px;
`;

const TicketChannelItem = ({
  channel,
  onClickDelete,
  onClickEnableVisibility
}) => {
  const { title, description, photoAvatar, category, id, isVisible } = channel;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [action, setAction] = useState(null);
  const [dialog, setDialog] = useState({ title: "", message: "" });
  const history = useHistory();

  const confirmDialogQuestion = selectedAction => {
    switch (selectedAction) {
      case "visibility":
        setDialog({
          title: `Deseja realmente ${
            isVisible ? "desativar" : "ativar"
          } o canal?`,
          message: `Ao ${
            isVisible ? "desativar" : "ativar"
          } o canal, os usuários ${
            isVisible ? "não terão mais" : "terão"
          } acesso a este canal de atendimento.`
        });
        break;
      case "exclude":
        setDialog({
          title: "Deseja realmente excluir o canal?",
          message: "Ao excluir o canal, os atendimentos serão excluídos"
        });
        break;
      default:
        break;
    }

    setAction(selectedAction);
    setShowDeleteDialog(true);
  };

  const onConfirmDialog = () => {
    switch (action) {
      case "visibility":
        onClickEnableVisibility(id, !isVisible);
        break;
      case "exclude":
        onClickDelete(id);
        break;
      default:
        break;
    }
  };

  return (
    <ChannelItemContainer container spacing={2} xs={12} alignItems="center">
      <ChannelInfoContainer
        container
        xs={10}
        alignItems="center"
        onClick={() => history.push(`/ticket-channels/${id}`)}
      >
        <Grid item xs={1}>
          <Avatar src={photoAvatar} />
        </Grid>
        <Grid item xs={3}>
          <ChannelTitle>{title}</ChannelTitle>
        </Grid>
        <Grid item xs={5}>
          <ChannelItemDescription
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Grid>
        <Grid item xs={3}>
          <ChannelItemCategory>{category.name}</ChannelItemCategory>
        </Grid>
      </ChannelInfoContainer>

      <Can I="create" a="ticketsCommunication">
        <ChannelActions item xs={2}>
          <Box mr={0.8}>
            <ActionButton
              color="#00303B"
              onClick={() => history.push(`/ticket-channels/${id}/edit`)}
            >
              <BorderColor />
            </ActionButton>
          </Box>
          <Box mr={0.8}>
            <ActionButton
              color="#FF0000"
              onClick={() => confirmDialogQuestion("exclude")}
            >
              <DeleteOutlined />
            </ActionButton>
          </Box>
          <Box mr={0.8}>
            <ActionButton
              color={isVisible ? "#00303B" : "#FF"}
              onClick={() => confirmDialogQuestion("visibility")}
            >
              {isVisible ? <Visibility /> : <VisibilityOff color="disabled" />}
            </ActionButton>
          </Box>
        </ChannelActions>
      </Can>

      {showDeleteDialog && (
        <ConfirmDialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={onConfirmDialog}
          cancelBtnText="Voltar"
          title={dialog.title}
          message={dialog.message}
          confirmBtnText="Confirmar"
        />
      )}
    </ChannelItemContainer>
  );
};

TicketChannelItem.propTypes = {
  channel: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    photoAvatar: PropTypes.string.isRequired,
    category: PropTypes.objectOf().isRequired,
    id: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired
  }).isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEnableVisibility: PropTypes.func.isRequired
};

export default TicketChannelItem;
