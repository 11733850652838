import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Typography
} from "@material-ui/core";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import SchoolGroupService from "../../services/SchoolGroupService";
import Button from "../../waybee-ui/Button";
import ReceiversSelector from "./components/ReceiversSelector";
import ReceiversList from "./components/ReceiversList";
import MuiCollapseGroup from "../../waybee-ui/CollapseGroup/CollapseGroup";

const CollapseGroup = styled(MuiCollapseGroup)`
  margin: 0 -20px;
`;

class ModalConfirm extends Component {
  state = {
    isLoading: false
  };

  loadStudents = async () => {
    const {
      studentSubscribers: selectedStudents,
      setSubscribers,
      studentCourses,
      studentClasses,
      employeeCourses,
      employeeClasses,
      employeeTypes,
      excludedIds
    } = this.props;
    this.setState({ isLoading: true });

    const studentCourseIds = studentCourses.map(e => e.CODIGO);
    const studentClassIds = studentClasses.map(e => e.CODIGO);

    let studentSubscribers = [];
    if (studentCourseIds.length) {
      const students = await SchoolGroupService.getDestinationUsers(
        studentCourseIds,
        studentClassIds
      );

      studentSubscribers = students.map(student => {
        return {
          ...student,
          ...{
            checked: selectedStudents.length
              ? selectedStudents.some(
                  selected => selected.id === student.id && selected.checked
                )
              : true
          }
        };
      });
      let excludedIdsList = [];
      if (excludedIds) excludedIdsList = excludedIds.split(",");
      studentSubscribers = studentSubscribers.map(student => {
        const studentClone = student;
        excludedIdsList.forEach(id => {
          if (parseFloat(id) === student.id) {
            studentClone.checked = false;
          }
        });
        return studentClone;
      });
    }
    setSubscribers("studentSubscribers", studentSubscribers);

    const employeeCoursesIds = employeeCourses.map(e => e.CODIGO);
    const employeeClassIds = employeeClasses.map(e => e.CODIGO);
    const employeeTypesIds = employeeTypes.map(e => e.CODIGO);
    if (employeeCoursesIds.length || employeeTypesIds.length) {
      const employees = await SchoolGroupService.getDestinationEmployees(
        employeeCoursesIds,
        employeeClassIds,
        employeeTypesIds
      );
      const filteredEmployee = employees.map(employee => {
        return { ...employee, ...{ checked: true } };
      });
      setSubscribers("employeeSubscribers", filteredEmployee);
    }

    this.setState({ isLoading: false });
  };

  handleOpen = () => {
    this.loadStudents();
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleSubmit = () => {
    const { onConfirm } = this.props;
    onConfirm();
  };

  render() {
    const { isLoading } = this.state;
    const {
      isOpen,
      textAction,
      studentSubscribers,
      employeeSubscribers
    } = this.props;

    const checkedStudents = studentSubscribers.filter(e => e.checked);
    const removedStudents = studentSubscribers.filter(e => !e.checked);
    const checkedEmployees = employeeSubscribers.filter(e => e.checked);
    const removedEmployees = employeeSubscribers.filter(e => !e.checked);

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        onEnter={this.handleOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle onClose={this.handleClose}>
          {`${textAction} Comunicação`}
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Verifique os destinatários desta atividade antes de enviá-la
            </Typography>
          </Box>

          {isLoading && <CircularProgress />}

          {!isLoading && checkedStudents.length > 0 && (
            <CollapseGroup divider={false} fontSize={16} title="Alunos">
              <ReceiversSelector
                users={checkedStudents}
                disabled
                isLoading={isLoading}
              />
            </CollapseGroup>
          )}

          {checkedEmployees.length > 0 && (
            <CollapseGroup
              divider={false}
              fontSize={16}
              title="Professores/Coordenadores"
            >
              <ReceiversSelector
                users={checkedEmployees}
                disabled
                isLoading={isLoading}
              />
            </CollapseGroup>
          )}

          {removedStudents.length + removedEmployees.length > 0 && (
            <Box my={1} mb={3} mt={2}>
              <FormLabel>Destinatários retirados da comunicação</FormLabel>

              {removedStudents.length > 0 && (
                <CollapseGroup divider={false} fontSize={16} title="Alunos">
                  <ReceiversList users={removedStudents} />
                </CollapseGroup>
              )}
              {removedEmployees.length > 0 && (
                <CollapseGroup
                  divider={false}
                  fontSize={16}
                  title="Professores/Coordenadores"
                >
                  <ReceiversList users={removedEmployees} />
                </CollapseGroup>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleClose}
            color="secondary"
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleSubmit}
            disabled={checkedStudents.length + checkedEmployees.length === 0}
            color="primary"
          >
            {textAction}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalConfirm.propTypes = {
  studentSubscribers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  employeeSubscribers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSubscribers: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  studentClasses: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ).isRequired,
  studentCourses: PropTypes.arrayOf(
    PropTypes.shape({
      NOME: PropTypes.string
    })
  ).isRequired,
  employeeClasses: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ).isRequired,
  employeeCourses: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ).isRequired,
  employeeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ).isRequired,
  textAction: PropTypes.string.isRequired,
  excludedIds: PropTypes.string
};

ModalConfirm.defaultProps = {
  excludedIds: ""
};

export default ModalConfirm;
