import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import PFDIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Collections";
import CodeIcon from "@material-ui/icons/Code";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import Divider from "@material-ui/core/Divider";
import AttachmentsItem from "./AttachmentsItem";
import { theme } from "../../../../../assets/theme/StyledTheme";

class AttachmentList extends Component {
  getIconByType = type => {
    switch (type) {
      case "application/pdf":
        return (
          <PFDIcon
            style={{ color: theme.color.primary.dark }}
            viewBox="0 0 29 29"
          />
        );
      case "image/jpeg":
      case "image/png":
        return (
          <ImageIcon
            style={{ color: theme.color.primary.dark }}
            viewBox="0 0 29 29"
          />
        );
      case "text/html":
        return (
          <CodeIcon
            style={{ color: theme.color.primary.dark }}
            viewBox="0 0 29 29"
          />
        );
      default:
        return (
          <AttachmentIcon
            style={{ color: theme.color.primary.dark }}
            viewBox="0 0 29 29"
          />
        );
    }
  };

  getMinimizedList = () => {
    const { attachments } = this.props;
    return (
      <div>
        <Divider component="div" />
        <List className="list-attachments">
          <AttachmentsItem
            text={`${attachments.length} Anexo${
              attachments.length > 1 ? "s" : ""
            }`}
            icon={this.getIconByType(null)}
          />
        </List>
      </div>
    );
  };

  render() {
    const { attachments, minimize } = this.props;

    if (minimize) return this.getMinimizedList();

    return (
      <div>
        <Divider component="div" />
        <List className="list-attachments">
          {attachments.map(attachment => {
            return (
              <AttachmentsItem
                key={
                  attachment.url + (attachment.customName || attachment.name)
                }
                text={attachment.customName || attachment.name}
                icon={this.getIconByType(attachment.type)}
                onClick={() => {
                  if (attachment.url) {
                    window.open(attachment.url, "_system");
                  }
                }}
              />
            );
          })}
        </List>
      </div>
    );
  }
}

AttachmentList.defaultProps = {
  attachments: [],
  minimize: false
};

AttachmentList.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      customName: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.url
    })
  ),
  minimize: PropTypes.bool
};

export default AttachmentList;
