import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, withTheme } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { withAlert } from "react-alert";
import BackButton from "../../components/BackButton";
import LoadingContent from "../../components/LoadingContent";
import ActivitiesService, { enums } from "../../services/ActivitiesService";
import BugsnagService from "../../services/BugsnagService";
import GroupDetails from "./components/GroupDetails";
import GroupContent from "./components/GroupContent";
import NotificationActivitiesService from "../../services/NotificationActivitiesService";
import GroupSubscribers from "./components/GroupSubscribers";
import GroupNotification from "./components/GroupNotification";
import Button from "../../waybee-ui/Button";
import ConfirmDialog from "../../waybee-ui/Dialog/ConfirmDialog";
import SelectScheduleDialog from "../../components/SelectScheduleDialog";
import { Can } from "../../acl/can";
import GroupCharts from "./components/GroupCharts";
import GroupHistory from "../../components/GroupHistory/GroupHistory";

class ActivityDetails extends Component {
  state = {
    data: {},
    loading: false,
    notifications: [],
    loadingNotifications: false,
    statistics: {},
    loadingStatistics: false,
    subscriberCount: null,
    author: {},
    openCancelSchedule: false,
    openDeleteData: false,
    openSendData: false,
    openSchedule: false,
    deleted: false,
    schedule: null,
    openEditData: false,
    audits: [],
    loadingAudits: false
  };

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Relatório da Atividade");
    this.getData();
    this.getStatistics();
    this.getAudits();
    this.getNotifications();
  }

  getData = async () => {
    const { match, openAlert } = this.props;
    const { id } = match.params;
    this.setState({ loading: true });
    try {
      const details = await ActivitiesService.getData(id);

      this.setState({
        data: details.data,
        subscriberCount: details.subscribers.length,
        author: details.user
      });
    } catch (e) {
      openAlert({
        status: "error",
        message: e.message,
        onClose: () => window.location.replace("/activities")
      });
      BugsnagService.notifyError(
        e,
        `Erro ao obter detalhes da atividade nº ${id}`
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  getAudits = async () => {
    this.setState({ loadingAudits: true });
    try {
      const {
        match: {
          params: { id }
        }
      } = this.props;
      const res = await ActivitiesService.getAudits(id);

      this.setState({
        audits: res.data
      });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      alert.show("Erro ao buscar histórico", {
        title: "Ocorreu um erro"
      });
    } finally {
      this.setState({ loadingAudits: false });
    }
  };

  getStatistics = async () => {
    this.setState({ loadingStatistics: true });
    try {
      const { match } = this.props;
      const { id } = match.params;

      const res = await ActivitiesService.getStatistics(id);

      this.setState({
        statistics: res.data
      });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      alert.show("Erro ao buscar estatísticas", {
        title: "Ocorreu um erro"
      });
    } finally {
      this.setState({ loadingStatistics: false });
    }
  };

  async getNotifications() {
    this.setState({ loadingNotifications: true });
    try {
      const {
        match: {
          params: { id }
        }
      } = this.props;
      const notifications = await NotificationActivitiesService.getNotifications(
        id
      );
      this.setState({
        notifications: notifications.data,
        loadingNotifications: false
      });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      alert.show("Erro ao buscar notificações", {
        title: "Ocorreu um erro"
      });
    } finally {
      this.setState({ loadingNotifications: false });
    }
  }

  reloadNotifications = async () => {
    await this.getNotifications();
  };

  dataAction = async action => {
    const { data, schedule } = this.state;
    const { openAlert, match, alert } = this.props;
    const { id } = match.params;

    this.setState({ loading: true });

    try {
      switch (action) {
        case "cancelSchedule":
          await ActivitiesService.cancelSchedule(data.id);
          this.setState({ openCancelSchedule: false }, () => {
            alert.show(
              "O agendamento da atividade foi cancelado com sucesso!",
              {
                title: "Agendamento Cancelado",
                onClose: this.getData
              }
            );
          });
          break;
        case "delete":
          await ActivitiesService.remove(data.id);
          alert.show("A atividade foi excluída com sucesso!", {
            title: "Atividade Excluída",
            onClose: () => this.setState({ deleted: true })
          });
          break;
        case "send":
          await ActivitiesService.send(data.id);
          this.setState({ openSendData: false }, () => {
            alert.show("Atividade enviada com sucesso!", {
              title: "Atividade Enviada",
              onClose: this.getData
            });
          });
          break;
        case "schedule":
          await ActivitiesService.schedule(data.id, schedule);
          this.setState({ openSchedule: false }, () => {
            alert.show("Atividade agendada com sucesso!", {
              title: "Atividade Agendada",
              onClose: this.getData
            });
          });
          break;
        case "edit":
          window.location.replace(`/activity/${id}/edit`);
          break;
        default:
      }
    } catch (e) {
      openAlert({
        status: "error",
        message: e.message,
        onClose: () => window.location.replace("/activities")
      });
      BugsnagService.notifyError(
        e,
        `Erro ao executar ação ${action} na atividade nº ${id}`
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      data,
      loading,
      notifications,
      loadingNotifications,
      subscriberCount,
      author,
      openCancelSchedule,
      openDeleteData,
      openSendData,
      openSchedule,
      deleted,
      schedule,
      openEditData,
      statistics,
      loadingStatistics,
      audits,
      loadingAudits
    } = this.state;

    const { theme } = this.props;

    if (deleted) {
      return <Redirect to="/activities" />;
    }

    return (
      <Fragment>
        {loading && <LoadingContent />}

        <Grid container spacing={2}>
          <BackButton to="/activities" />

          {data && data.id && (
            <>
              <Grid item xs={12} sm={4}>
                <GroupDetails
                  data={data}
                  author={author}
                  subscriberCount={subscriberCount}
                  enumsStatus={enums.status}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <GroupContent data={data} />
              </Grid>
              <Grid item xs={12}>
                <GroupNotification
                  notifications={notifications}
                  data={data}
                  reload={this.reloadNotifications}
                  enumsStatus={enums.status}
                  loading={loadingNotifications}
                />
              </Grid>
              <Grid item xs={12}>
                <GroupCharts
                  statistics={statistics ? statistics.counters : {}}
                  loading={loadingStatistics}
                />
              </Grid>
              <Grid item xs={12}>
                <GroupSubscribers
                  isAckRequired={data.isAckRequired}
                  destinations={statistics.destinations}
                  sent={data.status === enums.status.SENT}
                  destinationFilter={data.destinationFilter}
                  loading={loadingStatistics}
                />
              </Grid>

              <Grid item xs={12}>
                <GroupHistory audits={audits} loading={loadingAudits} />
              </Grid>

              {!data.deleted_at && (
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="center">
                    <Can I="edit" a="activity">
                      <Grid item xs={12} sm={6} lg={4}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => this.setState({ openEditData: true })}
                        >
                          Editar
                        </Button>
                      </Grid>
                    </Can>
                    {data.status === enums.status.SCHEDULED && (
                      <Grid item xs={12} sm={6} lg={4}>
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={() =>
                            this.setState({ openCancelSchedule: true })
                          }
                        >
                          Cancelar Agendamento
                        </Button>
                      </Grid>
                    )}
                    {data.status === enums.status.OPEN && (
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => this.setState({ openSchedule: true })}
                        >
                          Agendar Envio
                        </Button>
                      </Grid>
                    )}
                    {(data.status === enums.status.OPEN ||
                      data.status === enums.status.SCHEDULED) && (
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => this.setState({ openSendData: true })}
                        >
                          Enviar Agora
                        </Button>
                      </Grid>
                    )}
                    {(data.status === enums.status.OPEN ||
                      data.status === enums.status.SENT) && (
                      <Can I="delete" a="activity">
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            color="error"
                            fullWidth
                            onClick={() =>
                              this.setState({ openDeleteData: true })
                            }
                          >
                            Excluir
                          </Button>
                        </Grid>
                      </Can>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <ConfirmDialog
          title="Cancelar agendamento"
          message="Tem certeza que deseja cancelar o agendamento desta atividade?"
          onClose={() => this.setState({ openCancelSchedule: false })}
          onCancel={() => this.setState({ openCancelSchedule: false })}
          onConfirm={() => this.dataAction("cancelSchedule")}
          confirmBtnText="Cancelar Agendamento"
          confirmBtnColor={theme.palette.error.main}
          cancelBtnText="Voltar"
          open={openCancelSchedule}
        />

        <ConfirmDialog
          title="Excluir atividade"
          message="Tem certeza que deseja excluir essa atividade?"
          onClose={() => this.setState({ openDeleteData: false })}
          onCancel={() => this.setState({ openDeleteData: false })}
          onConfirm={() => this.dataAction("delete")}
          confirmBtnText="Excluir Atividade"
          confirmBtnColor={theme.palette.error.main}
          cancelBtnText="Voltar"
          open={openDeleteData}
        />

        <ConfirmDialog
          title="Enviar atividade"
          message="Tem certeza que deseja enviar essa atividade agora?"
          onClose={() => this.setState({ openSendData: false })}
          onCancel={() => this.setState({ openSendData: false })}
          onConfirm={() => this.dataAction("send")}
          confirmBtnText="Enviar"
          cancelBtnText="Voltar"
          open={openSendData}
        />

        <ConfirmDialog
          title="Editar atividade"
          message="Tem certeza que deseja editar essa atividade?"
          onClose={() => this.setState({ openEditData: false })}
          onCancel={() => this.setState({ openEditData: false })}
          onConfirm={() => this.dataAction("edit")}
          confirmBtnText="Confirmar"
          confirmBtnColor={theme.palette.primary.main}
          cancelBtnText="Voltar"
          open={openEditData}
        />

        <SelectScheduleDialog
          open={openSchedule}
          onClose={() => this.setState({ openSchedule: false })}
          value={schedule}
          onChange={value => this.setState({ schedule: value })}
          onConfirm={() => this.dataAction("schedule")}
          title="Agendamento de Atividade"
          message="Escolha a data e hora para a atividade ser enviada"
        />
      </Fragment>
    );
  }
}

ActivityDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired,
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // Injected by withTheme Hoc
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // Injected by withAlert hov
};

export default withAlert()(withTheme(ActivityDetails));
