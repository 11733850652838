import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core/";
import styled from "styled-components";
import Dialog from "./Dialog";
import DialogTitle from "./DialogTitle";
import DialogActions from "./DialogActions";
import DialogContent from "./DialogContent";

const Message = styled(Typography)`
  white-space: pre-line;
  line-height: 32px;
`;

const SimpleDialog = ({ onClose, open, title, message, actions, closeBtn }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth closeButton={closeBtn}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Message variant="span">{message}</Message>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

SimpleDialog.defaultProps = {
  open: false,
  actions: undefined,
  closeBtn: false
};

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  actions: PropTypes.node,
  closeBtn: PropTypes.bool
};

export default SimpleDialog;
