import MuiPaper from "@material-ui/core/Paper";
import styled from "styled-components";

const Group = styled(MuiPaper)`
  border-radius: 0;
  box-shadow: unset;
  padding: ${props => (props.p === undefined ? "30px" : "unset")};
  height: 100%;
`;

Group.GroupFooter = styled.div`
  margin: 0 -30px -30px -30px;
  width: calc(100% + 60px);
`;

Group.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ...MuiPaper.propTypes
};

export default Group;
