import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, withTheme } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { withAlert } from "react-alert";
import Alert from "@material-ui/lab/Alert";
import BackButton from "../../components/BackButton";
import LoadingContent from "../../components/LoadingContent";
import CommunicationService from "../../services/CommunicationService";
import BugsnagService from "../../services/BugsnagService";
import GroupDetails from "./components/GroupDetails";
import GroupContent from "./components/GroupContent";
import NotificationService from "../../services/NotificationService";
import GroupCharts from "./components/GroupCharts";
import GroupSubscribers from "./components/GroupSubscribers";
import GroupNotification from "./components/GroupNotification";
import Button from "../../waybee-ui/Button";
import ConfirmDialog from "../../waybee-ui/Dialog/ConfirmDialog";
import SelectScheduleDialog from "../../components/SelectScheduleDialog";
import GroupSurvey from "./components/GroupSurvey";
import { Can } from "../../acl/can";
import GroupHistory from "../../components/GroupHistory/GroupHistory";
import DestinationModal from "../CommunicationForm/components/DestinationModal";

const uuidV4 = require("uuid/v4");

class CommunicationDetails extends Component {
  state = {
    communication: {},
    loading: false,
    notifications: [],
    loadingNotifications: false,
    survey: undefined,
    loadingSurvey: false,
    subscriberCount: null,
    author: {},
    statistics: {},
    interactions: {
      acks: 0,
      clicks: 0,
      deliveries: 0,
      openings: 0,
      interests: 0,
      confirm: 0
    },
    loadingStatistics: false,
    openCancelSchedule: false,
    openDeleteCommunication: false,
    openSendCommunication: false,
    openSchedule: false,
    openEditData: false,
    openResendCommunication: false,
    openDestinationModal: false,
    deleted: false,
    schedule: null,
    excludedIds: undefined
  };

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Relatório da Comunicação");
    this.getCommunicationDetail();
    this.getNotifications();
    this.getAudits();
  }

  getCommunicationDetail = async () => {
    const { match, openAlert } = this.props;
    const { id } = match.params;
    this.setState({ loading: true });
    try {
      const details = await CommunicationService.getCommunicationDetail(id);
      this.setState(
        {
          communication: details.communication,
          author: details.user
        },
        () => {
          this.getSurvey(
            details.communication.surveyId,
            details.communication.id
          );
          this.getStatistics();
        }
      );
    } catch (e) {
      openAlert({
        status: "error",
        message: e.message,
        onClose: () => window.location.replace("/")
      });
      BugsnagService.notifyError(
        e,
        `Erro ao obter detalhes da comunicação nº ${id}`
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  async getStatistics() {
    this.setState({ loadingStatistics: true });
    try {
      const {
        match: {
          params: { id }
        }
      } = this.props;
      const statistics = await CommunicationService.getCommunicationStatistics(
        id
      );
      this.setState({
        subscriberCount: statistics.destinations.length,
        statistics,
        interactions: {
          acks: statistics.counters.acked,
          clicks: statistics.counters.clicked,
          deliveries: statistics.counters.received,
          openings: statistics.counters.opened,
          openingsBySubscribers: statistics.counters.opened_subscriber,
          interests: statistics.counters.interested,
          confirms: statistics.counters.confirmed
        }
      });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      alert.show("Erro ao buscar estatísticas", {
        title: "Ocorreu um erro"
      });
    } finally {
      this.setState({ loadingStatistics: false });
    }
  }

  async getSurvey(surveyId, communicationId) {
    if (!surveyId) return;
    this.setState({ loadingSurvey: true });
    try {
      const res = await CommunicationService.getSurveyStatistics(
        surveyId,
        communicationId
      );
      this.setState({ survey: res.data });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      alert.show("Erro ao buscar enquete", {
        title: "Ocorreu um erro"
      });
    } finally {
      this.setState({ loadingSurvey: false });
    }
  }

  async getAudits() {
    this.setState({ loadingAudits: true });
    try {
      const {
        match: {
          params: { id }
        }
      } = this.props;
      const audits = await CommunicationService.getAudits(id);
      this.setState({ audits });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      alert.show("Erro ao buscar histórico de edição", {
        title: "Ocorreu um erro"
      });
    } finally {
      this.setState({ loadingAudits: false });
    }
  }

  async getNotifications() {
    this.setState({ loadingNotifications: true });
    try {
      const {
        match: {
          params: { id }
        }
      } = this.props;
      const notifications = await NotificationService.getNotifications(id);
      this.setState({ notifications: notifications.data });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      alert.show("Erro ao buscar notificações", {
        title: "Ocorreu um erro"
      });
    } finally {
      this.setState({ loadingNotifications: false });
    }
  }

  reloadNotifications = async () => {
    await this.getNotifications();
  };

  communicationDestination = async () => {
    const { communication, statistics } = this.state;
    const excludedIds = communication.excludedDestinationIds
      ? communication.excludedDestinationIds.split(",")
      : [];
    communication.uuid = uuidV4();
    communication.resend = true;

    statistics.destinations.map(subscribers => {
      if (subscribers.statistics.opened !== null) {
        excludedIds.push(subscribers.id);
      }
      return undefined;
    });

    if (excludedIds.length !== 0) {
      communication.excludedDestinationIds = excludedIds;
      this.setState({ excludedIds: excludedIds.toString() });
    }

    this.setState({ openDestinationModal: true });
  };

  communicationAction = async action => {
    const { communication, schedule } = this.state;
    const { openAlert, match, alert } = this.props;
    const { id } = match.params;

    this.setState({ loading: true });

    const resend = communicationId => {
      window.location.replace(`/communication/${communicationId}`);
    };

    try {
      switch (action) {
        case "cancelSchedule":
          await CommunicationService.cancelSchedule(communication.id);
          this.setState({ openCancelSchedule: false }, () => {
            alert.show(
              "O agendamento da comunicação foi cancelado com sucesso!",
              {
                title: "Agendamento Cancelado",
                onClose: this.getCommunicationDetail
              }
            );
          });
          break;
        case "delete":
          await CommunicationService.deleteCommunication(communication.id);
          alert.show("A comunicação foi excluída com sucesso!", {
            title: "Comunicação Excluída",
            onClose: () => this.setState({ deleted: true })
          });
          break;
        case "send":
          await CommunicationService.sendCommunication(communication.id);
          this.setState({ openSendCommunication: false }, () => {
            alert.show("Comunicação enviada com sucesso!", {
              title: "Comunicação Enviada",
              onClose: this.getCommunicationDetail
            });
          });
          break;
        case "schedule":
          await CommunicationService.scheduleCommunication(
            communication.id,
            schedule
          );
          this.setState({ openSchedule: false }, () => {
            alert.show("Comunicação agendada com sucesso!", {
              title: "Comunicação Agendada",
              onClose: this.getCommunicationDetail
            });
          });
          break;
        case "edit":
          window.location.replace(`/communication/${id}/edit`);
          break;
        case "resendToAll":
          communication.resend = true;
          communication.uuid = uuidV4();
          await CommunicationService.saveCommunication(communication).then(
            copy => {
              this.setState({ openResendCommunication: false });
              alert.show("Uma cópia da comunicação foi criada!", {
                title: "Comunicação Reenviada",
                onClose: () => resend(copy.communication.id)
              });
            }
          );
          break;
        case "resendNonViewed":
          await CommunicationService.saveCommunication(communication).then(
            copy => {
              this.setState({
                openResendCommunication: false,
                openDestinationModal: false
              });
              alert.show("Uma cópia da comunicação foi criada!", {
                title: "Comunicação Reenviada",
                onClose: () => resend(copy.communication.id)
              });
            }
          );

          break;
        default:
      }
    } catch (e) {
      openAlert({
        status: "error",
        message: e.message,
        onClose: () => window.location.replace("/")
      });
      BugsnagService.notifyError(
        e,
        `Erro ao executar ação ${action} na comunicação nº ${id}`
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  alertMessage = () => {
    const { communication } = this.state;

    if (
      communication.destinationFilter.communicationRecipients === "responsibles"
    ) {
      return (
        <Alert severity="warning">
          <Typography>
            O comunicado será enviado para os responsáveis do aluno que não
            visualizaram o comunicado
          </Typography>
        </Alert>
      );
    }
    return null;
  };

  render() {
    const {
      communication,
      notifications,
      loadingNotifications,
      subscriberCount,
      author,
      interactions,
      statistics,
      loadingStatistics,
      openCancelSchedule,
      openDeleteCommunication,
      openSendCommunication,
      openSchedule,
      openEditData,
      openResendCommunication,
      openDestinationModal,
      deleted,
      schedule,
      survey,
      loadingSurvey,
      loading,
      audits,
      loadingAudits,
      excludedIds
    } = this.state;

    const { theme } = this.props;

    if (deleted) {
      return <Redirect to="/communication" />;
    }

    return (
      <Fragment>
        {loading && <LoadingContent />}

        <Grid container spacing={2}>
          <BackButton to="/communication" />

          {communication && communication.id && (
            <>
              <Grid item xs={12} sm={4}>
                <GroupDetails
                  communication={communication}
                  author={author}
                  subscriberCount={subscriberCount}
                  survey={survey}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <GroupContent communication={communication} />
              </Grid>

              <Grid item xs={12}>
                <GroupSurvey
                  survey={survey}
                  loading={loadingSurvey}
                  communicationId={communication.id}
                />
              </Grid>

              <Grid item xs={12}>
                <GroupNotification
                  notifications={notifications}
                  communication={communication}
                  reload={this.reloadNotifications}
                  loading={loadingNotifications}
                />
              </Grid>
              <Grid item xs={12} />
              {communication.status === "sent" && (
                <Grid item xs={12}>
                  <GroupCharts
                    communication={communication}
                    interactions={interactions}
                    subscriberCount={subscriberCount}
                    loading={loadingStatistics}
                    destinationFilter={communication.destinationFilter}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <GroupSubscribers
                  isEvent={communication.type.id === 2}
                  hasLinkButton={
                    communication.linkAddress && communication.buttonText
                  }
                  isAckRequired={communication.isAckRequired}
                  destinations={statistics ? statistics.destinations : null}
                  sent={communication.status === "sent"}
                  destinationFilter={communication.destinationFilter}
                  loading={loadingStatistics}
                  isSingleRecipient={
                    communication.destinationFilter &&
                    (communication.destinationFilter.communicationRecipients ===
                      "responsibles" ||
                      communication.destinationFilter
                        .communicationRecipients === "employees")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <GroupHistory audits={audits} loading={loadingAudits} />
              </Grid>

              {!communication.deleted_at && (
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="center">
                    <Can I="create" a="resendCommunication">
                      {communication.status === "sent" && (
                        <Grid item xs={12} sm={6} lg={4}>
                          <Button
                            variant="contained"
                            color="default"
                            fullWidth
                            onClick={() => {
                              this.setState({ openResendCommunication: true });
                            }}
                          >
                            Duplicar para reenvio
                          </Button>
                        </Grid>
                      )}
                    </Can>
                    <Can I="edit" a="communication">
                      <Grid item xs={12} sm={6} lg={4}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => this.setState({ openEditData: true })}
                        >
                          Editar
                        </Button>
                      </Grid>
                    </Can>
                    {communication.status === "scheduled" && (
                      <Grid item xs={12} sm={6} lg={4}>
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={() =>
                            this.setState({ openCancelSchedule: true })
                          }
                        >
                          Cancelar Agendamento
                        </Button>
                      </Grid>
                    )}
                    {communication.status === "open" && (
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => this.setState({ openSchedule: true })}
                        >
                          Agendar Envio
                        </Button>
                      </Grid>
                    )}
                    {communication.status === "open" && (
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() =>
                            this.setState({ openSendCommunication: true })
                          }
                        >
                          Enviar Agora
                        </Button>
                      </Grid>
                    )}
                    {(communication.status === "open" ||
                      communication.status === "sent") && (
                      <Can I="delete" a="communication">
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            color="error"
                            fullWidth
                            onClick={() =>
                              this.setState({ openDeleteCommunication: true })
                            }
                          >
                            Excluir
                          </Button>
                        </Grid>
                      </Can>
                    )}
                  </Grid>
                </Grid>
              )}
              <DestinationModal
                setSubscribers={() => {}}
                onClose={() => {
                  this.setState({ openDestinationModal: false });
                }}
                isOpen={openDestinationModal}
                simpleFilter={{
                  employeeClasses: communication.destinationFilter.employees
                    ? communication.destinationFilter.employees.classes
                    : [],
                  employeeTypes: communication.destinationFilter.employees
                    ? communication.destinationFilter.employees.types
                    : [],
                  employeeCourses: communication.destinationFilter.employees
                    ? communication.destinationFilter.employees.courses
                    : [],
                  studentClasses:
                    communication.destinationFilter.students.classes,
                  studentCourses:
                    communication.destinationFilter.students.courses,
                  destinationType:
                    communication.destinationFilter.communicationRecipients,
                  excludedIds
                }}
                advancedFilter={{
                  years: communication.destinationFilter.students.years,
                  series: communication.destinationFilter.students.series,
                  shift: communication.destinationFilter.students.shifts,
                  enrollmentTypes:
                    communication.destinationFilter.students.enrollmentTypes,
                  enrollmentSituation:
                    communication.destinationFilter.students.enrollmentSituation
                }}
                alert={this.alertMessage()}
                isConfirm
                onConfirm={() => this.communicationAction("resendNonViewed")}
                isResend
                confirmBtnText="Ok"
              />
            </>
          )}
        </Grid>

        <ConfirmDialog
          title="Duplicar/Copiar Comunicado"
          message="Esta ação não envia o comunicado automaticamente. Ela duplica o comunicado atual para que você possa reenviá-lo ao novo público. Selecione o público abaixo:"
          onClose={() => this.setState({ openResendCommunication: false })}
          onCancel={() => this.communicationAction("resendToAll")}
          onConfirm={() => this.communicationDestination()}
          confirmBtnText="Para quem não abriu"
          confirmBtnColor={theme.palette.primary.main}
          cancelBtnText="Para todos"
          open={openResendCommunication}
        />

        <ConfirmDialog
          title="Editar Comunicação"
          message="Tem certeza que deseja editar essa comunicação?"
          onClose={() => this.setState({ openEditData: false })}
          onCancel={() => this.setState({ openEditData: false })}
          onConfirm={() => this.communicationAction("edit")}
          confirmBtnText="Confirmar"
          confirmBtnColor={theme.palette.primary.main}
          cancelBtnText="Voltar"
          open={openEditData}
        />

        <ConfirmDialog
          title="Cancelar agendamento"
          message="Tem certeza que deseja cancelar o agendamento desta comunicação?"
          onClose={() => this.setState({ openCancelSchedule: false })}
          onCancel={() => this.setState({ openCancelSchedule: false })}
          onConfirm={() => this.communicationAction("cancelSchedule")}
          confirmBtnText="Cancelar Agendamento"
          confirmBtnColor={theme.palette.error.main}
          cancelBtnText="Voltar"
          open={openCancelSchedule}
        />

        <ConfirmDialog
          title="Excluir comunicação"
          message="Tem certeza que deseja excluir essa comunicação?"
          onClose={() => this.setState({ openDeleteCommunication: false })}
          onCancel={() => this.setState({ openDeleteCommunication: false })}
          onConfirm={() => this.communicationAction("delete")}
          confirmBtnText="Excluir Comunicação"
          confirmBtnColor={theme.palette.error.main}
          cancelBtnText="Voltar"
          open={openDeleteCommunication}
        />

        <ConfirmDialog
          title="Enviar comunicação"
          message="Tem certeza que deseja enviar essa comunicação agora?"
          onClose={() => this.setState({ openSendCommunication: false })}
          onCancel={() => this.setState({ openSendCommunication: false })}
          onConfirm={() => this.communicationAction("send")}
          confirmBtnText="Enviar"
          cancelBtnText="Voltar"
          open={openSendCommunication}
        />

        <SelectScheduleDialog
          open={openSchedule}
          onClose={() => this.setState({ openSchedule: false })}
          value={schedule}
          onChange={value => this.setState({ schedule: value })}
          onConfirm={() => this.communicationAction("schedule")}
          title="Agendamento de Comunicação"
          message="Escolha a data e hora para a comunicação ser enviada"
        />
      </Fragment>
    );
  }
}

CommunicationDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired,
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // Injected by withTheme Hoc
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // Injected by withAlert hov
};

export default withAlert()(withTheme(CommunicationDetails));
