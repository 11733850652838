import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { AppBar as MuiAppBar, Toolbar as MuiToolbar } from "@material-ui/core/";
import IconButton from "./IconButon";

const AppBar = styled(MuiAppBar)`
  background-color: #fff;
  box-shadow: none;
`;

const Toolbar = styled(MuiToolbar)`
  height: 90px;
  &,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.color.secondary.dark};
    font-weight: 300;
    font-size: 1.6rem;
  }
`;

const AppHeader = ({ children, className }) => (
  <AppBar className={className} position="static">
    <Toolbar>{children}</Toolbar>
  </AppBar>
);

AppHeader.IconButton = IconButton;

AppHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

AppHeader.defaultProps = {
  className: ""
};

export default AppHeader;
