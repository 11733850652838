import { api } from "../utils/api";

const getNotifications = async id => {
  return api.get(`Activities/${id}/getNotifications`);
};

const saveNotification = async (notification, id) => {
  return api.post(`Activities/${id}/createNotifications`, notification);
};

const editNotifications = async (notification, id) => {
  return api.put(
    `Activities/${id}/notifications/${notification.id}`,
    notification
  );
};

export default {
  getNotifications,
  saveNotification,
  editNotifications
};
