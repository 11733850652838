import { Ability } from "@casl/ability";
import AclService from "../services/AclService";

const createAbility = async () => {
  const res = await AclService.getAcl();

  if (!res) return [];

  return new Ability(res.data);
};

export default createAbility;
