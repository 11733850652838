import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box, IconButton, Popover } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import HeaderInfoTitle from "./HeaderInfoTitle";
import HeaderAvatar from "./HeaderAvatar";
import HeaderName from "./HeaderName";

const EyeIcon = styled(VisibilityOutlined)`
  color: #787486;
`;
const SupervisorButton = styled(IconButton)`
  padding: 6px;
`;

const TicketDetailSupervisors = ({ supervisors }) => {
  const [showSupervisors, setShowSupervisors] = useState(false);
  const [supervisorsAnchor, setSupervisorsAnchor] = useState();

  const onClickSupervisors = e => {
    setSupervisorsAnchor(e.currentTarget);
    setShowSupervisors(true);
  };

  return (
    <>
      <Box mb={1}>
        <HeaderInfoTitle>Acompanhado por</HeaderInfoTitle>
        <Box display="flex" alignItems="center" mt={-0.6}>
          <SupervisorButton onClick={onClickSupervisors}>
            <EyeIcon />
          </SupervisorButton>
          <HeaderName>{supervisors.count}</HeaderName>
        </Box>
      </Box>
      <Popover
        open={showSupervisors}
        anchorEl={supervisorsAnchor}
        onClose={() => setShowSupervisors(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box p={1} maxHeight={150}>
          {supervisors.users.map((user, idx) => (
            <Box display="flex" alignItems="center" mt={idx === 0 ? 0 : 1}>
              <HeaderAvatar src={user.photoProfile} />
              <HeaderName>{user.name}</HeaderName>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

TicketDetailSupervisors.propTypes = {
  supervisors: PropTypes.shape({
    count: PropTypes.number,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.name
      })
    )
  }).isRequired
};

export default TicketDetailSupervisors;
