import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import MuiAvatar from "@material-ui/core/Avatar";

const Avatar = styled(MuiAvatar)`
  border: solid 1px #fff;
  background-color: #f2f2f2;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const AvatarInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  height: ${props => props.size}px;
  * {
    margin-bottom: 0;
  }
`;

const Container = ({ alt, src, size, gutterBottom, children }) => {
  return (
    <Box display="flex" size={size} mb={gutterBottom ? 2 : 0}>
      <Avatar alt={alt} src={src} size={size} />
      {children && <AvatarInfo size={size}>{children}</AvatarInfo>}
    </Box>
  );
};

Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ...MuiAvatar.propTypes,
  ...{
    size: PropTypes.number
  }
};

Container.defaultProps = {
  size: 34
};

export default Container;
