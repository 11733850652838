import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
// services
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { AddCircleOutline, History as HistoryIcon } from "@material-ui/icons";
import _ from "lodash";
import styled from "styled-components";
import CommunicationService from "../../services/CommunicationService";
import Group from "../../waybee-ui/Group";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import Heading from "../../waybee-ui/Heading/Heading";
import CommunicationList from "./components/CommunicationList";
import { AbilityContext, Can } from "../../acl/can";

const ListMessage = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  text-align: center;
`;

class Dashboard extends Component {
  state = {
    communications: [],
    filteredCommunications: [],
    communicationLoading: false,
    hasAllCommunications: false,
    communicationCount: 0,
    noAccessError: false,
    types: [],
    filters: {
      searchText: "",
      initialDate: null,
      finalDate: null,
      typeComm: ""
    },
    communicationSelected: null
  };

  componentDidMount() {
    const { context } = this;

    if (context.can("list", "communication")) {
      const { communications } = this.state;

      if (!communications.length) {
        this.getCommunications().then(newCommunications =>
          this.setState(() => ({ communications: newCommunications }))
        );
      }

      window.addEventListener("scroll", this.scrollEvent);

      this.setCommunicationCount();
    }

    this.getTypes();

    const { setTitle } = this.props;
    setTitle("Central de Comunicações");
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEvent);
  }

  setFilter(field, value) {
    const { filters } = this.state;
    filters[field] = value;
    this.setState(
      { filters, filteredCommunications: [], hasAllCommunications: false },
      () => {
        if (this.hasAnyFilter()) {
          this.setCommunicationCount();
          this.getCommunications().then(newCommunications =>
            this.setState(() => ({ filteredCommunications: newCommunications }))
          );
        }
      }
    );
  }

  getTypes = () => {
    CommunicationService.getTypes().then(response => {
      const types = response.data.map(type => type);
      this.setState({ types });
    });
  };

  getCommunications = (countToSkip = 0) => {
    const { filters } = this.state;
    const { openAlert } = this.props;
    this.setState({ communicationLoading: true });

    return CommunicationService.getCommunications(
      countToSkip,
      filters.searchText,
      filters.initialDate,
      filters.finalDate,
      filters.typeComm
    )
      .catch(e => {
        const error = _.get(e, "response.data.error");
        if (error && error.name === "no_class_access") {
          this.setState({ noAccessError: true });
        } else {
          openAlert({
            status: "error",
            message: e.message
          });
        }
        return [];
      })
      .finally(() => this.setState({ communicationLoading: false }));
  };

  getMoreCommunications() {
    const {
      communications,
      filteredCommunications,
      hasAllCommunications,
      communicationLoading
    } = this.state;
    if (hasAllCommunications) return;
    if (communicationLoading) {
      setTimeout(() => {
        this.getMoreCommunications();
      }, 500);
      return;
    }
    this.getCommunications(
      this.hasAnyFilter()
        ? filteredCommunications.length
        : communications.length
    ).then(newCommunications => {
      this.setState(prevState => {
        const typeData = this.hasAnyFilter()
          ? "filteredCommunications"
          : "communications";
        return {
          hasAllCommunications: newCommunications.length === 0,
          [typeData]: prevState[typeData].concat(newCommunications)
        };
      });
    });
  }

  setCommunicationCount() {
    const { filters } = this.state;
    return CommunicationService.getCommunicationsCount(
      filters.searchText,
      filters.initialDate,
      filters.finalDate,
      filters.typeComm
    ).then(count => this.setState({ communicationCount: count }));
  }

  scrollEvent = () => {
    const { communicationLoading } = this.state;
    const container = document.querySelector("html");
    if (
      !communicationLoading &&
      container.scrollTop + window.innerHeight >= container.offsetHeight - 100
    ) {
      this.getMoreCommunications();
    }
  };

  hasAnyFilter() {
    const { filters } = this.state;
    const { searchText, initialDate, finalDate, typeComm } = filters;
    return !!(searchText.trim() || initialDate || finalDate || typeComm);
  }

  selectCommunication(communication) {
    this.setState({ communicationSelected: communication });
  }

  render() {
    const {
      communications,
      filteredCommunications,
      communicationLoading,
      filters,
      types,
      communicationCount,
      communicationSelected,
      noAccessError
    } = this.state;

    if (communicationSelected) {
      // if (communicationSelected.status !== 'sent') {
      //   return (
      //     <Redirect to={{
      //       pathname: '/form',
      //       communication: communicationSelected
      //     }}
      //     />
      //   );
      // }
      return <Redirect to={`/communication/${communicationSelected.id}`} />;
    }

    return (
      <Grid container spacing={2}>
        <Can I="create" a="communication">
          <Grid item xs={12}>
            <Group p={0}>
              <ButtonBlock
                startIcon={<AddCircleOutline />}
                component={Link}
                to="/form"
              >
                Nova Comunicação
              </ButtonBlock>
            </Group>
          </Grid>
        </Can>
        <Can I="list" a="communication">
          <Grid item xs={12}>
            <Group>
              <Box>
                <Heading level={2} icon={HistoryIcon} gutterBottom>
                  Histórico de Comunicações (
                  {communicationCount}
                  )
                </Heading>

                <CommunicationList
                  communications={
                    this.hasAnyFilter()
                      ? filteredCommunications
                      : communications
                  }
                  types={types}
                  filters={filters}
                  setFilter={(field, value) => this.setFilter(field, value)}
                  onSelectCommunication={(...props) =>
                    this.selectCommunication(...props)
                  }
                />

                {noAccessError && (
                  <ListMessage>
                    Não podemos exibir nenhuma comunicação pois não foram
                    encontrado vínculos deste usuário com nenhuma turma. Entre
                    em contato com um administrador do sistema para solicitar as
                    permissões necessárias
                  </ListMessage>
                )}

                {communicationLoading && (
                  <Box p={6} textAlign="center">
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Group>
          </Grid>
        </Can>
      </Grid>
    );
  }
}

Dashboard.contextType = AbilityContext;

Dashboard.defaultProps = {
  openAlert: null
};

Dashboard.propTypes = {
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.func
};

export default Dashboard;
