import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import styled from "styled-components";

const TableCell = styled(MuiTableCell)`
  border-bottom: none;
`;

const ReceiversSelector = ({ users, disabled, isLoading, onToggle }) => {
  if (isLoading) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  if (users && users.length === 0) {
    return (
      <Box my={4} mx={2}>
        <Typography variant="body1" align="center">
          Nenhum destinatário encontrado.
        </Typography>
      </Box>
    );
  }
  return (
    <Table aria-label="simple table">
      <TableBody>
        {users.map(user => {
          return (
            <TableRow key={user.id}>
              <TableCell className="user">
                <Typography variant="body2">{user.name}</Typography>
              </TableCell>
              <TableCell className="check" align="center">
                <Checkbox
                  checked={user.checked}
                  color="primary"
                  disableRipple
                  id={user.id}
                  name={user.id}
                  onChange={event => onToggle(user.id, event.target.checked)}
                  disabled={disabled}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

ReceiversSelector.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: "",
      name: "",
      checked: ""
    })
  ).isRequired,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

ReceiversSelector.defaultProps = {
  disabled: false,
  isLoading: false,
  onToggle: () => {}
};

export default ReceiversSelector;
