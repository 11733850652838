import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { HorizontalBar } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import { BarChart as BarChartIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading";
import { theme } from "../../../assets/theme/StyledTheme";
import Loading from "../../../components/Loading";

const ChartHeading = styled.h5`
  color: #000;
  text-align: left;
  margin: 40px 55px 0;
  font-size: 20px;
  font-weight: 500;
`;

const options = {
  layout: {
    padding: {
      right: 55
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          fontFamily: "Roboto, 'Helvetica Neue', 'Helvetica', sans-serif",
          fontSize: 16
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          offsetGridLines: false
        },
        ticks: {
          beginAtZero: true,
          padding: 10,
          fontFamily: "Roboto, 'Helvetica Neue', 'Helvetica', sans-serif",
          fontSize: 12,
          userCallback: label =>
            label.toString() === label.toFixed() ? label : ""
        }
      }
    ]
  },
  legend: {
    display: false
  },
  events: [],
  animation: {
    duration: 1,
    onComplete: function onComplete() {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.font = 'bold 18px Roboto, "Helvetica Neue", Helvetica, sans-serif';
      ctx.fillStyle = theme.color.secondary.dark;
      this.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          // eslint-disable-next-line no-underscore-dangle
          ctx.fillText(data, bar._model.x + 20, bar._model.y + 5);
        });
      });
    }
  }
};

class GroupCharts extends Component {
  state = {
    chartData: {}
  };

  componentDidMount() {
    this.getChartData();
  }

  componentDidUpdate(prevProps) {
    const {
      statistics: { opened, done, total },
      loading
    } = this.props;

    if (
      prevProps.statistics.total !== total ||
      prevProps.statistics.done !== done ||
      prevProps.statistics.opened !== opened ||
      prevProps.loading !== loading
    ) {
      this.getChartData();
    }
  }

  getChartData = () => {
    const {
      statistics: { opened, done, total }
    } = this.props;

    const chartData = {
      labels: [
        "Total de enviados",
        "Total de abertos",
        "Total de cliques em Concluído"
      ],
      datasets: [
        {
          label: "Número de usuários",
          backgroundColor: [
            "rgb(241,103,38)",
            "rgb(52,101,127)",
            "rgb(0,178,151)",
            "rgb(0,48,59)",
            "rgb(241,103,38)",
            "rgb(52,101,127)",
            "rgb(0,178,151)"
          ],
          hoverBackgroundColor: [
            "rgba(241,103,38,0.8)",
            "rgba(52,101,127, 0.8)",
            "rgba(0,178,151, 0.8)",
            "rgba(0,48,59, 0.8)",
            "rgba(241,103,38, 0.8)",
            "rgba(52,101,127, 0.8)",
            "rgba(0,178,151, 0.8)"
          ],
          data: [total, opened, done],
          barThickness: 30
        }
      ]
    };

    this.setState({ chartData });
  };

  render() {
    const { chartData } = this.state;
    const { loading } = this.props;

    return (
      <Group>
        <Heading level={2} icon={BarChartIcon} gutterBottom>
          Medidores de engajamento da atividade
        </Heading>

        {loading ? (
          <Loading />
        ) : (
          <>
            <ChartHeading>Análise de alcance</ChartHeading>
            <Box py={2}>
              <HorizontalBar data={chartData} options={options} height={400} />
            </Box>
          </>
        )}
      </Group>
    );
  }
}

GroupCharts.propTypes = {
  statistics: PropTypes.shape({
    opened: PropTypes.number,
    done: PropTypes.number,
    total: PropTypes.number
  }),
  loading: PropTypes.bool
};

GroupCharts.defaultProps = {
  statistics: {
    opened: 0,
    done: 0,
    total: 0
  },
  loading: false
};

export default GroupCharts;
