import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, withTheme } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { withAlert } from "react-alert";
import BackButton from "../../components/BackButton";
import LoadingContent from "../../components/LoadingContent";
import CampaignsService, { enums } from "../../services/CampaignsService";
import BugsnagService from "../../services/BugsnagService";
import GroupDetails from "./components/GroupDetails";
import GroupContent from "./components/GroupContent";
import GroupSubscribers from "./components/GroupSubscribers";
import Button from "../../waybee-ui/Button";
import ConfirmDialog from "../../waybee-ui/Dialog/ConfirmDialog";
import SelectScheduleDialog from "../../components/SelectScheduleDialog";
import SchoolGroupService from "../../services/SchoolGroupService";
import { Can } from "../../acl/can";

class EmailDetails extends Component {
  state = {
    campaign: {},
    survey: undefined,
    // subscribers: [],
    subscriberCount: null,
    author: {},
    openCancelSchedule: false,
    openDeleteCommunication: false,
    openSendCommunication: false,
    openSchedule: false,
    deleted: false,
    schedule: null
  };

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Relatório do Disparo");
    this.getCampaignDetail();
  }

  getCampaignDetail = async () => {
    const { match, openAlert } = this.props;
    const { id } = match.params;
    try {
      const details = await CampaignsService.getCampaignDetail(id);
      const destinatariesIds = details.destinataries.map(
        destinatary => destinatary.destinataryId
      );

      const destinataries = await SchoolGroupService.getEmployees({
        where: { CODIGO: { inq: destinatariesIds } }
      });

      this.setState({
        campaign: details,
        subscriberCount: details.destinataries.length,
        author: details.creator,
        destinataries
      });
    } catch (e) {
      openAlert({
        status: "error",
        message: e.message,
        onClose: () => window.location.replace("/")
      });
      BugsnagService.notifyError(
        e,
        `Erro ao obter detalhes da campanha nº ${id}`
      );
    }
  };

  reloadNotifications = async () => {
    const { campaign } = this.state;
    await this.getNotifications(campaign.id);
  };

  campaignAction = async action => {
    const { campaign, schedule } = this.state;
    const { openAlert, match, alert } = this.props;
    const { id } = match.params;

    try {
      switch (action) {
        case "cancelSchedule":
          await CampaignsService.updateCampaign(campaign.id, {
            scheduler: null
          });
          this.setState({ openCancelSchedule: false }, () => {
            alert.show("O agendamento do email foi cancelado com sucesso!", {
              title: "Agendamento Cancelado",
              onClose: this.getCampaignDetail
            });
          });
          break;
        case "delete":
          await CampaignsService.deleteCampaign(campaign.id);
          alert.show("O email foi excluído com sucesso!", {
            title: "Email Excluído",
            onClose: () => this.setState({ deleted: true })
          });
          break;
        case "send":
          await CampaignsService.sendCampaign(campaign.id);
          this.setState({ openSendCommunication: false }, () => {
            alert.show("Email enviado com sucesso!", {
              title: "Email Enviado",
              onClose: this.getCampaignDetail
            });
          });
          break;
        case "schedule":
          await CampaignsService.updateCampaign(campaign.id, {
            scheduler: schedule
          });
          this.setState({ openSchedule: false }, () => {
            alert.show("Email agendado com sucesso!", {
              title: "Email Agendado",
              onClose: this.getCampaignDetail
            });
          });
          break;
        default:
      }
    } catch (e) {
      openAlert({
        status: "error",
        message: e.message,
        onClose: () => window.location.replace("/")
      });
      BugsnagService.notifyError(
        e,
        `Erro ao executar ação ${action} na campanha nº ${id}`
      );
    }
  };

  render() {
    const {
      campaign,
      subscriberCount,
      author,
      openCancelSchedule,
      openDeleteCommunication,
      openSendCommunication,
      openSchedule,
      deleted,
      schedule,
      survey,
      destinataries
    } = this.state;

    const { theme } = this.props;

    if (!campaign || !campaign.id) {
      return <LoadingContent />;
    }

    if (deleted) {
      return <Redirect to="/email" />;
    }

    return (
      <Fragment>
        <Grid container spacing={2}>
          <BackButton to="/email" />
          <Grid item xs={12} sm={4}>
            <GroupDetails
              campaign={campaign}
              author={author}
              subscriberCount={subscriberCount}
              survey={survey}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <GroupContent campaign={campaign} />
          </Grid>

          <Grid item xs={12}>
            <GroupSubscribers destinataries={destinataries} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justify="center">
              {campaign.status === enums.status.SCHEDULED && (
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => this.setState({ openCancelSchedule: true })}
                  >
                    Cancelar Agendamento
                  </Button>
                </Grid>
              )}
              {campaign.status === enums.status.OPEN && (
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => this.setState({ openSchedule: true })}
                  >
                    Agendar Envio
                  </Button>
                </Grid>
              )}
              {campaign.status === enums.status.OPEN && (
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      this.setState({ openSendCommunication: true })
                    }
                  >
                    Enviar Agora
                  </Button>
                </Grid>
              )}
              {campaign.status === enums.status.OPEN && (
                <Can I="delete" a="campaign">
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() =>
                        this.setState({ openDeleteCommunication: true })
                      }
                    >
                      Excluir
                    </Button>
                  </Grid>
                </Can>
              )}
            </Grid>
          </Grid>
        </Grid>

        <ConfirmDialog
          title="Cancelar agendamento"
          message="Tem certeza que deseja cancelar o agendamento deste email?"
          onClose={() => this.setState({ openCancelSchedule: false })}
          onCancel={() => this.setState({ openCancelSchedule: false })}
          onConfirm={() => this.campaignAction("cancelSchedule")}
          confirmBtnText="Cancelar Agendamento"
          confirmBtnColor={theme.palette.error.main}
          cancelBtnText="Voltar"
          open={openCancelSchedule}
        />

        <ConfirmDialog
          title="Excluir Campanha"
          message="Tem certeza que deseja excluir essa campanha?"
          onClose={() => this.setState({ openDeleteCommunication: false })}
          onCancel={() => this.setState({ openDeleteCommunication: false })}
          onConfirm={() => this.campaignAction("delete")}
          confirmBtnText="Excluir Campanha"
          confirmBtnColor={theme.palette.error.main}
          cancelBtnText="Voltar"
          open={openDeleteCommunication}
        />

        <ConfirmDialog
          title="Enviar campanha"
          message="Tem certeza que deseja enviar essa campanha agora?"
          onClose={() => this.setState({ openSendCommunication: false })}
          onCancel={() => this.setState({ openSendCommunication: false })}
          onConfirm={() => this.campaignAction("send")}
          confirmBtnText="Enviar"
          cancelBtnText="Voltar"
          open={openSendCommunication}
        />

        <SelectScheduleDialog
          open={openSchedule}
          onClose={() => this.setState({ openSchedule: false })}
          value={schedule}
          onChange={value => this.setState({ schedule: value })}
          onConfirm={() => this.campaignAction("schedule")}
          title="Agendamento do disparo"
          message="Escolha a data e hora para o disparo ser realizado"
        />
      </Fragment>
    );
  }
}

EmailDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired,
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // Injected by withTheme Hoc
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // Injected by withAlert hov
};

export default withAlert()(withTheme(EmailDetails));
