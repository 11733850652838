import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { withAlert } from "react-alert";
import {
  Tabs,
  Tab as TabMui,
  TextField,
  InputAdornment
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  AddCircleOutline as AddIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import { DebounceInput } from "react-debounce-input";
import Skeleton from "@material-ui/lab/Skeleton";
import { withTranslation } from "react-i18next";
import { AbilityContext, Can } from "../../../acl/can";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatService from "../../../services/ChatService";
import ChatItem from "./ChatItem";
import ScrollColumnBody from "./ScrollColumnBody";
// import FilterChatModal from "./FilterChatModal";
import ButtonBlock from "../../../waybee-ui/ButtonBlock";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import Dialog from "../../../waybee-ui/Dialog";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import Button from "../../../waybee-ui/Button";
import DialogActions from "../../../waybee-ui/Dialog/DialogActions";

const Tab = styled(TabMui)`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  border-bottom: 2px solid ${({ theme }) => theme.color.background.default};

  &:first-child {
    border-right: 2px solid ${({ theme }) => theme.color.background.default};
  }

  text-transform: none;
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.5);

  &.Mui-selected {
    color: rgba(0, 0, 0, 0.5);
  }

  min-width: 50px;
`;
const AddButton = styled(ButtonBlock)`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  position: absolute;
  bottom: 0;
`;
const SearchField = styled(DebounceInput)`
  margin-top: 45px;
  margin-bottom: 15px;
`;
const EmptyList = styled.p`
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const TabText = styled.span`
  white-space: pre;
`;

class ChatListColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingChats: true,
      openResyncModal: false,
      chatsNotAnswered: [],
      allChats: [],
      selectedTab: 0,
      // openSearch: false,
      filter: ""
    };
  }

  componentDidMount() {
    this.getChats();

    document.addEventListener("refresh-chat-item", this.refreshChatItem, false);
    document.addEventListener("refresh", this.refreshCallback, false);
  }

  componentDidUpdate(prevProps) {
    const { channelId } = this.props;
    const prevChannelId = prevProps.channelId;
    if (channelId !== prevChannelId) this.getChats();
  }

  componentWillUnmount() {
    document.removeEventListener(
      "refresh-chat-item",
      this.refreshChatItem,
      false
    );
    document.removeEventListener("refresh", this.refreshCallback, false);
  }

  refreshCallback = async () => {
    await this.getChats(true);
  };

  refreshChatItem = async e => {
    const { msg, chatId, deleted } = e.detail;
    const { allChats } = this.state;

    let newChats;
    if (!deleted) {
      newChats = allChats.map(chat => {
        let { lastMessage } = chat;
        if (chat.id === chatId) {
          lastMessage = {
            ...chat.lastMessage,
            sender: {
              ...chat.lastMessage.sender,
              name: localStorage.getItem("userName")
            },
            deleted: false,
            body: msg,
            date: new Date()
          };
        }

        return { ...chat, lastMessage };
      });
    } else {
      newChats = allChats.map(chat => {
        const lastMessage = {
          ...chat.lastMessage,
          deleted: true,
          body: ""
        };
        return { ...chat, lastMessage };
      });
    }

    newChats = _.orderBy(
      newChats,
      chat => chat.lastMessage && chat.lastMessage.date,
      ["desc"]
    );

    this.updateChats(newChats);
  };

  getChats = async refresh => {
    this.setState({ loadingChats: true });
    const { t } = this.props;
    try {
      const { channelId } = this.props;
      const { allChats } = this.state;
      let newChats = await ChatService.getChats(channelId);
      if (allChats.length && newChats.length) {
        if (refresh) {
          for (let i = 0; i < allChats.length; i += 1) {
            if (allChats[i].selected) {
              newChats = newChats.map(newChat => {
                const formattedChat = newChat;
                if (newChat.id === allChats[i].id)
                  formattedChat.selected = true;
                return formattedChat;
              });
            }
          }
        }
      }

      this.updateChats(newChats);
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(
        t("messages.error_fetching_conversations", "Erro ao buscar conversas"),
        { title: "Erro" }
      );
    }
  };

  updateChats = chats => {
    const chatsNotAnswered = [];
    chats.forEach(chat => {
      if (!chat.resolvedAt) chatsNotAnswered.push(chat);
    });

    this.setState({
      chatsNotAnswered,
      allChats: chats,
      loadingChats: false
    });
  };

  resolveList = (id, resolved) => {
    const { allChats } = this.state;
    const newChats = allChats.map(chat => {
      const newChat = chat;
      if (chat.id === id) newChat.resolvedAt = resolved ? new Date() : null;
      return newChat;
    });
    return newChats;
  };

  resyncChannel = async () => {
    const { channelId } = this.props;
    try {
      await ChatService.resyncChannel(channelId);
      this.getChats();
      this.setState({ openResyncModal: false });
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao sincronizar chat\n${e.message}`, { title: "Erro" });
    }
  };

  onSelectChat = async (value, selectChat) => {
    const { t } = this.props;
    try {
      await ChatService.updateChatResolvedStatus(selectChat.id, value);

      const allChats = this.resolveList(selectChat.id, value);
      if (value) {
        this.setState({ allChats });
      } else {
        this.setState({
          chatsNotAnswered: allChats.filter(chat => !chat.resolvedAt)
        });
      }

      const onResolve = new Event("onResolve");
      onResolve.resolved = value;
      onResolve.roomId = selectChat.rocketChatId;
      document.dispatchEvent(onResolve);
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(
        t("messages.error_updating_status", "Erro ao atualizar status"),
        { title: "Erro" }
      );
    }
  };

  onClickChat = selectedChat => {
    const { onSelect } = this.props;
    let { chatsNotAnswered, allChats } = this.state;

    chatsNotAnswered = chatsNotAnswered.map(chat => {
      const newChat = chat;
      if (selectedChat.id === chat.id) newChat.selected = true;
      else newChat.selected = false;
      return newChat;
    });

    allChats = allChats.map(chat => {
      const newChat = chat;
      if (selectedChat.id === chat.id) newChat.selected = true;
      else newChat.selected = false;
      return newChat;
    });

    this.setState({
      chatsNotAnswered,
      allChats
    });
    onSelect(selectedChat);
  };

  // onFilter = filter => {

  //   this.setState({ openSearch: false });
  // };

  render() {
    const {
      selectedTab,
      // openSearch,
      chatsNotAnswered,
      allChats,
      filter,
      loadingChats,
      openResyncModal
    } = this.state;
    const { context } = this;

    const { onClickAdd, t } = this.props;

    const filteredAllChats = allChats.filter(chat =>
      chat.student.name.toLowerCase().includes(filter.toLowerCase())
    );
    const filteredChatsNotAnswered = chatsNotAnswered.filter(chat =>
      chat.student.name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
      <Box position="relative" height="100%">
        <Box id="chat-header">
          <ChatColumnHeader
            title={t("messages.conversations", "Conversas")}
            icon={context.can("create", "channel") ? "sync" : ""}
            onClickIcon={() => this.setState({ openResyncModal: true })}
          >
            <SearchField
              minLength={1}
              debounceTimeout={400}
              element={TextField}
              value={filter}
              onChange={e => this.setState({ filter: e.target.value })}
              placeholder={t(
                "messages.search_student_placeholder",
                "Procurar Aluno"
              )}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </ChatColumnHeader>

          <Tabs
            value={selectedTab}
            onChange={(e, val) => this.setState({ selectedTab: val })}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant="fullWidth"
            selectionFollowsFocus={false}
          >
            <Tab
              wrapped
              variant="fullWidth"
              label={(
                <TabText>
                  {t(
                    "messages.unanswered_messages",
                    "Mensagens\nnão respondidas"
                  )}
                </TabText>
              )}
            />
            <Tab
              wrapped
              variant="fullWidth"
              label={(
                <TabText>
                  {t("messages.all_messages", "Todas\nmensagens")}
                </TabText>
              )}
            />
          </Tabs>
        </Box>

        <ScrollColumnBody
          bodyId="chat-body"
          subtractIds={["chat-header", "add-chat-btn"]}
          mb={52}
        >
          {selectedTab === 0 && (
            <div>
              {loadingChats ? (
                <Box display="flex" p={1.2}>
                  <Skeleton variant="circle" width={66} height={66} />
                  <Box
                    flexGrow={1}
                    ml={1}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="50%" height={10} />
                    <Skeleton variant="text" width="50%" height={10} />
                  </Box>
                </Box>
              ) : (
                <span>
                  {filteredChatsNotAnswered.length ? (
                    filteredChatsNotAnswered.map(chat => {
                      let title;
                      if (chat.type === "private") {
                        const membersFound = chat.members.find(
                          member => member.type === "family_member"
                        );
                        title =
                          (membersFound && membersFound.name) ||
                          t(
                            "messages.member_not_found",
                            "Membro não encontrado"
                          );
                      } else {
                        title = `Grupo - ${chat.student.name}`;
                      }
                      return (
                        <ChatItem
                          key={chat.id}
                          onClick={() => this.onClickChat(chat)}
                          onCheck={value => {
                            this.onSelectChat(value, chat);
                          }}
                          type={chat.type}
                          isResolved={!!chat.resolvedAt}
                          photo={chat.members[0] && chat.members[0].photo}
                          title={title}
                          studentName={chat.student.name}
                          studentInfos={`
                          RA${chat.student.ra}
                          ${
                            chat.student.classe
                              ? `- ${chat.student.classe}`
                              : ""
                          }
                          ${
                            chat.student.course
                              ? `- ${chat.student.course}`
                              : ""
                          }
                          ${chat.student.shift ? `- ${chat.student.shift}` : ""}
                        `}
                          message={
                            chat.lastMessage && {
                              body: chat.lastMessage.deleted
                                ? t(
                                    "messages.this_message_has_been_deleted",
                                    "Essa mensagem foi apagada."
                                  )
                                : chat.lastMessage.body,
                              date: chat.lastMessage.date,
                              sender: chat.lastMessage.sender.name
                            }
                          }
                          unread={chat.unread}
                          selected={chat.selected}
                        />
                      );
                    })
                  ) : (
                    <EmptyList>
                      {t(
                        "messages.no_conversation_here",
                        "Nenhuma conversa aqui"
                      )}
                    </EmptyList>
                  )}
                </span>
              )}
            </div>
          )}

          {selectedTab === 1 && (
            <div>
              {loadingChats ? (
                <Box display="flex" p={1.2}>
                  <Skeleton variant="circle" width={66} height={66} />
                  <Box
                    flexGrow={1}
                    ml={1}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="50%" height={10} />
                    <Skeleton variant="text" width="50%" height={10} />
                  </Box>
                </Box>
              ) : (
                <span>
                  {filteredAllChats.length ? (
                    filteredAllChats.map(chat => {
                      let title;
                      if (chat.type === "private") {
                        const membersFound = chat.members.find(
                          member => member.type === "family_member"
                        );
                        title =
                          (membersFound && membersFound.name) ||
                          t(
                            "messages.member_not_found",
                            "Membro não encontrado"
                          );
                      } else {
                        title = `Grupo - ${chat.student.name}`;
                      }
                      return (
                        <ChatItem
                          key={chat.id}
                          onClick={() => this.onClickChat(chat)}
                          onCheck={value => {
                            this.onSelectChat(value, chat);
                          }}
                          type={chat.type}
                          isResolved={!!chat.resolvedAt}
                          photo={chat.members[0] && chat.members[0].photo}
                          title={title}
                          studentName={chat.student.name}
                          studentInfos={`
                      RA${chat.student.ra}
                      ${chat.student.classe ? `- ${chat.student.classe}` : ""}
                      ${chat.student.course ? `- ${chat.student.course}` : ""}
                      ${chat.student.shift ? `- ${chat.student.shift}` : ""}
                    `}
                          message={
                            chat.lastMessage && {
                              body: chat.lastMessage.body,
                              date: chat.lastMessage.date,
                              sender: chat.lastMessage.sender.name
                            }
                          }
                          unread={chat.unread}
                          selected={chat.selected}
                        />
                      );
                    })
                  ) : (
                    <EmptyList>
                      {t(
                        "messages.no_conversation_here",
                        "Nenhuma conversa aqui"
                      )}
                    </EmptyList>
                  )}
                </span>
              )}
            </div>
          )}
        </ScrollColumnBody>

        <Can I="create" a="message">
          <AddButton
            id="add-chat-btn"
            size="small"
            startIcon={<AddIcon />}
            onClick={onClickAdd}
          >
            {t("messages.create_new_conversation", "Criar Nova Conversa")}
          </AddButton>
        </Can>

        <Dialog
          onClose={() => this.setState({ openResyncModal: false })}
          maxWidth="sm"
          fullWidth
          open={openResyncModal}
        >
          <DialogTitle>Resincronizar canal</DialogTitle>
          <DialogContent>
            Ao clicar em &quot;Resincronizar&quot;, você estará recarregando as
            informações do canal de conversa. Isso significa que todos os alunos
            que não se enquadram mais nos filtros aplicados a este canal serão
            removidos. Simultaneamente, quaisquer novos alunos que agora se
            enquadrem nos filtros serão adicionados ao canal. Certifique-se de
            que é isso que você deseja fazer antes de continuar
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.resyncChannel()}
            >
              Resincronizar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => this.setState({ openResyncModal: false })}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

ChatListColumn.contextType = AbilityContext;

ChatListColumn.propTypes = {
  channelId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withAlert()(ChatListColumn));
