import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Compressor from "compressorjs";
import { theme } from "../../assets/theme/StyledTheme";

const styles = {
  FileInput: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "100%",
    height: "100%",
    marginBottom: "-30px"
  },
  Button: {
    width: "100%",
    height: "80px",
    border: "2px dashed rgba(0,0,0,.2)",
    fontSize: 16,
    backgroundColor: "white",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px"
  },
  Input: {
    position: "absolute",
    left: "0",
    top: "0",
    height: "80px",
    width: "100%",
    opacity: "0",
    cursor: "pointer"
  },
  ButtonText: {
    fontFamily: "Roboto",
    margin: 0,
    marginRight: 24,
    fontWeight: 300,
    color: "rgb(153,153,153)",
    display: "flex",
    alignItems: "center"
  },
  ButtonIcon: {
    fontSize: 32,
    color: "rgba(0,0,0,.2)"
  },
  Loading: {
    width: "32px !important",
    height: "32px !important",
    color: "rgba(0,0,0,.2)"
  },
  StatusMessage: {
    margin: "4px 0 0 0",
    fontSize: "12px"
  }
};

class FileInput extends Component {
  state = {
    status: "NOT_SENT"
  };

  statusProperties = {
    NOT_SENT: {
      color: "rgba(0,0,0,.2)",
      label: "Adicionar",
      icon: "archive"
    },
    LOADING: {
      color: "rgba(0,0,0,.2)",
      label: "Adicionando"
    },
    SUCCESS: {
      color: theme.color.primary.main,
      label: "Carregado(a)",
      icon: "check_circle_outline"
    },
    ERROR: {
      color: "#ec0000",
      label: "Erro em",
      icon: "error_outline"
    }
  };

  minimizeImage = file => {
    return new Promise((resolve, reject) => {
      return new Compressor(file, {
        convertSize: 0,
        quality: 0.5,
        maxWidth: 1024,
        maxHeight: 1024,
        success: resultFile => resolve(resultFile),
        error: () => reject()
      });
    });
  };

  handleChange = async e => {
    const { onChange, onError } = this.props;
    this.setState({ status: "LOADING" });
    const file = e.target.files[0];
    onChange(URL.createObjectURL(file));
    if (file.type.split("/")[0] === "image") {
      this.minimizeImage(file)
        .then(minimizedFile => this.uploadFile(minimizedFile))
        .catch(err => {
          this.setState({ status: "ERROR" });
          onError(err);
        });
    } else {
      try {
        await this.uploadFile(file);
      } catch (err) {
        this.setState({ status: "ERROR" });
        onError(err);
      }
    }
  };

  uploadFile = async file => {
    const { fieldOptions, upload, onSuccess, onError } = this.props;
    try {
      const res = await upload(file, fieldOptions);
      const sentFile = file;
      sentFile.url = res.data.url;
      onSuccess(res.data, sentFile);
      this.setState({ status: "SUCCESS" });
    } catch (e) {
      onError(e);
      throw e;
    }
  };

  render() {
    const {
      classes,
      name,
      id,
      label,
      accept,
      onClick,
      status: statusProps
    } = this.props;
    const { status: statusState } = this.state;

    const status =
      statusState === "LOADING" ? statusState : statusProps || statusState;

    const statusColor = this.statusProperties[status].color;
    const labelPrefix = this.statusProperties[status].label;
    const statusIcon = this.statusProperties[status].icon;

    return (
      <div className={`FileInput-root ${classes.FileInput}`}>
        <button
          style={{ borderColor: statusColor }}
          className={classes.Button}
          type="button"
          onClick={onClick}
        >
          <p className={classes.ButtonText}>{`${labelPrefix} ${label}`}</p>
          {status === "LOADING" ? (
            <CircularProgress style={{ color: statusColor }} />
          ) : (
            <Icon style={{ color: statusColor }}>{statusIcon}</Icon>
          )}
        </button>
        {status !== "LOADING" && (
          <input
            className={classes.Input}
            id={id}
            name={name}
            type="file"
            onChange={this.handleChange}
            accept={accept}
          />
        )}
      </div>
    );
  }
}

FileInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  status: PropTypes.string,
  upload: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

FileInput.defaultProps = {
  id: "",
  accept: "",
  status: "",
  fieldOptions: {},
  upload: () => {},
  onClick: () => {},
  onSuccess: () => {},
  onChange: () => {},
  onError: () => {}
};

export default withStyles(styles)(FileInput);
