import OneSignal from "react-onesignal";
import { api } from "../utils/api";

const getI18n = async () => {
  try {
    return await api.get("Settings/i18n");
  } catch (e) {
    console.error(e);
  }
  return null;
};

const getTheme = async () => {
  const res = await api.get("Settings/theme");
  return res.data;
};

const getOneSignalAppId = async () => {
  const res = await api.get("Settings/appId");
  return res.data;
};

/**
 * Registra o deviceId na base de dados
 * @param playerId - id Obtido pelo oneSignal para cadastrar o dispositivo
 * @returns {Promise<*>}
 */
const registerInstallation = async playerId => {
  if (!playerId) {
    throw new Error(
      "Token inválido informado para registrar a Installation do usuário"
    );
  }

  return api.post("Installations/registerInstallation", { deviceId: playerId });
};

const updateInstallationUser = async () => {
  const userId = localStorage.getItem("userId");
  const playerId = await OneSignal.getUserId();
  // valida os dados no service
  if (!playerId) {
    console.error(
      "Informações inválidas. Necessário informar deviceId para realizar a installation"
    );
  }
  if (!userId) {
    console.error(
      "Informações inválidas. Necessário informar userId para realizar a installation"
    );
  }

  if (userId && playerId) {
    // Retorna o objeto que também pode retornar um erro neste caso
    return api.post("Installations/updateInstallationUser", {
      userId,
      deviceId: playerId
    });
  }

  return null;
};

const disableInstallationUser = async playerId => {
  const userId = localStorage.getItem("userId");
  // valida os dados no service
  if (!playerId) {
    console.error(
      "Informações inválidas. Necessário informar deviceId para realizar a installation"
    );
  }
  if (!userId) {
    console.error(
      "Informações inválidas. Necessário informar userId para realizar a installation"
    );
  }

  // Retorna o objeto que também pode retornar um erro neste caso
  return api.post("Installations/disableInstallationUser", {
    userId,
    deviceId: playerId
  });
};

const getForgotPassword = async () => {
  const res = await api.get("Settings/forgotPasswordLabel");
  return res.data;
};

export default {
  getI18n,
  getTheme,
  getOneSignalAppId,
  registerInstallation,
  updateInstallationUser,
  disableInstallationUser,
  getForgotPassword
};
