// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon as IconMui } from "@material-ui/core";

const Container = styled.div`
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ customColor, theme }) =>
    customColor || theme.color.primary.dark};
`;

const Icon = styled(IconMui)`
  color: white;
  && {
    font-size: ${({ size }) => size / 2}px;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

class RoundedAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: props.imageSrc
    };
  }

  componentDidUpdate(prevProps) {
    const { imageSrc } = this.props;
    if (prevProps.imageSrc !== imageSrc) this.updateSrc();
  }

  updateSrc = () => {
    const { imageSrc } = this.props;
    const { imageSrc: stateSrc } = this.state;
    if (imageSrc !== stateSrc) this.setState({ imageSrc });
  };

  handleErrorImg = () => {
    this.setState({ imageSrc: undefined });
  };

  render() {
    const { imageSrc } = this.state;
    const { size, imageAlt, icon, customColor, className } = this.props;

    return (
      <Container size={size} className={className} customColor={customColor}>
        {imageSrc ? (
          <Photo src={imageSrc} onError={this.handleErrorImg} alt={imageAlt} />
        ) : (
          <Icon size={size}>{icon}</Icon>
        )}
      </Container>
    );
  }
}

RoundedAvatar.defaultProps = {
  imageSrc: undefined,
  imageAlt: "Imagem de avatar arredondada",
  size: 44,
  customColor: ""
};

RoundedAvatar.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  icon: PropTypes.string.isRequired,
  customColor: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  size: PropTypes.number, // Used in styles
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string // Came from Styled Components
};

export default RoundedAvatar;
