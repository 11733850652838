import { Store as ReduxStore } from "../redux/store";
import { setCampuses } from "../redux/actions";
import { normalizeUserCampuses } from "../utils/normalizers";
import { api } from "../utils/api";
import UserStore from "../utils/UserStore";

const isAuthenticated = () => {
  return !!UserStore.getToken();
};

const authenticate = (email, password) => {
  const params = { email, password, app: "student" };
  return api
    .post("WaybeeUsers/Authenticate", params)
    .then(response => response.data.response);
};

const tokenAuthenticate = async token => {
  const res = await api.post("AuthenticationTokens/validate", { token });
  return res.data;
};

const logout = async () => {
  const token = UserStore.getToken();
  if (!token) {
    return true;
  }
  const params = { access_token: token };
  await api.post("WaybeeUsers/logout", { params });
  return UserStore.clear();
};

const getSchoolUser = userId => {
  const filter = {
    where: {
      and: [{ id: userId }]
    },
    include: [
      {
        relation: "userCampuses",
        scope: {
          where: {
            isDeleted: false,
            active: true,
            type: { inq: ["employee", "communication"] }
          },
          include: {
            relation: "Campus",
            scope: {
              include: {
                relation: "Schools"
              }
            }
          }
        }
      }
    ]
  };
  return api
    .get("WaybeeUsers", { params: { filter } })
    .then(response => response.data[0]);
};

const login = async authData => {
  let userId = 0;
  let userData = {};
  try {
    const tokenData = authData.token
      ? await tokenAuthenticate(authData.token)
      : await authenticate(authData.email, authData.password);

    UserStore.setToken(tokenData.id);
    ({ userId } = tokenData);
  } catch (err) {
    console.error(err);
    if (!err.response) {
      throw new Error("Falha ao conectar no servidor");
    }
    const { error } = err.response.data;
    if (
      error.statusCode === 400 ||
      error.statusCode === 401 ||
      error.statusCode === 403
    ) {
      if (!authData.token) throw new Error("Senha incorreta");
      else throw new Error("Token inválido");
    } else {
      throw new Error("Falha ao conectar no servidor");
    }
  }

  try {
    userData = await getSchoolUser(userId);
    const campus = userData.userCampuses[0].Campus;
    const school = campus.Schools;
    const normalizedUserCampuses = normalizeUserCampuses(userData);
    ReduxStore.dispatch(setCampuses(normalizedUserCampuses));
    UserStore.setUserId(userData.id);
    UserStore.setUserEmail(userData.email);
    UserStore.setUserName(userData.name);
    UserStore.setUserPhoto(userData.photoProfile);
    UserStore.setUserCampusId(campus.id);
    UserStore.setUserSchoolId(school.id);
    UserStore.setUserSchoolName(school.name);
  } catch (err) {
    logout();
    throw new Error("Usuário não cadastrado no sistema");
  }
};

const UserService = {
  isAuthenticated,
  authenticate,
  login,
  logout,
  getSchoolUser
};

export default UserService;
