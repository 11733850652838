import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./utils/i18n";
import AppProvider from "./AppProvider";
import { Store } from "./redux/store";

ReactDOM.render(
  <ReduxProvider store={Store}>
    <AppProvider>
      <App />
    </AppProvider>
  </ReduxProvider>,
  document.getElementById("root")
);
