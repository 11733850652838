import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import Dialog from "../../../waybee-ui/Dialog";

const InfoText = styled.div`
  p {
    margin-top: 8px;
    line-height: 22px;
  }
`;

const SyncInfoDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} closeButton maxWidth="md" fullWidth>
      <DialogTitle>Sincronização de dados</DialogTitle>
      <DialogContent>
        <InfoText>
          <Typography variant="subtitle1">
            Sincronização de comunicações
          </Typography>
          <p>
            Essa ação associa os comunicados enviados ao aluno, no ano corrente,
            para a turma atual deste discente.
          </p>
        </InfoText>
        <InfoText>
          <Typography variant="subtitle1">
            Sincronização de canais de chat
          </Typography>
          <p>
            Essa ação remove o aluno dos canais da turma antiga e o associa aos
            canais da nova turma.
          </p>
        </InfoText>
        <InfoText>
          <Typography variant="subtitle1">
            Sincronização de informações do aluno
          </Typography>
          <p>
            Essa ação atualiza as informações da turma que constam no aplicativo
            e sincroniza grupo familiar para alunos que não pertencem a nenhum.
          </p>
        </InfoText>
        <InfoText>
          <Typography variant="subtitle1">
            Sincronização de calendário de eventos
          </Typography>
          <p>
            Essa ação remove o vínculo dos eventos do ano corrente da turma
            antiga, e associa os eventos da nova turma ao aluno.
          </p>
        </InfoText>
        <InfoText>
          <Typography variant="subtitle1">Sincronização de campus</Typography>
          <p>
            Essa ação apaga o vínculo do aluno com o campus antigo e cria um
            novo vínculo no campus atual, também atualiza, se necessário, o
            vínculo dos responsáveis do discente.
            <br />
            OBS: Todos os outros métodos de sincronização serão executados
            automaticamente após sincronizar o campus.
          </p>
        </InfoText>
      </DialogContent>
    </Dialog>
  );
};

SyncInfoDialog.defaultProps = {
  open: false
};

SyncInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default SyncInfoDialog;
