import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Paper as MuiPaper } from "@material-ui/core";

const Paper = styled(MuiPaper)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Widget = ({ children, ...other }) => {
  return (
    <Grid item {...other}>
      <Paper square>{children}</Paper>
    </Grid>
  );
};

Widget.defaultProps = {
  title: "",
  children: null
};

Widget.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any
};

export default Widget;
