import moment from "@moment";
import fileSize from "filesize";

const formatMessage = (chatMessage, members) => {
  if (!chatMessage.u) return null;

  let foundMember = members.find(
    // eslint-disable-next-line no-underscore-dangle
    member => member && member.rocketChatId === chatMessage.u._id
  );
  if (!foundMember) {
    foundMember = {
      id: 0,
      name: chatMessage.alias || "Usuário de comunicação"
    };
  }

  if (chatMessage.t) return null;

  const msg = {
    // eslint-disable-next-line no-underscore-dangle
    id: chatMessage._id,
    date: moment(chatMessage.ts.$date).format("DD/MM/YYYY HH:mm"),
    body: chatMessage.msg,
    sender: foundMember
  };

  if (chatMessage.attachments && chatMessage.attachments.length) {
    if (chatMessage.file.type.includes("image")) {
      msg.type = "image";
    } else {
      msg.type = "file";
    }
  } else {
    msg.type = "text";
  }

  if (msg.type === "file" || msg.type === "image") {
    const nameParts = chatMessage.file.name.split(".");
    const extension = nameParts[nameParts.length - 1];

    const rocketInfo = JSON.parse(atob(localStorage.getItem("rocketChatInfo")));
    const fileUrl = rocketInfo.url + chatMessage.attachments[0].title_link;

    const size = chatMessage.attachments[0].description;

    msg.file = {
      url: fileUrl,
      name: chatMessage.file.name.split(".")[0],
      extension,
      size: fileSize(Number.isNaN(Number(size)) ? 0 : size)
    };
  }

  return msg;
};

const ChatUtil = {
  formatMessage
};

export default ChatUtil;
