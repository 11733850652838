import styled from "styled-components";
import { Avatar } from "@material-ui/core";

const HeaderAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export default HeaderAvatar;
