import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Chip, Checkbox } from "@material-ui/core";
import {
  RadioButtonUnchecked as UncheckIcon,
  CheckCircle as CheckIcon
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "@moment";
import RoundedAvatar from "../../../waybee-ui/RoundedAvatar/RoundedAvatar";

const Container = styled.div`
  padding: 16px 12px;
  border-bottom: 2px solid ${({ theme }) => theme.color.background.default};
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.primary.light : "transparent"};
  p {
    margin: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const ChatInfos = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Title = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 300;
  color: #000;
  margin-right: 5px;
`;
const StudentInfo = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
`;
const MessageDate = styled.p`
  font-size: 11px;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
`;
const Message = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 16px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
`;
const MessageSender = styled.span`
  color: ${({ theme }) => theme.color.primary.main};
`;

const ChatItem = ({
  type,
  isResolved,
  photo,
  title,
  studentName,
  studentInfos,
  message,
  unread,
  onClick,
  onCheck,
  selected
}) => {
  const [checkValue, setCheckValue] = useState(isResolved);

  useEffect(() => {
    setCheckValue(isResolved);
  });

  const formatDate = () => {
    const { date } = message;

    const parsedDate = moment(date).parseZone();
    const days = moment()
      .endOf("day")
      .diff(moment(parsedDate).endOf("day"), "days");
    const years = moment()
      .endOf("day")
      .diff(moment(parsedDate).endOf("day"), "years");

    if (days === 0) return parsedDate.format("HH:mm");
    if (days === 1) return "Ontem";
    if (days > 1 && years === 0) return parsedDate.format("DD/MM");
    if (years >= 1) return parsedDate.format("DD/MM/YYYY");

    return "";
  };

  const onChange = (e, val) => {
    setCheckValue(val);
    onCheck(val);
  };

  return (
    <Container onClick={onClick} selected={selected}>
      <Box display="flex" alignItems="center">
        <div>
          <RoundedAvatar
            imageSrc={type === "private" ? photo : null}
            imageAlt="Foto do chat"
            icon={type === "private" ? "person" : "group"}
            size={66}
          />
        </div>
        <ChatInfos>
          <Box display="flex" justifyContent="space-between">
            <Title title={title}>{title}</Title>
            {message && <MessageDate>{formatDate()}</MessageDate>}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <div>
              <StudentInfo>{studentName}</StudentInfo>
              <StudentInfo>{studentInfos}</StudentInfo>
            </div>
            <div>
              {unread ? (
                <Chip size="small" label={unread} color="primary" />
              ) : (
                <Tooltip
                  title={
                    checkValue
                      ? "Marcar como não respondido"
                      : "Marcar como respondido"
                  }
                >
                  <Checkbox
                    icon={<UncheckIcon />}
                    checkedIcon={<CheckIcon />}
                    checked={checkValue}
                    color="primary"
                    onChange={onChange}
                  />
                </Tooltip>
              )}
            </div>
          </Box>
        </ChatInfos>
      </Box>
      <div>
        {message && (
          <Message>
            <MessageSender>
              {message.sender && `${message.sender.split(" ")[0]}: `}
            </MessageSender>
            {message.body}
          </Message>
        )}
      </div>
    </Container>
  );
};

ChatItem.defaultProps = {
  photo: null,
  message: null,
  unread: 0,
  selected: false
};

ChatItem.propTypes = {
  type: PropTypes.string.isRequired,
  isResolved: PropTypes.bool.isRequired,
  photo: PropTypes.string,
  title: PropTypes.string.isRequired,
  studentName: PropTypes.string.isRequired,
  studentInfos: PropTypes.string.isRequired,
  message: PropTypes.shape({
    body: PropTypes.string,
    date: PropTypes.string,
    sender: PropTypes.string
  }),
  unread: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

export default ChatItem;
