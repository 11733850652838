import React, { useState } from "react";
import styled from "styled-components";
import { Badge as MuiBadge, Box, Popover } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import HeaderName from "./HeaderName";
import HeaderInfoTitle from "./HeaderInfoTitle";
import HeaderAvatar from "./HeaderAvatar";

const EditIcon = styled(EditOutlined)`
  width: 100%;
  height: 100%;
  padding: 2px;
  color: white;
`;
const EditBadge = styled.span`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 12px;
  background-color: #00303b;
`;
const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    height: 24px;
    padding: 0;
  }
`;
const EmployeeOption = styled(Box)`
  :hover {
    background-color: #d9d9d940;
    cursor: pointer;
  }
`;
const TicketDetailAttendee = ({
  attendee,
  employees,
  setAttendee,
  disabled
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showEmployees, setShowEmployees] = useState(false);
  const [supervisorsAnchor, setEmployeesAnchor] = useState();
  const [selectedAttendee, setSelectedAttendee] = useState(attendee);

  const onClickEmployees = e => {
    if (disabled) return;
    setEmployeesAnchor(e.currentTarget);
    setShowEmployees(true);
  };

  const onSetAttendee = async user => {
    await setAttendee(user);
    setSelectedAttendee(user);
    setShowEmployees(false);
  };

  return (
    <>
      <Box
        mb={1}
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
        onClick={onClickEmployees}
      >
        <HeaderInfoTitle>Atendente</HeaderInfoTitle>

        <Box display="flex" alignItems="center">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: showEdit ? "center" : "bottom",
              horizontal: showEdit ? "center" : "left"
            }}
            badgeContent={
              disabled ? null : (
                <EditBadge size={showEdit ? 24 : 16}>
                  <EditIcon />
                </EditBadge>
              )
            }
          >
            <HeaderAvatar
              src={selectedAttendee ? selectedAttendee.photoProfile : null}
            />
          </Badge>

          <HeaderName highlight={!disabled}>
            {selectedAttendee ? selectedAttendee.name : "Não atribuído"}
          </HeaderName>
        </Box>
      </Box>
      <Popover
        open={showEmployees}
        anchorEl={supervisorsAnchor}
        onClose={() => setShowEmployees(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box pv={0.5} maxHeight={150}>
          {employees.map(user => (
            <EmployeeOption
              p={1}
              display="flex"
              alignItems="center"
              onClick={() => onSetAttendee(user)}
            >
              <HeaderAvatar src={user.photoProfile} />
              <HeaderName>{user.name}</HeaderName>
            </EmployeeOption>
          ))}
        </Box>
      </Popover>
    </>
  );
};

TicketDetailAttendee.propTypes = {
  attendee: PropTypes.shape({
    name: PropTypes.string,
    photoProfile: PropTypes.string
  }).isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photoProfile: PropTypes.string
    })
  ).isRequired,
  setAttendee: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default TicketDetailAttendee;
