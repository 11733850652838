import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import SimpleDialog from "./SimpleDialog";
import Button from "../Button";

const SimpleConfirmDialog = ({ onClose, open, title, message, onConfirm }) => {
  return (
    <SimpleDialog
      closeBtn
      title={title}
      message={message}
      onClose={onClose}
      open={open}
      actions={(
        <Grid container justify="space-around">
          <Grid item xs={5}>
            <Button
              onClick={onClose}
              variant="contained"
              fullWidth
              color="error"
            >
              Não
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              onClick={onConfirm}
              variant="contained"
              fullWidth
              color="primary"
            >
              Sim
            </Button>
          </Grid>
        </Grid>
      )}
    />
  );
};

SimpleConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired
};

SimpleConfirmDialog.defaultProps = {
  open: false,
  title: "Tem certeza?"
};

export default SimpleConfirmDialog;
