import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import styled from "styled-components";
import Avatar from "../../../waybee-ui/Avatar";
import StudentInfoPopover from "../../../components/StudentInfoPopover";

const TableCell = styled(MuiTableCell)`
  border-bottom: none;
`;

const TextName = styled(Typography)`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ReceiversSelector = ({
  users,
  disabled,
  isLoading,
  onToggleUser,
  isResend
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverUserId, setPopoverUserId] = useState(null);

  const popoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverUserId(id);
  };

  const popoverClose = () => {
    setAnchorEl(null);
    setPopoverUserId(null);
  };

  if (isLoading) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  if (users && users.length === 0) {
    return (
      <Box my={4} mx={2}>
        <Typography variant="body1" align="center">
          Nenhum destinatário encontrado.
        </Typography>
      </Box>
    );
  }
  return (
    <Table aria-label="simple table">
      <TableBody>
        {users.map(student => {
          return (
            <TableRow key={student.id}>
              <TableCell className="student">
                <Avatar alt="" src={student.photoProfile} size={40}>
                  <TextName
                    variant="body2"
                    onClick={e => {
                      popoverOpen(e, student.id);
                    }}
                  >
                    {student.name}
                  </TextName>
                  <Typography variant="body2">
                    RA: 
                    {' '}
                    {student.studentId}
                  </Typography>
                </Avatar>
              </TableCell>
              {!isResend && (
                <TableCell className="check" align="center">
                  <Checkbox
                    checked={student.checked}
                    color="primary"
                    disableRipple
                    id={student.id}
                    name={student.id}
                    onChange={event =>
                      onToggleUser(student.id, event.target.checked)
                    }
                    disabled={disabled}
                  />
                </TableCell>
              )}
            </TableRow>
          );
        })}
        {popoverUserId && (
          <StudentInfoPopover
            anchorEl={anchorEl}
            onClose={popoverClose}
            userId={popoverUserId}
          />
        )}
      </TableBody>
    </Table>
  );
};

ReceiversSelector.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: "",
      photoProfile: "",
      name: "",
      checked: ""
    })
  ).isRequired,
  onToggleUser: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isResend: PropTypes.bool
};

ReceiversSelector.defaultProps = {
  disabled: false,
  isLoading: false,
  onToggleUser: () => {},
  isResend: false
};

export default ReceiversSelector;
