import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import MaterialContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import moment from "@moment";
import EventInfo from "./event_info";
import { theme } from "../../../../../assets/theme/StyledTheme";
import AttachmentList from "./AttachmentsList";
import purify from "../../../../../utils/purify";

const SurveyContainer = styled.div`
  padding: 12px;
`;

const SurveyTitle = styled.p`
  color: ${({ palette }) => palette.primary.main};
  font-size: 14px;
  font-weight: 300;
`;

const OptionContainer = styled.div`
  padding: 10px 0;
`;

const styles = {
  image: {
    width: "100%",
    maxHeight: "480px",
    objectFit: "cover",
    position: "relative"
  },
  mainContent: {
    overflowY: "hidden",
    position: "relative"
  },
  minimizedContent: {
    maxHeight: "8.3em",
    overflowX: "hidden"
  },
  description: {
    padding: "20px 12px",
    color: "#808080",
    fontWeight: 400,
    lineHeight: "18.6px"
  },
  extraButton: {
    backgroundColor: theme.color.primary.main,
    color: "#FFF",
    borderRadius: "20px"
  },
  extraButtonContainer: {
    padding: "0 40px 12px"
  },
  readMore: {
    color: theme.color.primary.dark,
    margin: "-20px 12px 14px",
    paddingTop: 24,
    backgroundImage: "linear-gradient(rgba(255,255,255,0), white 50%)",
    position: "relative"
  }
};

class Content extends Component {
  state = {
    descriptionHeight: 0
  };

  componentDidMount() {
    this.setDescriptionHeight();
  }

  setDescriptionHeight() {
    const { cardKey } = this.props;
    const descriptionHeight = document.getElementById(`mainContent${cardKey}`)
      .clientHeight;
    this.setState({ descriptionHeight });
  }

  formatImages = htmlStr => {
    return htmlStr.replace(/<img /g, `<img style="max-width: 100%" `);
  };

  addTableContainer = htmlStr => {
    const containerTable = `<div class="table-responsive">$1</div>`;
    return htmlStr.replace(/((<table(?:.|\n)*?<\/table>))/gm, containerTable);
  };

  formatContent = htmlStr => {
    let formatedHtml = this.addTableContainer(htmlStr);
    formatedHtml = this.formatImages(formatedHtml);
    return formatedHtml;
  };

  formatDateHeader = date => {
    const headerDateFormat = "DD/MM/YYYY - [às] HH:mm";
    return moment(date).format(headerDateFormat);
  };

  isMaxHeightContent = () => {
    const maxHeightPixels = 151;
    const { descriptionHeight } = this.state;
    return descriptionHeight > maxHeightPixels;
  };

  render() {
    const {
      photo,
      extraButtonText,
      extraButtonLinkAddress,
      type,
      attachments,
      eventDate,
      eventLocation,
      minimizeContent,
      cardKey,
      survey
    } = this.props;

    const { bodyMessage: htmlBodyMessage } = this.props;
    const bodyMessage = this.formatContent(htmlBodyMessage);

    return (
      <MaterialContent classes={{ root: "notification-container-content" }}>
        {!!photo && <img alt="" style={styles.image} src={photo} />}

        <div
          id={`mainContent${cardKey}`}
          style={{
            ...styles.mainContent,
            ...(minimizeContent && this.isMaxHeightContent()
              ? styles.minimizedContent
              : {})
          }}
        >
          <div
            style={styles.description}
            className="notification-description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(bodyMessage)
            }}
          />
          {!!extraButtonText && !!extraButtonLinkAddress && (
            <div style={styles.extraButtonContainer}>
              <Button
                target="_blank"
                style={styles.extraButton}
                href={extraButtonLinkAddress}
                fullWidth
              >
                {extraButtonText}
              </Button>
            </div>
          )}
        </div>

        {survey && (
          <SurveyContainer>
            <SurveyTitle palette={theme.color}>Enquete</SurveyTitle>

            <Typography variant="body1">{survey.title}</Typography>

            <div>
              {survey.options.map(option => (
                <OptionContainer>
                  <FormControlLabel
                    control={
                      <Radio disabled color="primary" name="survey-radio" />
                    }
                    label={option.question}
                  />
                </OptionContainer>
              ))}
            </div>
          </SurveyContainer>
        )}

        {minimizeContent && this.isMaxHeightContent() && (
          <div style={styles.readMore}>... Ver mais</div>
        )}

        {!!attachments.length && (
          <AttachmentList
            attachments={attachments}
            minimize={minimizeContent}
          />
        )}

        {type === 2 && <EventInfo date={eventDate} location={eventLocation} />}
      </MaterialContent>
    );
  }
}

Content.defaultProps = {
  photo: "",
  bodyMessage: "",
  extraButtonText: "",
  extraButtonLinkAddress: undefined,
  attachments: [],
  eventDate: "",
  eventLocation: "",
  minimizeContent: false,
  survey: undefined
};

Content.propTypes = {
  photo: PropTypes.string,
  bodyMessage: PropTypes.string,
  extraButtonText: PropTypes.string,
  extraButtonLinkAddress: PropTypes.string,
  type: PropTypes.number.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      customName: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.url
    })
  ),
  cardKey: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  eventLocation: PropTypes.string,
  survey: PropTypes.shape({
    title: PropTypes.string, // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.array
  }),
  minimizeContent: PropTypes.bool
  // eslint-disable-next-line react/forbid-prop-types
};

export default withTheme(Content);
