import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { People as PeopleIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading";
import StatisticSubscriber from "../../../components/StatisticSubscriber";
import Loading from "../../../components/Loading";

const GroupSubscribers = ({
  destinations,
  sent,
  destinationFilter,
  loading
}) => {
  const subscribers = destinations.map(destination => ({
    id: destination.id,
    userId: destination.userId,
    name: destination.name,
    photo: destination.photo,
    description: destination.class,
    columns: [
      {
        type: "numeric",
        value: destination.statistics.totalInteractions,
        settings: {
          popover: destination.statistics.opened
        }
      },
      {
        type: "check",
        value: destination.statistics.done,
        settings: {
          popover: true
        }
      }
    ]
  }));

  return (
    <Group>
      <Heading level={2} icon={PeopleIcon} gutterBottom>
        Destinatários
      </Heading>

      {loading ? (
        <Loading />
      ) : (
        <Box py={2}>
          {sent ? (
            <StatisticSubscriber
              subscribers={subscribers}
              headers={["Abriu", "Concluiu"]}
            />
          ) : (
            <Fragment>
              {destinationFilter.students && (
                <>
                  <Typography variant="subtitle1">Alunos</Typography>
                  <Typography variant="body1">
                    {`Cursos: ${destinationFilter.students.courses
                      .map(e => e.NOME)
                      .join(", ")}`}
                  </Typography>
                  <Typography variant="body1">
                    {`Turmas: ${destinationFilter.students.classes
                      .map(e => e.NOME)
                      .join(", ")}`}
                  </Typography>
                </>
              )}
              {destinationFilter.employees && (
                <>
                  <Typography variant="subtitle1">
                    Professores/Coordenadores
                  </Typography>
                  <Typography variant="body1">
                    {`Cursos: ${destinationFilter.employees.courses
                      .map(e => e.NOME)
                      .join(", ")}`}
                  </Typography>
                  <Typography variant="body1">
                    {`Turmas: ${destinationFilter.employees.classes
                      .map(e => e.NOME)
                      .join(", ")}`}
                  </Typography>
                  <Typography variant="body1">
                    {`Cargos: ${destinationFilter.employees.types
                      .map(e => e.NOME)
                      .join(", ")}`}
                  </Typography>
                </>
              )}
            </Fragment>
          )}
        </Box>
      )}
    </Group>
  );
};

GroupSubscribers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  destinations: PropTypes.array,
  sent: PropTypes.bool,
  destinationFilter: PropTypes.shape({
    students: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      courses: PropTypes.array.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      classes: PropTypes.array.isRequired
    }),
    employees: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      courses: PropTypes.array.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      classes: PropTypes.array.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      types: PropTypes.array.isRequired
    })
  }).isRequired,
  loading: PropTypes.bool
};

GroupSubscribers.defaultProps = {
  sent: true,
  loading: false,
  destinations: []
};

export default GroupSubscribers;
