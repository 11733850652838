import React from "react";
import PropTypes from "prop-types";
import Dialog from "./Dialog";
import DialogContent from "./DialogContent";
import Loading from "../../components/Loading";

const LoadingDialog = ({ onClose, open }) => {
  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Loading />
      </DialogContent>
    </Dialog>
  );
};

LoadingDialog.defaultProps = {
  open: false
};

LoadingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default LoadingDialog;
