import PropTypes from "prop-types";
import styled from "styled-components";
import Box from "@material-ui/core/Box";

const ImageBox = styled(Box)`
  background-image: url("${props => props.src}");
  border-radius: 8.2px;
  background-position: center;
  background-size: cover;
`;

ImageBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ...Box.propTypes,
  ...{
    src: PropTypes.string,
    alt: PropTypes.string
  }
};

ImageBox.defaultProps = {
  src: "#",
  alt: ""
};

export default ImageBox;
