import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Grid, Typography } from "@material-ui/core";
import { Description as DescriptionIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import ImageBox from "../../../waybee-ui/ImageBox";
import Button from "../../../waybee-ui/Button";
import Attachments from "../../../components/Attachments";
import manageSelectedOptions from "../../../utils/SelectedCommunicationOption";

const BreakWord = styled(Typography)`
  word-wrap: break-word;
`;

const BoxLinkButton = styled(Box)`
  display: flex;
  align-items: flex-start;
`;

const GroupContent = ({ communication }) => {
  const options = manageSelectedOptions(communication);

  return (
    <Group>
      <Box minHeight={380}>
        <Heading level={2} icon={DescriptionIcon} gutterBottom>
          Conteúdo
        </Heading>
        <Grid container spacing={2}>
          {!!communication.photo && (
            <Grid item md={5} xs={12}>
              <ImageBox
                height={160}
                mb={3}
                alt="Imagem da comunicação"
                src={communication.photo}
              />
            </Grid>
          )}
          <Grid item md={communication.photo ? 7 : 12}>
            <Typography variant="caption">Título</Typography>
            <Typography variant="body1" gutterBottom>
              {communication.title}
            </Typography>
            {communication.linkAddress &&
              communication.linkAddress !== "http://undefined" && (
                <Fragment>
                  <Typography variant="caption">Link da postagem</Typography>
                  <BreakWord variant="body1" gutterBottom>
                    {communication.linkAddress}
                  </BreakWord>
                </Fragment>
              )}
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: communication.bodyMessage }}
          gutterBottom
        />

        {options && !!options.length && (
          <Grid>
            <Typography variant="caption">Opções Selecionadas</Typography>
            {options.map(option => {
              return <Typography>{option}</Typography>;
            })}
          </Grid>
        )}
        <Grid>
          {communication.attachments.length !== 0 && (
            <Fragment>
              <Typography variant="caption">Anexos</Typography>
              <Attachments attachments={communication.attachments} />
            </Fragment>
          )}
        </Grid>
        {communication.hasLinkButton && (
          <Grid>
            <Typography style={{ marginBottom: 0 }} variant="caption">
              Botão de Link
            </Typography>
            <BoxLinkButton display="flex">
              {communication.buttons.map(btn => (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => window.open(btn.link, "_system")}
                >
                  {btn.text}
                </Button>
              ))}
            </BoxLinkButton>
          </Grid>
        )}
      </Box>
    </Group>
  );
};

GroupContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  communication: PropTypes.object.isRequired
};

export default GroupContent;
