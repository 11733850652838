import { DialogContent, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Dialog from "../../../waybee-ui/Dialog";
import Button from "../../../waybee-ui/Button";
import IFrame from "../../../components/IFrame";

const PreviewContent = styled(DialogContent)`
  padding: 26px;
`;

const PreviewDialog = props => {
  const {
    open,
    onClose,
    onSubmit,
    onChange,
    link,
    displayPreview,
    variable,
    linkWithVariable
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          height: "852px",
          width: "393px",
          borderRadius: 12
        }
      }}
      closeButton
    >
      {displayPreview && !linkWithVariable ? (
        <PreviewContent>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Identificamos uma variável no seu link, adicione o valor da
              variável no campo abaixo para visualizar a prévia.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Preencha com o valor da variável"
              onChange={onChange}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={!variable}
            >
              Confirmar
            </Button>
          </Grid>
        </PreviewContent>
      ) : (
        <IFrame url={linkWithVariable || link} />
      )}
    </Dialog>
  );
};

PreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  displayPreview: PropTypes.bool.isRequired,
  variable: PropTypes.string.isRequired,
  linkWithVariable: PropTypes.string.isRequired
};

export default PreviewDialog;
