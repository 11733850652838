import React from "react";
import PropTypes from "prop-types";
import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";

const CheckColumn = ({ check }) =>
  check ? <CheckIcon color="primary" /> : <CloseIcon color="error" />;

CheckColumn.defaultProps = {
  check: false
};

CheckColumn.propTypes = {
  check: PropTypes.bool
};

export default CheckColumn;
