import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button as MuiButton } from "@material-ui/core";

const Button = styled(MuiButton)`
  color: #787486;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
`;

const NavigationButton = ({ to, icon, children }) => {
  const history = useHistory();

  return (
    <Button
      onClick={() => history.push(to)}
      variant="outlined"
      fullWidth
      startIcon={icon}
    >
      {children}
    </Button>
  );
};

NavigationButton.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired // injected
};

export default NavigationButton;
