import React from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

const Alert = ({ open, type, title, message, status, onClose, loading }) => {
  if (!open) return null;

  if (type === "swal") {
    Swal.fire({
      title,
      text: message,
      icon: status,
      onClose
    });
    if (loading) {
      Swal.showLoading();
    } else {
      Swal.hideLoading();
    }
    return null;
  }

  return <div />;
};

Alert.defaultProps = {
  onClose: () => { },
  loading: false
};

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  // swal
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  loading: PropTypes.bool
};

export default Alert;
