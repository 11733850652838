import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal as MuiModal, IconButton } from "@material-ui/core";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
  Close as CloseIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  AspectRatio as ResizeIcon,
  GetApp as DownloadIcon
} from "@material-ui/icons";

const Modal = styled(MuiModal)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CloseButton = styled(IconButton)`
  position: fixed;
  right: 15px;
  top: 15px;
  color: white;
  &:hover {
    cursor: pointer;
  }
`;
const Actions = styled.div`
  position: fixed;
  right: 15px;
  bottom: 15px;
  color: white;
  display: flex;
  flex-direction: column;
`;
const Image = styled.img`
  max-width: 90vw;
  max-height: 90vh;
`;

const OpenImage = ({ src, alt, onClose }) => {
  return (
    <Modal open onClose={onClose}>
      <>
        <CloseButton color="inherit" onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Actions>
                <IconButton color="inherit" onClick={zoomIn}>
                  <AddIcon />
                </IconButton>
                <IconButton color="inherit" onClick={zoomOut}>
                  <RemoveIcon />
                </IconButton>
                <IconButton color="inherit" onClick={resetTransform}>
                  <ResizeIcon />
                </IconButton>
                <IconButton color="inherit" href={src} target="_blank">
                  <DownloadIcon />
                </IconButton>
              </Actions>
              <TransformComponent>
                <Image src={src} alt={alt} />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </>
    </Modal>
  );
};

OpenImage.defaultProps = {
  alt: "Imagem"
};

OpenImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default OpenImage;
