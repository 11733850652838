import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { AccountCircle as AccountIcon, Search } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import _ from "lodash";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import styled from "styled-components";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import Heading from "../../waybee-ui/Heading";
import Group from "../../waybee-ui/Group";
import BackButton from "../../components/BackButton";
import ResponsibleItem from "../../components/AccessionResponsibleItem";
import StudentItem from "../../components/AccessionStudentItem";
import AccessionService from "../../services/AccessionService";
import Loading from "../../components/Loading";
import {
  setAccessionResponsible,
  setAccessionStudent
} from "../../redux/actions";
import InfinityScroll from "../../components/InfinityScroll";
import { AbilityContext } from "../../acl/can";
import Dialog from "../../waybee-ui/Dialog";
import DialogTitle from "../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../waybee-ui/Dialog/DialogContent";
import DialogActions from "../../waybee-ui/Dialog/DialogActions";
import UserStore from "../../utils/UserStore";

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

const AllCaption = styled.p`
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
`;

class AccessionUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingUsers: false,
      users: [],
      stopLoad: false,
      page: 0,
      filter: {
        status: "ALL"
      },
      allStatus: [],
      isDialogOpen: false,
      loadingSubscribedResponsible: false,
      hasSubscribedResponsible: false,
      module: {
        REGISTER_RESPONSIBLE: "register_responsible"
      }
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Painel de Adesão");

    this.getUsers();
    this.getStatus();
  }

  onRegisterResponsible = async () => {
    const { module, users } = this.state;

    if (!users.length || !users) return;

    this.setState({ loadingSubscribedResponsible: true });

    await AccessionService.registerResponsible(
      UserStore.getUserCampusId(),
      module.REGISTER_RESPONSIBLE
    );

    this.setState({
      loadingSubscribedResponsible: false,
      hasSubscribedResponsible: true
    });
  };

  getStatus = async () => {
    const allStatus = await AccessionService.getStatus();
    this.setState({ allStatus });
  };

  getUsers = async (page = 0) => {
    const {
      match: {
        params: { type }
      }
    } = this.props;
    const { users, filter } = this.state;

    this.setState({ loadingUsers: true });

    try {
      let newUsers = [];
      if (type === "responsibles")
        newUsers = await AccessionService.getResponsibles(page, filter);
      if (type === "students")
        newUsers = await AccessionService.getStudents(page, filter);

      const stopLoad = !newUsers.length;
      this.setState({
        users: users.concat(newUsers),
        stopLoad
      });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao buscar usuários"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingUsers: false });
    }
  };

  getMoreUsers = async () => {
    const { loadingUsers, stopLoad, page } = this.state;
    if (loadingUsers || stopLoad) return;

    const newPage = page + 1;
    await this.getUsers(newPage);
    this.setState({ page: newPage });
  };

  setFilter(field, value) {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          [field]: value
        },
        users: [],
        page: 0
      },
      this.getUsers
    );
  }

  clickUser = userId => {
    const { context } = this;
    if (!context.can("edit", "accession")) return;

    const {
      history,
      setResponsible,
      setStudent,
      match: {
        params: { type }
      }
    } = this.props;
    const { users } = this.state;

    const foundUser = users.find(user => user.id === userId);
    if (type === "responsibles") setResponsible(foundUser);
    else setStudent(foundUser);

    history.push(`/accession-panel/${type}/${userId}`);
  };

  render() {
    const {
      match: {
        params: { type }
      }
    } = this.props;
    const {
      users,
      loadingUsers,
      filter,
      allStatus,
      isDialogOpen,
      loadingSubscribedResponsible,
      hasSubscribedResponsible
    } = this.state;
    const { context } = this;

    return (
      <Fragment>
        <InfinityScroll onReachBottom={!isDialogOpen && this.getMoreUsers}>
          <Grid container>
            <Grid xs={12}>
              <BackButton to="/" />
            </Grid>
            <Grid item xs={12}>
              <Group>
                <Box>
                  <Heading level={2} icon={AccountIcon} gutterBottom>
                    {type === "responsibles" ? "Responsáveis" : "Alunos"}
                  </Heading>

                  <Grid xs={12} item>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <DebounceInput
                          minLength={3}
                          debounceTimeout={400}
                          element={TextField}
                          value={filter.name}
                          onChange={({ target: { value } }) =>
                            this.setFilter("name", value)
                          }
                          placeholder={
                            type === "responsibles"
                              ? "Procurar por nome"
                              : "Procurar por nome ou RA"
                          }
                          margin="normal"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search color="action" />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                        />
                      </Grid>
                      {type === "responsibles" && (
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                              value={filter.status}
                              onChange={({ target: { value } }) =>
                                this.setFilter("status", value)
                              }
                            >
                              <MenuItem value="ALL">
                                Todos 
                                {' '}
                                <AllCaption>(exceto inativo)</AllCaption>
                              </MenuItem>
                              {allStatus.map(status => (
                                <MenuItem value={status.id}>
                                  {status.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {type === "responsibles" &&
                        context.can(
                          "use module",
                          "syncResponsibleCommunication"
                        ) && (
                          <Grid item xs={3} alignContent="center">
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                this.setState({ isDialogOpen: true })
                              }
                            >
                              Cadastrar Responsáveis
                            </Button>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>

                  <Grid xs={12}>
                    <Box margin="30px -30px">
                      {users && !!users.length && (
                        <>
                          {type === "responsibles"
                            ? users.map(user => (
                              <ResponsibleItem
                                user={user}
                                onClick={
                                    user.status === "INACTIVE"
                                      ? null
                                      : () => this.clickUser(user.id)
                                  }
                              />
                              ))
                            : users.map(user => (
                              <StudentItem
                                user={user}
                                onClick={() => this.clickUser(user.id)}
                                hasStatus
                              />
                              ))}
                        </>
                      )}
                      {loadingUsers && <Loading />}
                    </Box>
                  </Grid>
                </Box>
              </Group>
            </Grid>
          </Grid>
        </InfinityScroll>
        {isDialogOpen && type === "responsibles" && (
          <Dialog
            open={isDialogOpen}
            onClose={() => this.setState({ isDialogOpen: false })}
            closeButton
            fullWidth
          >
            <DialogTitle>
              {!hasSubscribedResponsible
                ? "Cadastro em massa"
                : "Processo de Cadastro em Andamento"}
            </DialogTitle>

            {!loadingSubscribedResponsible ? (
              <Fragment>
                <DialogContent>
                  <Box mt={1} mb={3}>
                    <Typography variant="subtitle1">
                      {!hasSubscribedResponsible
                        ? "Ao confirmar, os responsáveis não cadastrados serão cadastrados no sistema. Tem certeza que deseja continuar?"
                        : "Estamos sincronizando os responsáveis que ainda não foram cadastrados. O processo pode demorar um pouco, mas estamos trabalhando para finalizá-lo o mais rápido possível."}
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions>
                  {!hasSubscribedResponsible ? (
                    <Fragment>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => this.setState({ isDialogOpen: false })}
                        color="error"
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => this.onRegisterResponsible()}
                        color="primary"
                      >
                        Confirmar
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={() =>
                        this.setState({
                          isDialogOpen: false,
                          hasSubscribedResponsible: false
                        })
                      }
                      color="primary"
                    >
                      Ok
                    </Button>
                  )}
                </DialogActions>
              </Fragment>
            ) : (
              <LoadContainer>
                <CircularProgress />
              </LoadContainer>
            )}
          </Dialog>
        )}
      </Fragment>
    );
  }
}

AccessionUsers.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired, // inject by withAlert
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string
    })
  }).isRequired, // inject by withRouter
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired, // inject by withRouter
  setResponsible: PropTypes.func.isRequired, // injected by mapDispatchToProps
  setStudent: PropTypes.func.isRequired // injected by mapDispatchToProps
};

AccessionUsers.contextType = AbilityContext;

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setResponsible: setAccessionResponsible,
      setStudent: setAccessionStudent
    },
    dispatch
  );

export default compose(
  withAlert(),
  connect(
    null,
    mapDispatchToProps
  )
)(withRouter(AccessionUsers));
