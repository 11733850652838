import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Grid,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from "@material-ui/icons";
import PropTypes from "prop-types";
import styled from "styled-components";
import FilterField from "../../CommunicationForm/components/FilterField";
import SchoolGroupService from "../../../services/SchoolGroupService";

const AutocompleteField = styled(Autocomplete)`
  margin: 12px 0;
`;

const SectionTitle = styled(Grid)`
  margin: 12px 0;
`;

const TicketChannelFormDestinations = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  filters,
  employees,
  yearFilterEnabled
}) => {
  const [filterTypes] = useState(["Série", "Turma"]);
  const [selectedFilter, setSelectedFilter] = useState("Turma");
  const [coursesAndClasses, setCoursesAndClasses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCoursesAndClasses = async year => {
    try {
      setCoursesAndClasses(await SchoolGroupService.getCoursesAndClasses(year));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getClassesByCourses = courses => {
    if (!courses) return [];

    courses.forEach(course => {
      if (course.VW_TURMA) classes.push(course.VW_TURMA);
      if (course.VW_SERIES) grades.push(course.VW_SERIES);
    });

    setClasses(classes.flat());
    setGrades(grades.flat());

    return classes;
  };

  useEffect(() => {
    getCoursesAndClasses(filters.schoolYear);
  }, [filters.schoolYear]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid container xs={10} justifyContent="space-between">
        <SectionTitle item xs={12} sx={{ margin: "12px 0" }}>
          <Typography variant="h5">Selecione os filtros:</Typography>
        </SectionTitle>
        {yearFilterEnabled === 0 ? (
          <></>
        ) : (
          <Grid item xs={5}>
            <FilterField
              value={values.destinationFilter.years}
              onChange={(event, yearsValues) => {
                setFieldValue("destinationFilters.year", yearsValues);
                getCoursesAndClasses(yearsValues);
              }}
              name="year"
              options={filters.schoolYear}
              label="Ano letivo"
              error={errors.destinationFilter}
              helperText=""
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xs={yearFilterEnabled ? 5 : 12}>
          <Autocomplete
            fullWidth={false}
            multiple
            value={values.destinationFilter.students.courses}
            id="courses"
            name="courses"
            getOptionLabel={option => option.NOME || ""}
            options={coursesAndClasses}
            renderInput={params => (
              <TextField
                {...params}
                margin="none"
                label="Cursos"
                fullWidth
                variant="outlined"
              />
            )}
            onChange={(event, coursesValues) => {
              setFieldValue(
                "destinationFilter.students.courses",
                coursesValues
              );
              getClassesByCourses(coursesValues);
            }}
            disableCloseOnSelect
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.NOME || ""}
              </>
            )}
          />
        </Grid>
        {values.destinationFilter.students.courses.length > 0 && (
          <>
            <Grid item xs={5}>
              <AutocompleteField
                value={selectedFilter}
                options={filterTypes}
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Filtrar por turma/série"
                    fullWidth
                    variant="outlined"
                  />
                )}
                onChange={(event, val) => {
                  if (val === "Turma" || !val) {
                    setFieldValue("destinationFilter.grades", []);
                  }
                  if (val === "Série") {
                    setFieldValue("destinationFilter.classes", []);
                  }
                  setSelectedFilter(val);
                }}
              />
            </Grid>

            {selectedFilter === "Turma" && (
              <Grid item xs={5}>
                <AutocompleteField
                  multiple
                  value={values.destinationFilter.students.classes}
                  id="classes"
                  name="classes"
                  getOptionLabel={option => option.NOME || ""}
                  options={classes}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Turmas"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  onChange={(event, classesValues) => {
                    setFieldValue(
                      "destinationFilter.students.classes",
                      classesValues
                    );
                  }}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.NOME || ""}
                    </>
                  )}
                />
              </Grid>
            )}
            {selectedFilter === "Série" && (
              <Grid item xs={5}>
                <AutocompleteField
                  multiple
                  value={values.destinationFilter.students.grades}
                  id="grades"
                  name="grades"
                  getOptionLabel={option => option.NOME || ""}
                  options={grades}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Séries"
                      fullWidth
                    />
                  )}
                  onChange={(event, gradesValues) => {
                    setFieldValue(
                      "destinationFilter.students.grades",
                      gradesValues
                    );
                  }}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.NOME || ""}
                    </>
                  )}
                />
              </Grid>
            )}
          </>
        )}
        <SectionTitle item xs={12} sx={{ margin: "12px 0" }}>
          <Typography variant="h5">Selecione os responsáveis:</Typography>
        </SectionTitle>
        <Grid item xs={12}>
          <AutocompleteField
            multiple
            id="attendees"
            name="attendees"
            value={values.destinationFilter.employees.attendees || []}
            onChange={(event, employeesValues) => {
              setFieldValue(
                "destinationFilter.employees.attendees",
                employeesValues
              );
              handleChange(event);
            }}
            options={employees}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Atendentes"
                fullWidth
              />
            )}
            disableCloseOnSelect
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </>
            )}
          />
          <AutocompleteField
            multiple
            id="supervisors"
            name="supervisors"
            value={values.destinationFilter.employees.supervisors || []}
            onChange={(event, supervisorsValues) => {
              setFieldValue(
                "destinationFilter.employees.supervisors",
                supervisorsValues
              );
              handleChange(event);
            }}
            options={employees}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Acompanhado Por"
                fullWidth
              />
            )}
            disableCloseOnSelect
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

TicketChannelFormDestinations.propTypes = {
  values: PropTypes.shape({
    destinationFilter: PropTypes.objectOf().isRequired
  }).isRequired,
  errors: PropTypes.objectOf().isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  filters: PropTypes.objectOf().isRequired,
  employees: PropTypes.arrayOf().isRequired,
  yearFilterEnabled: PropTypes.bool.isRequired
};

export default TicketChannelFormDestinations;
