import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Search as SearchIcon, Edit as EditIcon } from "@material-ui/icons";
import {
  Box,
  TextField,
  Chip,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { DebounceInput } from "react-debounce-input";
import { withAlert } from "react-alert";
import { withTranslation } from "react-i18next";
import { AbilityContext, Can } from "../../../acl/can";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatService from "../../../services/ChatService";
import RoundedAvatar from "../../../waybee-ui/RoundedAvatar";
import ScrollColumnBody from "./ScrollColumnBody";

const SearchField = styled(DebounceInput)`
  margin-top: 45px;
  margin-bottom: 15px;
`;

const ChannelItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.primary.light : "transparent"};

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);

    .channel-unread {
      display: none;
    }

    .edit-button {
      display: flex;
    }
  }
`;

const ChannelInfos = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  &,
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ChannelText = styled.div`
  margin-left: 12px;
  margin-right: 25px;
`;

const ChannelName = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  white-space: nowrap;
`;

const ChannelChatsCount = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
`;

const ChannelUnread = styled.div`
  display: flex;
  align-items: center;
`;

const ChannelEdit = styled.div`
  display: none;
  align-items: center;
`;

class ChannelsColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingChannels: true,
      channels: [],
      filteredChannels: [],
      filter: ""
    };
  }

  componentDidMount() {
    this.getChannels();
    this.setupListener();
  }

  componentWillUnmount() {
    document.removeEventListener("onResolve", this.onResolveListener, false);
    document.removeEventListener("refresh", this.getChannels, false);
  }

  resolveChat = (roomId, resolved) => {
    const { channels } = this.state;
    const newChannels = channels.map(channel => {
      const newChannel = channel;
      newChannel.rooms = channel.rooms.map(room => {
        const newRoom = room;
        if (room.rocketChatRoomId === roomId) {
          newRoom.resolvedAt = resolved ? new Date() : null;
        }
        return newRoom;
      });

      return newChannel;
    });

    if (newChannels !== channels) {
      this.updateChannels(newChannels);
    }
  };

  onResolveListener = e => {
    this.resolveChat(e.roomId, e.resolved);
  };

  setupListener = () => {
    document.addEventListener("onResolve", this.onResolveListener, false);
    document.addEventListener("refresh", this.getChannels, false);
  };

  updateChannels = rawChannels => {
    let channels = rawChannels;
    channels = channels.map(channel => ({
      ...channel,
      metadata: {
        totalChats: channel.rooms.length,
        totalNotResolved: channel.rooms.filter(room => room.resolvedAt === null)
          .length
      }
    }));

    this.setState({
      channels,
      filteredChannels: channels,
      loadingChannels: false
    });
  };

  getChannels = async () => {
    try {
      const { onSelect } = this.props;
      const { channels } = this.state;

      const newChannels = await ChatService.getChannels();
      if (!channels.length && newChannels.length) {
        newChannels[0].selected = true;
        onSelect(newChannels[0]);
      } else if (channels.length && newChannels.length) {
        for (let i = 0; i < channels.length; i += 1) {
          if (channels[i].selected) newChannels[i].selected = true;
        }
      }
      this.updateChannels(newChannels);
    } catch (e) {
      console.error(e);
      const { alert, t } = this.props;
      alert.show(
        t("messages.error_loading_channels", "Erro ao carregar canais"),
        { title: "Erro" }
      );
    }
  };

  onClickChannel = (e, channel, editMode) => {
    const { onSelect } = this.props;
    let { channels } = this.state;

    if (editMode) e.stopPropagation();
    onSelect(channel, editMode);

    channels = channels.map(chann => {
      const newChannel = chann;
      if (newChannel.id === channel.id) newChannel.selected = true;
      else newChannel.selected = false;
      return newChannel;
    });

    this.setState({ channels });
  };

  onSearch = e => {
    const { channels } = this.state;
    const filter = e.target.value || "";

    const filteredChannels = channels.filter(channel =>
      channel.name.toLowerCase().includes(filter.toLowerCase())
    );

    this.setState({ filter, filteredChannels });
  };

  render() {
    const { filteredChannels, filter, loadingChannels } = this.state;
    const { onClickAdd, t } = this.props;
    const { context } = this;

    return (
      <>
        <Box id="channel-header">
          <ChatColumnHeader
            title={t("messages.channels", "Canais")}
            icon={context.can("create", "channel") ? "add" : ""}
            onClickIcon={onClickAdd}
          >
            <SearchField
              minLength={1}
              debounceTimeout={400}
              element={TextField}
              value={filter}
              onChange={this.onSearch}
              placeholder={t(
                "messages.search_channels_placeholder",
                "Procurar"
              )}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </ChatColumnHeader>
        </Box>
        <ScrollColumnBody
          bodyId="channel-body"
          subtractIds={["channel-header"]}
        >
          {loadingChannels
            ? Array.from(new Array(3)).map(() => (
              <Box display="flex" p={1.2}>
                <Skeleton variant="circle" width={56} height={56} />
                <Box
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="50%" height={10} />
                </Box>
              </Box>
              ))
            : filteredChannels.map(channel => (
              <ChannelItem
                key={channel.id}
                onClick={e => this.onClickChannel(e, channel, false)}
                selected={channel.selected}
              >
                <ChannelInfos>
                  <RoundedAvatar
                    imageSrc={channel.photo}
                    imageAlt="Foto do canal"
                    icon="group"
                    size={56}
                  />
                  <ChannelText>
                    <ChannelName>{channel.name}</ChannelName>
                    <ChannelChatsCount>
                      {`${channel.metadata.totalChats} ${t(
                          "messages.conversations",
                          "Conversas"
                        )}`}
                    </ChannelChatsCount>
                  </ChannelText>
                </ChannelInfos>
                <ChannelUnread className="channel-unread">
                  {!!channel.metadata.totalNotResolved && (
                  <Chip
                    size="small"
                    label={channel.metadata.totalNotResolved}
                    color="primary"
                  />
                    )}
                </ChannelUnread>

                <Can I="edit" a="channel">
                  <ChannelEdit className="edit-button">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={e => this.onClickChannel(e, channel, true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </ChannelEdit>
                </Can>
              </ChannelItem>
              ))}
        </ScrollColumnBody>
      </>
    );
  }
}

ChannelsColumn.contextType = AbilityContext;

ChannelsColumn.propTypes = {
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withAlert()(ChannelsColumn));
