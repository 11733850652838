import React from "react";
import PropType from "prop-types";
import { Checkbox, TextField } from "@material-ui/core";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";

const FilterField = ({
  label,
  name,
  options,
  value,
  error,
  helperText,
  labelField,
  onChange,
  disabled,
  noOptionsText,
  variant
}) => {
  return (
    <Autocomplete
      multiple
      id={name}
      disabled={disabled}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      noOptionsText={noOptionsText}
      getOptionLabel={option =>
        labelField ? option[labelField] || "-" : option || "-"
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={variant !== "outlined" ? "dense" : "none"}
          fullWidth
          error={error}
          helperText={helperText}
        />
      )}
      disableCloseOnSelect
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            color="primary"
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {labelField ? option[labelField] || "-" : option || "-"}
        </React.Fragment>
      )}
    />
  );
};

FilterField.defaultProps = {
  value: "",
  labelField: "",
  disabled: false,
  noOptionsText: "",
  variant: "standard"
};

FilterField.propTypes = {
  label: PropType.string.isRequired,
  name: PropType.string.isRequired,
  options: PropType.string.isRequired,
  value: PropType.string,
  error: PropType.bool.isRequired,
  helperText: PropType.string.isRequired,
  labelField: PropType.string,
  onChange: PropType.func.isRequired,
  disabled: PropType.bool,
  noOptionsText: PropType.string,
  variant: PropType.string
};

export default FilterField;
