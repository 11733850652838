import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import {
  LocationOn as LocationIcon,
  Subject as SubjectIcon
} from "@material-ui/icons";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { useParams } from "react-router-dom";
import Color from "../../../waybee-ui/ColorPicker/Color";

const DetailForm = ({ onChange, values, calendars, resetFlag }) => {
  const { register, getValues, setValue, reset } = useForm({
    defaultValues: {
      ...values,
      resetFlag
    }
  });

  const params = useParams();

  const allDay = getValues("allDay");

  const selectedCalendar =
    getValues("calendar") &&
    calendars.find(calendar => calendar.id === getValues("calendar"));

  useEffect(() => {
    if (getValues("resetFlag") !== resetFlag) {
      reset({
        ...values,
        resetFlag
      });
    }
  });

  const handleChange = (field, val) => {
    if (field) setValue(field, val);
    onChange(getValues());
  };

  const publicField = getValues("publicField");

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            {...register("title")}
            value={getValues("title") || ""}
            onChange={e => handleChange("title", e.target.value)}
            label="Título do evento"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel>Tipo do calendário</InputLabel>
            <Select
              {...register("calendar")}
              onChange={e => handleChange("calendar", e.target.value)}
              value={selectedCalendar ? selectedCalendar.id : ""}
              input={<Input />}
              renderValue={selected => {
                const calendar = calendars.find(c => c.id === selected);
                return (
                  <Box display="flex" alignItems="center">
                    <Color color={calendar.color} />
                    <Box pl={0.8}>{calendar.name}</Box>
                  </Box>
                );
              }}
            >
              {calendars.map(calendar => (
                <MenuItem key={calendar.id} value={calendar.id}>
                  <Box display="flex" alignItems="center">
                    <Color color={calendar.color} />
                    <Box pl={0.8}>{calendar.name}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Box pt={3.6}>
            <FormLabel>Informações sobre o evento</FormLabel>
          </Box>
        </Grid>

        {!params.eventId && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    {...register("sendNotification")}
                    onChange={e =>
                      handleChange("sendNotification", e.target.checked)
                    }
                    checked={getValues("sendNotification")}
                  />
                )}
                label="Enviar notificação"
              />
            </Grid>
            {values.sendNotification && (
              <Grid item xs={8}>
                <TextField
                  margin="dense"
                  {...register("notifyMessage")}
                  value={getValues("notifyMessage") || ""}
                  onChange={e => handleChange("notifyMessage", e.target.value)}
                  label="Mensagem da Notificação"
                  helperText="Será mostrada no push notification e no card de aviso do aplicativo"
                  fullWidth
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                {...register("allDay")}
                onChange={e => handleChange("allDay", e.target.checked)}
                checked={getValues("allDay")}
              />
            )}
            label="Dia inteiro"
          />
        </Grid>

        <Grid item xs={4}>
          <DatePicker
            {...register("initialDate")}
            value={getValues("initialDate")}
            autoOk
            fullWidth
            label="De"
            clearable={false}
            format="dd 'de' MMMM 'de' yyyy',' eeee"
            onChange={val => handleChange("initialDate", val)}
          />
        </Grid>
        {!allDay && (
          <Grid item xs={2}>
            <TimePicker
              {...register("initialTime")}
              value={getValues("initialTime")}
              clearable={false}
              ampm={false}
              label="&#160;"
              onChange={val => handleChange("initialTime", val)}
            />
          </Grid>
        )}

        <Grid item xs={4}>
          <DatePicker
            {...register("finalDate")}
            value={getValues("finalDate")}
            autoOk
            minDate={getValues("initialDate")}
            minDateMessage="Deve ser porterior a data inicial do evento"
            fullWidth
            label="Até"
            clearable={false}
            format="dd 'de' MMMM 'de' yyyy',' eeee"
            onChange={val => handleChange("finalDate", val)}
          />
        </Grid>
        {!allDay && (
          <Grid item xs={2}>
            <TimePicker
              {...register("finalTime")}
              value={getValues("finalTime")}
              clearable={false}
              ampm={false}
              label="&#160;"
              onChange={val => handleChange("finalTime", val)}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <TextField
            {...register("location")}
            onChange={e => handleChange("location", e.target.value)}
            label="Localização"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationIcon color="disabled" />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...register("description")}
            onChange={e => handleChange("description", e.target.value)}
            label="Descrição"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SubjectIcon color="disabled" />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {selectedCalendar && selectedCalendar.public && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Especificações de público *</InputLabel>
              <Select
                {...register("publicField")}
                value={publicField}
                onChange={e => handleChange("publicField", e.target.value)}
              >
                <MenuItem value="Y">Evento público</MenuItem>
                <MenuItem value="N">Adicionar público fixo ao evento</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

DetailForm.defaultProps = {
  values: {}
};

DetailForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  calendars: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
  resetFlag: PropTypes.number.isRequired
};

export default DetailForm;
