import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  Details as DetailsIcon,
  People as PeopleIcon,
  GroupWork
} from "@material-ui/icons";
import moment from "@moment";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import Avatar from "../../../waybee-ui/Avatar";
import HoverPopover from "../../../components/HoverPopover";
import CoursesInfoPopover from "../../../components/CoursesInfoPopover";

const GroupDetails = ({ data, author, subscriberCount, enumsStatus }) => {
  let statusText = "";
  const { destinationFilter } = data;
  const studentsClassInfo = () => {
    if (destinationFilter) {
      const { students } = destinationFilter;
      return [{ students }];
    }
    return undefined;
  };

  switch (data.status) {
    case enumsStatus.OPEN:
      statusText = "Em aberto";
      break;
    case enumsStatus.SENT:
      statusText = "Enviado";
      break;
    case enumsStatus.SCHEDULED:
      statusText = "Agendado";
      break;
    default:
  }

  return (
    <Group>
      <Box minHeight={380}>
        <Heading level={2} icon={DetailsIcon} gutterBottom>
          Detalhes
        </Heading>

        <Typography variant="caption">Data de envio</Typography>
        <Typography variant="body1">
          {moment(data.schedule || data.createdAt).format(
            "DD[ de ]MMMM[ de ]YYYY"
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {moment(data.schedule || data.createdAt).format("HH:mm")}
        </Typography>

        <Typography variant="caption">Data de criação</Typography>
        <Typography variant="body1">
          {moment(data.createdAt).format("DD[ de ]MMMM[ de ]YYYY")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {moment(data.createdAt).format("HH:mm")}
        </Typography>

        {data.deleted_at && (
          <Fragment>
            <Typography variant="caption">Data de exclusão</Typography>
            <Typography variant="body1">
              {moment(data.deleted_at).format("DD[ de ]MMMM[ de ]YYYY")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {moment(data.deleted_at).format("HH:mm")}
            </Typography>
          </Fragment>
        )}

        <Typography variant="caption">Emissor</Typography>

        <Box display="flex">
          <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
            <Typography variant="body1" gutterBottom>
              {author.name}
            </Typography>
          </Avatar>
        </Box>

        {data.deleted_at && (
          <Fragment>
            <Typography variant="caption">Deletado por</Typography>

            <Box display="flex">
              <Avatar
                alt="Avatar"
                src={data.deletedByUser.photoProfile}
                gutterBottom
              >
                <Typography variant="body1" gutterBottom>
                  {data.deletedByUser.name}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        {data.createdByExternalUser && (
          <Fragment>
            <Typography variant="caption">Criado por</Typography>
            <Box display="flex">
              <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
                <Typography variant="body1" gutterBottom>
                  {data.createdByExternalUser}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        {data.updatedByExternalUser && (
          <Fragment>
            <Typography variant="caption">Atualizado por</Typography>
            <Box display="flex">
              <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
                <Typography variant="body1" gutterBottom>
                  {data.updatedByExternalUser}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        <Typography variant="caption">Status</Typography>
        <Typography variant="body1" gutterBottom>
          {statusText}
        </Typography>

        <Typography variant="caption">Disciplina</Typography>
        <Typography variant="body1" gutterBottom>
          {data.subject.title}
        </Typography>

        <Typography variant="caption">Quantidade de destinatários</Typography>

        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <PeopleIcon fontSize="large" color="action" />
          </Grid>
          <Grid item>
            <Typography variant="body1">{subscriberCount}</Typography>
          </Grid>
        </Grid>

        <Typography variant="caption">Quantidade de Grupos</Typography>

        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            {destinationFilter ? (
              <HoverPopover
                id={data.id}
                value={<CoursesInfoPopover groups={studentsClassInfo()} />}
              >
                <GroupWork fontSize="large" color="action" />
              </HoverPopover>
            ) : (
              <GroupWork fontSize="large" color="action" />
            )}
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {destinationFilter
                ? destinationFilter.students.courses.length
                : 0}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Group>
  );
};

GroupDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  author: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  subscriberCount: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  enumsStatus: PropTypes.object.isRequired
};

export default GroupDetails;
