import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core";
import styled from "styled-components";

const HighlightText = styled.span`
  color: ${props => props.theme.palette[props.color].main};
`;

HighlightText.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "error"])
};
HighlightText.defaultProps = {
  color: "primary"
};
export default withTheme(HighlightText);
