import { api } from "../utils/api";

const getNotifications = async communicationId => {
  return api.get(`Communications/${communicationId}/notifications`);
};

const saveNotification = async (notification, communicationId) => {
  return api.post(
    `Communications/${communicationId}/notifications`,
    notification
  );
};

const editNotifications = async (notification, communicationId) => {
  return api.put(
    `Communications/${communicationId}/notifications/${notification.id}`,
    notification
  );
};

export default {
  getNotifications,
  saveNotification,
  editNotifications
};
