import React from "react";
import PropTypes from "prop-types";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@material-ui/core";
import {
  LocationOnOutlined as LocationIcon,
  Subject as DescriptionIcon,
  PeopleOutline as PublicIcon,
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon
} from "@material-ui/icons";
import moment from "@moment";
import styled from "styled-components";
import withTheme from "@material-ui/core/styles/withTheme";
import { Link } from "react-router-dom";
import Dialog from "../../../waybee-ui/Dialog";

const Title = styled.span`
  display: flex;
  padding-top: 24px;
`;
const EventColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  flex-shrink: 0;
  margin-right: 10px;
`;
const EventTitle = styled.p`
  margin: 0;
  font-weight: 300;
  color: black;
  font-size: 20px;
  line-height: 28px;
`;
const EventTitleInfo = styled.p`
  margin: 4px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
`;
const EventSection = styled.p`
  margin: 0;
  color: ${({ color }) => color};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 12px;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;

  svg {
    align-self: start;
  }
`;
const InfoText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 0 14px;
`;

const EventDialog = ({ open, event, onClose, onDelete, theme }) => {
  if (!event) return null;
  const { extendedProps } = event;

  let courses = null;
  let classes = null;

  if (extendedProps.destinationFilter) {
    const {
      destinationFilter: { students }
    } = extendedProps;
    courses =
      students &&
      students.courses.map(course => course.NOME_REDUZIDO).join(",");
    classes =
      students &&
      students.classes.map(classe => classe.NOME_REDUZIDO).join(",");
  }

  let eventDate = "";
  if (event.allDay) {
    eventDate = `${moment(event.start).format(
      "dddd[, ] DD [de] MMMM [• Dia inteiro]"
    )}`;
  } else if (moment(event.start).isSame(moment(event.end), "day"))
    eventDate = `${moment(event.start).format(
      "dddd[, ] DD [de] MMMM [•] HH:mm[h]"
    )} - ${moment(event.end).format("HH:mm[h]")}`;
  else
    eventDate = `${moment(event.start).format(
      "dddd[, ] DD [de] MMMM [•] HH:mm[h]"
    )} - ${moment(event.end).format("dddd[, ] DD [de] MMMM [•] HH:mm[h]")}`;

  return (
    <Dialog open={open} onClose={onClose} fullWidth closeButton>
      <DialogTitle>
        <Title>
          <EventColor color={event.backgroundColor} />
          <div>
            <EventTitle>{event.title}</EventTitle>
            <EventTitleInfo>
              {eventDate}
              {extendedProps &&
                extendedProps.creator &&
                ` - por: ${extendedProps.creator}`}
            </EventTitleInfo>
          </div>
        </Title>
      </DialogTitle>
      <DialogContent>
        {extendedProps &&
          (extendedProps.location || extendedProps.description) && (
            <>
              <EventSection color={theme.palette.primary.dark}>
                Detalhes sobre o evento
              </EventSection>
              {extendedProps && extendedProps.location && (
                <Info>
                  <LocationIcon color="primary" />
                  <InfoText>{extendedProps.location}</InfoText>
                </Info>
              )}

              {extendedProps && extendedProps.description && (
                <Info>
                  <DescriptionIcon color="primary" />
                  <InfoText>{extendedProps.description}</InfoText>
                </Info>
              )}
            </>
          )}

        <EventSection color={theme.palette.primary.dark}>
          Público do evento
        </EventSection>
        <Info>
          <PublicIcon color="primary" />
          <div>
            {courses || classes ? (
              <>
                {courses && <InfoText>{`Cursos: ${courses}`}</InfoText>}
                {classes && <InfoText>{`Turmas: ${classes}`}</InfoText>}
              </>
            ) : (
              <InfoText>Evento Público</InfoText>
            )}
          </div>
        </Info>
      </DialogContent>
      <DialogActions>
        <IconButton
          size="small"
          color="secondary"
          component={Link}
          to={`/calendar/event/form/${event.id}`}
        >
          <EditIcon />
        </IconButton>
        <IconButton size="small" onClick={onDelete}>
          <DeleteIcon color="error" />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

EventDialog.defaultProps = {
  event: null
};

EventDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    backgroundColor: PropTypes.string,
    allDay: PropTypes.bool,
    extendedProps: PropTypes.shape({
      creator: PropTypes.string,
      description: PropTypes.string,
      location: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      destinationFilter: PropTypes.object
    })
  }),
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(EventDialog);
