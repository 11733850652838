import React, { Component } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

class ScrollColumnBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0
    };
  }

  componentDidMount() {
    this.calculateHeight();
  }

  componentDidUpdate(prevProps) {
    const { mb } = this.props;
    if (prevProps.mb !== mb) this.calculateHeight();
  }

  calculateHeight = () => {
    const { bodyId, subtractIds } = this.props;

    const parent = document.getElementById("chat-container");
    const body = document.getElementById(bodyId);

    if (!body || !parent) return;

    let parentHeight = parent.style.maxHeight;
    if (!parentHeight) return;
    parentHeight = parentHeight.substr(0, parentHeight.length - 2);

    let bodyMaxHeight = parentHeight;
    subtractIds.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (!element) return;
      const elementHeight = element.offsetHeight;
      bodyMaxHeight -= elementHeight;
    });

    this.setState({ maxHeight: `${bodyMaxHeight}px` });
  };

  render() {
    const { bodyId, children, mb } = this.props;
    const { maxHeight } = this.state;

    return (
      <Box id={bodyId} maxHeight={maxHeight} overflow="auto" mb={mb / 10}>
        {children}
      </Box>
    );
  }
}

ScrollColumnBody.defaultProps = {
  children: null,
  mb: 0
};

ScrollColumnBody.propTypes = {
  bodyId: PropTypes.string.isRequired,
  subtractIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  mb: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any // injected
};

export default ScrollColumnBody;
