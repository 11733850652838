import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import styled from "styled-components";
import WuiButton from "../waybee-ui/Button";
import DialogConfirm from "./DialogConfirm";

const ArrowBackIcon = styled(ArrowBack)`
  && {
    width: 22px;
  }
`;

const Button = styled(WuiButton)`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #787486;
`;

const BackButton = ({ to, hasConfirm }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <Grid item sm={12}>
      <Button
        color="default"
        startIcon={<ArrowBackIcon />}
        size="large"
        component={Link}
        to={!hasConfirm ? to : null}
        onClick={hasConfirm ? () => setOpen(true) : null}
      >
        Voltar
      </Button>
      <DialogConfirm
        onClose={() => setOpen(!open)}
        onSend={() => history.push(to)}
        message="Todas as informações preenchidas serão perdidas"
        open={open}
        title="Deseja sair?"
        sendText="Sair mesmo assim"
      />
    </Grid>
  );
};

BackButton.propTypes = {
  to: PropTypes.string,
  hasConfirm: PropTypes.bool
};

BackButton.defaultProps = {
  to: "/",
  hasConfirm: false
};

export default BackButton;
