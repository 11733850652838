import { combineReducers } from "redux";
import { campusesReducer } from "./campusesReducer";
import { accessionReducer } from "./accessionReducer";
import { themeReducer } from "./themeReducer";

export const Reducers = combineReducers({
  userState: campusesReducer,
  accessionState: accessionReducer,
  theme: themeReducer
});
