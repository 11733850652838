import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Grid, withTheme, IconButton } from "@material-ui/core";
import {
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
  InfoOutlined as InfoIcon,
  Group as GroupIcon
} from "@material-ui/icons";
import styled from "styled-components";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import BackButton from "../../components/BackButton";
import Group from "../../waybee-ui/Group";
import StudentItem from "../../components/AccessionStudentItem";
import Heading from "../../waybee-ui/Heading";
import Button from "../../waybee-ui/Button";
import SyncInfoDialog from "./components/SyncInfoDialog";
import NotRegisteredInfoDialog from "./components/NotRegisteredInfoDialog";
import SimpleConfirmDialog from "../../waybee-ui/Dialog/SimpleConfirmDialog";
import AccessionService from "../../services/AccessionService";
import UserStore from "../../utils/UserStore";
import SimpleDialog from "../../waybee-ui/Dialog/SimpleDialog";
import { setAccessionStudent } from "../../redux/actions";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import UserItem from "../../components/UserItem";
import Loading from "../../components/Loading";

const ActionButton = styled(Button)`
  display: flex;
  margin: 25px 0;

  p {
    margin: 0 0 0 12px;
  }
`;

const SyncHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const Groups = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const FamilyGroup = styled.div`
  margin: 0 -30px;
`;
const FamGroupTitle = styled.p`
  padding: 16px 30px;
  margin: 0;
  font-weight: 300;
  font-size: 24px;
  border-top: 1px solid #f2f2f2;
`;
const MemberType = styled.p`
  color: ${({ color }) => color};
  text-align: right;
  margin-right: 16px;
`;
const NoGroup = styled.p`
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
`;

class AccessionStudentActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openInfoDialog: false,
      confirmDialogMsg: false,
      openSuccessDialog: false,
      selectedModule: null,
      loadingAction: false,
      groups: [],
      syncModules: {
        CHAT: "chat_job",
        CALENDAR_EVENTS: "calendar_events_job",
        COMMUNICATION: "communication",
        INFOS: "infos",
        CAMPUS: "campus"
      },
      otherModules: {
        CREATE_USER: "CREATE_USER"
      }
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Painel de Adesão");

    const {
      accessionStudent,
      match: {
        params: { userId }
      }
    } = this.props;
    if (!accessionStudent || accessionStudent.id !== userId) this.getStudent();
    else this.getGroupByUsers();
  }

  getGroupByUsers = async () => {
    const { accessionStudent } = this.props;
    const groups = await AccessionService.getGroupsByUser(
      accessionStudent.waybeeId
    );
    this.setState({ groups });
  };

  getStudent = async () => {
    const {
      history,
      alert,
      setStudent,
      match: {
        params: { userId }
      }
    } = this.props;

    this.setState({ loading: true });

    try {
      const student = await AccessionService.getStudents(0, { ids: [userId] });
      if (student && student.length) {
        setStudent(student[0]);
        this.getGroupByUsers();
      } else {
        alert.show("Aluno não encontrado", { title: "Erro" });
        history.push(`/accession-panel/students`);
      }
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar o aluno", { title: "Erro" });
    } finally {
      this.setState({ loading: false });
    }
  };

  action = async () => {
    const { selectedModule, otherModules } = this.state;
    if (selectedModule === otherModules.CREATE_USER) {
      await this.createUser();
    } else {
      await this.syncModule();
    }
  };

  createUser = async () => {
    this.setState({ loadingAction: true });

    const { accessionStudent, setStudent } = this.props;
    await AccessionService.createStudentUser(
      accessionStudent.id,
      UserStore.getUserCampusId()
    );
    this.setState(
      {
        openSuccessDialog: true,
        confirmDialogMsg: null,
        loadingAction: false
      },
      () => {
        accessionStudent.status = "COMPLETE";
        setStudent(accessionStudent);
      }
    );
  };

  syncModule = async () => {
    const { accessionStudent } = this.props;
    const { selectedModule } = this.state;

    await AccessionService.syncStudent(
      accessionStudent.waybeeId,
      UserStore.getUserCampusId(),
      selectedModule
    );
    this.setState({ openSuccessDialog: true, confirmDialogMsg: null });
  };

  render() {
    const { accessionStudent, theme } = this.props;
    const {
      openInfoDialog,
      confirmDialogMsg,
      syncModules,
      otherModules,
      openSuccessDialog,
      loading,
      loadingAction,
      groups
    } = this.state;

    if (!accessionStudent) return null;

    const SYNC_COMM_TEXT = `Tem certeza que deseja sincronizar as comunicações do(a) aluno(a) ${accessionStudent.name}?`;
    const SYNC_CHAT_TEXT = `Tem certeza que deseja sincronizar os chats do(a) aluno(a) ${accessionStudent.name}?`;
    const SYNC_INFO_TEXT = `Tem certeza que deseja sincronizar as informações do(a) aluno(a) ${accessionStudent.name}?`;
    const SYNC_CALENDAR_TEXT = `Tem certeza que deseja sincronizar os eventos do(a) aluno(a) ${accessionStudent.name}?`;
    const SYNC_CAMPUS_TEXT = `Tem certeza que deseja sincronizar o campus do(a) aluno(a) ${accessionStudent.name}?`;
    const CREATE_USER_TEXT = `Tem certeza que deseja gerar uma conta para o(a) aluno(a) ${accessionStudent.name}?`;

    if (loading) return <Loading />;

    return (
      <Grid container>
        {loadingAction && <LoadingBackdrop />}
        <Grid xs={12}>
          <BackButton to="/accession-panel/students" />
        </Grid>
        <Grid item xs={12}>
          <Group>
            <Box margin="-30px">
              {accessionStudent && (
                <>
                  <StudentItem user={accessionStudent} hasStatus />
                </>
              )}
            </Box>
          </Group>
        </Grid>

        <Grid item xs={12}>
          {accessionStudent &&
            accessionStudent.status !== "USER_NOT_REGISTERED" && (
              <>
                <Box mt={1.2}>
                  <Group>
                    <Heading level={2} icon={GroupIcon} gutterBottom>
                      Grupo familiar
                    </Heading>

                    {!groups.length && (
                      <NoGroup>
                        Nenhum grupo familiar encontrado para o aluno
                      </NoGroup>
                    )}

                    <Groups>
                      {groups.map((group, i) => (
                        <FamilyGroup>
                          {groups.length > 1 && (
                            <FamGroupTitle>
                              Grupo
                              {i + 1}
                            </FamGroupTitle>
                          )}

                          {group.members.map(member => (
                            <UserItem
                              key={member.id}
                              photoSize={40}
                              name={member.name}
                              photoUrl={member.photoProfile}
                              rightInfo={(
                                <Grid item xs={3}>
                                  <MemberType
                                    color={theme.palette.primary.main}
                                  >
                                    {member.typeMember}
                                  </MemberType>
                                </Grid>
                              )}
                            />
                          ))}
                        </FamilyGroup>
                      ))}
                    </Groups>
                  </Group>
                </Box>

                <Box mt={1.2}>
                  <Group>
                    <Heading level={2} icon={SettingsIcon} gutterBottom>
                      <SyncHeader>
                        <span>Sincronização de dados</span>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            this.setState({ openInfoDialog: true })
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </SyncHeader>
                    </Heading>

                    <Box mt={3}>
                      <ActionButton
                        color="secondary"
                        onClick={() =>
                          this.setState({
                            confirmDialogMsg: SYNC_COMM_TEXT,
                            selectedModule: syncModules.COMMUNICATION
                          })
                        }
                      >
                        <RefreshIcon />
                        <p>Sincronizar comunicações</p>
                      </ActionButton>
                      <ActionButton
                        color="secondary"
                        onClick={() =>
                          this.setState({
                            confirmDialogMsg: SYNC_CHAT_TEXT,
                            selectedModule: syncModules.CHAT
                          })
                        }
                      >
                        <RefreshIcon />
                        <p>Sincronizar canais de chat</p>
                      </ActionButton>
                      <ActionButton
                        color="secondary"
                        onClick={() =>
                          this.setState({
                            confirmDialogMsg: SYNC_INFO_TEXT,
                            selectedModule: syncModules.INFOS
                          })
                        }
                      >
                        <RefreshIcon />
                        <p>Sincronizar informações do aluno</p>
                      </ActionButton>
                      <ActionButton
                        color="secondary"
                        onClick={() =>
                          this.setState({
                            confirmDialogMsg: SYNC_CALENDAR_TEXT,
                            selectedModule: syncModules.CALENDAR_EVENTS
                          })
                        }
                      >
                        <RefreshIcon />
                        <p>Sincronizar calendário de eventos</p>
                      </ActionButton>
                      <ActionButton
                        color="secondary"
                        onClick={() =>
                          this.setState({
                            confirmDialogMsg: SYNC_CAMPUS_TEXT,
                            selectedModule: syncModules.CAMPUS
                          })
                        }
                      >
                        <RefreshIcon />
                        <p>Sincronizar campus</p>
                      </ActionButton>
                    </Box>
                  </Group>
                </Box>
              </>
            )}

          {accessionStudent &&
            accessionStudent.status === "USER_NOT_REGISTERED" && (
              <Group>
                <Heading level={2} icon={SettingsIcon} gutterBottom>
                  <SyncHeader>
                    <span>Criar usuário</span>
                    <IconButton
                      color="secondary"
                      onClick={() => this.setState({ openInfoDialog: true })}
                    >
                      <InfoIcon />
                    </IconButton>
                  </SyncHeader>
                </Heading>

                <Box mt={3}>
                  <ActionButton
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        confirmDialogMsg: CREATE_USER_TEXT,
                        selectedModule: otherModules.CREATE_USER
                      });
                    }}
                  >
                    <RefreshIcon />
                    <p>Gerar usuário para o app</p>
                  </ActionButton>
                </Box>
              </Group>
            )}
        </Grid>

        {accessionStudent &&
          accessionStudent.status === "USER_NOT_REGISTERED" && (
            <NotRegisteredInfoDialog
              open={openInfoDialog}
              onClose={() => this.setState({ openInfoDialog: false })}
            />
          )}

        {accessionStudent &&
          accessionStudent.status !== "USER_NOT_REGISTERED" && (
            <SyncInfoDialog
              open={openInfoDialog}
              onClose={() => this.setState({ openInfoDialog: false })}
            />
          )}

        <SimpleConfirmDialog
          open={confirmDialogMsg}
          onConfirm={this.action}
          message={confirmDialogMsg}
          onClose={() => this.setState({ confirmDialogMsg: null })}
        />

        <SimpleDialog
          open={openSuccessDialog}
          closeBtn
          title="Sucesso"
          message="Ação executada com sucesso, em breve as informações estarão atualizadas"
          onClose={() => this.setState({ openSuccessDialog: false })}
        />
      </Grid>
    );
  }
}

AccessionStudentActions.propTypes = {
  setTitle: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string
    })
  }).isRequired, // inject by withRouter
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired, // inject by withRouter
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired, // Injected by withAlert hov
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // Injected by withTheme Hoc
  // eslint-disable-next-line react/forbid-prop-types
  accessionStudent: PropTypes.object.isRequired, // injected by mapStateToProps
  setStudent: PropTypes.func.isRequired // injected by mapDispatchToProps
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setStudent: setAccessionStudent
    },
    dispatch
  );

const mapStateToProps = store => ({
  accessionStudent: store.accessionState.accessionStudent
});

export default compose(
  withAlert(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withTheme(withRouter(AccessionStudentActions)));
