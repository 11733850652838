import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import {
  Details as DetailsIcon,
  People as GroupIcon
} from "@material-ui/icons";
import { withAlert } from "react-alert";
import * as yup from "yup";
import moment from "@moment";
import _ from "lodash";
import BackButton from "../../components/BackButton";
import Heading from "../../waybee-ui/Heading";
import Group from "../../waybee-ui/Group";
import DetailForm from "./components/DetailForm";
import PublicSelector from "../../components/PublicSelector";
import CalendarService from "../../services/CalendarService";
import Button from "../../waybee-ui/Button";
import LoadingDialog from "../../waybee-ui/Dialog/LoadingDialog";
import SimpleConfirmDialog from "../../waybee-ui/Dialog/SimpleConfirmDialog";
import EventNotifications from "./components/EventNotifications";

require("../../utils/yup.locale.pt-br");

yup.addMethod(yup.mixed, "requiredWhen", function requiredWhen(
  ref,
  caseValue = true,
  message
) {
  return this.test({
    name: "requiredWhen",
    exclusive: false,
    message: message || "Campo Obigatório",
    params: {
      reference: ref.path
    },
    test: function test(value) {
      return this.resolve(ref) === caseValue ? !!value : true;
    }
  });
});

class CalendarEventForm extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      loading: false,
      notificationDialog: false,
      calendars: [],
      resetFormFlag: 0,
      values: {
        allDay: true,
        sendNotification: false,
        notifyMessage: "",
        initialDate: now,
        initialTime: now,
        finalDate: now,
        finalTime: now,
        publicField: "Y"
      },
      schema: yup.object().shape({
        title: yup.string().required("Título do evento é obrigatório"),
        calendar: yup.string().required("Tipo do calendário é obrigatório"),
        initialDate: yup
          .date()
          .required("Data de início do evento é obrigatória")
          .nullable()
          .transform((curr, orig) =>
            orig === ""
              ? null
              : moment(curr)
                  .startOf("day")
                  .toDate()
          ),
        initialTime: yup
          .date()
          .requiredWhen(
            yup.ref("allDay"),
            false,
            "Hora de início do evento é obrigatório"
          )
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr)),
        finalDate: yup
          .date()
          .required("Data de fim do evento é obrigatória")
          .nullable()
          .min(
            yup.ref("initialDate"),
            "Data de fim do evento deve ser posterior a data de início"
          )
          .transform((curr, orig) =>
            orig === ""
              ? null
              : moment(curr)
                  .startOf("day")
                  .toDate()
          ),
        finalTime: yup
          .date()
          .requiredWhen(
            yup.ref("allDay"),
            false,
            "Hora do fim do evento é obrigatório"
          )
          .nullable()
          .test({
            name: "minDate",
            message:
              "Hora de fim do evento deve ser posterior a hora de início",
            test(value) {
              if (this.parent.allDay) return true;
              const isSameDay = moment(this.parent.initialDate).isSame(
                moment(this.parent.finalDate),
                "day"
              );
              if (isSameDay)
                return moment(this.parent.initialTime).isSameOrBefore(
                  moment(value)
                );
              return true;
            }
          })
          .transform((curr, orig) => (orig === "" ? null : curr)),
        allDay: yup.bool()
      })
    };
  }

  componentDidMount() {
    this.getCalendars();
  }

  getSelectedEvent = async () => {
    const {
      alert,
      setTitle,
      match: {
        params: { eventId }
      }
    } = this.props;
    const { resetFormFlag } = this.state;

    if (eventId) {
      setTitle("Editar Evento");

      try {
        const event = await CalendarService.getEvent(eventId);

        const initialTime = moment(event.endDate)
          .set("day", 1)
          .set("month", 1)
          .set("year", 2022);
        const finalTime = moment(event.startDate)
          .set("day", 1)
          .set("month", 1)
          .set("year", 2022);

        const minutes = moment
          .duration(initialTime.diff(finalTime))
          .asMinutes();
        const allDay = minutes > 1439;

        const values = {
          title: event.title,
          calendar: event.calendarId,
          initialDate: new Date(event.startDate),
          initialTime: new Date(event.startDate),
          finalDate: new Date(event.endDate),
          finalTime: new Date(event.endDate),
          location: event.location,
          description: event.description,
          allDay,
          publicField: event.destinationFilter ? "N" : "Y",
          destinationFilter: event.destinationFilter,
          notification: event.notification,
          sendNotification: false,
          notifyMessage: ""
        };
        this.setState({
          values,
          resetFormFlag: resetFormFlag === 1 ? 0 : 1
        });
      } catch (e) {
        console.error(e);
        const message = _.get(
          e,
          "response.data.error.message",
          "Erro ao buscar evento"
        );
        alert.show(message, {
          title: "Erro"
        });
      }
    } else {
      setTitle("Novo Evento");
    }
  };

  reload = () => {
    this.setState({ loading: true });
    this.getSelectedEvent().then(() => this.setState({ loading: false }));
  };

  validate = values => {
    const { schema } = this.state;
    return schema.validate(values, { abortEarly: false });
  };

  getCalendars = async () => {
    const { alert } = this.props;
    this.setState({ loading: true });

    try {
      const calendars = await CalendarService.getCalendars();
      this.setState({ calendars }, this.getSelectedEvent);
    } catch (e) {
      let message = "";
      if (e.errors) message = e.errors.join("\n");

      message = _.get(
        e,
        "response.data.error.message",
        "Erro ao salvar calendário"
      );
      alert.show(message, {
        title: "Erro ao bucar calendários"
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  saveValues = val => {
    const newValues = val;
    const { values } = this.state;
    if (newValues.allDay) {
      newValues.initialTime = null;
      newValues.finalTime = null;
    }

    let { destinationFilter } = newValues;
    if (newValues.publicField === "Y") {
      destinationFilter = null;
    }

    const sendValues = {
      ...values,
      ...newValues,
      destinationFilter
    };

    this.setState({
      values: sendValues
    });
  };

  saveEvent = async () => {
    const {
      alert,
      match: {
        params: { eventId }
      }
    } = this.props;
    this.setState({ loading: true });

    try {
      const { values } = this.state;

      if (values.initialTime) {
        const initialTime = moment(values.initialTime)
          .set("date", 1)
          .set("month", 0)
          .set("year", 2022)
          .toDate();
        values.initialTime = initialTime;
      }

      if (values.finalTime) {
        const finalTime = moment(values.finalTime)
          .set("date", 1)
          .set("month", 0)
          .set("year", 2022)
          .toDate();
        values.finalTime = finalTime;
      }

      await this.validate(values);

      if (eventId) {
        await CalendarService.editEvent(values, eventId);
      } else {
        await CalendarService.saveEvent(values);
        this.reset();
      }

      if (values.publicField === "N" && eventId) {
        this.setState({ notificationDialog: true });
      } else {
        alert.show("Evento salvo com sucesso", {
          title: "Sucesso"
        });
      }
    } catch (e) {
      const message =
        (e.errors && e.errors.join("\n")) ||
        e.message ||
        "Ocorreu um erro ao savar o evento";
      alert.show(message, {
        title: "Erro ao enviar formulário"
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  sendNotification = async () => {
    const {
      alert,
      match: {
        params: { eventId }
      }
    } = this.props;

    try {
      await CalendarService.notifyEventEdit(eventId);
      alert.show("Notificações enviadas com sucesso", {
        title: "Sucesso"
      });
    } catch (e) {
      const message =
        (e.errors && e.errors.join("\n")) ||
        e.message ||
        "Ocorreu um erro ao savar o evento";
      alert.show(message, {
        title: "Erro ao enviar notificação"
      });
    } finally {
      this.setState({ notificationDialog: false });
    }
  };

  reset = () => {
    const { resetFormFlag, values } = this.state;

    const now = new Date();
    this.setState({
      values: {
        title: "",
        calendar: values.calendar,
        allDay: true,
        initialDate: now,
        initialTime: now,
        finalDate: now,
        finalTime: now,
        location: "",
        description: "",
        destinationFilter: {
          students: {
            courses: [],
            classes: [],
            excluded: []
          }
        }
      },
      resetFormFlag: resetFormFlag === 1 ? 0 : 1
    });
  };

  render() {
    const {
      values,
      calendars,
      resetFormFlag,
      loading,
      notificationDialog
    } = this.state;

    const {
      match: {
        params: { eventId }
      }
    } = this.props;

    const selectedCalendar =
      values.calendar &&
      calendars.find(calendar => calendar.id === values.calendar);

    const showPublicSelector =
      selectedCalendar &&
      ((selectedCalendar.public && values.publicField === "N") ||
        !selectedCalendar.public);

    if (loading) {
      return <></>;
    }

    return (
      <span>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <BackButton to="/calendar" />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} mb={2}>
              <Group>
                <Heading level={2} icon={DetailsIcon} gutterBottom>
                  Detalhes do evento
                </Heading>

                <DetailForm
                  values={values}
                  calendars={calendars}
                  onChange={this.saveValues}
                  resetFlag={resetFormFlag}
                />
              </Group>
            </Box>
          </Grid>

          {showPublicSelector && (
            <Grid item xs={12}>
              <Group>
                <Heading level={2} icon={GroupIcon} gutterBottom>
                  Público do calendário
                </Heading>

                <PublicSelector
                  description={
                    selectedCalendar.public
                      ? "Assinale para quem deseja enviar o evento:"
                      : "Público definido no calendário"
                  }
                  onChange={val => this.saveValues({ destinationFilter: val })}
                  value={
                    selectedCalendar.public
                      ? values.destinationFilter
                      : selectedCalendar.destinationFilter
                  }
                  resetFlag={resetFormFlag}
                  disabled={!selectedCalendar.public}
                />
              </Group>
            </Grid>
          )}

          {eventId && (
            <Grid item xs={12}>
              <Box mt={2}>
                <EventNotifications
                  reload={this.reload}
                  event={values}
                  eventId={eventId}
                  notifications={values.notification}
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={4}>
            <Box mt={6.4}>
              <Button
                onClick={this.saveEvent}
                color="primary"
                variant="contained"
                fullWidth
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <LoadingDialog
          onClose={() => this.setState({ loading: false })}
          open={loading}
        />

        <SimpleConfirmDialog
          title="Evento alterado com sucesso"
          message="Deseja notificar os remetentes das alterações?"
          onClose={() => this.setState({ notificationDialog: false })}
          onConfirm={this.sendNotification}
          open={notificationDialog}
        />
      </span>
    );
  }
}

CalendarEventForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // Injected by withAlert hoc
};

export default withAlert()(CalendarEventForm);
