import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@material-ui/core";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import Button from "../waybee-ui/Button";

function SelectScheduleDialog({
  open,
  onClose,
  value,
  onChange,
  onConfirm,
  title,
  message,
  alert
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {alert}
          <Typography variant="body2" gutterBottom>
            {message}
          </Typography>
          <Box py={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
              <KeyboardDateTimePicker
                id="schedule"
                name="schedule"
                margin="dense"
                variant="inline"
                ampm={false}
                format="dd/MM/yyyy HH:mm"
                label="Data e hora"
                value={value}
                onChange={onChange}
                invalidDateMessage="Data inválida"
                autoOk
                fullWidth
                disablePast
              />
            </MuiPickersUtilsProvider>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Agendar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectScheduleDialog.defaultProps = {
  alert: ""
};

SelectScheduleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  alert: PropTypes.func
};

export default SelectScheduleDialog;
