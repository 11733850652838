import { api } from "../utils/api";

const saveExternalLinkMenu = async data => {
  const res = await api.put("Settings/createCustomMenu", data);
  return res.data;
};

const getMenuLinks = async () => {
  const res = await api.get(`Settings/getCustomMenu`);
  return res.data;
};

export default {
  saveExternalLinkMenu,
  getMenuLinks
};
