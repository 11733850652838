import React from "react";
import styled from "styled-components";
import MuiButton from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";

const DefaultButton = styled(MuiButton)`
  color: ${props => (props.variant === "contained" ? "#fff" : "")};
  color: ${props =>
    props.color === "default" && props.variant !== "contained"
      ? "#7e7e7e"
      : ""};
  background-color: ${props =>
    props.variant === "contained" && props.theme.palette[props.color].main};
  margin: ${props => (!props.variant ? "0 -8px" : "")};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.11px;
  text-transform: none;
  height: 44px;
  line-height: 1em;
  box-shadow: none;
  border-radius: 4px;
  svg {
    width: 1em;
    height: 1em;
  }
  &:hover {
    background-color: ${props =>
      props.variant === "contained" && props.theme.palette[props.color].dark};
  }
`;

const LargeButton = styled(DefaultButton)`
  font-size: 20px;
  height: 48px;
  line-height: 35px;
  svg {
    width: 35px;
    height: 35px;
  }
`;

const SmallButton = styled(DefaultButton)`
  font-size: 16px;
  letter-spacing: normal;
  text-transform: none;
  svg {
    width: 1.35em;
    height: 1.35em;
    margin-right: 10px;
  }
`;

const Button = props => {
  const theme = useTheme();
  const { size } = props;
  let ButtonComponent = DefaultButton;
  if (size === "small") {
    ButtonComponent = SmallButton;
  }
  if (size === "large") {
    ButtonComponent = LargeButton;
  }
  return <ButtonComponent {...props} theme={theme} />;
};

Button.propTypes = {
  ...MuiButton.propTypes
};

export default Button;
