import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class InfinityScroll extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.scrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEvent);
  }

  scrollEvent = async () => {
    const { onReachBottom } = this.props;

    const container = document.querySelector("html");
    if (
      container.scrollTop + window.innerHeight >=
      container.offsetHeight - 100
    )
      await onReachBottom();
  };

  render() {
    return <Fragment {...this.props} />;
  }
}

InfinityScroll.propTypes = {
  onReachBottom: PropTypes.func.isRequired
};

export default InfinityScroll;
