const manageSelectedOptions = option => {
  if (!option) {
    return undefined;
  }
  const selectedOptions = [];
  if (option.sentSms) selectedOptions.push("Enviar SMS");
  if (option.sendNotification)
    selectedOptions.push("Enviar noficação da comunicação");
  if (option.isAckRequired) selectedOptions.push("Ciência obrigatória");
  if (option.surveyId) selectedOptions.push("Enquete");
  if (option.sentEmail) selectedOptions.push("Enviar E-mail");
  if (option.hasLinkButton) selectedOptions.push("Botão de link");
  return selectedOptions;
};

export default manageSelectedOptions;
