import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import {
  Details as DetailsIcon,
  GroupWork,
  People as PeopleIcon
} from "@material-ui/icons";
import moment from "@moment";
import styled from "styled-components";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import Avatar from "../../../waybee-ui/Avatar";

const InfoGroup = styled.div`
  margin: 12px 0;
`;

const GroupDetails = ({ communication, author, subscriberCount, survey }) => {
  let statusText = "";
  let receiverType = "";
  const classes = [];
  const grades = [];
  const { destinationFilter } = communication;

  switch (communication.status) {
    case "open":
      statusText = "Em aberto";
      break;
    case "sent":
      statusText = "Enviado";
      break;
    case "scheduled":
      statusText = "Agendado";
      break;
    case "deleted":
      statusText = "Deletado";
      break;
    default:
  }

  if (destinationFilter && destinationFilter.communicationRecipients) {
    switch (destinationFilter.communicationRecipients) {
      case "responsibles":
        receiverType = " Somente responsáveis";
        break;
      case "students":
        receiverType = "Somente alunos";
        break;
      case "studentsAndResponsibles":
        receiverType = "Alunos e Responsaveis";
        break;
      default:
    }

    destinationFilter.students.classes.map(classe => classes.push(classe.NOME));
    destinationFilter.students.grades.map(grade => grades.push(grade.NOME));
  }

  return (
    <Group>
      <Box minHeight={380}>
        <Heading level={2} icon={DetailsIcon} gutterBottom>
          Detalhes
        </Heading>

        <Typography variant="caption">Data de envio</Typography>
        <Typography variant="body1">
          {moment(communication.schedule || communication.createdAt).format(
            "DD[ de ]MMMM[ de ]YYYY"
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {moment(communication.schedule || communication.createdAt).format(
            "HH:mm"
          )}
        </Typography>

        <Typography variant="caption">Data de criação</Typography>
        <Typography variant="body1">
          {moment(communication.createdAt).format("DD[ de ]MMMM[ de ]YYYY")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {moment(communication.createdAt).format("HH:mm")}
        </Typography>

        {communication.deleted_at && (
          <Fragment>
            <Typography variant="caption">Data de exclusão</Typography>
            <Typography variant="body1">
              {moment(communication.deleted_at).format(
                "DD[ de ]MMMM[ de ]YYYY"
              )}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {moment(communication.deleted_at).format("HH:mm")}
            </Typography>
          </Fragment>
        )}

        {survey && (
          <div>
            <Typography variant="caption">
              Data de Encerrameto da Enquete
            </Typography>
            <Typography variant="body1">
              {moment(survey.limitDate).format("DD[ de ]MMMM[ de ]YYYY")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {moment(survey.limitDate).format("HH:mm")}
            </Typography>
          </div>
        )}

        <Typography variant="caption">Emissor</Typography>
        <Box display="flex">
          <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
            <Typography variant="body1" gutterBottom>
              {author.name}
            </Typography>
          </Avatar>
        </Box>

        {communication.deleted_at && (
          <Fragment>
            <Typography variant="caption">Deletado por</Typography>
            <Box display="flex">
              <Avatar
                alt="Avatar"
                src={communication.deletedByUser.photoProfile}
                gutterBottom
              >
                <Typography variant="body1" gutterBottom>
                  {communication.deletedByUser.name}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        {communication.createdByExternalUser && (
          <Fragment>
            <Typography variant="caption">Criado por</Typography>
            <Box display="flex">
              <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
                <Typography variant="body1" gutterBottom>
                  {communication.createdByExternalUser}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        {communication.updatedByExternalUser && (
          <Fragment>
            <Typography variant="caption">Atualizado por</Typography>
            <Box display="flex">
              <Avatar alt="Avatar" src={author.photoProfile} gutterBottom>
                <Typography variant="body1" gutterBottom>
                  {communication.updatedByExternalUser}
                </Typography>
              </Avatar>
            </Box>
          </Fragment>
        )}

        <Typography variant="caption">Status</Typography>
        <Typography variant="body1" gutterBottom>
          {statusText}
        </Typography>

        <Typography variant="caption">Tipo de comunicação</Typography>
        <Typography variant="body1" gutterBottom>
          {communication.type.name}
        </Typography>

        <Typography variant="caption">Categoria selecionada</Typography>
        <Typography variant="body1" gutterBottom>
          {communication.category.name}
        </Typography>

        <Typography variant="caption">Quantidade de destinatários</Typography>

        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <PeopleIcon fontSize="large" color="action" />
          </Grid>
          <Grid item>
            <Typography variant="body1">{subscriberCount}</Typography>
          </Grid>
        </Grid>

        <Typography variant="caption">Quantidade de Grupos</Typography>

        <Grid item direction="row" alignItems="center" spacing={1} xs={12}>
          <Grid item>
            {destinationFilter && destinationFilter.communicationRecipients ? (
              <Fragment>
                {destinationFilter.students.courses.map(course => (
                  <InfoGroup key={Math.random()}>
                    <Tooltip title={course.NOME}>
                      <Typography noWrap variant="body1">
                        Curso: 
                        {' '}
                        {course.NOME}
                      </Typography>
                    </Tooltip>
                    {destinationFilter.students.grades.length !== 0 && (
                      <Typography noWrap variant="body1">
                        Série: 
                        {' '}
                        {grades.join(", ")}
                      </Typography>
                    )}
                    {destinationFilter.students.classes.length !== 0 ? (
                      <Tooltip title={classes.join(", ")}>
                        <Typography noWrap variant="body1">
                          Turma: 
                          {' '}
                          {classes.join(", ")}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="body1">
                        Turma: Todas as turmas
                      </Typography>
                    )}
                  </InfoGroup>
                ))}
                <Tooltip title={receiverType}>
                  <Typography noWrap variant="body1">
                    Tipo de destinatário: 
                    {' '}
                    {receiverType}
                  </Typography>
                </Tooltip>
                <Typography>
                  {destinationFilter.students.enrollmentTypes.length !== 0
                    ? `Tipo de matricula: ${destinationFilter.students.enrollmentTypes.map(
                        type => type.NOME_TIPO_MATRICULA
                      )}`
                    : ""}
                </Typography>
                <Typography>
                  {destinationFilter.students.enrollmentSituation.length !== 0
                    ? `Situação de matricula: ${destinationFilter.students.enrollmentSituation.map(
                        sit => sit.NOME_STATUS_MATRICULA
                      )}`
                    : ""}
                </Typography>
                <Typography>
                  Ano:
                  {destinationFilter.students.years}
                </Typography>
              </Fragment>
            ) : (
              <GroupWork fontSize="large" color="action" />
            )}
          </Grid>
        </Grid>
      </Box>
    </Group>
  );
};

GroupDetails.defaultProps = {
  survey: undefined
};

GroupDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  author: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  communication: PropTypes.object.isRequired,
  subscriberCount: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  survey: PropTypes.object
};

export default GroupDetails;
