import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Details as DetailsIcon,
  Edit as EditIcon,
  People as PeopleIcon,
  Visibility as VisibilityIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  HowToReg as HowToRegIcon
} from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import isBlob from "is-blob";
import UserStore from "../../utils/UserStore";
import ActivitiesService from "../../services/ActivitiesService";
import SchoolGroupService from "../../services/SchoolGroupService";
import FileInputService from "../../services/FileInputService";
import BugsnagService from "../../services/BugsnagService";
import Button from "../../waybee-ui/Button";
import Group from "../../waybee-ui/Group";
import Divider from "../../waybee-ui/Divider";
import Heading from "../../waybee-ui/Heading/Heading";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import ImageBox from "../../waybee-ui/ImageBox";
import BackButton from "../../components/BackButton";
import FileInput from "../../components/FileInput/FileInput";
import ModalReceiversSelector from "./ModalReceiversSelector";
import ModalConfirm from "./ModalConfirm";
import Preview from "./components/preview";
import SelectScheduleDialog from "../../components/SelectScheduleDialog";
import HighlightText from "../../waybee-ui/HighlightText";
import AttachmentList from "../../components/AttachmentsList";
import LoadingContent from "../../components/LoadingContent";
import ModalConfirmUpdate from "./ModalConfirmUpdate";
import UploadUtil from "../../utils/UploadUtil";

const myAccess = UserStore.getAccess();

const uuidV4 = require("uuid/v4");
require("../../utils/yup.locale.pt-br");

Yup.addMethod(Yup.mixed, "requiredWhen", function requiredWhen(
  ref,
  caseValue = true,
  message
) {
  return this.test({
    name: "requiredWhen",
    exclusive: false,
    message: message || "Campo Obigatório",
    params: {
      reference: ref.path
    },
    test: function test(value) {
      return this.resolve(ref) === caseValue ? !!value : true;
    }
  });
});

class ActivityForm extends Component {
  state = {
    submitAction: "",
    coursesAndClasses: [],
    studentSelectorIsOpen: false,
    modalConfirmIsOpen: false,
    modalConfirmUpdateIsOpen: false,
    scheduleDialogIsOpen: false,
    uuid: null,
    schedule: null,
    finished: false,
    attachments: [],
    openPreview: false,
    uploading: false,
    loading: true,
    formValues: {
      attachments: [],
      bodyMessage: "",
      buttonText: "",
      studentClasses: [],
      studentCourses: [],
      destinationFilter: {},
      eventStart: new Date(),
      sendNotification: false,
      sentEmail: false,
      studentSubscribers: [],
      employeeSubscribers: [],
      title: "",
      filterStudents: true,
      filterEmployees: false,
      responsibleTeachers: false,
      employeeClasses: [],
      employeeCourses: [],
      employeeTypes: [],
      hasHomeActivity: false,
      bodyHomeActivity: "",
      subject: ""
    },
    id: 0
  };

  validationSchema = Yup.object().shape({
    hasHomeActivity: Yup.bool().required(),
    bodyHomeActivity: Yup.string().notRequired(),
    subject: Yup.string().required(),
    eventStart: Yup.date()
      .required()
      .min(new Date(2019, 0, 1)),
    sentEmail: Yup.bool(),
    sendNotification: Yup.bool(),
    bodyMessage: Yup.string().notRequired(),
    // eslint-disable-next-line react/forbid-prop-types
    attachments: Yup.array(),
    title: Yup.string().required(),
    // eslint-disable-next-line react/forbid-prop-types
    studentClasses: Yup.array(),
    // eslint-disable-next-line react/forbid-prop-types
    studentCourses: Yup.array(),
    // eslint-disable-next-line react/forbid-prop-types
    employeeClasses: Yup.array(),
    // eslint-disable-next-line react/forbid-prop-types
    employeeCourses: Yup.array(),
    // eslint-disable-next-line react/forbid-prop-types
    employeeTypes: Yup.array()
  });

  subjects = [];

  async componentDidMount() {
    const { setTitle, openAlert } = this.props;
    const { match } = this.props;
    const { id } = match.params;
    const { formValues } = this.state;

    // Dados necessários tanto para inclusão como na edição
    // Tornei assincrono, pois preciso desta informação para edição da atividade
    await SchoolGroupService.getCoursesAndClasses()
      .then(coursesAndClasses => this.setState({ coursesAndClasses }))
      .catch(err => {
        openAlert({
          status: "error",
          message: "Falha ao obter dados da atividade",
          onClose: () => window.location.replace("/activities")
        });
        BugsnagService.notifyError(err);
      });

    await ActivitiesService.getSubjects()
      .then(data => {
        this.subjects = data;
      })
      .catch(err => {
        openAlert({
          status: "error",
          message: "Falha ao obter dados das disciplinas",
          onClose: () => window.location.replace("/activities")
        });
        BugsnagService.notifyError(err);
      });

    // Se tem id, tratasse de edição, então carrego os dados
    if (id) {
      setTitle("Editar Atividade");
      ActivitiesService.getData(id).then(response => {
        const formValuesEdit = {
          subject: response.data.subjectId.toString(),
          eventStart: response.data.eventStart,
          title: response.data.title,
          bodyMessage: response.data.bodyMessage,
          hasHomeActivity: response.data.hasHomeActivity,
          bodyHomeActivity: response.data.bodyHomeActivity,
          status: response.data.status,
          excludedDestinationIds: response.data.excludedDestinationIds,
          attachments: [],
          buttonText: "",
          destinationFilter: {},
          sendNotification: false,
          sentEmail: false,
          studentSubscribers: [],
          employeeSubscribers: [],
          filterStudents: true,
          filterEmployees: false,
          responsibleTeachers: false,
          employeeClasses: [],
          employeeCourses: [],
          employeeTypes: [],
          studentCourses: [],
          studentClasses: []
        };

        // Carregando dados dos cursos
        response.data.destinationFilter.students.courses.forEach(item => {
          const { coursesAndClasses } = this.state;
          const course = coursesAndClasses.find(
            itemCourse => itemCourse.CODIGO === item.CODIGO
          );

          if (course) {
            formValuesEdit.studentCourses.push(course);
          }
        });

        // Carregando dados das classes
        response.data.destinationFilter.students.classes.forEach(item => {
          const { coursesAndClasses } = this.state;
          const course = coursesAndClasses.find(
            itemCourse => itemCourse.CODIGO === item.CODIGO_CURSO
          );
          const classe = course.VW_TURMA.find(
            itemClasse => itemClasse.CODIGO === item.CODIGO
          );

          if (classe) {
            formValuesEdit.studentClasses.push(classe);
          }
        });
        this.setState({
          loading: false,
          uuid: response.data.uuid,
          id,
          attachments: response.data.attachments.map(attach => ({
            id: attach.id,
            file: attach,
            name: attach.name
          })),
          formValues: { formValues, ...formValuesEdit }
        });
      });
    } else {
      this.setState({ loading: false });
      setTitle("Nova Atividade");

      const { uuid } = this.state;
      if (!uuid) {
        this.setState({ uuid: uuidV4() });
      }
    }
  }

  getClassesByCourses = courses => {
    return courses ? courses.map(course => course.VW_TURMA).flat() : [];
  };

  setSubmitAction(textAction) {
    this.setState({ submitAction: textAction });
  }

  handleOpenPreview = () => {
    this.setState({ openPreview: true });
  };

  save = async data => {
    let response;
    const { id } = this.state;

    try {
      if (id) {
        response = await ActivitiesService.update(id, data);
      } else {
        response = await ActivitiesService.save(data);
      }
    } catch (e) {
      throw Error("Erro ao salvar atividade");
    }

    this.setState({ finished: true });

    return response.id;
  };

  schedule = async data => {
    const id = await this.save(data);
    try {
      const { schedule } = this.state;
      await ActivitiesService.schedule(id, schedule);
    } catch (e) {
      throw Error(
        `A atividade foi salva mas houve um problema ao agendar: ${e.message}`
      );
    }
  };

  send = async data => {
    try {
      const id = await this.save(data);
      await ActivitiesService.send(id);
    } catch (e) {
      throw Error(
        `A atividade foi salva mas houve um problema ao enviar: ${e.message}`
      );
    }
  };

  submitData = async params => {
    const { uuid, submitAction } = this.state;
    const { openAlert } = this.props;
    const textAction = this.getTextAction(submitAction);

    this.setState({ loading: true });

    const data = { ...params };

    data.uuid = uuid;
    const allSubscribers = data.studentSubscribers.concat(
      data.employeeSubscribers
    );
    data.subscribers = allSubscribers
      .filter(e => e.checked)
      .map(e => ({ id: e.id }));
    data.excludedDestinationIds = allSubscribers
      .filter(e => e.checked === false)
      .map(e => e.id);

    let submitPromise;
    switch (submitAction) {
      case "save":
        submitPromise = this.save;
        break;
      case "schedule":
        submitPromise = this.schedule;
        break;
      case "send":
        submitPromise = this.send;
        break;
      case "update":
        submitPromise = this.send;
        break;
      default:
    }

    try {
      await submitPromise(data);
      const { history } = this.props;
      history.replace(`/reload`);
      setTimeout(() => {
        history.replace(`/activities`);
        openAlert({
          status: "success",
          message: `Sucesso ao ${textAction} atividade`
        });
      });
    } catch (e) {
      this.closeModalConfirm();
      openAlert({
        status: "warning",
        title: "Atenção",
        message: e.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  getTextAction = submitAction => {
    switch (submitAction) {
      case "send":
        return "Enviar";
      case "save":
        return "Salvar";
      case "schedule":
        return "Agendar";
      case "update":
        return "Editar";
      default:
        return "";
    }
  };

  verifyErrors(errors) {
    const { openAlert } = this.props;
    if (Object.keys(errors).length !== 0) {
      openAlert({
        status: "error",
        message: "Alguns campos não foram preeenchidos corretamente."
      });
      return true;
    }
    return false;
  }

  formErrorAlert(message) {
    const { openAlert } = this.props;
    openAlert({
      status: "error",
      message: message || "Alguns campos não foram preeenchidos corretamente."
    });
  }

  addAttachment(attachment) {
    this.setState(prevState => {
      prevState.attachments.push(attachment);
      return { attachments: prevState.attachments };
    });
  }

  removeAttachment(attachmentId) {
    FileInputService.deleteActivityAttachment(attachmentId)
      .then(() => {
        this.setState(prevState => {
          return {
            attachments: prevState.attachments.filter(
              attach => attach.id !== attachmentId
            )
          };
        });
      })
      .catch(err => {
        BugsnagService.notifyError(
          err,
          `Erro ao deletar o anexo ${attachmentId}.`
        );

        const { openAlert } = this.props;
        openAlert({
          status: "error",
          message: err.response.data.error.message
        });
      });
  }

  openStudentSelector() {
    this.setState({ studentSelectorIsOpen: true });
  }

  closeStudentSelector() {
    this.setState({ studentSelectorIsOpen: false });
  }

  openModalConfirm() {
    this.setState({ modalConfirmIsOpen: true });
  }

  openModalConfirmUpdate() {
    this.setState({ modalConfirmUpdateIsOpen: true });
  }

  closeModalConfirmUpdate() {
    this.setState({ modalConfirmUpdateIsOpen: false });
  }

  closeModalConfirm() {
    this.setState({ modalConfirmIsOpen: false });
  }

  openScheduleDialog() {
    this.setState({ scheduleDialogIsOpen: true });
  }

  closeScheduleDialog() {
    this.setState({ scheduleDialogIsOpen: false });
  }

  disableSubmitButtons() {
    this.setState({ uploading: true });
  }

  enableSubmitButtons() {
    this.setState({ uploading: false });
  }

  generateGenericAttachmentsName(name) {
    const { attachments } = this.state;
    const filterAttachments = attachments.filter(attachment =>
      attachment.file.name.includes(name)
    );

    if (filterAttachments.length) {
      return `${name} (${filterAttachments.length})`;
    }
    return name;
  }

  render() {
    const {
      coursesAndClasses,
      studentSelectorIsOpen,
      modalConfirmIsOpen,
      modalConfirmUpdateIsOpen,
      scheduleDialogIsOpen,
      submitAction,
      schedule,
      finished,
      uuid, // eslint-disable-line no-shadow
      attachments,
      openPreview,
      uploading,
      loading,
      formValues
    } = this.state;
    const { match } = this.props;
    const { id } = match.params;

    const { openAlert } = this.props;

    if (loading) return <LoadingContent />;

    if (finished) {
      return <Redirect to="/activities" />;
    }

    const textAction = this.getTextAction(submitAction);

    const ScheduleDialog = (
      <SelectScheduleDialog
        open={scheduleDialogIsOpen}
        onClose={() => this.closeScheduleDialog()}
        value={schedule}
        onChange={value => this.setState({ schedule: value })}
        title="Agendamento de atividade"
        message="Escolha a data e hora para a atividade ser enviada"
        onConfirm={() => {
          this.closeScheduleDialog();
          this.openModalConfirm();
        }}
      />
    );

    const modalConfirm = (values, setFieldValue) => (
      <ModalConfirm
        isOpen={modalConfirmIsOpen}
        onClose={() => this.closeModalConfirm()}
        onConfirm={() => this.submitData(values)}
        studentSubscribers={values.studentSubscribers}
        employeeSubscribers={values.employeeSubscribers}
        studentClasses={values.studentClasses}
        studentCourses={values.studentCourses}
        employeeClasses={values.employeeClasses}
        employeeCourses={values.employeeCourses}
        employeeTypes={values.employeeTypes}
        excludedIds={values.excludedDestinationIds}
        textAction={textAction}
        setSubscribers={(type, subscribers) => setFieldValue(type, subscribers)}
      />
    );

    const employeeTypes = [
      { CODIGO: "PROFESSOR", NOME: "Professor" },
      { CODIGO: "COORDENADOR", NOME: "Coordenador" }
    ];

    return (
      <Formik
        validateOnBlur
        validateOnChange={false}
        initialValues={formValues}
        validationSchema={this.validationSchema}
        onSubmit={() => {
          if (submitAction === "schedule") {
            this.openScheduleDialog();
          } else if (submitAction === "update") {
            this.openModalConfirmUpdate();
          } else {
            this.openModalConfirm();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid,
          setErrors,
          setFieldTouched
        }) => {
          return (
            <React.Fragment>
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <BackButton to="/activities" />
                  <Grid item xs={12}>
                    <Group>
                      <Heading level={2} icon={DetailsIcon} gutterBottom>
                        Detalhes da atividade
                      </Heading>
                      <Box pt={4} pb={2}>
                        <Grid container spacing={6}>
                          <Grid item xs={6}>
                            <FormControl
                              fullWidth
                              margin="dense"
                              error={errors.subject && touched.subject}
                            >
                              <InputLabel id="subject">Disciplina</InputLabel>
                              <Select
                                id="subject"
                                name="subject"
                                value={values.subject}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <MenuItem value="" />
                                {this.subjects.map(item => (
                                  <MenuItem value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {touched.subject && errors.subject}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} implementation="css">
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={ptBrLocale}
                            >
                              <KeyboardDatePicker
                                id="eventStart"
                                name="eventStart"
                                margin="dense"
                                variant="inline"
                                ampm={false}
                                format="dd/MM/yyyy"
                                label="Data inicial"
                                value={values.eventStart}
                                onChange={value => {
                                  setFieldValue(
                                    "eventStart",
                                    value || undefined
                                  );
                                }}
                                onBlur={handleBlur}
                                invalidDateMessage="Data inválida"
                                autoOk
                                fullWidth
                                error={errors.eventStart && touched.eventStart}
                                helperText={
                                  touched.eventStart && errors.eventStart
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>
                      </Box>
                    </Group>
                  </Grid>

                  <Grid item xs={12}>
                    <Group>
                      <Heading level={2} icon={PeopleIcon} gutterBottom>
                        Público da atividade
                      </Heading>
                      <Box py={4}>
                        <Grid container spacing={5}>
                          <Grid item container spacing={2} xs={12}>
                            <Grid item xs={12}>
                              <FormLabel>
                                Assinale para quem deseja enviar a atividade:
                              </FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                control={(
                                  <Field
                                    id="filterStudents"
                                    name="filterStudents"
                                    component={Checkbox}
                                    checked={values.filterStudents}
                                    disabled={
                                      !myAccess.classDiary &&
                                      values.status === 2
                                    }
                                    onChange={(event, value) => {
                                      handleChange(event, value);
                                      if (!value) {
                                        setFieldValue("studentCourses", []);
                                        setFieldValue("studentClasses", []);
                                      }
                                    }}
                                    color="primary"
                                  />
                                )}
                                label="Alunos"
                              />
                            </Grid>
                          </Grid>
                          {values.filterStudents && (
                            <>
                              <Grid item xs={12}>
                                <Box mb={5} mt={-2}>
                                  <FormLabel component="legend">
                                    Selecione os cursos e turmas:
                                  </FormLabel>
                                </Box>
                                <Autocomplete
                                  multiple
                                  noOptionsText="Não foram encontrados cursos para exibir, verifique com um administrador do sistema se você possui as permissões necessárias"
                                  id="studentCourses"
                                  name="studentCourses"
                                  value={values.studentCourses}
                                  disabled={values.status === 2}
                                  onChange={(event, coursesValues) => {
                                    if (
                                      coursesValues.length === 0 &&
                                      !myAccess.classDiary
                                    ) {
                                      setFieldValue("filterEmployees", false);
                                      setFieldValue(
                                        "responsibleTeachers",
                                        false
                                      );
                                      setFieldValue("employeeCourses", []);
                                      setFieldValue("employeeClasses", []);
                                      setFieldValue("employeeTypes", []);
                                    }
                                    setFieldValue(
                                      "studentCourses",
                                      coursesValues
                                    );
                                    const filteredClasses = this.getClassesByCourses(
                                      coursesValues
                                    ).filter(
                                      classItem =>
                                        values.studentClasses.filter(
                                          i => i.CODIGO === classItem.CODIGO
                                        ).length
                                    );
                                    setFieldValue(
                                      "studentClasses",
                                      filteredClasses
                                    );
                                    if (values.responsibleTeachers) {
                                      setFieldValue(
                                        "employeeCourses",
                                        coursesValues
                                      );
                                      setFieldValue(
                                        "employeeClasses",
                                        filteredClasses
                                      );
                                    }
                                  }}
                                  options={coursesAndClasses}
                                  getOptionLabel={option => option.NOME}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      label="Cursos"
                                      margin="dense"
                                      fullWidth
                                      error={
                                        errors.studentCourses &&
                                        touched.studentCourses
                                      }
                                      helperText={
                                        touched.studentCourses &&
                                        errors.studentCourses
                                      }
                                    />
                                  )}
                                  disableCloseOnSelect
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        color="primary"
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        checkedIcon={<CheckBoxIcon />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.NOME}
                                    </React.Fragment>
                                  )}
                                />
                              </Grid>
                              {!!values.studentCourses.length && (
                                <Grid item xs={12}>
                                  <Autocomplete
                                    multiple
                                    id="studentClasses"
                                    name="studentClasses"
                                    noOptionsText="Não foram encontradas turmas para exibir, verifique com um administrador do sistema se você possui as permissões necessárias"
                                    options={this.getClassesByCourses(
                                      values.studentCourses
                                    )}
                                    disabled={values.status === 2}
                                    getOptionLabel={option => option.NOME}
                                    onChange={(event, classesValues) => {
                                      setFieldValue(
                                        "studentClasses",
                                        classesValues
                                      );
                                      if (values.responsibleTeachers)
                                        setFieldValue(
                                          "employeeClasses",
                                          classesValues
                                        );
                                    }}
                                    value={values.studentClasses}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        label="Turmas"
                                        margin="dense"
                                        fullWidth
                                        error={
                                          errors.studentClasses &&
                                          touched.studentClasses
                                        }
                                        helperText={
                                          touched.studentClasses &&
                                          errors.studentClasses
                                        }
                                      />
                                    )}
                                    disableCloseOnSelect
                                    renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                        <Checkbox
                                          color="primary"
                                          icon={<CheckBoxOutlineBlankIcon />}
                                          checkedIcon={<CheckBoxIcon />}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.NOME}
                                      </React.Fragment>
                                    )}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                          {values.filterEmployees && (
                            <>
                              <Grid item xs={12}>
                                <Box mb={1} mt={-2}>
                                  <FormLabel component="legend">
                                    Especifique os
                                    {" "}
                                    <HighlightText>
                                      professores/coordenadores
                                    </HighlightText>
                                    :
                                  </FormLabel>
                                </Box>
                                <FormControlLabel
                                  control={(
                                    <Field
                                      id="responsibleTeachers"
                                      name="responsibleTeachers"
                                      component={Checkbox}
                                      checked={values.responsibleTeachers}
                                      disabled={!myAccess.classDiary}
                                      onChange={(event, value) => {
                                        handleChange(event, value);
                                        if (value) {
                                          setFieldValue(
                                            "employeeCourses",
                                            values.studentCourses
                                          );
                                          setFieldValue(
                                            "employeeClasses",
                                            values.studentClasses
                                          );
                                          setFieldValue("employeeTypes", [
                                            employeeTypes.find(
                                              type =>
                                                type.CODIGO === "PROFESSOR"
                                            )
                                          ]);
                                        } else {
                                          setFieldValue("employeeCourses", []);
                                          setFieldValue("employeeClasses", []);
                                          setFieldValue("employeeTypes", []);
                                        }
                                      }}
                                      color="primary"
                                    />
                                  )}
                                  label="Enviar apenas para os professores responsáveis pelos cursos/turmas acima"
                                />
                              </Grid>
                              {!values.responsibleTeachers && (
                                <>
                                  <Grid item xs={12}>
                                    <Autocomplete
                                      multiple
                                      id="employeeCourses"
                                      name="employeeCourses"
                                      value={values.employeeCourses}
                                      onChange={(event, coursesValues) => {
                                        setFieldValue(
                                          "employeeCourses",
                                          coursesValues
                                        );
                                        setFieldValue(
                                          "employeeClasses",
                                          this.getClassesByCourses(
                                            coursesValues
                                          ).filter(
                                            classItem =>
                                              values.employeeClasses.filter(
                                                i =>
                                                  i.CODIGO === classItem.CODIGO
                                              ).length
                                          )
                                        );
                                      }}
                                      options={coursesAndClasses}
                                      getOptionLabel={option => option.NOME}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          label="Cursos"
                                          margin="dense"
                                          fullWidth
                                          error={
                                            errors.employeeCourses &&
                                            touched.employeeCourses
                                          }
                                          helperText={
                                            touched.employeeCourses &&
                                            errors.employeeCourses
                                          }
                                        />
                                      )}
                                      disableCloseOnSelect
                                      renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                          <Checkbox
                                            color="primary"
                                            icon={<CheckBoxOutlineBlankIcon />}
                                            checkedIcon={<CheckBoxIcon />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.NOME}
                                        </React.Fragment>
                                      )}
                                    />
                                  </Grid>
                                  {!!values.employeeCourses.length && (
                                    <Grid item xs={12}>
                                      <Autocomplete
                                        multiple
                                        id="employeeClasses"
                                        name="employeeClasses"
                                        options={this.getClassesByCourses(
                                          values.employeeCourses
                                        )}
                                        getOptionLabel={option => option.NOME}
                                        onChange={(event, classesValues) => {
                                          setFieldValue(
                                            "employeeClasses",
                                            classesValues
                                          );
                                        }}
                                        value={values.employeeClasses}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            label="Turmas"
                                            margin="dense"
                                            fullWidth
                                            error={
                                              errors.employeeClasses &&
                                              touched.employeeClasses
                                            }
                                            helperText={
                                              touched.employeeClasses &&
                                              errors.employeeClasses
                                            }
                                          />
                                        )}
                                        disableCloseOnSelect
                                        renderOption={(
                                          option,
                                          { selected }
                                        ) => (
                                          <React.Fragment>
                                            <Checkbox
                                              color="primary"
                                              icon={
                                                <CheckBoxOutlineBlankIcon />
                                              }
                                              checkedIcon={<CheckBoxIcon />}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                            />
                                            {option.NOME}
                                          </React.Fragment>
                                        )}
                                      />
                                    </Grid>
                                  )}
                                  <Grid item xs={12}>
                                    <Autocomplete
                                      multiple
                                      id="employeeTypes"
                                      name="employeeTypes"
                                      options={employeeTypes}
                                      getOptionLabel={option => option.NOME}
                                      onChange={(event, typesValues) => {
                                        setFieldValue(
                                          "employeeTypes",
                                          typesValues
                                        );
                                      }}
                                      value={values.employeeTypes}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          label="Cargo"
                                          margin="dense"
                                          fullWidth
                                          error={
                                            errors.employeeTypes &&
                                            touched.employeeTypes
                                          }
                                          helperText={
                                            touched.employeeTypes &&
                                            errors.employeeTypes
                                          }
                                        />
                                      )}
                                    />
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      </Box>
                      <Divider />
                      <Group.GroupFooter>
                        <ButtonBlock
                          startIcon={<HowToRegIcon />}
                          disabled={
                            (!values.studentCourses.length &&
                              !values.employeeCourses.length) ||
                            values.status === 2
                          }
                          onClick={() => this.openStudentSelector()}
                        >
                          Gerenciar destinatários
                          {" "}
                          {values.status === 2 &&
                            "- Não é possível alterar os destinatários de uma atividade já enviada"}
                        </ButtonBlock>
                      </Group.GroupFooter>
                    </Group>
                  </Grid>

                  <Grid item xs={12}>
                    <Group>
                      <Heading level={2} icon={EditIcon} gutterBottom>
                        Conteúdo da atividade
                      </Heading>
                      <Box py={4}>
                        <Grid container spacing={6}>
                          <Grid item md={6}>
                            <Field
                              component={TextField}
                              id="title"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label="Título"
                              fullWidth
                              margin="dense"
                              error={errors.title && touched.title}
                              helperText={touched.title && errors.title}
                            />
                          </Grid>
                          <Grid itemn md={1}>
                            <ImageBox
                              my={1}
                              height={60}
                              width="calc(100% + 20px)"
                              alt="Imagem da atividade"
                              src={values.photo}
                            />
                          </Grid>

                          <Grid item md={12}>
                            <InputLabel>Texto da atividade</InputLabel>
                            <Editor
                              initialValue={values.bodyMessage}
                              id="bodyMessage"
                              name="bodyMessage"
                              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                              init={{
                                branding: false,
                                height: 300,
                                menubar: false,
                                paste_data_images: true,
                                setup: editor => {
                                  editor.on("KeyDown", e => {
                                    if (
                                      (e.keyCode === 8 || e.keyCode === 46) &&
                                      editor.selection
                                    ) {
                                      const selectedNode = editor.selection.getNode();
                                      if (
                                        selectedNode &&
                                        selectedNode.nodeName === "IMG"
                                      ) {
                                        const selectedAttachment = attachments.find(
                                          attachment =>
                                            attachment.file.url ===
                                            selectedNode.src
                                        );
                                        this.removeAttachment(
                                          selectedAttachment.id
                                        );
                                      }
                                    }
                                  });
                                },
                                images_upload_handler: async (
                                  blobInfo,
                                  success,
                                  failure
                                ) => {
                                  const { uuid: uuidCopy } = this.state;
                                  let file = blobInfo.blob();
                                  if (!isBlob(file)) {
                                    const base64Response = await fetch(
                                      blobInfo.blobUri()
                                    );
                                    file = await base64Response.blob();
                                  }
                                  const filename = this.generateGenericAttachmentsName(
                                    "Anexo"
                                  );
                                  UploadUtil.activityUploadAttachment(
                                    file,
                                    filename,
                                    uuidCopy
                                  ).then(attachment => {
                                    if (attachment) {
                                      this.addAttachment(attachment);
                                      success(attachment.file.url);
                                    } else {
                                      failure(
                                        "Erro ao fazer o upload da imagem"
                                      );
                                    }
                                  });
                                },
                                paste_retain_style_properties:
                                  "color font-size",
                                plugins: [
                                  "advlist autolink lists link print preview anchor",
                                  "searchreplace fullscreen",
                                  "insertdatetime table help wordcount image link powerpaste"
                                ],
                                powerpaste_allow_local_images: true,
                                powerpaste_word_import: "prompt",
                                powerpaste_html_import: "prompt",
                                toolbar: `undo redo | formatselect | bold italic forecolor backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | table | link`,
                                language_url: `${window.location.protocol}//${window.location.host}/tinymce/pt_BR.js`,
                                language: "pt_BR",
                                link_assume_external_targets: true,
                                default_link_target: "_system",
                                link_default_protocol: "https",
                                target_list: [
                                  { title: "Nova Página", value: "_system" }
                                ]
                              }}
                              onBlur={e =>
                                setFieldValue(
                                  "bodyMessage",
                                  e.target.getContent()
                                )
                              }
                            />
                          </Grid>

                          <Grid item md={6}>
                            <FormControlLabel
                              control={(
                                <Field
                                  component={Checkbox}
                                  checked={values.hasHomeActivity}
                                  color="primary"
                                  name="hasHomeActivity"
                                  id="hasHomeActivity"
                                  onChange={e => {
                                    setFieldValue(
                                      "hasHomeActivity",
                                      values.hasHomeActivity
                                    );
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                              )}
                              label="Possui lição de casa"
                            />
                          </Grid>

                          {values.hasHomeActivity && (
                            <Grid item md={12}>
                              <InputLabel>Texto da lição de casa</InputLabel>
                              <Editor
                                initialValue={values.bodyHomeActivity}
                                id="bodyHomeActivity"
                                name="bodyHomeActivity"
                                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                init={{
                                  branding: false,
                                  height: 300,
                                  menubar: false,
                                  paste_data_images: true,
                                  setup: editor => {
                                    editor.on("KeyDown", e => {
                                      if (
                                        (e.keyCode === 8 || e.keyCode === 46) &&
                                        editor.selection
                                      ) {
                                        const selectedNode = editor.selection.getNode();
                                        if (
                                          selectedNode &&
                                          selectedNode.nodeName === "IMG"
                                        ) {
                                          const selectedAttachment = attachments.find(
                                            attachment =>
                                              attachment.file.url ===
                                              selectedNode.src
                                          );
                                          this.removeAttachment(
                                            selectedAttachment.id
                                          );
                                        }
                                      }
                                    });
                                  },
                                  images_upload_handler: async (
                                    blobInfo,
                                    success,
                                    failure
                                  ) => {
                                    const { uuid: uuidCopy } = this.state;
                                    let file = blobInfo.blob();
                                    if (!isBlob(file)) {
                                      const base64Response = await fetch(
                                        blobInfo.blobUri()
                                      );
                                      file = await base64Response.blob();
                                    }
                                    const filename = this.generateGenericAttachmentsName(
                                      "Anexo"
                                    );
                                    UploadUtil.activityUploadAttachment(
                                      file,
                                      filename,
                                      uuidCopy
                                    ).then(attachment => {
                                      if (attachment) {
                                        this.addAttachment(attachment);
                                        success(attachment.file.url);
                                      } else {
                                        failure(
                                          "Erro ao fazer o upload da imagem"
                                        );
                                      }
                                    });
                                  },
                                  paste_retain_style_properties:
                                    "color font-size",
                                  plugins: [
                                    "advlist autolink lists link print preview anchor",
                                    "searchreplace fullscreen",
                                    "insertdatetime table help wordcount image link powerpaste"
                                  ],
                                  powerpaste_allow_local_images: true,
                                  powerpaste_word_import: "prompt",
                                  powerpaste_html_import: "prompt",
                                  toolbar: `undo redo | formatselect | bold italic forecolor backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | table | link`,
                                  language_url: `${window.location.protocol}//${window.location.host}/tinymce/pt_BR.js`,
                                  language: "pt_BR",
                                  link_assume_external_targets: true,
                                  default_link_target: "_system",
                                  link_default_protocol: "https",
                                  target_list: [
                                    { title: "Nova Página", value: "_system" }
                                  ]
                                }}
                                onBlur={e =>
                                  setFieldValue(
                                    "bodyHomeActivity",
                                    e.target.getContent()
                                  )
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      <Divider />
                      <Divider />
                      <Box py={4}>
                        <FileInput
                          accept="image/png,image/jpeg,application/pdf"
                          name="attachments"
                          id="attachments"
                          label="Anexo"
                          fieldOptions={{ uuid }}
                          upload={FileInputService.uploadActivityAttachment}
                          status="NOT_SENT"
                          onError={error => {
                            if (error.response) {
                              const errorData = error.response.data.error;
                              const { message } = errorData;
                              openAlert({ status: "error", message });
                            } else {
                              openAlert({
                                status: "error",
                                message: "Falha durante upload do arquivo."
                              });
                            }
                            this.enableSubmitButtons();
                          }}
                          onSuccess={(response, file) => {
                            const attachment = {
                              id: response.attachmentId,
                              file,
                              name: response.name
                            };
                            this.addAttachment(attachment);
                            this.enableSubmitButtons();
                          }}
                          onChange={() => this.disableSubmitButtons()}
                        />
                        {!!attachments.length && (
                          <Box my={4}>
                            <AttachmentList
                              attachments={attachments}
                              onDelete={attachment =>
                                this.removeAttachment(attachment.id)
                              }
                            />
                          </Box>
                        )}
                      </Box>
                      <Group.GroupFooter>
                        <ButtonBlock
                          startIcon={<VisibilityIcon />}
                          onClick={() => {
                            validateForm().then(validationErrors => {
                              if (_.isEmpty(validationErrors)) {
                                this.handleOpenPreview();
                              } else {
                                setErrors(validationErrors);
                                Object.keys(validationErrors).forEach(key =>
                                  setFieldTouched(key, true, false)
                                );
                                this.formErrorAlert();
                              }
                            });
                          }}
                        >
                          Visualizar atividade
                        </ButtonBlock>
                      </Group.GroupFooter>
                    </Group>
                  </Grid>
                </Grid>
                <Box my={9} mx={5}>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={uploading}
                        onClick={() => {
                          validateForm().then(() => {
                            if (isValid) {
                              this.setSubmitAction("save");
                            } else {
                              this.formErrorAlert();
                            }
                          });
                        }}
                      >
                        Salvar
                      </Button>
                    </Grid>
                    {formValues.status !== 2 && (
                      <Grid item md={6}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          fullWidth
                          disabled={uploading}
                          onClick={() => {
                            validateForm().then(() => {
                              if (isValid) {
                                this.setSubmitAction("schedule");
                              } else {
                                this.formErrorAlert();
                              }
                            });
                          }}
                        >
                          Agendar Envio
                        </Button>
                      </Grid>
                    )}
                    {formValues.status !== 2 && (
                      <Grid item md={6}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={uploading}
                          onClick={() => {
                            validateForm().then(() => {
                              if (isValid) {
                                if (id) this.setSubmitAction("update");
                                else this.setSubmitAction("send");
                              } else {
                                this.formErrorAlert();
                              }
                            });
                          }}
                        >
                          Enviar agora
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <ModalConfirmUpdate
                  isOpen={modalConfirmUpdateIsOpen}
                  onClose={() => this.closeModalConfirmUpdate()}
                  onConfirm={() => this.submitData(values)}
                  textAction="Alterar informações da atividade"
                />
                <ModalReceiversSelector
                  isOpen={studentSelectorIsOpen}
                  onClose={() => this.closeStudentSelector()}
                  studentClasses={values.studentClasses}
                  studentCourses={values.studentCourses}
                  employeeClasses={values.employeeClasses}
                  employeeCourses={values.employeeCourses}
                  employeeTypes={values.employeeTypes}
                  excludedIds={values.excludedDestinationIds}
                  setSubscribers={(type, subscribers) =>
                    setFieldValue(type, subscribers)
                  }
                />
                {ScheduleDialog}
                {modalConfirm(values, setFieldValue)}
              </form>

              {values.subject && (
                <Preview
                  openDialog={openPreview}
                  closeDialog={() => this.setState({ openPreview: false })}
                  title={values.title}
                  // eslint-disable-next-line radix
                  subject={
                    this.subjects.find(
                      sub => sub.id === parseInt(values.subject, 10)
                    ).title
                  }
                  icon={UserStore.getUserPhoto()}
                  createdAt={new Date()}
                  body={values.bodyMessage}
                  homeActivity={values.bodyHomeActivity}
                  attachments={attachments}
                />
              )}
            </React.Fragment>
          );
        }}
      </Formik>
    );
  }
}

ActivityForm.defaultProps = {
  openAlert: false
};

ActivityForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.bool,
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired
};

export default ActivityForm;
