import React, { useContext } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { DebounceInput } from "react-debounce-input";
import styled from "styled-components";
import {
  Box,
  Grid,
  InputAdornment,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TextField,
  Typography,
  withTheme
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  AccessTime as AccessTimeIcon,
  People as PeopleIcon,
  Search
} from "@material-ui/icons";
import moment from "@moment";
import { enums } from "../../../services/CampaignsService";
import { AbilityContext } from "../../../acl/can";

const Table = styled(MuiTable)`
  width: calc(100% + 60px);
  max-height: calc(100vh - 430px);
  margin: 0 -30px -20px;
  border: solid #f2f2f2;
  border-width: 2px 0 2px 0;
`;
const TableRow = styled(MuiTableRow)`
  cursor: pointer;
`;
const TableCell = styled(MuiTableCell)`
  padding: 20px 7px;
  border-bottom: solid #f2f2f2 2px;
  max-width: 280px;
  min-width: 110px;
  color: #727272;

  &.title {
    padding-left: 30px;
    h5 {
      margin: 0;
      font-size: 22px;
      font-weight: 300;
      color: #000;
      line-height: 1.15em;
      margin-bottom: 4px;
    }
    span {
      font-size: 16px;
    }
  }
  &.type {
    font-size: 16px;
  }
  &.qtde {
    * {
      color: #727272;
    }
    font-size: 18px;
  }
  &.status {
    svg {
      color: ${props => props.color};
      font-size: 28px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      padding-right: 30px;
      color: ${props => props.color};
    }
  }
`;

const EmailList = ({
  campaigns,
  filters,
  setFilter,
  onSelectCampaign,
  theme
}) => {
  const ability = useContext(AbilityContext);

  let searchTimeout = null;
  const onChangeDate = (date, field) => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setFilter(field, date);
    }, 600);
  };

  return (
    <Box>
      <Box pb={4} pt={2}>
        <form noValidate autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
            <Grid container spacing={2} alignContent="space-between">
              <Grid item md={6}>
                <DebounceInput
                  minLength={3}
                  debounceTimeout={400}
                  element={TextField}
                  value={filters.searchText}
                  onChange={e => setFilter("searchText", e.target.value)}
                  label="Filtrar"
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="action" />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Data inicial"
                  value={filters.initialDate}
                  onChange={date => onChangeDate(date, "initialDate")}
                  KeyboardButtonProps={{
                    "aria-label": "Selecione a data inicial"
                  }}
                  fullWidth
                  invalidDateMessage="Data inválida"
                />
              </Grid>
              <Grid item md={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Data final"
                  value={filters.finalDate}
                  onChange={date => onChangeDate(date, "finalDate")}
                  KeyboardButtonProps={{
                    "aria-label": "Selecione a data final"
                  }}
                  fullWidth
                  invalidDateMessage="Data inválida"
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      </Box>
      <Table aria-label="simple table">
        <TableBody>
          {campaigns.map(campaign => {
            let statusText;
            let statusIcon;
            let statusColor;
            switch (campaign.status) {
              case enums.status.OPEN:
                statusText = "Em aberto";
                statusIcon = <RadioButtonUncheckedIcon />;
                statusColor = "#727272";
                break;
              case enums.status.SENT:
                statusText = "Enviado";
                statusIcon = <CheckCircleOutlineIcon />;
                statusColor = theme.palette.primary.main;
                break;
              case enums.status.SCHEDULED:
                statusText = "Agendado";
                statusIcon = <AccessTimeIcon />;
                statusColor = theme.palette.secondary.main;
                break;
              default:
            }
            return (
              <TableRow
                key={campaign.id}
                hover
                onClick={() =>
                  ability.can("view", "campaign") && onSelectCampaign(campaign)
                }
              >
                <TableCell className="title" align="left">
                  <h5>{campaign.title}</h5>
                  <span>
                    {campaign.status === enums.status.SCHEDULED
                      ? moment(campaign.schedule).format(
                          "[Agendado para ]DD/MM/YYYY[ às ]HH:mm"
                        )
                      : moment(campaign.createdAt).format(
                          "DD/MM/YYYY[ às ]HH:mm"
                        )}
                  </span>
                </TableCell>
                <TableCell className="qtde" align="center">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    spacing={1}
                  >
                    <Grid item>
                      <PeopleIcon fontSize="large" color="action" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {campaign.destinataries.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className="status" align="right" color={statusColor}>
                  <Grid
                    container
                    direction="row-reverse"
                    alignItems="center"
                    justify="right"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body1">{statusText}</Typography>
                    </Grid>
                    <Grid item>{statusIcon}</Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

EmailList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  campaigns: PropTypes.array.isRequired,
  filters: PropTypes.shape({
    searchText: PropTypes.string.isRequired,
    initialDate: PropTypes.instanceOf(Date),
    finalDate: PropTypes.instanceOf(Date)
  }).isRequired,
  onSelectCampaign: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(EmailList);
