import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  InputAdornment,
  Popover,
  TextField,
  withTheme
} from "@material-ui/core";
import { SketchPicker } from "react-color";
import Color from "./Color";

const ColorPicker = ({ TextFieldProps, theme }) => {
  const { palette } = theme;
  const colors = [
    palette.primary.light,
    palette.primary.main,
    palette.primary.dark,
    palette.secondary.light,
    palette.secondary.main,
    palette.secondary.dark,
    palette.error.main
  ];

  const [color, setColor] = useState({
    h: 0,
    s: 0,
    l: 100,
    a: 1
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeComplete = data => {
    setColor(data.hsl);
    if (TextFieldProps.onChange) TextFieldProps.onChange(data.hex);
  };

  return (
    <div>
      <TextField
        label="Cor"
        fullWidth
        onClick={handleClick}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Color color={TextFieldProps.value} />
            </InputAdornment>
          )
        }}
        {...TextFieldProps}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <SketchPicker
          color={color}
          onChange={handleChangeComplete}
          presetColors={colors}
        />
      </Popover>
    </div>
  );
};

ColorPicker.defaultProps = {
  TextFieldProps: {}
};

ColorPicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  TextFieldProps: PropTypes.object, // TextField Props https://v4.mui.com/pt/api/text-field/
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(ColorPicker);
