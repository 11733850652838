import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { People as PeopleIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading";

const Table = styled(MuiTable)`
  width: calc(100% + 60px);
  max-height: calc(100vh - 430px);
  margin: 0 -30px -20px;
`;

const TableHead = styled(MuiTableHead)`
  tr {
    height: 48px;
  }
  th {
    color: #018675;
    padding: 0;
    border-bottom: none;
  }
`;

const TableBody = styled(MuiTableBody)`
  tr {
    height: 96px;
    padding: 18px 30px;
    border-bottom: solid #f2f2f2 2px;
  }
  td {
    color: #000;
  }
`;

const StyledTypography = styled(Typography)`
  width: 100%;
  text-align: ${props => props.align || "left"};
`;

const TextName = styled(Typography)`
  overflow: hidden;
`;

const GroupSubscribers = ({ destinataries }) => {
  return (
    <Group>
      <Heading level={2} icon={PeopleIcon} gutterBottom>
        Destinatários do e-mail
      </Heading>
      <Box py={2}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {destinataries.map(destinatary => {
              return (
                <TableRow>
                  <TableCell className="student">
                    <TextName variant="h6">
                      {destinatary.NOME_COMPLETO}
                    </TextName>
                  </TableCell>
                  <TableCell className="emails">
                    <StyledTypography variant="caption" align="right">
                      {destinatary.EMAIL}
                    </StyledTypography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Group>
  );
};

GroupSubscribers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  destinataries: PropTypes.array.isRequired
};

export default GroupSubscribers;
