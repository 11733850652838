import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { ChatBubbleOutline } from "@material-ui/icons";
import PropTypes from "prop-types";
import HeaderInfoTitle from "./HeaderInfoTitle";
import Loading from "../../../components/Loading";
import RocketChatWS from "../../../utils/RocketChatWS";
import ChatUtil from "../../../utils/ChatUtil";
import ChatService from "../../../services/ChatService";
import TicketMessages from "./TicketMessages";
import TicketsService from "../../../services/TicketsService";
import TicketMessageField from "./TicketMessageField";

const ChatBubbleIcon = styled(ChatBubbleOutline)`
  color: #787486;
  margin-right: 8px;
`;

const DisabledLabel = styled.p`
  margin: 10px 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #787486;
  text-align: center;
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const TicketDetailComments = ({
  ticketId,
  roomId,
  members,
  disableActions,
  ticketChannelId,
  detailDrawerRef
}) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [shortcuts, setShortcuts] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);

  const formatMessage = messageData => {
    return ChatUtil.formatMessage(messageData, members);
  };

  const scrollToBottom = () => {
    const ticketMessagesElement = document.getElementById("ticket-messages");
    if (ticketMessagesElement) {
      ticketMessagesElement.scrollTop = ticketMessagesElement.scrollHeight;
    }
  };

  const scrollToTop = () => {
    if (detailDrawerRef.current) {
      detailDrawerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };

  const getShortcutMessages = async () => {
    const shortcutsData = await TicketsService.getShortcutMessages(
      ticketChannelId
    );
    setShortcuts(shortcutsData);
  };

  const setup = async () => {
    const authData = await ChatService.authenticate();
    const { token } = authData;
    let { url } = authData;
    localStorage.setItem(
      "rocketChatInfo",
      btoa(JSON.stringify({ ...authData, url }))
    );

    url = `${url.replace(new RegExp("(?:http)"), "ws")}/websocket`;
    await RocketChatWS.open(url, token);

    RocketChatWS.sendLoadHistory(roomId);
    RocketChatWS.addOnMessageFunc(event => {
      const messageData = JSON.parse(event.data);
      if (messageData.msg === "result" && messageData.result.messages) {
        const chatMessages = messageData.result.messages
          .map(chatMessage => formatMessage(chatMessage))
          .filter(Boolean);
        setMessages(chatMessages);
        setLoading(false);
        scrollToBottom();
      }
    });

    RocketChatWS.sendSubRoom(roomId);
    RocketChatWS.addOnMessageFunc(event => {
      const messageData = JSON.parse(event.data);
      if (
        messageData.collection === "stream-room-messages" &&
        messageData.fields
      ) {
        const formattedMessage = formatMessage(messageData.fields.args[0]);
        if (
          formattedMessage &&
          !messages.some(msg => msg.id === formattedMessage.id)
        ) {
          setMessages(prevMessages => [formattedMessage, ...prevMessages]);
          scrollToBottom();
        }
      }
    });
  };

  const sendMessage = async messageToSend => {
    setLoadingSend(true);
    await TicketsService.sendMessage(ticketId, messageToSend);
    setLoadingSend(false);
    setMessage("");
  };

  const sendFile = async file => {
    setLoadingSend(true);

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("description", file.size);
    formData.append("msg", "Arquivo");

    await TicketsService.sendFile(ticketId, formData);

    setLoadingSend(false);
  };

  useEffect(() => {
    setup();
    getShortcutMessages();

    return () => {
      RocketChatWS.sendUnsubRoom();
      RocketChatWS.close();
    };
  }, []);

  if (loading) return <Loading />;

  return (
    <Box mt={1}>
      <Box display="flex" alignItems="center">
        <ChatBubbleIcon />
        <HeaderInfoTitle>Comentários</HeaderInfoTitle>
      </Box>

      <TicketMessages id="ticket-messages" messages={messages} />
      {disableActions ? (
        <DisabledLabel>
          Apenas o
          {" "}
          <UnderlinedText onClick={scrollToTop}>atendente</UnderlinedText>
          {' '}
          do
          chamado pode enviar mensagens
        </DisabledLabel>
      ) : (
        <TicketMessageField
          shortcuts={shortcuts}
          value={message}
          onChange={setMessage}
          onSend={sendMessage}
          onSendFile={sendFile}
          loading={loadingSend}
        />
      )}
    </Box>
  );
};

TicketDetailComments.propTypes = {
  ticketId: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disableActions: PropTypes.bool.isRequired,
  ticketChannelId: PropTypes.number.isRequired,
  detailDrawerRef: PropTypes.objectOf().isRequired
};

export default TicketDetailComments;
