import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Edit as EditIcon,
  HowToReg as HowToRegIcon,
  People as PeopleIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Visibility as VisibilityIcon
} from "@material-ui/icons";
import isBlob from "is-blob";
import SchoolGroupService from "../../services/SchoolGroupService";
import FileInputService from "../../services/FileInputService";
import BugsnagService from "../../services/BugsnagService";
import Button from "../../waybee-ui/Button";
import Group from "../../waybee-ui/Group";
import Divider from "../../waybee-ui/Divider";
import Heading from "../../waybee-ui/Heading/Heading";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import BackButton from "../../components/BackButton";
import FileInput from "../../components/FileInput/FileInput";
import ModalReceiversSelector from "./ModalReceiversSelector";
import ModalConfirm from "./ModalConfirm";
import SelectScheduleDialog from "../../components/SelectScheduleDialog";
import HighlightText from "../../waybee-ui/HighlightText";
import CampaignsService from "../../services/CampaignsService";
import DialogNotification from "./components/notification/dialog_notification";
import AttachmentList from "../../components/AttachmentsList";
import UploadUtil from "../../utils/UploadUtil";

const uuid = require("uuid/v4");
require("../../utils/yup.locale.pt-br");

Yup.addMethod(Yup.mixed, "requiredWhen", function requiredWhen(
  ref,
  caseValue = true,
  message
) {
  return this.test({
    name: "requiredWhen",
    exclusive: false,
    message: message || "Campo Obigatório",
    params: {
      reference: ref.path
    },
    test: function test(value) {
      return this.resolve(ref) === caseValue ? !!value : true;
    }
  });
});

class EmailForm extends Component {
  state = {
    submitAction: "",
    employeeGroups: [],
    employeeSelectorIsOpen: false,
    modalConfirmIsOpen: false,
    scheduleDialogIsOpen: false,
    schedule: null,
    finished: false,
    attachments: [],
    uploading: false,
    uuid: null,
    openPreview: false,
    previewData: {
      title: "",
      message: "",
      attachments: []
    },
    previewContent: ""
  };

  validationSchema = Yup.object().shape({
    filterEmployees: Yup.bool(),
    body: Yup.string().notRequired(),
    // eslint-disable-next-line react/forbid-prop-types
    attachments: Yup.array(),
    title: Yup.string().required(),
    // eslint-disable-next-line react/forbid-prop-types
    employeeGroups: Yup.array()
  });

  componentDidMount() {
    const { setTitle, openAlert } = this.props;
    setTitle("Novo e-mail");

    this.setState({ uuid: uuid() });

    SchoolGroupService.getEmployeesGroups()
      .then(employeeGroups => {
        this.setState({ employeeGroups });
      })
      .catch(err => {
        openAlert({
          status: "error",
          message: "Falha ao obter dados da campanha",
          onClose: () => window.location.replace("/")
        });
        BugsnagService.notifyError(err);
      });
  }

  getClassesByCourses = courses => {
    return courses ? courses.map(course => course.VW_TURMA).flat() : [];
  };

  setSubmitAction(textAction) {
    this.setState({ submitAction: textAction });
  }

  handleOpenPreview = () => {
    const {
      previewData: { title, message, attachments, campaignOptions }
    } = this.state;

    CampaignsService.previewCampaign(
      title,
      message,
      attachments,
      campaignOptions
    ).then(data => {
      this.setState({ previewContent: data.html }, () => {
        this.setState({ openPreview: true });
      });
    });
  };

  adjustValues = formValues => {
    const values = formValues;
    const { uuid: campaignUUID } = this.state;

    const formattedValues = _.pick(values, [
      "title",
      "body",
      "destinataries",
      "scheduler",
      "customTemplate",
      "standardAttachList"
    ]);
    formattedValues.uuid = campaignUUID;

    return formattedValues;
  };

  save = async formValues => {
    const campaign = this.adjustValues(formValues);

    let updateResult;

    try {
      updateResult = await CampaignsService.saveCampaign(campaign);
    } catch (e) {
      throw Error("Erro ao salvar o email");
    }

    this.setState({ finished: true });
    return updateResult;
  };

  schedule = async campaign => {
    const { schedule } = this.state;
    const data = { ...campaign, scheduler: schedule };
    const { openAlert } = this.props;

    const alertLoading = {
      loading: true,
      message: "Agendando disparo."
    };
    openAlert(alertLoading);
    try {
      await this.save(data);
    } catch (e) {
      throw Error(`Ocorreu uma falha ao agendar o email: ${e.message}`);
    }
  };

  send = async formValues => {
    const { openAlert } = this.props;

    openAlert({
      loading: true,
      message: "Enviando campanha."
    });
    try {
      const campaign = await this.save(formValues);
      await CampaignsService.sendCampaign(campaign.id);
    } catch (e) {
      throw Error(
        `A campanha foi salva mas houve um problema ao enviar: ${e.message}`
      );
    }
  };

  submitCampaign = async formValues => {
    const { submitAction } = this.state;
    const { openAlert } = this.props;
    const textAction = this.getTextAction(submitAction);
    const campaign = formValues;
    const allSubscribers = campaign.employeeSubscribers;

    campaign.destinataries = allSubscribers
      .filter(e => e.checked)
      .map(e => e.id);

    let submitPromise;
    switch (submitAction) {
      case "save":
        submitPromise = this.save;
        break;
      case "schedule":
        submitPromise = this.schedule;
        break;
      case "send":
        submitPromise = this.send;
        break;
      default:
    }

    try {
      await submitPromise(campaign);
      const { history } = this.props;
      history.replace(`/reload`);
      setTimeout(() => {
        history.replace(`/email`);
        openAlert({
          status: "success",
          message: `Sucesso ao ${textAction} a Campanha`
        });
      });
    } catch (e) {
      this.closeModalConfirm();
      openAlert({
        status: "warning",
        title: "Atenção",
        message: e.message
      });
    }
  };

  getTextAction = submitAction => {
    switch (submitAction) {
      case "send":
        return "Enviar";
      case "save":
        return "Salvar";
      case "schedule":
        return "Agendar";
      default:
        return "";
    }
  };

  verifyErrors(errors) {
    const { openAlert } = this.props;
    if (Object.keys(errors).length !== 0) {
      openAlert({
        status: "error",
        message: "Alguns campos não foram preeenchidos corretamente."
      });
      return true;
    }
    return false;
  }

  formErrorAlert() {
    const { openAlert } = this.props;
    openAlert({
      status: "error",
      message: "Alguns campos não foram preeenchidos corretamente."
    });
  }

  addAttachment(attachment) {
    this.setState(prevState => {
      prevState.attachments.push(attachment);
      return { attachments: prevState.attachments };
    });
  }

  removeAttachment(attachmentId) {
    FileInputService.deleteCampaignAttachment(attachmentId);
    this.setState(prevState => {
      return {
        attachments: prevState.attachments.filter(
          attach => attach.id !== attachmentId
        )
      };
    });
  }

  openEmployeSelector() {
    this.setState({ employeeSelectorIsOpen: true });
  }

  closeEmployeeSelector() {
    this.setState({ employeeSelectorIsOpen: false });
  }

  openModalConfirm() {
    this.setState({ modalConfirmIsOpen: true });
  }

  closeModalConfirm() {
    this.setState({ modalConfirmIsOpen: false });
  }

  openScheduleDialog() {
    this.setState({ scheduleDialogIsOpen: true });
  }

  closeScheduleDialog() {
    this.setState({ scheduleDialogIsOpen: false });
  }

  disableSubmitButtons() {
    this.setState({ uploading: true });
  }

  enableSubmitButtons() {
    this.setState({ uploading: false });
  }

  generateGenericAttachmentsName(name) {
    const { attachments } = this.state;
    const filterAttachments = attachments.filter(attachment =>
      attachment.file.name.includes(name)
    );

    if (filterAttachments.length) {
      return `${name} (${filterAttachments.length})`;
    }
    return name;
  }

  render() {
    const {
      employeeGroups,
      employeeSelectorIsOpen,
      modalConfirmIsOpen,
      scheduleDialogIsOpen,
      submitAction,
      schedule,
      finished,
      attachments,
      uploading,
      openPreview,
      uuid: campaignUUID,
      previewContent
    } = this.state;
    const { openAlert } = this.props;

    if (finished) {
      return <Redirect to="/email" />;
    }

    const textAction = this.getTextAction(submitAction);

    const ScheduleDialog = (
      <SelectScheduleDialog
        open={scheduleDialogIsOpen}
        onClose={() => this.closeScheduleDialog()}
        value={schedule}
        onChange={value => this.setState({ schedule: value })}
        onConfirm={() => {
          this.closeScheduleDialog();
          this.openModalConfirm();
        }}
        title="Agendamento do disparo"
        message="Escolha a data e hora para o disparo ser realizado"
      />
    );

    const modalConfirm = (values, setFieldValue) => (
      <ModalConfirm
        isOpen={modalConfirmIsOpen}
        onClose={() => this.closeModalConfirm()}
        onConfirm={() => this.submitCampaign(values)}
        employeeSubscribers={values.employeeSubscribers}
        employeeGroups={values.employeeGroups}
        textAction={textAction}
        setSubscribers={(type, subscribers) => setFieldValue(type, subscribers)}
      />
    );

    return (
      <Formik
        validateOnBlur
        validateOnChange={false}
        initialValues={{
          attachments: [],
          body: "",
          employeeGroups: [],
          destinationFilter: {},
          employeeSubscribers: [],
          title: "",
          filterEmployees: true,
          customTemplate: false,
          standardAttachList: true
        }}
        validationSchema={this.validationSchema}
        onSubmit={() => {
          if (submitAction === "schedule") {
            this.openScheduleDialog();
          } else {
            this.openModalConfirm();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid
        }) => {
          return (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <BackButton to="/email" />
                <Grid item xs={12}>
                  <Group>
                    <Heading level={2} icon={PeopleIcon} gutterBottom>
                      Público do e-mail
                    </Heading>
                    <Box py={4}>
                      <Grid container spacing={5}>
                        <Grid item container spacing={2} xs={12}>
                          <Grid item xs={12}>
                            <FormLabel>
                              Assinale para quem deseja enviar o e-mail:
                            </FormLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={(
                                <Field
                                  id="filterEmployees"
                                  name="filterEmployees"
                                  component={Checkbox}
                                  checked={values.filterEmployees}
                                  disabled
                                  onChange={(event, value) => {
                                    handleChange(event, value);
                                    if (!value) {
                                      setFieldValue("employeeGroups", []);
                                    }
                                  }}
                                  color="primary"
                                />
                              )}
                              label="Contribuidores/Terceirizados"
                            />
                          </Grid>
                        </Grid>
                        {values.filterEmployees && (
                          <>
                            <Grid item xs={12}>
                              <Box mb={5} mt={-2}>
                                <FormLabel component="legend">
                                  Especifique os
                                  {" "}
                                  <HighlightText>contribuidores</HighlightText>
                                </FormLabel>
                              </Box>
                              <Autocomplete
                                multiple
                                id="employeeGroups"
                                name="employeeGroups"
                                value={values.employeeGroups}
                                onChange={(event, coursesValues) => {
                                  setFieldValue(
                                    "employeeGroups",
                                    coursesValues
                                  );
                                }}
                                options={employeeGroups}
                                getOptionLabel={option => option.NOME_COMPLETO}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Grupos"
                                    margin="dense"
                                    fullWidth
                                    error={
                                      errors.employeeGroups &&
                                      touched.employeeGroups
                                    }
                                    helperText={
                                      touched.employeeGroups &&
                                      errors.employeeGroups
                                    }
                                  />
                                )}
                                disableCloseOnSelect
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      color="primary"
                                      icon={<CheckBoxOutlineBlankIcon />}
                                      checkedIcon={<CheckBoxIcon />}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.NOME_COMPLETO}
                                  </React.Fragment>
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                    <Divider />
                    <Group.GroupFooter>
                      <ButtonBlock
                        startIcon={<HowToRegIcon />}
                        disabled={!values.employeeGroups.length}
                        onClick={() => this.openEmployeSelector()}
                      >
                        Gerenciar destinatários
                      </ButtonBlock>
                    </Group.GroupFooter>
                  </Group>
                </Grid>

                <Grid item xs={12}>
                  <Group>
                    <Heading level={2} icon={EditIcon} gutterBottom>
                      Conteúdo do e-mail
                    </Heading>
                    <Box py={4}>
                      <Grid container spacing={6}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            component={TextField}
                            id="title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Título"
                            fullWidth
                            margin="dense"
                            error={errors.title && touched.title}
                            helperText={touched.title && errors.title}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={(
                              <Field
                                id="customTemplate"
                                name="customTemplate"
                                component={Checkbox}
                                checked={values.customTemplate}
                                onChange={handleChange}
                                color="primary"
                              />
                            )}
                            label="Template Customizado"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel>Texto do e-mail</InputLabel>
                          <Editor
                            id="body"
                            name="body"
                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                            init={{
                              branding: false,
                              height: 300,
                              menubar: false,
                              paste_data_images: true,
                              setup: editor => {
                                editor.on("KeyDown", e => {
                                  if (
                                    (e.keyCode === 8 || e.keyCode === 46) &&
                                    editor.selection
                                  ) {
                                    const selectedNode = editor.selection.getNode();
                                    if (
                                      selectedNode &&
                                      selectedNode.nodeName === "IMG"
                                    ) {
                                      const selectedAttachment = attachments.find(
                                        attachment =>
                                          attachment.file.url ===
                                          selectedNode.src
                                      );
                                      this.removeAttachment(
                                        selectedAttachment.id
                                      );
                                    }
                                  }
                                });
                              },
                              images_upload_handler: async (
                                blobInfo,
                                success,
                                failure
                              ) => {
                                let file = blobInfo.blob();
                                if (!isBlob(file)) {
                                  const base64Response = await fetch(
                                    blobInfo.blobUri()
                                  );
                                  file = await base64Response.blob();
                                }
                                const filename = this.generateGenericAttachmentsName(
                                  "Anexo"
                                );
                                UploadUtil.campaignUploadAttachment(
                                  file,
                                  filename,
                                  campaignUUID
                                ).then(attachment => {
                                  if (attachment) {
                                    this.addAttachment(attachment);
                                    success(attachment.file.url);
                                  } else {
                                    failure("Erro ao fazer o upload da imagem");
                                  }
                                });
                              },
                              paste_retain_style_properties: "color font-size",
                              plugins: [
                                "advlist autolink lists link print preview anchor",
                                "searchreplace fullscreen",
                                "insertdatetime table help wordcount image link powerpaste",
                                "code"
                              ],
                              powerpaste_allow_local_images: true,
                              powerpaste_word_import: "prompt",
                              powerpaste_html_import: "prompt",
                              toolbar: `undo redo | formatselect | bold italic forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | table | image | link | code`,
                              language_url: `${window.location.protocol}//${window.location.host}/tinymce/pt_BR.js`,
                              language: "pt_BR",
                              link_assume_external_targets: true,
                              default_link_target: "_system",
                              link_default_protocol: "https",
                              target_list: [
                                { title: "Nova Página", value: "_system" }
                              ]
                            }}
                            onBlur={e =>
                              setFieldValue("body", e.target.getContent())
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <Divider />
                    <Box py={4}>
                      <FileInput
                        accept="image/png,image/jpeg,application/pdf"
                        name="attachments"
                        id="attachments"
                        label="Anexo"
                        fieldOptions={{ campaignUUID }}
                        upload={FileInputService.uploadCampaignAttachment}
                        status="NOT_SENT"
                        onError={error => {
                          if (error.response) {
                            const errorData = error.response.data.error;
                            const { message } = errorData;
                            openAlert({ status: "error", message });
                          } else {
                            openAlert({
                              status: "error",
                              message: "Falha durante upload do arquivo."
                            });
                          }
                          this.enableSubmitButtons();
                        }}
                        onSuccess={(response, file) => {
                          const attachment = {
                            id: response.id,
                            file
                          };
                          this.addAttachment(attachment);
                          this.enableSubmitButtons();
                        }}
                        onChange={() => this.disableSubmitButtons()}
                      />
                      {!!attachments.length && (
                        <>
                          <Box my={4}>
                            <AttachmentList
                              attachments={attachments}
                              onDelete={attachment =>
                                this.removeAttachment(attachment.id)
                              }
                            />
                          </Box>
                          {values.customTemplate && (
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={(
                                  <Field
                                    id="standardAttachList"
                                    name="standardAttachList"
                                    component={Checkbox}
                                    checked={values.standardAttachList}
                                    onChange={handleChange}
                                    color="primary"
                                  />
                                )}
                                label="Utilizar lista de anexos padrão"
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </Box>
                    <Group.GroupFooter>
                      <ButtonBlock
                        startIcon={<VisibilityIcon />}
                        onClick={() => {
                          validateForm().then(() => {
                            const attachmentNames = attachments.map(item => {
                              return item.file ? item.file.name : "";
                            });

                            this.setState(
                              {
                                previewData: {
                                  title: values.title,
                                  message: values.body,
                                  attachments: attachmentNames,
                                  campaignOptions: {
                                    standardAttachList:
                                      values.standardAttachList,
                                    customTemplate: values.customTemplate
                                  }
                                }
                              },
                              () => {
                                this.handleOpenPreview();
                              }
                            );
                          });
                        }}
                      >
                        Visualizar Email
                      </ButtonBlock>
                    </Group.GroupFooter>
                  </Group>
                </Grid>
              </Grid>
              <Box my={9} mx={5}>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={uploading}
                      onClick={() => {
                        validateForm().then(() => {
                          if (isValid) {
                            this.setSubmitAction("schedule");
                          } else {
                            this.formErrorAlert();
                          }
                        });
                      }}
                    >
                      Agendar Envio
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={uploading}
                      onClick={() => {
                        validateForm().then(() => {
                          if (isValid) {
                            this.setSubmitAction("send");
                          } else {
                            this.formErrorAlert();
                          }
                        });
                      }}
                    >
                      Enviar agora
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <ModalReceiversSelector
                isOpen={employeeSelectorIsOpen}
                onClose={() => this.closeEmployeeSelector()}
                employeeGroups={values.employeeGroups}
                setSubscribers={(type, subscribers) =>
                  setFieldValue(type, subscribers)
                }
              />
              {ScheduleDialog}
              {modalConfirm(values, setFieldValue)}
              <DialogNotification
                open={openPreview}
                onClose={() => this.setState({ openPreview: false })}
                content={previewContent}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

EmailForm.defaultProps = {
  openAlert: false
};

EmailForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  openAlert: PropTypes.bool,
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired
};

export default EmailForm;
