import styled from "styled-components";
import { withTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import AppHeader from "../AppHeader";

const ToolBarAppHeader = styled(AppHeader)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    margin-left: ${props => props.sidebarSize}px;
    width: calc(100% - ${props => props.sidebarSize}px);
  }
`;

ToolBarAppHeader.defaultProps = {
  sidebarSize: 0
};

ToolBarAppHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(ToolBarAppHeader);
