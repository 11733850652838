import React from "react";
import {
  FormControl,
  Grid,
  Icon,
  Select as MuiSelect,
  withTheme
} from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";

const icons = [
  "appsoutlinedicon",
  "badgeoutlinedicon",
  "bookmarkborderoutlinedicon",
  "bookoutlinedicon",
  "casesoutlinedicon",
  "camerarolloutlinedicon",
  "categoryoutlinedicon",
  "codeoutlinedicon",
  "collectionsbookmarkoutlinedicon",
  "attachment",
  "dashboardoutlinedicon",
  "fastfoodoutlinedicon",
  "folderoutlinedicon",
  "laptopoutlinedicon",
  "mapoutlinedicon",
  "schooloutlinedicon",
  "link",
  "help",
  "info",
  "description",
  "menu_book",
  "person",
  "group",
  "settings",
  "attach_money"
];

const Select = styled(MuiSelect)`
  max-height: 56px;
`;

const Menu = styled(Grid)`
  max-width: 300px;
`;
const MenuItem = styled.div`
  text-align: center;
  padding: 8px;
  ${({ selected }) =>
    selected
      ? `
    background-color: #ccc;
  `
      : ""}
  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
`;

function IconPicker({ onChange, value, name }) {
  const setIcon = icon => {
    if (icons.includes(icon)) {
      onChange(icon);
    }
  };

  return (
    <FormControl variant="outlined">
      <Select
        name={name}
        value={value}
        renderValue={icon => <Icon>{icon}</Icon>}
      >
        <Menu container>
          {icons.map(icon => (
            <Grid item xs={3} key={icon} onClick={() => setIcon(icon)}>
              <MenuItem selected={icon === value}>
                <Icon>{icon}</Icon>
              </MenuItem>
            </Grid>
          ))}
        </Menu>
      </Select>
    </FormControl>
  );
}

IconPicker.defaultProps = {
  value: "link",
  name: "icon"
};

IconPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string
};

export default withTheme(IconPicker);
