import React, { Component } from "react";
import PropTypes from "prop-types";
import { Add as AddIcon, Edit as EditIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  Box,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Grid,
  IconButton
} from "@material-ui/core";
import fontColorContrast from "font-color-contrast";
import chroma from "chroma-js";
import moment from "@moment";
import styled from "styled-components";
import { withAlert } from "react-alert";
import _ from "lodash";
import WuiCalendar from "../../waybee-ui/Calendar/Calendar";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import CalendarService from "../../services/CalendarService";
import MiniCalendar from "../../waybee-ui/Calendar/MiniCalendar";
import EventDialog from "./components/EventDialog";
import LoadingDialog from "../../waybee-ui/Dialog/LoadingDialog";
import SimpleConfirmDialog from "../../waybee-ui/Dialog/SimpleConfirmDialog";

const OptionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Filter = styled.div`
  background-color: white;
  margin-top: 20px;
  height: 100%;
`;
const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  border-bottom: 2px solid #f2f2f2;

  p {
    margin: 0;
    font-weight: 300;
    font-size: 21px;
    color: black;
  }
`;
const Checkbox = styled(MuiCheckbox).withConfig({
  shouldForwardProp: prop => !["color"].includes(prop)
})`
  color: ${({ color }) => color};

  &.Mui-checked {
    color: ${({ color }) => color};
  }
`;
const CalendarCheck = styled.div`
  padding: 14px 16px;
  border-bottom: 2px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
`;

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendars: [],
      selectedCalendars: {},
      selectedDay: moment(),
      selectedEvent: null,
      loading: false,
      openConfirm: false
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Calendário Geral");

    this.getCalendars();
  }

  getCalendars = async () => {
    try {
      this.setState({ loading: true });
      const calendars = await CalendarService.getCalendars();
      const selectedCalendars = {};
      calendars.forEach(calendar => {
        selectedCalendars[calendar.id] = true;
      });
      this.setState({
        calendars,
        selectedCalendars
      });
    } catch (e) {
      const { alert } = this.props;
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao buscar calendários"
      );
      alert.show(message, { title: "Erro" });
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  formatEvents = events => {
    return events.map(event => {
      const minutes = moment
        .duration(moment(event.finalDate).diff(moment(event.initialDate)))
        .asMinutes();
      const allDay =
        moment(event.initialDate).isSame(moment(event.finalDate), "day") &&
        minutes > 1439;

      return {
        id: event.id,
        start: event.initialDate,
        end: event.finalDate,
        title: event.title,
        extendedProps: {
          description: event.description,
          location: event.location,
          destinationFilter: event.destinationFilter,
          creator: event.creator
        },
        backgroundColor: event.color,
        borderColor: chroma(event.color)
          .darken()
          .hex(),
        textColor: fontColorContrast(event.color),
        allDay
      };
    });
  };

  eventClick = info => {
    this.setState({ selectedEvent: info.event });
  };

  getOptions = () => {
    return {
      height: 1000,
      views: {
        timeGridWeek: {
          titleFormat: { year: "numeric", month: "long" },
          slotLabelFormat: { hour: "numeric", minute: "2-digit" }
        },
        timeGridDay: {
          titleFormat: { month: "long", day: "numeric" }
        }
      },
      headerToolbar: {
        left: "title prev,next",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
      },
      allDaySlot: true,
      defaultView: "dayGridMonth",
      eventClick: this.eventClick,
      events: this.getEvents
    };
  };

  getEvents = async (info, successCallback, failureCallback) => {
    try {
      const { selectedCalendars } = this.state;
      const calendars = Object.keys(selectedCalendars);
      const events = await CalendarService.getEvents({
        startDate: info.startStr,
        endDate: info.endStr,
        calendars: calendars.filter(calendar => selectedCalendars[calendar])
      });
      successCallback(this.formatEvents(events));
    } catch (e) {
      const { alert } = this.props;
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao buscar calendários"
      );
      alert.show(message, { title: "Erro" });
      console.error(e);
      failureCallback(e);
    }
  };

  onChangeDate = date => {
    this.setState({ selectedDay: date });
  };

  changeCalendar = (id, value) => {
    const { selectedCalendars } = this.state;
    this.setState({
      selectedCalendars: {
        ...selectedCalendars,
        [id]: value
      }
    });
  };

  deleteEvent = async id => {
    await CalendarService.deleteEvent(id);
    this.setState(
      { selectedEvent: null, openConfirm: false },
      this.getCalendars
    );
  };

  onDeleteEvent = async () => {
    this.setState({ openConfirm: true });
  };

  render() {
    const {
      calendars,
      selectedCalendars,
      selectedDay,
      selectedEvent,
      loading,
      openConfirm
    } = this.state;
    const options = this.getOptions();

    if (loading) return <LoadingDialog open={loading} onClose={() => {}} />;

    return (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <WuiCalendar
            selectedCalendars={selectedCalendars}
            selectedDay={selectedDay}
            options={options}
          />
        </Grid>
        <Grid item xs={4}>
          <OptionsColumn>
            <ButtonBlock
              startIcon={<AddIcon />}
              component={Link}
              to="/calendar/event/form"
            >
              CRIAR NOVO EVENTO
            </ButtonBlock>

            <Box mt={2}>
              <MiniCalendar
                selectedDate={selectedDay}
                onChange={this.onChangeDate}
              />
            </Box>

            <Filter>
              <FilterHeader>
                <p>Meus Calendários</p>
                <IconButton
                  size="small"
                  color="primary"
                  component={Link}
                  to="/calendar/form"
                >
                  <AddIcon />
                </IconButton>
              </FilterHeader>

              {calendars.map(calendar => (
                <CalendarCheck>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name={calendar.id}
                        checked={selectedCalendars[calendar.id]}
                        onChange={(e, val) =>
                          this.changeCalendar(calendar.id, val)
                        }
                        color={calendar.color}
                      />
                    )}
                    label={calendar.name}
                  />
                  <IconButton
                    size="small"
                    color="default"
                    component={Link}
                    to={`/calendar/form/${calendar.id}`}
                  >
                    <EditIcon />
                  </IconButton>
                </CalendarCheck>
              ))}
            </Filter>
          </OptionsColumn>
        </Grid>

        <EventDialog
          event={selectedEvent}
          onClose={() => this.setState({ selectedEvent: null })}
          open={!!selectedEvent}
          onDelete={this.onDeleteEvent}
        />

        <SimpleConfirmDialog
          message="Tem certeza que deseja deletar o evento?"
          onClose={() => this.setState({ openConfirm: false })}
          onConfirm={() => this.deleteEvent(selectedEvent.id)}
          open={openConfirm}
        />
      </Grid>
    );
  }
}

Calendar.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(Calendar);
