import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import Dialog from "../../../waybee-ui/Dialog";

const InfoText = styled.div`
  p {
    margin-top: 8px;
    line-height: 22px;
  }
`;

const NotRegisteredInfoDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} closeButton maxWidth="md" fullWidth>
      <DialogTitle>Sincronização de dados</DialogTitle>
      <DialogContent>
        <InfoText>
          <Typography variant="subtitle1">Gerar usuário para o app</Typography>
          <p>Gera manualmente uma conta no aplicativo para este aluno</p>
        </InfoText>
      </DialogContent>
    </Dialog>
  );
};

NotRegisteredInfoDialog.defaultProps = {
  open: false
};

NotRegisteredInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default NotRegisteredInfoDialog;
