import moment from "@moment";
import { api } from "../utils/api";
import UserStore from "../utils/UserStore";
import purify from "../utils/purify";

const formatBodyMessage = bodyMessage => {
  const addTableContainer = htmlStr => {
    const containerTable = `<div class="table-responsive">$1</div>`;
    return htmlStr.replace(/((<table(?:.|\n)*?<\/table>))/gm, containerTable);
  };

  const formatImages = htmlStr => {
    return htmlStr.replace(/<img /g, `<img style="max-width: 100%" `);
  };

  let formattedMessage = addTableContainer(bodyMessage);
  formattedMessage = formatImages(formattedMessage);
  return purify.sanitize(formattedMessage);
};

const getAudits = async id => {
  const response = await api.get(`Communications/${id}/getAudits`);
  return response.data;
};

const getCommunicationStatistics = async id => {
  const response = await api
    .get(`Communications/${id}/statistics`)
    .catch(err => {
      const errorData = err.response.data.error;
      if (
        errorData.statusCode === 403 ||
        errorData.message === "Unauthorized"
      ) {
        throw Error("Você não tem acesso a essa comunicação");
      }
      throw Error("Erro ao obter estatísticas da comunicação");
    });
  return response.data;
};

const getCommunicationDetail = async id => {
  const response = await api
    .get(`Communications/getCommunicationsDetail`, {
      params: { communicationId: id }
    })
    .catch(() => {
      throw Error("Essa comunicação não foi encontrada.");
    });

  const data = response.data.response;
  data.communication.bodyMessage = formatBodyMessage(
    data.communication.bodyMessage
  );

  return data;
};

/* o tipo 99 é o tipo referente a comunicações criadas diretamente pela API */
const getCommunications = (
  skip = 0,
  searchText = "",
  initialDate,
  endDate,
  typeComm = ""
) => {
  return api
    .get("Communications/list", {
      params: {
        filter: {
          skip,
          initialDate: moment(initialDate).isValid()
            ? new Date(initialDate.setHours(0, 0, 0))
            : new Date(moment().startOf("year")),
          endDate,
          searchText,
          typeComm
        }
      }
    })
    .then(response => response.data);
};

/* o tipo 99 é o tipo referente a comunicações criadas diretamente pela API */
const getCommunicationsCount = (
  searchText = "",
  initialDate,
  endDate,
  typeComm = ""
) => {
  return api
    .get("Communications/list/count", {
      params: {
        filter: {
          initialDate: moment(initialDate).isValid()
            ? new Date(initialDate.setHours(0, 0, 0))
            : new Date(moment().startOf("year")),
          endDate,
          searchText,
          typeComm
        }
      }
    })
    .then(response => {
      return response.data;
    });
};

// Adapt communication props to suit with api params
const newCommunicationParams = formValues => {
  const communication = { ...formValues };
  const communicationId = communication.id && null;
  communication.type = !communication.resend
    ? { id: communication.type }
    : communication.type;
  communication.category = !communication.resend
    ? { id: communication.category }
    : communication.category;

  communication.destinationFilter = communication.destinationFilter || {};

  if (communication.studentCourses && communication.studentCourses.length) {
    const studentCourses = communication.studentCourses.map(course => {
      return {
        CODIGO: course.CODIGO,
        NOME: course.NOME
      };
    });
    const studentClasses = communication.studentClasses.map(classItem => {
      return {
        CODIGO: classItem.CODIGO,
        CODIGO_CURSO: classItem.CODIGO_CURSO,
        NOME: classItem.NOME
      };
    });
    communication.destinationFilter.students = {
      courses: studentCourses,
      classes: studentClasses,
      grades: communication.studentSeries,
      years: communication.schoolYears,
      shifts: communication.classesShifts,
      enrollmentTypes: communication.enrollmentTypes,
      enrollmentSituation: communication.enrollmentSituation
    };
  }

  if (
    (communication.employeeCourses || communication.employeeTypes) &&
    (communication.employeeCourses.length || communication.employeeTypes.length)
  ) {
    const employeeCourses = communication.employeeCourses.map(course => {
      return {
        CODIGO: course.CODIGO,
        NOME: course.NOME
      };
    });
    const employeeClasses = communication.employeeClasses.map(classItem => {
      return {
        CODIGO: classItem.CODIGO,
        CODIGO_CURSO: classItem.CODIGO_CURSO,
        NOME: classItem.NOME
      };
    });
    const employeeTypes = communication.employeeTypes.map(course => {
      return {
        CODIGO: course.CODIGO,
        NOME: course.NOME
      };
    });
    communication.destinationFilter.employees = {
      courses: employeeCourses,
      classes: employeeClasses,
      types: employeeTypes
    };
  }

  const allRecipients = communication.subscribers
    ? communication.subscribers.map(e => e.id)
    : [];

  communication.destinationFilter.communicationRecipients =
    communication.destinationFilter.communicationRecipients ||
    communication.selectedFilterType;

  communication.destinationFilter.responsibleRecipients =
    communication.destinationFilter.responsibleRecipients ||
    communication.responsibleTypes;

  // Deleting remapped filters above
  delete communication.subscribers;
  delete communication.courses;
  delete communication.classes;

  const params = {
    communicationId,
    communication,
    allRecipients,
    userId: UserStore.getUserId()
  };
  return params;
};

const saveCommunication = async communication => {
  const params = newCommunicationParams(communication);
  try {
    const response = await api.post(
      "/Communications/saveCommunication",
      params
    );
    return response.data.response;
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data.error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};
const updateCommunication = async (id, communication) => {
  const params = newCommunicationParams(communication);
  try {
    const response = await api.put(`/Communications/${id}/edit`, params);
    return response.data;
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data.error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};

const sendCommunication = async communicationId => {
  try {
    // O timeout é maior aqui pois pode haver envio de comunicações grandes que podem demorar a responder
    const response = await api.post(
      "/Communications/sendCommunication",
      {
        communicationId
      },
      { timeout: 240000 }
    );
    return response.data.response;
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data.error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    const responseError = { ...error, ...{ message: errorMessage } };
    throw responseError;
  }
};

const scheduleCommunication = async (communicationId, schedule) => {
  try {
    const response = await api.post(
      `/Communications/${communicationId}/schedule`,
      { schedule }
    );
    return response.data.response;
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data.error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    const responseError = { ...error, ...{ message: errorMessage } };
    throw responseError;
  }
};

const resendCommunication = communication => {
  const params = {
    communication,
    userId: UserStore.getUserId(),
    schoolId: UserStore.getUserSchoolId()
  };
  return api.post("/Communications/resendCommunication", params);
};

const deleteCommunication = communicationId => {
  return api.delete(`Communications/${communicationId}`);
};

const cancelSchedule = communicationId => {
  return api.delete(`Communications/${communicationId}/schedule`);
};

const getSurveyStatistics = (surveyId, communicationId) => {
  return api.get(`Surveys/${surveyId}/statistics/`, {
    params: { communicationId }
  });
};

const getCategories = () => {
  return api.get("CommunicationCategoryCampuses/myCategories");
};

const getTypes = () => {
  return api.get("CommunicationTypes/");
};

const getResponsibleType = () => {
  return api.get("Communications/getResponsibleType");
};

const getSurveyReport = communicationId => {
  return api.get(`Surveys/report/${communicationId}`);
};

export default {
  getCommunicationDetail,
  getCommunications,
  getCommunicationsCount,
  sendCommunication,
  saveCommunication,
  updateCommunication,
  scheduleCommunication,
  resendCommunication,
  deleteCommunication,
  cancelSchedule,
  getSurveyStatistics,
  getCategories,
  getTypes,
  getCommunicationStatistics,
  getAudits,
  getResponsibleType,
  getSurveyReport
};
