import {
  SET_ACCESSION_STUDENT,
  SET_ACCESSION_RESPONSIBLE
} from "../actions/actionTypes";

const initialState = {
  accessionResponsible: null,
  accessionStudent: null
};

export const accessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESSION_RESPONSIBLE:
      return {
        ...state,
        accessionResponsible: action.payload
      };
    case SET_ACCESSION_STUDENT:
      return {
        ...state,
        accessionStudent: action.payload
      };
    default:
      return state;
  }
};
