import styled from "styled-components";
import withTheme from "@material-ui/core/styles/withTheme";
import MuiDialogActions from "@material-ui/core/DialogActions";

const DialogActions = styled(MuiDialogActions)`
  justify-content: space-around;
  margin: 28px 0;
`;

export default withTheme(DialogActions);
