import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { HorizontalBar } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import { BarChart as BarChartIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import Loading from "../../../components/Loading";
import { theme } from "../../../assets/theme/StyledTheme";

const ChartHeading = styled.h5`
  color: #000;
  text-align: left;
  margin: 40px 55px 0;
  font-size: 20px;
  font-weight: 500;
`;

const options = {
  layout: {
    padding: {
      right: 55
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          fontFamily: "Roboto, 'Helvetica Neue', 'Helvetica', sans-serif",
          fontSize: 16
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          offsetGridLines: false
        },
        ticks: {
          beginAtZero: true,
          padding: 10,
          fontFamily: "Roboto, 'Helvetica Neue', 'Helvetica', sans-serif",
          fontSize: 12,
          userCallback: label =>
            label.toString() === label.toFixed() ? label : ""
        }
      }
    ]
  },
  legend: {
    display: false
  },
  events: [],
  animation: {
    duration: 1,
    onComplete: function onComplete() {
      const chartInstance = this.chart;
      const { ctx } = chartInstance;
      ctx.font = 'bold 18px Roboto, "Helvetica Neue", Helvetica, sans-serif';
      ctx.fillStyle = theme.color.secondary.dark;
      this.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          // eslint-disable-next-line no-underscore-dangle
          ctx.fillText(data, bar._model.x + 20, bar._model.y + 5);
        });
      });
    }
  }
};

class GroupCharts extends Component {
  state = {
    chartData: {}
  };

  componentDidMount() {
    this.setData();
  }

  shouldComponentUpdate(nextProps) {
    const {
      communication,
      interactions,
      subscriberCount,
      loading
    } = this.props;
    return (
      communication !== nextProps.communication ||
      interactions !== nextProps.interactions ||
      subscriberCount !== nextProps.subscriberCount ||
      loading !== nextProps.loading
    );
  }

  componentDidUpdate() {
    this.setData();
  }

  setData = () => {
    const { communication, interactions, subscriberCount } = this.props;
    const {
      acks,
      openings,
      interests,
      confirms,
      clicks,
      openingsBySubscribers
    } = interactions;

    const chartData = {
      labels: [
        "Total de enviados",
        "Total de entregues",
        "Total de abertos(Geral)",
        "Total de abertos(por Destinatário)"
      ],
      datasets: [
        {
          label: "Número de usuários",
          backgroundColor: [
            "rgb(241,103,38)",
            "rgb(52,101,127)",
            "rgb(0,178,151)",
            "rgb(0,48,59)",
            "rgb(241,103,38)",
            "rgb(52,101,127)",
            "rgb(0,178,151)"
          ],
          hoverBackgroundColor: [
            "rgba(241,103,38, 0.8)",
            "rgba(52,101,127, 0.8)",
            "rgba(0,178,151, 0.8)",
            "rgba(0,48,59, 0.8)",
            "rgba(241,103,38, 0.8)",
            "rgba(52,101,127, 0.8)",
            "rgba(0,178,151, 0.8)"
          ],
          data: [
            subscriberCount,
            subscriberCount,
            openings,
            openingsBySubscribers
          ],
          barThickness: 30
        }
      ]
    };

    if (communication.isAckRequired) {
      chartData.labels.push("Total de cliques em Ciência");
      chartData.datasets[0].data.push(acks);
    }

    if (communication.hasLinkButton) {
      chartData.labels.push("Total de cliques em botão de link");
      chartData.datasets[0].data.push(clicks);
    }

    if (communication.type.id === 2) {
      chartData.labels.push("Total de Interessados");
      chartData.labels.push("Total de Confirmados");
      chartData.datasets[0].data.push(interests);
      chartData.datasets[0].data.push(confirms);
    }

    this.setState({ chartData });
  };

  render() {
    const { chartData } = this.state;
    const { loading } = this.props;
    return (
      <Group>
        <Heading level={2} icon={BarChartIcon} gutterBottom>
          Medidores de engajamento da comunicação
        </Heading>

        {loading ? (
          <Loading />
        ) : (
          <>
            <ChartHeading>Análise de alcance</ChartHeading>
            <Box py={2}>
              <HorizontalBar data={chartData} options={options} height={400} />
            </Box>
          </>
        )}
      </Group>
    );
  }
}

GroupCharts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  communication: PropTypes.object.isRequired,
  interactions: PropTypes.shape({
    acks: PropTypes.number,
    clicks: PropTypes.number,
    openings: PropTypes.number,
    interests: PropTypes.number,
    confirms: PropTypes.number,
    openingsBySubscribers: PropTypes.number
  }),
  subscriberCount: PropTypes.number,
  loading: PropTypes.bool
};

GroupCharts.defaultProps = {
  interactions: {
    acks: 0,
    clicks: 0,
    openings: 0,
    interests: 0,
    confirms: 0
  },
  subscriberCount: 0,
  loading: false
};

export default GroupCharts;
