import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DialogContent as MuiDialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withTheme
} from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Dialog from "../../../waybee-ui/Dialog";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogActions from "../../../waybee-ui/Dialog/DialogActions";
import DialogButton from "../../../waybee-ui/Dialog/DialogButton";

const Content = styled.p`
  font-size: 18px;
  color: ${props => props.theme.palette.secondary.dark};
`;

const DialogContent = styled(MuiDialogContent)`
  overflow-y: hidden;
`;

const FormTitle = styled.p`
  color: ${props => props.theme.palette.primary.dark};
  font-size: 16px;
  font-weight: 500;
  margin: 40px 0;
`;

const NewNotificationDialog = ({
  open,
  onClose,
  onSubmit,
  onCancel,
  action,
  notification,
  communication,
  theme
}) => {
  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
    title: Yup.string()
      .required()
      .max(40),
    message: Yup.string()
      .required()
      .max(178),
    schedule: Yup.date()
      .nullable()
      .requiredWhen(Yup.ref("type"), "SCHEDULE")
      .min(new Date(2019, 0, 1))
  });
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
      <Formik
        validateOnBlur
        enableReinitialize
        validateOnChange={false}
        initialValues={{
          type: notification.schedule ? "SCHEDULE" : "IMMEDIATE",
          title: notification.title,
          message: notification.message,
          schedule: notification.schedule || null
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values, action).then(resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid,
          isSubmitting
        }) => {
          return (
            <Dialog closeButton onClose={onClose} open={open} maxWidth="lg">
              <DialogTitle>
                {action === "NEW_NOTIFICATION"
                  ? "Criar Nova Notificação"
                  : "Notificação Agendada"}
              </DialogTitle>
              <DialogContent>
                <Content theme={theme}>
                  A notificação será um lembrete enviado aos destinatários da
                  comunicação.
                </Content>

                <FormTitle theme={theme}>Detalhes da notificação</FormTitle>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        margin="dense"
                        error={errors.type && touched.type}
                      >
                        <InputLabel id="type">Tipo de Notificação</InputLabel>
                        <Select
                          id="type"
                          name="type"
                          value={values.type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="IMMEDIATE">Imediata</MenuItem>
                          <MenuItem value="SCHEDULE">Agendada</MenuItem>
                        </Select>
                        <FormHelperText>
                          {touched.type && errors.type}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {values.type === "SCHEDULE" && (
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={errors.schedule && touched.schedule}
                        >
                          <DateTimePicker
                            id="schedule"
                            name="schedule"
                            format="dd/MM/yyyy HH:mm"
                            autoOk
                            fullWidth
                            ampm={false}
                            disablePast
                            minDate={
                              new Date(
                                communication.schedule ||
                                  communication.createdAt
                              )
                            }
                            value={values.schedule}
                            onChange={value => {
                              setFieldValue("schedule", value);
                            }}
                            onBlur={handleBlur}
                            label="Data e hora do envio"
                            error={errors.schedule && touched.schedule}
                            helperText={touched.schedule && errors.schedule}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        id="title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Título"
                        helperText={touched.title && errors.title}
                        fullWidth
                        error={errors.title && touched.title}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        id="message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Mensagem"
                        helperText={touched.message && errors.message}
                        fullWidth
                        error={errors.message && touched.message}
                      />
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
              <DialogActions>
                <Grid container justify="space-around">
                  <Grid item xs={5}>
                    {action === "NEW_NOTIFICATION" ? (
                      <DialogButton
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                        disabled={isSubmitting}
                      >
                        Voltar
                      </DialogButton>
                    ) : (
                      <DialogButton
                        fullWidth
                        variant="contained"
                        color="error"
                        backgroundColor="#ec0000"
                        onClick={onCancel}
                        disabled={isSubmitting}
                      >
                        Cancelar Notificação
                      </DialogButton>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <DialogButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      onClick={() => {
                        validateForm().then(() => {
                          if (isValid) handleSubmit();
                        });
                      }}
                    >
                      {action === "NEW_NOTIFICATION"
                        ? "Enviar"
                        : "Salvar Alterações"}
                    </DialogButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

NewNotificationDialog.defaultProps = {
  open: false,
  notification: {}
};

NewNotificationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  notification: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  communication: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(NewNotificationDialog);
