import React, { useState } from "react";
import Proptypes from "prop-types";
import styled, { css } from "styled-components";
import { Avatar as MuiAvatar } from "@material-ui/core";
import { DescriptionOutlined as FileIcon } from "@material-ui/icons";
import OpenImage from "../../../waybee-ui/OpenImage";

const Container = styled.div`
  overflow: auto;
  margin-top: 25px;
  max-height: 400px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column-reverse;
`;
const MessageContainer = styled.div`
  display: flex;
  margin-top: 16px;
  ${({ selfMessage }) =>
    selfMessage &&
    css`
      justify-content: flex-end;
    `}
`;
const Avatar = styled(MuiAvatar)`
  width: 32px;
  height: 32px;
`;
const IconAvatar = styled(MuiAvatar)`
  width: 40px;
  height: 40px;
`;
const MessageInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
const MessageSender = styled.p`
  margin: 4px 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #0d062d;
`;
const Bubble = styled.div`
  max-width: 325px;
  padding: 12px;
  ${({ selfMessage }) =>
    selfMessage
      ? css`
          border-radius: 12px 0 12px 12px;
          background-color: #00303b;
        `
      : css`
          border-radius: 0 12px 12px 12px;
          background-color: #f2f2f2;
        `}
`;
const BubbleMessage = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  ${({ selfMessage }) =>
    selfMessage
      ? css`
          color: #ffffff;
        `
      : css`
          color: #0d062d;
        `}
`;
const BubbleDate = styled.p`
  margin: 4px 0 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  ${({ selfMessage }) =>
    selfMessage
      ? css`
          color: #ffffff;
        `
      : css`
          color: #787486;
        `}
`;
const BubbleFile = styled.a`
  display: flex;
  text-decoration: underline;
  & * {
    white-space: nowrap;
    overflow: hidden;
  }
`;
const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
`;
const FileName = styled.div`
  font-size: 14px;
  ${({ selfMessage }) =>
    selfMessage
      ? css`
          color: #ffffff;
        `
      : css`
          color: #666666;
        `}
  display: flex;
  min-width: 0;
  p {
    margin: 0;
  }
`;
const FileNameBase = styled.p`
  text-overflow: ellipsis;
`;
const FileNameExtension = styled.p`
  flex-shrink: 0;
`;
const BubbleImage = styled.div`
  text-align: center;
  img {
    max-width: 100%;
    max-height: 250px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const TicketMessages = ({ id, messages }) => {
  const [selectedImage, setSelectedImage] = useState();

  const userId = parseInt(localStorage.getItem("userId"), 10);

  return (
    <Container id={id}>
      {messages.map(message => {
        const selfMessage = message.sender.id === userId;
        return (
          <MessageContainer selfMessage={selfMessage}>
            {!selfMessage && <Avatar src={message.sender.photoProfile} />}
            <MessageInfo>
              {!selfMessage && (
                <MessageSender>{message.sender.name}</MessageSender>
              )}
              <Bubble selfMessage={selfMessage}>
                {message.type === "text" && (
                  <BubbleMessage selfMessage={selfMessage}>
                    {message.body}
                  </BubbleMessage>
                )}

                {message.type === "file" && (
                  <BubbleFile href={message.file.url} target="_blank" download>
                    <IconAvatar>
                      <FileIcon />
                    </IconAvatar>
                    <FileInfo>
                      <FileName selfMessage={selfMessage}>
                        <FileNameBase>{message.file.name}</FileNameBase>
                        <FileNameExtension>
                          {`.${message.file.extension}`}
                        </FileNameExtension>
                      </FileName>
                    </FileInfo>
                  </BubbleFile>
                )}

                {message.type === "image" && (
                  <BubbleImage
                    onClick={() => setSelectedImage(message.file.url)}
                  >
                    <img
                      src={message.file.url}
                      // onLoad={this.scrollToBottom}
                      alt="Imagem enviada"
                    />
                  </BubbleImage>
                )}

                <BubbleDate selfMessage={selfMessage}>
                  {message.date}
                </BubbleDate>
              </Bubble>
            </MessageInfo>
          </MessageContainer>
        );
      })}

      {selectedImage && (
        <OpenImage src={selectedImage} onClose={() => setSelectedImage(null)} />
      )}
    </Container>
  );
};

TicketMessages.propTypes = {
  id: Proptypes.string.isRequired,
  messages: Proptypes.arrayOf(Proptypes.shape({})).isRequired
};

export default TicketMessages;
