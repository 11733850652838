import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import Avatar from "../../../waybee-ui/Avatar";

const TableCell = styled(MuiTableCell)`
  background-color: #e5e5e5;
  border: solid 2px #fff;
  padding: 7px;
`;

const ReceiversSelector = ({ users }) => {
  return (
    <Table aria-label="simple table">
      <TableBody>
        {users.map(user => {
          return (
            <TableRow key={user.id}>
              <TableCell className="student">
                <Avatar alt="" src={user.photoProfile} size={40}>
                  <Typography variant="body2">{user.name}</Typography>
                </Avatar>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

ReceiversSelector.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: "",
      photoProfile: "",
      name: ""
    })
  ).isRequired
};

export default ReceiversSelector;
