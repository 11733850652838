import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import Button from "../../../waybee-ui/Button";
import RoundedAvatar from "../../../waybee-ui/RoundedAvatar";

const Voter = styled.div`
  padding: 5px;
  &:hover {
    background-color: #ccc;
    cursor: pointer;
  }
`;

const UserName = styled.p`
  font-size: 16px;
`;

const VoteText = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

const DependentPlaceholder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalVoters = ({ option, open, onClose }) => {
  const [detailMode, setDetailMode] = useState(false);
  const [selectedVote, setSelectedVote] = useState({});

  const onClickVote = vote => {
    setSelectedVote(vote);
    setDetailMode(true);
  };

  if (!option) return null;
  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <DialogTitle onClose={onClose}>
        {detailMode
          ? "Justificativa"
          : `Votos da opção "${option.question}" da enquete`}
      </DialogTitle>
      <DialogContent>
        {detailMode ? (
          <Grid container>
            <Grid item xs={12}>
              <Button
                color="default"
                startIcon={<ArrowBackIcon />}
                size="large"
                onClick={() => setDetailMode(false)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mt={3} mb={3}>
                <RoundedAvatar
                  imageSrc={selectedVote.user.photoProfile}
                  icon="person"
                  size={44}
                />
                <Box ml={1}>
                  <UserName>
                    {selectedVote.user.name}
                    {' '}
                  </UserName>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <VoteText>{selectedVote.justification}</VoteText>
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <Grid container justify="space-between">
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h6">Responsável</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h6">Aluno(a)</Typography>
                </Box>
              </Grid>
            </Grid>
            {option.users.map(userVote => (
              <Voter onClick={() => onClickVote(userVote)}>
                <Grid container justify="space-between">
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <RoundedAvatar
                        imageSrc={userVote.user.photoProfile}
                        icon="person"
                        size={44}
                      />
                      <Box ml={1}>{userVote.user.name}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {userVote.dependent ? (
                        <Fragment>
                          <RoundedAvatar
                            imageSrc={userVote.dependent.photoProfile}
                            icon="person"
                            size={44}
                          />
                          <Box ml={1}>{userVote.dependent.name}</Box>
                        </Fragment>
                      ) : (
                        <DependentPlaceholder>-</DependentPlaceholder>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      whiteSpace="pre"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      <VoteText>{userVote.justification}</VoteText>
                    </Box>
                  </Grid>
                </Grid>
              </Voter>
            ))}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Grid justify="center" container>
          <Grid xs={6} item>
            <Button
              variant="contained"
              onClick={onClose}
              color="primary"
              fullWidth
            >
              Ok
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ModalVoters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  option: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalVoters;
