import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import DialogButton from "./DialogButton";
import SimpleDialog from "./SimpleDialog";

const ConfirmDialog = ({
  onClose,
  open,
  title,
  message,
  confirmBtnText,
  confirmBtnColor,
  onConfirm,
  cancelBtnText,
  cancelBtnColor,
  onCancel
}) => {
  return (
    <SimpleDialog
      closeBtn
      title={title}
      message={message}
      onClose={onClose}
      open={open}
      actions={(
        <Grid container justify="space-around">
          <Grid item xs={5}>
            <DialogButton
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onCancel}
              backgroundColor={cancelBtnColor}
            >
              {cancelBtnText}
            </DialogButton>
          </Grid>
          <Grid item xs={5}>
            <DialogButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={onConfirm}
              backgroundColor={confirmBtnColor}
            >
              {confirmBtnText}
            </DialogButton>
          </Grid>
        </Grid>
      )}
    />
  );
};

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  confirmBtnColor: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  cancelBtnColor: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

ConfirmDialog.defaultProps = {
  open: false,
  confirmBtnColor: undefined,
  cancelBtnColor: undefined
};

export default ConfirmDialog;
