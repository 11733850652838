import React, { Component } from "react";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import moment from "@moment";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";

const Styled = styled.div`
  background-color: white;

  .MuiPickersCalendarHeader-switchHeader {
    margin: 0;
    display: flex;
    justify-content: end;
    padding: 20px 8px 20px 20px;

    .MuiPickersCalendarHeader-iconButton {
      z-index: 0;
    }

    .MuiPickersCalendarHeader-transitionContainer {
      height: auto;
      width: auto;
      position: static;
      margin-right: auto;

      p {
        margin: 0;
        text-transform: capitalize;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        color: ${({ theme }) => theme.color.primary.main};
        position: static;
      }
    }
  }

  .MuiPickersSlideTransition-transitionContainer > * {
    position: static;
  }

  .MuiPickersCalendar-week {
    justify-content: space-around;
    padding: 0 30px;
  }

  button.MuiPickersDay-day {
    width: 40px;
    height: 40px;
    box-shadow: none;

    .MuiIconButton-label {
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }

    &.MuiPickersDay-daySelected {
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.color.primary.dark};

      .MuiIconButton-label {
        color: black;
      }
    }
  }

  .MuiPickersCalendarHeader-daysHeader {
    justify-content: space-around;
    padding: 10px 30px 0 30px;

    span {
      font-family: "Roboto", sans-serif;
      font-size: 17.4px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.secondary.dark};
    }
  }
`;

class MiniCalendar extends Component {
  componentDidMount() {
    this.formatHeader();
  }

  formatHeader = () => {
    const daysHeaders = document.querySelectorAll(
      ".MuiPickersCalendarHeader-daysHeader .MuiPickersCalendarHeader-dayLabel"
    );

    daysHeaders.forEach(day => {
      day.textContent = day.textContent.substring(0, 1).toUpperCase(); // eslint-disable-line no-param-reassign
    });

    const titleContainer = document.querySelector(
      ".MuiPickersCalendarHeader-transitionContainer"
    );
    const headerContainer = titleContainer.parentNode;
    const headerBtn = document.querySelector(
      ".MuiPickersCalendarHeader-switchHeader button"
    );
    headerContainer.insertBefore(headerBtn, titleContainer.nextSibling);
  };

  render() {
    const { selectedDate, onChange } = this.props;
    return (
      <Styled>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
          <Calendar date={selectedDate} onChange={onChange} />
        </MuiPickersUtilsProvider>
      </Styled>
    );
  }
}

MiniCalendar.defaultProps = {
  selectedDate: moment()
};

MiniCalendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedDate: PropTypes.object, // moment object
  onChange: PropTypes.func.isRequired
};

export default withTheme(MiniCalendar);
