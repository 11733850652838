import Compressor from "compressorjs";
import FileInputService from "../services/FileInputService";

const minimizeImage = file => {
  return new Promise((resolve, reject) => {
    return new Compressor(file, {
      convertSize: 0,
      quality: 0.5,
      maxWidth: 1024,
      maxHeight: 1024,
      success: resultFile => resolve(resultFile),
      error: e => reject(e)
    });
  });
};

const communicationUploadAttachment = async (blob, filename, uuid) => {
  const minimizedFile = await minimizeImage(blob);

  minimizedFile.name = filename;
  const res = await FileInputService.uploadAttachment(minimizedFile, { uuid });

  minimizedFile.url = res.data.url;

  return {
    id: res.data.attachmentId,
    file: minimizedFile
  };
};

const activityUploadAttachment = async (blob, filename, uuid) => {
  const minimizedFile = await minimizeImage(blob);

  minimizedFile.name = filename;
  const res = await FileInputService.uploadActivityAttachment(minimizedFile, {
    uuid
  });

  minimizedFile.url = res.data.url;

  return {
    id: res.data.attachmentId,
    file: minimizedFile,
    name: filename
  };
};

const campaignUploadAttachment = async (blob, filename, campaignUUID) => {
  const minimizedFile = await minimizeImage(blob);

  minimizedFile.name = filename;
  const res = await FileInputService.uploadCampaignAttachment(minimizedFile, {
    campaignUUID
  });

  minimizedFile.url = res.data.url;

  return {
    id: res.data.id,
    file: minimizedFile
  };
};

const UploadUtil = {
  minimizeImage,
  communicationUploadAttachment,
  activityUploadAttachment,
  campaignUploadAttachment
};

export default UploadUtil;
