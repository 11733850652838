import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { AvatarGroup } from "@material-ui/lab";
import { Add as AddIcon } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";
import tinyColor from "tinycolor2";

const KanbanCard = styled.div`
  width: 314px;
  border-radius: 16px;
  background-color: white;
  padding: 18px 20px;
  margin-bottom: 20px;
`;

const TicketDate = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #787486;
  margin: 0;
`;

const TicketTitle = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #0d062d;
  padding: 8px 0;
`;

const TicketDescription = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #787486;
`;

const TicketUsers = styled.div`
  padding-top: 28px;
  display: flex;
`;

const TicketUsersLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
`;

const TicketUsersName = styled.p`
  font-family: Roboto, sans-serif;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #787486;
  line-height: 14px;

  span {
    font-weight: 500;
  }
`;

const TicketAssign = styled.p`
  margin: 0;
  display: inline-block;
  ${({ clickable }) =>
    clickable &&
    css`
      text-decoration: underline;
      :hover {
        color: blue;
        cursor: pointer;
      }
    `}
`;

const TicketAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

const TicketChannel = styled.p`
  transition: max-width 0.3s linear;
  margin: 0;
  padding: 5px;
  border-radius: 4px;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 45%;

  :hover {
    overflow: visible;
    max-width: 100%;
  }
`;

const TicketDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const TicketCard = ({
  ticket,
  openDetails,
  setAttendee,
  selectedChannel,
  disableActions
}) => {
  const {
    id,
    title,
    description,
    createdAt,
    createdBy,
    attendee,
    ticketChannel,
    ticketStatus
  } = ticket;

  return (
    <KanbanCard key={`ticket-${id}`} onClick={() => openDetails(id)}>
      <TicketDetails>
        {(selectedChannel === 0 || selectedChannel === "unassignedTickets") &&
          ticketChannel && (
            <TicketChannel
              color={tinyColor(ticketStatus.color).brighten(45)}
              bgColor={ticketStatus.color}
            >
              {ticketChannel.title}
            </TicketChannel>
          )}
        <TicketDate>
          Aberto em 
          {' '}
          {moment(createdAt).format("DD/MM/YYYY")}
        </TicketDate>
      </TicketDetails>

      <TicketTitle>{title}</TicketTitle>
      <TicketDescription>
        {`${description.substring(0, 150)}...`}
      </TicketDescription>
      <TicketUsers>
        <AvatarGroup>
          <TicketAvatar src={createdBy.photoProfile} />
          {attendee ? (
            <TicketAvatar src={attendee.photoProfile} />
          ) : (
            <TicketAvatar>
              <AddIcon />
            </TicketAvatar>
          )}
        </AvatarGroup>
        <TicketUsersLabel>
          <TicketUsersName>
            <span>Solicitado por: </span>
            {createdBy.name}
          </TicketUsersName>
          <TicketUsersName>
            <span>Atendido por: </span>
            {attendee ? (
              attendee.name
            ) : (
              <TicketAssign
                clickable={!disableActions}
                onClick={e => {
                  if (disableActions) return;
                  e.stopPropagation();
                  setAttendee(id);
                }}
              >
                {disableActions ? "Não atribuído" : "Atribuir a mim"}
              </TicketAssign>
            )}
          </TicketUsersName>
        </TicketUsersLabel>
      </TicketUsers>
    </KanbanCard>
  );
};

TicketCard.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.shape(),
    attendee: PropTypes.shape(),
    ticketChannel: PropTypes.objectOf(),
    ticketStatus: PropTypes.objectOf().isRequired
  }).isRequired,
  openDetails: PropTypes.func.isRequired,
  setAttendee: PropTypes.func.isRequired,
  selectedChannel: PropTypes.number.isRequired,
  disableActions: PropTypes.bool.isRequired
};

export default TicketCard;
