import React from "react";
import PropTypes from "prop-types";
import Dialog from "../waybee-ui/Dialog";
import DialogContent from "../waybee-ui/Dialog/DialogContent";
import Loading from "./Loading";

const DialogLoading = ({ open, onClose }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="xs">
      <DialogContent>
        <Loading />
      </DialogContent>
    </Dialog>
  );
};

DialogLoading.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DialogLoading;
