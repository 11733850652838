import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";

const AclForm = ({ permissions, onChange }) => {
  const forceUpdate = React.useReducer(() => ({}))[1];

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      permissions: []
    }
  });

  const handleChange = (field, value) => {
    setValue(field, value);
    onChange(getValues());
    forceUpdate();
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={4}>
        <TextField
          fullWidth
          {...register("name")}
          onChange={e => handleChange("name", e.target.value)}
          placeholder="name"
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          fullWidth
          {...register("description")}
          onChange={e => handleChange("description", e.target.value)}
          placeholder="description"
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          fullWidth
          {...register("counterpart")}
          onChange={e => handleChange("counterpart", e.target.value)}
          placeholder="counterpart"
        />
      </Grid>
      <Grid xs={6}>
        <FormControl fullWidth>
          <InputLabel>Permissões</InputLabel>
          <Select
            {...register("permissions")}
            value={getValues("permissions")}
            multiple
            onChange={e => handleChange("permissions", e.target.value)}
            input={<Input />}
            renderValue={selected => (
              <div>
                {selected.map(value => {
                  const permission = permissions.find(p => {
                    return p.id === value;
                  });
                  return (
                    <Chip
                      key={value}
                      label={`
                      ${permission.action} - ${permission.subject} - ${permission.module}
                    `}
                    />
                  );
                })}
              </div>
            )}
          >
            {permissions.map(permission => (
              <MenuItem key={permission.id} value={permission.id}>
                {`${permission.action} - ${permission.subject} - ${permission.module}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

AclForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  permissions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AclForm;
