import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Grid } from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";

const Container = styled.div`
  background-color: white;
  overflow: hidden;
`;

const HelloText = styled.p`
  margin: 0;
  font-size: 48px;
  font-weight: 300;
  color: ${({ color }) => color};
  line-height: normal;
  @media (max-width: 1440px) {
    font-size: 36px;
  }
`;
const SupportText = styled.p`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.46;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
`;
const HighlightText = styled.span`
  color: ${({ color }) => color};
`;

const BannerImage = styled.img`
  height: 100%;
  max-width: 100%;
`;

const Banner = ({ theme, userName }) => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={7}>
          <Box p={3}>
            <HelloText color={theme.palette.primary.main}>
              Olá,
              <br />
              <strong>
                {userName}
                .
              </strong>
            </HelloText>
            <SupportText>
              Bem-vindo(a) à
              {" "}
              <HighlightText color={theme.palette.primary.main}>
                Central de Comunicados!
              </HighlightText>
              {" "}
              Envie comunicados, e-mails e mensagens.
            </SupportText>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <BannerImage
            src={theme.overrides.assets.home}
            alt="Arte de um notebook e alguns livros"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

Banner.propTypes = {
  userName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Inject by withTheme
};

export default withTheme(Banner);
