import Access from "./Access";

const setUserSchoolName = value => {
  localStorage.setItem("userSchoolName", value);
};

const getUserSchoolName = () => {
  return localStorage.getItem("userSchoolName");
};

const setUserEmail = value => {
  localStorage.setItem("userEmail", value);
};

const getUserEmail = () => {
  return localStorage.getItem("userEmail");
};

const setUserPhoto = value => {
  localStorage.setItem("userPhoto", value);
};

const getUserPhoto = () => {
  return localStorage.getItem("userPhoto");
};

const setUserId = value => {
  localStorage.setItem("userId", value);
};

const getUserId = () => {
  return localStorage.getItem("userId");
};

const setUserSchoolId = value => {
  localStorage.setItem("userSchoolId", value);
};

const getUserSchoolId = () => {
  return localStorage.getItem("userSchoolId");
};

const setUserCampusId = value => {
  localStorage.setItem("userCampusId", value);
};

const getUserCampusId = () => {
  return localStorage.getItem("userCampusId");
};

const setUserName = value => {
  localStorage.setItem("userName", value);
};

const getUserName = () => {
  return localStorage.getItem("userName");
};

const setToken = value => {
  localStorage.setItem("token", value);
};

const getToken = () => {
  return localStorage.getItem("token");
};

const getAccess = () => {
  return Access[getUserCampusId()] || {};
};

const clear = () => {
  localStorage.clear();
};

const UserStore = {
  setUserSchoolName,
  getUserSchoolName,
  setUserEmail,
  getUserEmail,
  setUserPhoto,
  getUserPhoto,
  setUserId,
  getUserId,
  setUserSchoolId,
  getUserSchoolId,
  setUserCampusId,
  getUserCampusId,
  setUserName,
  getUserName,
  setToken,
  getToken,
  getAccess,
  clear
};

export default UserStore;
