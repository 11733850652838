import React from "react";
import PropTypes from "prop-types";
import {
  AccessTime as ClockIcon,
  Close as CloseIcon
} from "@material-ui/icons";
import { Grid, withTheme } from "@material-ui/core";
import styled from "styled-components";

const Status = styled(Grid)`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
`;
const StatusLabel = styled.p`
  margin: 0 0 0 14px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

const AccessionStatus = ({ status, theme }) => {
  let StatusIcon;
  let statusLabel;
  let statusColor;

  switch (status) {
    case "USER_NOT_REGISTERED":
      StatusIcon = CloseIcon;
      statusLabel = "Contato não cadastrado";
      statusColor = theme.palette.error.main;
      break;
    case "EMAIL_NOT_ACTIVATED":
      StatusIcon = ClockIcon;
      statusLabel = "E-mail não ativado";
      statusColor = theme.palette.secondary.main;
      break;
    case "PENDING_REGISTER":
      StatusIcon = ClockIcon;
      statusLabel = "Cadastro pendente de aprovação";
      statusColor = theme.palette.secondary.main;
      break;
    case "CANCELED_REGISTER":
      StatusIcon = CloseIcon;
      statusLabel = "Cadastro recusado";
      statusColor = theme.palette.error.main;
      break;
    case "COMPLETE":
      StatusIcon = ClockIcon;
      statusLabel = "Cadastro Completo";
      statusColor = theme.palette.primary.main;
      break;
    case "DELETED":
      StatusIcon = CloseIcon;
      statusLabel = "Usuário deletado";
      statusColor = theme.palette.error.main;
      break;
    case "INACTIVE":
      StatusIcon = CloseIcon;
      statusLabel = "Usuário inativo";
      statusColor = theme.palette.error.main;
      break;
    default:
      StatusIcon = CloseIcon;
      statusLabel = "Status Inválido";
      statusColor = theme.palette.error.main;
  }

  return (
    <Status item container justify="flex-end" xs={3} color={statusColor}>
      <StatusIcon />
      <StatusLabel>{statusLabel}</StatusLabel>
    </Status>
  );
};

AccessionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // inject by withTheme
};

export default withTheme(AccessionStatus);
