import { api } from "../utils/api";
import BugsnagService from "./BugsnagService";

const uploadAttachment = (file, { uuid }) => {
  const formData = new FormData();
  formData.append("fileToUpload", file);
  formData.append("customFileName", file.name);
  formData.append("communicationUUID", uuid);

  return api.post("Communications/uploadAttachment", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const uploadActivityAttachment = async (file, { uuid }) => {
  const formData = new FormData();
  formData.append("fileToUpload", file);
  formData.append("customFileName", file.name);
  formData.append("uuid", uuid);

  return api.post("Activities/uploadAttachment", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const uploadCampaignAttachment = (file, { campaignUUID }) => {
  const formData = new FormData();
  formData.append("fileToUpload", file);
  formData.append("filename", file.name);
  formData.append("campaignUUID", campaignUUID);

  return api.post(`Campaigns/attachment`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const uploadCoverImage = (file, { uuid }) => {
  const formData = new FormData();
  formData.append("fileToUpload", file);
  formData.append("communicationUUID", uuid);

  return api.post("Communications/uploadCoverImage", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const deleteAttachment = attachmentId => {
  return api
    .delete(`Communications/deleteAttachment/${attachmentId}`)
    .catch(err => {
      BugsnagService.notifyError(
        err,
        `Erro ao deletar o anexo ${attachmentId}.`
      );
    });
};

const deleteActivityAttachment = async attachmentId => {
  return api.delete(`Activities/deleteAttachment/${attachmentId}`);
};

const deleteCampaignAttachment = attachmentId => {
  return api.delete(`Campaigns/attachment/${attachmentId}`).catch(err => {
    BugsnagService.notifyError(err, `Erro ao deletar o anexo ${attachmentId}.`);
  });
};

const FileInputService = {
  uploadAttachment,
  uploadCoverImage,
  deleteAttachment,
  uploadCampaignAttachment,
  deleteCampaignAttachment,
  uploadActivityAttachment,
  deleteActivityAttachment
};

export default FileInputService;
