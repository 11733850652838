import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Typography
} from "@material-ui/core";
import styled from "styled-components";
import SchoolGroupService from "../../services/SchoolGroupService";
import Button from "../../waybee-ui/Button";
import ReceiversSelector from "./components/ReceiversSelector";
import ReceiversList from "./components/ReceiversList";
import MuiCollapseGroup from "../../waybee-ui/CollapseGroup/CollapseGroup";

const CollapseGroup = styled(MuiCollapseGroup)`
  margin: 0 -20px;
`;

class ModalConfirm extends Component {
  state = {
    isLoading: false
  };

  loadStudents = async () => {
    const { setSubscribers, employeeGroups } = this.props;
    this.setState({ isLoading: true });

    const employeeGroupIds = employeeGroups.map(group => group.CODIGO);
    if (employeeGroupIds.length) {
      const employees = await SchoolGroupService.getEmployeesByGroupIds(
        employeeGroupIds
      );

      const filteredEmployee = employees.map(employee => {
        return {
          name: employee.NOME_COMPLETO,
          id: employee.CODIGO,
          checked: true
        };
      });
      setSubscribers("employeeSubscribers", filteredEmployee);
    }

    this.setState({ isLoading: false });
  };

  handleOpen = () => {
    const { employeeSubscribers } = this.props;
    if (employeeSubscribers.length === 0) {
      this.loadStudents();
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleSubmit = () => {
    const { onConfirm } = this.props;
    onConfirm();
  };

  render() {
    const { isLoading } = this.state;
    const { isOpen, textAction, employeeSubscribers } = this.props;

    const checkedEmployees = employeeSubscribers.filter(e => e.checked);
    const removedEmployees = employeeSubscribers.filter(e => !e.checked);

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        onEnter={this.handleOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle onClose={this.handleClose}>
          {`${textAction} E-mail`}
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Verifique os destinatários deste e-mail antes de envia-lo
            </Typography>
          </Box>

          {checkedEmployees.length > 0 && (
            <CollapseGroup divider={false} fontSize={16} title="Contribuidores">
              <ReceiversSelector
                users={checkedEmployees}
                disabled
                isLoading={isLoading}
              />
            </CollapseGroup>
          )}

          {removedEmployees.length > 0 && (
            <Box my={1} mb={3} mt={2}>
              <FormLabel>Destinatários retirados da comunicação</FormLabel>
              {removedEmployees.length > 0 && (
                <CollapseGroup
                  divider={false}
                  fontSize={16}
                  title="Contribuidores"
                >
                  <ReceiversList users={removedEmployees} />
                </CollapseGroup>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleClose}
            color="secondary"
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleSubmit}
            disabled={checkedEmployees.length === 0}
            color="primary"
          >
            {textAction}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalConfirm.propTypes = {
  employeeSubscribers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSubscribers: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  employeeGroups: PropTypes.arrayOf(
    PropTypes.shape({
      CODIGO: PropTypes.string
    })
  ).isRequired,
  textAction: PropTypes.string.isRequired
};

export default ModalConfirm;
