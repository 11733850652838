import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Divider, Typography, withTheme } from "@material-ui/core";
import {
  Smartphone,
  ChangeHistory as TriangleIcon,
  Settings as SettingsIcon,
  LocalPhone,
  Edit as EditIcon,
  Send as SendIcon,
  Link as LinkIcon,
  Check as CheckIcon,
  CheckCircleOutline as CheckCircleIcon,
  Close as CloseIcon,
  InfoOutlined as InfoIcon
} from "@material-ui/icons";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { withAlert } from "react-alert";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "@moment";
import BackButton from "../../components/BackButton";
import Group from "../../waybee-ui/Group";
import ResponsibleItem from "../../components/AccessionResponsibleItem";
import StudentItem from "../../components/AccessionStudentItem";
import Heading from "../../waybee-ui/Heading";
import Button from "../../waybee-ui/Button";
import DialogConfirm from "../../components/DialogConfirm";
import DialogCopy from "./components/DialogCopy";
import DialogEdit from "./components/DialogEdit";
import AccessionService from "../../services/AccessionService";
import Loading from "../../components/Loading";
import DialogLoading from "../../components/DialogLoading";
import DialogInfo from "./components/DialogInfo";
import { setAccessionResponsible } from "../../redux/actions";
import { AbilityContext } from "../../acl/can";

const EditButton = styled(Button)`
  margin-left: auto;

  p {
    margin: 0 0 0 15px;
  }
`;
const InfoLabel = styled.p`
  margin: 0;
  padding: 20px 0 2px;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
`;
const InfoValue = styled.p`
  margin: 8px 0 0;
  font-size: 18px;
  line-height: 20px;
  color: ${({ color }) => color || "black"};
`;

const PhoneIcon = styled(LocalPhone)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 8px;
`;

const ActionButton = styled(Button)`
  display: flex;
  margin: 25px 0;

  p {
    margin: 0 0 0 12px;
  }
`;

const ValidateTitle = styled.p`
  margin: 8px 0;
  font-size: 18px;
  font-weight: 500;
  color: ${({ color }) => color};
`;

class AccessionActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingUser: false,
      user: null,
      openLinkEmail: false,
      linkEmail: "",
      openDialog: false,
      linkPassword: "",
      loadingDialog: false,
      deviceSubscription: null
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Painel de Adesão");

    this.getUser();
  }

  getUser = async () => {
    this.setState({ loadingUser: true });
    const {
      accessionResponsible: { waybeeId, status }
    } = this.props;

    if (status === "USER_NOT_REGISTERED") {
      this.setState({ loadingUser: false });
      return;
    }

    try {
      const user = await AccessionService.getResponsibleDetails(waybeeId);
      this.setState({ user }, this.getInstallation);
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao buscar dados de adesão"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingUser: false });
    }
  };

  getInstallation = async () => {
    const { context } = this;
    const {
      accessionResponsible: { waybeeId }
    } = this.props;

    try {
      if (context.can("use module", "installation")) {
        const installation = await AccessionService.getInstallationDetails(
          waybeeId
        );

        if (installation) {
          this.setState({
            deviceSubscription: {
              lastActive: installation.last_active,
              subscriptions: installation.subscriptions
            }
          });
        }
      }
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      const message = "Erro ao buscar dados do dispositivo do usuário";
      alert.show(message, { title: "Erro" });
    }
  };

  sendConfirmationEmail = async () => {
    const {
      alert,
      accessionResponsible: { waybeeId }
    } = this.props;

    this.setState({ loadingDialog: true });

    try {
      await AccessionService.sendConfirmationEmail(waybeeId);
      this.setState({ openDialog: false });
      alert.show(`E-mail de confirmação reenviado com sucesso`, {
        title: "Sucesso"
      });
    } catch (e) {
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao enviar email de confirmação"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingDialog: false });
    }
  };

  sendPasswordEmail = async () => {
    const {
      alert,
      accessionResponsible: { waybeeId }
    } = this.props;

    this.setState({ loadingDialog: true });

    try {
      await AccessionService.sendPasswordEmail(waybeeId);
      this.setState({ openDialog: false });
      alert.show(`E-mail de restauração de senha enviado com sucesso`, {
        title: "Sucesso"
      });
    } catch (e) {
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao enviar email de restauração de senha"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingDialog: false });
    }
  };

  getPasswordLink = async () => {
    const {
      alert,
      accessionResponsible: { waybeeId }
    } = this.props;

    this.setState({ loadingDialog: true });

    try {
      const link = await AccessionService.getPasswordLink(waybeeId);
      this.setState({ openDialog: "openLinkPassword", linkPassword: link });
    } catch (e) {
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao gerar link de restauração de senha"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingDialog: false });
    }
  };

  getEmailLink = async () => {
    const {
      alert,
      accessionResponsible: { waybeeId }
    } = this.props;

    this.setState({ loadingDialog: true });
    try {
      const link = await AccessionService.getEmailLink(waybeeId);
      this.setState({ openLinkEmail: true, linkEmail: link });
    } catch (e) {
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao gerar link de confirmação de email"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingDialog: false });
    }
  };

  sendEdit = async data => {
    const {
      alert,
      accessionResponsible: { waybeeId }
    } = this.props;

    this.setState({ loadingDialog: true });

    try {
      await AccessionService.editUser(waybeeId, data);
      this.setState({ openDialog: false }, this.getUser);
      alert.show(`Dados editados com sucesso`, {
        title: "Sucesso"
      });
    } catch (e) {
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao editar dados do usuário"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingDialog: false });
    }
  };

  changeStatus = async status => {
    const { alert, accessionResponsible, setResponsible } = this.props;

    this.setState({ loadingDialog: true });

    try {
      await AccessionService.changeStatus(
        accessionResponsible.waybeeId,
        status
      );
      this.setState({ openDialog: false }, this.getUser);
      alert.show(
        `Cadastro ${status === "CANCELED" ? "reprovado" : "aprovado"}.`,
        {
          title: "Sucesso"
        }
      );

      accessionResponsible.status =
        status === "CANCELED" ? "CANCELED_REGISTER" : "COMPLETE";
      setResponsible(accessionResponsible);
    } catch (e) {
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao eidtar status do usuário"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingDialog: false });
    }
  };

  clickDependent = studentId => {
    const { history } = this.props;
    history.push(`/accession-panel/students/${studentId}`);
  };

  render() {
    const { context } = this;
    const { theme, accessionResponsible } = this.props;
    const {
      loadingUser,
      user,
      openLinkEmail,
      linkEmail,
      openDialog,
      linkPassword,
      loadingDialog,
      deviceSubscription
    } = this.state;

    return (
      <Grid container>
        <Grid xs={12}>
          <BackButton to="/accession-panel/responsibles" />
        </Grid>
        <Grid item xs={12}>
          <Group>
            {loadingUser ? (
              <Loading />
            ) : (
              <Box margin="-30px">
                {accessionResponsible && (
                  <>
                    <ResponsibleItem
                      user={accessionResponsible}
                      photoSize={100}
                      hideDependents
                    />
                    {accessionResponsible.dependents.map(dependent => (
                      <StudentItem
                        user={dependent}
                        onClick={() => this.clickDependent(dependent.id)}
                      />
                    ))}
                  </>
                )}
              </Box>
            )}
          </Group>
        </Grid>
        {!loadingUser &&
          user &&
          accessionResponsible &&
          accessionResponsible.status !== "USER_NOT_REGISTERED" &&
          accessionResponsible.status !== "PENDING_REGISTER" &&
          accessionResponsible.status !== "CANCELED_REGISTER" &&
          accessionResponsible.status !== "DELETED" && (
            <>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Group>
                    <Heading level={2} icon={TriangleIcon} gutterBottom>
                      Detalhes do responsável
                      <EditButton
                        color="secondary"
                        onClick={() =>
                          this.setState({ openDialog: "openEdit" })
                        }
                      >
                        <EditIcon />
                        <p>Editar Detalhes</p>
                      </EditButton>
                    </Heading>
                    <Box mt={1}>
                      <InfoLabel>Nome no app</InfoLabel>
                      <InfoValue>{user.name}</InfoValue>

                      <InfoLabel>CPF</InfoLabel>
                      <InfoValue>{user.documentNumber}</InfoValue>

                      <InfoLabel>E-mail cadastrado no app</InfoLabel>
                      <InfoValue>{user.email}</InfoValue>

                      <InfoLabel>Número de contato</InfoLabel>
                      <InfoValue>
                        <PhoneIcon />
                        {user.phone}
                      </InfoValue>

                      <InfoLabel>Data de cadastro do usuário</InfoLabel>
                      <InfoValue>
                        {moment(user.joinDate).format("DD/MM/YYYY HH:mm:SS")}
                      </InfoValue>
                    </Box>
                  </Group>
                </Box>
              </Grid>
              {context.can("use module", "installation") && (
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Group>
                      <Heading level={2} icon={Smartphone} gutterBottom>
                        Detalhes da instalação
                      </Heading>
                      {deviceSubscription ? (
                        <Box mt={1}>
                          <InfoLabel>Última sessão</InfoLabel>
                          <InfoValue>
                            {moment(deviceSubscription.lastActive).format(
                              "DD/MM/YYYY HH:mm:SS"
                            )}
                          </InfoValue>
                          {deviceSubscription.subscriptions.map(
                            (subscription, index) => (
                              <>
                                <InfoLabel>Aparelho cadastrado</InfoLabel>
                                <InfoValue>
                                  {subscription.deviceModel}
                                </InfoValue>

                                <InfoLabel>Status da notificação</InfoLabel>
                                <InfoValue>
                                  {subscription.enabled
                                    ? "Ativado"
                                    : "Desativado"}
                                </InfoValue>

                                <InfoLabel>Versão do app</InfoLabel>
                                <InfoValue>{subscription.appVersion}</InfoValue>

                                {deviceSubscription.subscriptions.length > 0 &&
                                  index !==
                                    deviceSubscription.subscriptions.length -
                                      1 && (
                                      <Divider style={{ marginTop: "20px" }} />
                                  )}
                              </>
                            )
                          )}
                        </Box>
                      ) : (
                        <Box mt={1}>
                          <Typography>
                            Nenhum detalhe de instalação encontrado
                          </Typography>
                        </Box>
                      )}
                    </Group>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box mt={1.2}>
                  <Group>
                    <Heading level={2} icon={SettingsIcon} gutterBottom>
                      Ações na área do responsável
                    </Heading>

                    <Box mt={3}>
                      <ActionButton
                        color="secondary"
                        onClick={() =>
                          this.setState({ openDialog: "openConfirmEmail" })
                        }
                      >
                        <SendIcon />
                        <p>Reenviar e-mail de confirmação</p>
                      </ActionButton>
                      <ActionButton
                        color="secondary"
                        onClick={this.getEmailLink}
                      >
                        <LinkIcon />
                        <p>Gerar link de confirmação do e-mail</p>
                      </ActionButton>
                      <ActionButton
                        color="secondary"
                        onClick={() =>
                          this.setState({ openDialog: "openConfirmPassword" })
                        }
                      >
                        <SendIcon />
                        <p>Enviar e-mail de restauração de senha</p>
                      </ActionButton>
                      <ActionButton
                        color="secondary"
                        onClick={this.getPasswordLink}
                      >
                        <LinkIcon />
                        <p>Gerar link de restauração da senha</p>
                      </ActionButton>
                    </Box>
                  </Group>
                </Box>
              </Grid>
            </>
          )}

        {!loadingUser &&
          user &&
          user.externalUser &&
          accessionResponsible &&
          accessionResponsible.status === "PENDING_REGISTER" && (
            <Grid item xs={12}>
              <Box mt={2}>
                <Group>
                  <Heading level={2} icon={CheckCircleIcon} gutterBottom>
                    Validar Cadastro
                    <EditButton
                      color="secondary"
                      onClick={() => this.setState({ openDialog: "openInfo" })}
                    >
                      <InfoIcon />
                    </EditButton>
                  </Heading>
                  <Box mt={1}>
                    <Grid container>
                      <Grid item xs={6}>
                        <ValidateTitle color={theme.palette.secondary.main}>
                          Cadastro no app
                        </ValidateTitle>

                        <InfoLabel>Nome</InfoLabel>
                        <InfoValue
                          color={
                            !user.externalUser.name ||
                            user.name.toLowerCase() !==
                              user.externalUser.name.toLowerCase()
                              ? theme.palette.error.main
                              : null
                          }
                        >
                          {user.name}
                        </InfoValue>

                        <InfoLabel>E-mail</InfoLabel>
                        <InfoValue
                          color={
                            !user.externalUser.email ||
                            user.email.toLowerCase() !==
                              user.externalUser.email.toLowerCase()
                              ? theme.palette.error.main
                              : null
                          }
                        >
                          {user.email}
                        </InfoValue>

                        <InfoLabel>CPF</InfoLabel>
                        <InfoValue>{user.documentNumber}</InfoValue>
                      </Grid>

                      <Grid item xs={6}>
                        <ValidateTitle color={theme.palette.secondary.main}>
                          Cadastro no colégio
                        </ValidateTitle>

                        <InfoLabel>Nome</InfoLabel>
                        <InfoValue
                          color={
                            !user.externalUser.name ||
                            user.name.toLowerCase() !==
                              user.externalUser.name.toLowerCase()
                              ? theme.palette.error.main
                              : null
                          }
                        >
                          {user.externalUser.name}
                        </InfoValue>

                        <InfoLabel>E-mail</InfoLabel>
                        <InfoValue
                          color={
                            !user.externalUser.email ||
                            user.email.toLowerCase() !==
                              user.externalUser.email.toLowerCase()
                              ? theme.palette.error.main
                              : null
                          }
                        >
                          {user.externalUser.email}
                        </InfoValue>

                        <InfoLabel>CPF</InfoLabel>
                        <InfoValue>
                          {user.externalUser.documentNumber}
                        </InfoValue>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.setState({
                          openDialog: "openConfirmAprovRegister"
                        })
                      }
                    >
                      <CheckIcon />
                      <p>Aprovar cadastro</p>
                    </Button>
                    <Box ml={1} component="span">
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          this.setState({
                            openDialog: "openConfirmReprovRegister"
                          })
                        }
                      >
                        <CloseIcon />
                        <p>Reprovar cadastro</p>
                      </Button>
                    </Box>
                  </Box>
                </Group>
              </Box>
            </Grid>
          )}

        <DialogConfirm
          title="Confirmar"
          message="Confirmar reenvio do e-mail de confirmação"
          open={openDialog === "openConfirmEmail"}
          onSend={this.sendConfirmationEmail}
          onClose={() => this.setState({ openDialog: "openConfirmEmail" })}
          sendText="Confirmar"
        />

        <DialogConfirm
          title="Confirmar"
          message="Confirmar envio do e-mail de restauração de senha"
          open={openDialog === "openConfirmPassword"}
          onSend={this.sendPasswordEmail}
          onClose={() => this.setState({ openDialog: false })}
          sendText="Confirmar"
        />

        <DialogConfirm
          title="Confirmar"
          message="Confirmar reprovação do cadastro. Após reprovado o usuário não poderá acessar o app e terá que relizar o cadastro novamente com os dados corretos."
          open={openDialog === "openConfirmReprovRegister"}
          onSend={() => this.changeStatus("CANCELED")}
          onClose={() => this.setState({ openDialog: false })}
          sendText="Confirmar"
        />

        <DialogConfirm
          title="Confirmar"
          message="Confirmar aprovação do cadastro. Após aprovado o usuário poderá acessar o app e ver as informações do alunos vinculados."
          open={openDialog === "openConfirmAprovRegister"}
          onSend={() => this.changeStatus("COMPLETED")}
          onClose={() => this.setState({ openDialog: false })}
          sendText="Confirmar"
        />

        <DialogCopy
          onClose={() => this.setState({ openLinkEmail: false })}
          open={openLinkEmail}
          value={linkEmail}
          title="Link de confirmação do e-mail"
        />

        <DialogCopy
          onClose={() => this.setState({ openDialog: false })}
          open={openDialog === "openLinkPassword"}
          value={linkPassword}
          title="Link de restauração da senha"
        />

        {user && (
          <DialogEdit
            onSend={this.sendEdit}
            onClose={() => this.setState({ openDialog: false })}
            user={user}
            open={openDialog === "openEdit"}
          />
        )}

        <DialogInfo
          onClose={() => this.setState({ openDialog: false })}
          open={openDialog === "openInfo"}
        />

        <DialogLoading
          onClose={() => this.setState({ loadingDialog: false })}
          open={loadingDialog}
        />
      </Grid>
    );
  }
}

AccessionActions.propTypes = {
  setTitle: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // injected by withTheme
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired, // inject by withRouter
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string
    })
  }).isRequired, // inject by withRouter
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired, // Injected by withAlert hov
  // eslint-disable-next-line react/forbid-prop-types
  accessionResponsible: PropTypes.object.isRequired, // injected by mapStateToProps
  setResponsible: PropTypes.func.isRequired // injected by mapDispatchToProps
};

const mapStateToProps = store => ({
  accessionResponsible: store.accessionState.accessionResponsible
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setResponsible: setAccessionResponsible
    },
    dispatch
  );

AccessionActions.contextType = AbilityContext;

export default compose(
  withAlert(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withTheme(withRouter(AccessionActions)));
