import React, { useEffect, memo } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import _ from "lodash";
import ColorPicker from "../../../waybee-ui/ColorPicker";

const DetailForm = ({ onChange, resetFlag, values }) => {
  const { register, getValues, setValue, reset } = useForm({
    defaultValues: {
      ...values,
      resetFlag
    }
  });

  useEffect(() => {
    if (getValues("resetFlag") !== resetFlag) {
      reset({
        ...values,
        resetFlag
      });
    }
  });

  const handleChange = (field, val) => {
    if (field) setValue(field, val);
    onChange(getValues());
  };

  const publicField = getValues("publicField");
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TextField
            {...register("name")}
            value={getValues("name") || ""}
            onChange={e => handleChange("name", e.target.value)}
            label="Nome do calendário *"
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <ColorPicker
            TextFieldProps={{
              ...register("color"),
              onChange: color => handleChange("color", color),
              value: getValues("color"),
              label: "Cor do calendário *"
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            value={getValues("description") || ""}
            onChange={e => handleChange("description", e.target.value)}
            label="Descrição"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Especificações de público *</InputLabel>
            <Select
              {...register("publicField")}
              value={publicField}
              onChange={e => handleChange("publicField", e.target.value)}
            >
              <MenuItem value="N">
                Adicionar público fixo ao calendário
              </MenuItem>
              <MenuItem value="Y">
                Calendário público / Adicionar público ao criar o evento
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

DetailForm.defaultProps = {
  values: {}
};

DetailForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
  resetFlag: PropTypes.number.isRequired
};

function areEqual(prevProps, nextProps) {
  return (
    _.isEqual(prevProps.values, nextProps.values) &&
    prevProps.resetFlag === nextProps.resetFlag
  );
}

export default memo(DetailForm, areEqual);
