import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Button } from "@material-ui/core";
import {
  AddCircleOutline,
  Announcement as AnnouncementIcon
} from "@material-ui/icons";
import { withAlert } from "react-alert";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";
import ConfirmDialog from "../../../waybee-ui/Dialog/ConfirmDialog";
import Loading from "../../../components/Loading";
import CalendarService from "../../../services/CalendarService";
import NotificationList from "../../CommunicationDetails/components/NotificationList";
import EventNotificationForm from "./EventNotificationForm";

const AddNotificationButton = styled(Button)`
  height: 80px;
  margin: 0 -30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  text-transform: none;
  font-size: 16px;
  color: #34657f;

  p {
    margin: 0 0 0 14px;
  }
`;

const EventNotifications = ({
  notifications,
  event,
  eventId,
  reload,
  alert,
  loading
}) => {
  // Modals: NEW_NOTIFICATION, EDIT_NOTIFICATION, NOTIFICATION_CANCELED, NOTIFICATION_EDITED, CONFIRM_EDIT, CONFIRM_CANCEL
  const [usedModal, setUsedModal] = useState("");
  const [selectedNotification, setSelectedNotification] = useState(undefined);

  const saveNotifications = async (notification, action) => {
    if (action === "NEW_NOTIFICATION") {
      try {
        const notificationData = notification;
        notificationData.id = eventId;

        await CalendarService.saveEventNotification(notificationData);
        setUsedModal("");
        alert.show("A notificação foi salva com sucesso!", {
          title: "Notificação Salva",
          onClose: reload
        });
      } catch (e) {
        console.error(e);
        const message = e.isAxiosError
          ? e.response.data.error.message
          : "Ocorreu um erro ao salvar a notificação!";
        alert.show(message, {
          title: "Erro ao salvar"
        });
      }
    } else {
      const notificationData = {
        ...selectedNotification,
        ...notification
      };
      setSelectedNotification(notificationData);
      setUsedModal("CONFIRM_EDIT");
    }
  };

  const editNotification = async () => {
    try {
      await CalendarService.editEventNotification(selectedNotification);
      setUsedModal("");
      alert.show("As alterações foram salvas com sucesso!", {
        title: "Notificação Editada",
        onClose: reload
      });
    } catch (e) {
      console.error(e);
      const message = e.isAxiosError
        ? e.response.data.error.message
        : "Ocorreu um erro um erro ao salvar as alterações da notificação.";
      alert.show(message, { title: "Erro ao salvar alterações" });
    }
  };

  const cancelNotification = async () => {
    try {
      const editedNotification = {
        ...selectedNotification,
        status: "CANCELED"
      };

      await CalendarService.cancelEventNotification(editedNotification.id);
      setUsedModal("");
      alert.show("A notificação foi cancelada com sucesso!", {
        title: "Notificação Cancelada",
        onClose: reload
      });
    } catch (e) {
      console.error(e);
      const message = e.isAxiosError
        ? e.response.data.error.message
        : "Ocorreu um erro um erro ao salvar as alterações da notificação.";
      alert.show(message, {
        title: "Erro ao cancelar"
      });
    }
  };

  return (
    <Group>
      <Box>
        <Heading level={2} icon={AnnouncementIcon} gutterBottom>
          Notificações do Evento
        </Heading>

        {loading ? (
          <Loading />
        ) : (
          <>
            <AddNotificationButton
              component="div"
              onClick={() => {
                setSelectedNotification(undefined);
                setUsedModal("NEW_NOTIFICATION");
              }}
            >
              <AddCircleOutline />
              <p>Nova Notificação</p>
            </AddNotificationButton>

            {notifications && (
              <NotificationList
                notifications={notifications}
                onSelectItem={item => {
                  setSelectedNotification(item);
                  setUsedModal("EDIT_NOTIFICATION");
                }}
              />
            )}
          </>
        )}

        <EventNotificationForm
          open={
            usedModal === "NEW_NOTIFICATION" ||
            usedModal === "EDIT_NOTIFICATION"
          }
          communication={event}
          onClose={() => {
            setUsedModal("");
          }}
          onSubmit={saveNotifications}
          onCancel={() => setUsedModal("CONFIRM_CANCEL")}
          notification={selectedNotification}
          action={usedModal}
        />

        <ConfirmDialog
          title="Notificação Agendada"
          message="Tem certeza que deseja salvar alterações nessa notificação?"
          onClose={() => {
            setUsedModal("");
          }}
          onCancel={() => {
            setUsedModal("EDIT_NOTIFICATION");
          }}
          onConfirm={editNotification}
          confirmBtnText="Salvar Alterações"
          cancelBtnText="Voltar"
          open={usedModal === "CONFIRM_EDIT"}
        />

        <ConfirmDialog
          title="Notificação Agendada"
          message="Tem certeza que deseja cancelar essa notificação?"
          onClose={() => {
            setUsedModal("");
          }}
          onCancel={() => {
            setUsedModal("EDIT_NOTIFICATION");
          }}
          onConfirm={cancelNotification}
          confirmBtnText="Cancelar Notificação"
          confirmBtnColor="#ec0000"
          cancelBtnText="Voltar"
          open={usedModal === "CONFIRM_CANCEL"}
        />
      </Box>
    </Group>
  );
};

EventNotifications.defaultProps = {
  loading: false
};

EventNotifications.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  notifications: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  event: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  reload: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default withAlert()(EventNotifications);
