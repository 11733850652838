import { api } from "../utils/api";

const getTicket = async ticketId => {
  const res = await api.get(`Tickets/detail/${ticketId}`);
  return res.data;
};
const getTickets = async channelId => {
  const res = await api.get("TicketChannels/tickets", {
    params: {
      channelId
    }
  });
  return res.data;
};

const changeStatus = async (ticketId, statusId) => {
  const res = await api.put(`Tickets/${ticketId}/status`, { statusId });
  return res.data;
};

const setAttendee = async (ticketId, userId) => {
  const res = await api.put(`Tickets/${ticketId}/assign`, { userId });
  return res.data;
};

const getChannels = async () => {
  const res = await api.get("TicketChannels/getChannels");
  return res.data;
};

const getEmployees = async () => {
  const res = await api.get("TicketChannels/employees");
  return res.data;
};

const getAttendees = async ticketChannelId => {
  const res = await api.get(`TicketChannels/${ticketChannelId}/attendees`);
  return res.data;
};

const getCategories = async () => {
  const res = await api.get("TicketChannelCategories/getCategories");
  return res.data;
};

const createChannel = async data => {
  return api.post("TicketChannels/createChannel", data);
};

const getStatus = async () => {
  const res = await api.get("TicketStatus/getStatuses");
  return res.data;
};

const getChannelInfo = async channelId => {
  const res = await api.get("TicketChannels/getChannelById", {
    params: {
      channelId
    }
  });

  return res.data;
};

const editChannel = async (id, data) => {
  return api.put(`TicketChannels/editChannel/${id}`, data);
};

const removeChannel = async id => {
  return api.delete(`TicketChannels/${id}`);
};

const visibilityChannel = async (id, isVisible) => {
  return api.put(`TicketChannels/${id}/visibility`, { isVisible });
};

const uploadPhoto = async file => {
  const formData = new FormData();
  formData.append("fileToUpload", file);

  const res = await api.post("TicketChannels/uploadPhoto", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return res.data;
};

const deleteAutomaticMessage = async id => {
  return api.delete(`TicketChannelAutomaticMessages/${id}`);
};

const sendMessage = async (ticketId, message) => {
  const res = await api.post(`Tickets/${ticketId}/sendMessage`, { message });
  return res.data;
};

const sendFile = async (ticketId, formData) => {
  const res = await api.post(`Tickets/${ticketId}/sendFile`, formData);
  return res.data;
};

const getShortcutMessages = async ticketChannelId => {
  const res = await api.get(
    `TicketChannelAutomaticMessages/shortcut/${ticketChannelId}`
  );
  return res.data;
};

const getEnableYearField = async () => {
  const res = await api.get(`TicketChannels/getEnableYearField`);
  return res.data;
};

export default {
  getTicket,
  getTickets,
  changeStatus,
  setAttendee,
  getChannels,
  getEmployees,
  getAttendees,
  getCategories,
  getStatus,
  createChannel,
  getChannelInfo,
  removeChannel,
  editChannel,
  uploadPhoto,
  deleteAutomaticMessage,
  sendMessage,
  sendFile,
  getShortcutMessages,
  getEnableYearField,
  visibilityChannel
};
