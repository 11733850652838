import { flow, getOr, map, get, pick } from "lodash/fp";

const renameKeys = keysMap => data => {
  return Object.keys(data).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: data[key] }
    }),
    {}
  );
};

export const normalizeUserCampuses = flow(
  getOr([], "userCampuses"),
  map(
    flow(
      get("Campus"),
      pick(["id", "name", "Schools.id", "Schools.name"]),
      renameKeys({ Schools: "school" })
    )
  )
);
