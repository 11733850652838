import Iframe from "react-iframe";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledIframe = styled(Iframe)`
  height: 100%;
  width: 100%;
`;

const IFrame = ({ url }) => {
  return <StyledIframe url={url} />;
};

IFrame.propTypes = {
  url: PropTypes.string.isRequired
};

export default IFrame;
