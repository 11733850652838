import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import Button from "../../waybee-ui/Button";

class ModalConfirm extends Component {
  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleSubmit = () => {
    const { onConfirm } = this.props;
    onConfirm();
  };

  render() {
    const { isOpen, textAction, alert } = this.props;

    return (
      <Dialog onClose={this.handleClose} open={isOpen} maxWidth="md">
        <DialogTitle onClose={this.handleClose}>{`${textAction}`}</DialogTitle>
        <DialogContent dividers>
          <Box mt={1}>
            {alert}
            <Typography variant="subtitle1">
              Tem certeza que deseja alterar o conteúdo desta comunicação?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleClose}
            color="secondary"
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={this.handleSubmit}
            color="primary"
          >
            {textAction}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  textAction: PropTypes.string.isRequired,
  alert: PropTypes.func.isRequired
};

export default ModalConfirm;
