import { api } from "../utils/api";

const getStudentInfo = async userId => {
  const res = await api.get("WaybeeUsers/getStudentInfo", {
    params: { userId }
  });
  return res;
};

export default {
  getStudentInfo
};
