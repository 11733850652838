import styled from "styled-components";

const HeaderInfoTitle = styled.p`
  margin: 9px 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #00303b;
  text-transform: uppercase;
`;

export default HeaderInfoTitle;
