import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Table as MuiTable, TableBody } from "@material-ui/core";
import moment from "@moment";
import TableListItem from "../../../waybee-ui/TableList/TableListItem";

const Table = styled(MuiTable)`
  width: calc(100% + 60px);
  max-height: calc(100vh - 430px);
  margin: 0 -30px -20px;
  border: solid #f2f2f2;
  border-width: 2px 0 2px 0;
`;

const NotificationList = ({ notifications, onSelectItem }) => {
  return (
    <Box>
      <Table aria-label="simple table">
        <TableBody>
          {notifications.map(notification => {
            let notificationDate = "";
            if (notification.schedule) {
              notificationDate = moment(notification.schedule).format(
                "[Agendada para] DD/MM/YYYY [às] HH:mm"
              );
            } else {
              notificationDate = moment(notification.created_at).format(
                "[Enviado em] DD/MM/YYYY [às] HH:mm"
              );
            }
            const listItem = {
              title: notification.title,
              info: notificationDate
            };

            const statusObj = {
              color: "",
              icon: "",
              text: ""
            };
            switch (notification.status) {
              case "SENT":
                statusObj.color = "#00b297";
                statusObj.icon = "check_circle_outline";
                statusObj.text = "Notificação Enviada";
                break;
              case "CANCELED":
                statusObj.color = "#ec0000";
                statusObj.icon = "highlight_off";
                statusObj.text = "Notificação Cancelada";
                break;
              case "PENDING":
                statusObj.color = "rgba(0,0,0,.5)";
                statusObj.icon = "access_time";
                statusObj.text = "Notificação Agendada";
                break;
              case "ERROR":
                statusObj.color = "#ec0000";
                statusObj.icon = "highlight_off";
                statusObj.text = "Erro ao Enviar";
                break;
              default:
                statusObj.color = "rgba(0,0,0,.5)";
                statusObj.icon = "highlight_off";
                statusObj.text = "Status Indefinido";
                break;
            }

            return (
              <TableListItem
                key={notification.id}
                statusText={statusObj.text}
                statusIcon={statusObj.icon}
                statusColor={statusObj.color}
                highlight={notification.status === "PENDING"}
                item={listItem}
                onSelectitem={
                  notification.status === "PENDING" &&
                  (() => onSelectItem(notification))
                }
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

NotificationList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  notifications: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired
};

export default NotificationList;
