import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { People as PeopleIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading";
import StatisticSubscriber from "../../../components/StatisticSubscriber";
import Loading from "../../../components/Loading";

const GroupSubscribers = ({
  hasLinkButton,
  isEvent,
  isAckRequired,
  destinations,
  sent,
  destinationFilter,
  isSingleRecipient,
  loading
}) => {
  const getUserType = type => {
    switch (type) {
      case "student":
        return "Aluno";
      case "employee":
        return "Funcionário";
      default:
        return "Usuário";
    }
  };

  const headers = ["Recebeu", "Abriu"];
  if (hasLinkButton) headers.push("Botão de link");
  if (isEvent) {
    headers.push("Interessado");
    headers.push("Confirmado");
  }
  if (isAckRequired) headers.push("Ciente");

  const subscribers = destinations.map(destination => {
    const totalInteractions = destination.statistics.totalBoundInteractions;

    // Header 'Recebeu'
    const columns = [
      {
        type: "check",
        value: true
      }
    ];

    if (isSingleRecipient) {
      // Header 'Abriu'
      columns.push({
        type: "check",
        value: destination.statistics.opened
      });

      // Header Botão de Link
      if (hasLinkButton)
        columns.push({
          type: "check",
          value: destination.statistics.clicked
        });

      if (isEvent) {
        // Header Interessado
        columns.push({
          type: "check",
          value: destination.statistics.interested
        });

        // Header 'COnfirmado'
        columns.push({
          type: "check",
          value: destination.statistics.confirmed
        });
      }

      // Header Ciência
      if (isAckRequired)
        columns.push({
          type: "check",
          value: destination.statistics.acked
        });
    } else {
      // Header 'Abriu'
      columns.push({
        type: "numeric",
        value: totalInteractions,
        settings: {
          popover: destination.statistics.opened
        }
      });

      // Header Botão de Link
      if (hasLinkButton)
        columns.push({
          type: "numeric",
          value: totalInteractions,
          settings: {
            popover: destination.statistics.clicked
          }
        });

      if (isEvent) {
        // Header Interessado
        columns.push({
          type: "numeric",
          value: totalInteractions,
          settings: {
            popover: destination.statistics.interested
          }
        });

        // Header 'COnfirmado'
        columns.push({
          type: "numeric",
          value: totalInteractions,
          settings: {
            popover: destination.statistics.confirmed
          }
        });
      }

      // Header Ciência
      if (isAckRequired)
        columns.push({
          type: "numeric",
          value: totalInteractions,
          settings: {
            popover: destination.statistics.acked
          }
        });
    }

    return {
      id: destination.id,
      name: destination.name,
      photo: destination.photo,
      description: getUserType(destination.type),
      columns
    };
  });

  return (
    <Group>
      <Heading level={2} icon={PeopleIcon} gutterBottom>
        Destinatários da comunicação
      </Heading>

      {loading ? (
        <Loading />
      ) : (
        <Box py={2}>
          {sent ? (
            <StatisticSubscriber
              subscribers={subscribers}
              headers={headers}
              isStudent={!isSingleRecipient}
            />
          ) : (
            destinationFilter && (
              <Fragment>
                {destinationFilter.students && (
                  <>
                    <Typography variant="subtitle1">Alunos</Typography>
                    <Typography variant="body1">
                      {`Cursos: ${destinationFilter.students.courses
                        .map(e => e.NOME)
                        .join(", ")}`}
                    </Typography>
                    <Typography variant="body1">
                      {`Turmas: ${destinationFilter.students.classes
                        .map(e => e.NOME)
                        .join(", ")}`}
                    </Typography>
                  </>
                )}
                {destinationFilter.employees && (
                  <>
                    <Typography variant="subtitle1">
                      Professores/Coordenadores
                    </Typography>
                    <Typography variant="body1">
                      {`Cursos: ${destinationFilter.employees.courses
                        .map(e => e.NOME)
                        .join(", ")}`}
                    </Typography>
                    <Typography variant="body1">
                      {`Turmas: ${destinationFilter.employees.classes
                        .map(e => e.NOME)
                        .join(", ")}`}
                    </Typography>
                    <Typography variant="body1">
                      {`Cargos: ${destinationFilter.employees.types
                        .map(e => e.NOME)
                        .join(", ")}`}
                    </Typography>
                  </>
                )}
              </Fragment>
            )
          )}
        </Box>
      )}
    </Group>
  );
};

GroupSubscribers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  destinations: PropTypes.array,
  isEvent: PropTypes.bool,
  isAckRequired: PropTypes.bool,
  hasLinkButton: PropTypes.bool,
  sent: PropTypes.bool,
  destinationFilter: PropTypes.shape({
    isResponsible: PropTypes.bool.isRequired,
    students: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      courses: PropTypes.array.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      classes: PropTypes.array.isRequired
    }),
    employees: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      courses: PropTypes.array.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      classes: PropTypes.array.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      types: PropTypes.array.isRequired
    }),
    communicationRecipients: PropTypes.string.isRequired
  }).isRequired,
  isSingleRecipient: PropTypes.bool,
  loading: PropTypes.bool
};

GroupSubscribers.defaultProps = {
  isEvent: false,
  isAckRequired: true,
  hasLinkButton: true,
  sent: true,
  isSingleRecipient: false,
  destinations: [],
  loading: false
};

export default GroupSubscribers;
