import React, { Component } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-autosize-textarea";
import styled from "styled-components";
import {
  AttachFile as AttachIcon,
  InsertEmoticon as EmojiIcon,
  Send as SendIcon
} from "@material-ui/icons";
import { CircularProgress, IconButton, withTheme } from "@material-ui/core";
import { Picker } from "emoji-mart";
import { withAlert } from "react-alert";
import Compressor from "compressorjs";

const ContainerInput = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
`;
const MessageInput = styled.div`
  height: 50px;
  margin: 20px 12px;
  background-color: ${({ theme }) => theme.color.background.default};
  display: flex;
  align-items: center;
  padding: 15px;
  overflow-y: auto;
  height: 100%;
`;
const EmojiPicker = styled.div`
  display: inline;
  &:hover {
    cursor: pointer;
  }
`;
const TextField = styled(TextareaAutosize)`
  border: none;
  width: 100%;
  background: none;
  flex-grow: 1;
  margin: none;
  box-shadow: 0 0 0 0;
  outline: 0;
  resize: none;

  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
`;
const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

class MessageField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmojiPicker: false
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.hidePicker);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.hidePicker);
  }

  hidePicker = e => {
    if (e.target.classList.contains("emoji-picker")) return;
    if (e.target.closest(".emoji-picker")) return;

    this.setState({ showEmojiPicker: false });
  };

  onSelectEmoji = emojiObj => {
    const { onChange, value } = this.props;

    const newText = (value || "") + emojiObj.native;
    this.setState({ showEmojiPicker: false }, () => onChange(newText));
  };

  minimizeImage = file => {
    return new Promise((resolve, reject) => {
      return new Compressor(file, {
        convertSize: 0,
        quality: 0.5,
        maxWidth: 1024,
        maxHeight: 1024,
        success: resultFile => resolve(resultFile),
        error: () => reject()
      });
    });
  };

  handleChangeFile = async e => {
    const { onSendFile, alert } = this.props;
    const file = e.target.files[0];

    if (!file) return;
    if (
      file.type.split("/")[0] === "image" &&
      file.type.split("/")[1] !== "gif"
    ) {
      this.minimizeImage(file)
        .then(minimizedFile => onSendFile(minimizedFile))
        .catch(err => {
          console.error(err);
          alert.show(`Erro ao enviar imagem`, { title: "Erro" });
        });
    } else {
      try {
        const sizeInMb = file.size / 1024 / 1024;
        if (sizeInMb > 30) {
          alert.show(`Arquivo não pode ser maior que 30Mb`, { title: "Erro" });
          return;
        }

        onSendFile(file);
      } catch (err) {
        console.error(err);
        alert.show(`Erro ao enviar arquivo`, { title: "Erro" });
      }
    }
  };

  handleKeyPress = event => {
    const { onSend, value, loading } = this.props;

    if (!event.shiftKey && event.key === "Enter" && !loading) {
      event.preventDefault();
      onSend(value);
    }
  };

  render() {
    const { showEmojiPicker } = this.state;
    const { onSend, onChange, value, loading, theme } = this.props;

    const translationObj = {
      search: "Buscar",
      clear: "Apagar",
      notfound: "Emoji não encontrado",
      categories: {
        search: "Resultados da busca",
        recent: "Usados frequentemente",
        people: "Carinhas e pessoas",
        nature: "Animais e natureza",
        foods: "Comidas e bebidas",
        activity: "Atividades",
        places: "Viagem e lugares",
        objects: "Objetos",
        symbols: "Símbolos",
        flags: "Bandeiras"
      },
      categorieslabel: "Categorias dos emojis"
    };

    return (
      <form
        onSubmit={e => {
          onSend(value);
          e.preventDefault();
        }}
      >
        <ContainerInput id="messages-bottom">
          <MessageInput>
            <IconButton size="small" disabled={loading}>
              <AttachIcon style={{ color: "rgba(0,0,0,.5)" }} />
              <FileInput type="file" onChange={this.handleChangeFile} />
            </IconButton>
            <EmojiPicker className="emoji-picker">
              {showEmojiPicker && (
                <Picker
                  onSelect={this.onSelectEmoji}
                  showPreview={false}
                  showSkinTones={false}
                  i18n={translationObj}
                  style={{ position: "absolute", bottom: 0, zIndex: 1 }}
                />
              )}
              <IconButton
                size="small"
                disabled={loading}
                onClick={() => this.setState({ showEmojiPicker: true })}
              >
                <EmojiIcon style={{ color: "rgba(0,0,0,.5)" }} />
              </IconButton>
            </EmojiPicker>

            <TextField
              onKeyDown={this.handleKeyPress}
              maxRows={8}
              placeholder="Escreva sua mensagem..."
              value={value}
              onChange={e => onChange(e.target.value)}
              // InputProps={{ disableUnderline: true }}
            />

            {loading ? (
              <CircularProgress color="primary" size={20} />
            ) : (
              <IconButton type="submit" size="small">
                <SendIcon style={{ color: theme.palette.primary.dark }} />
              </IconButton>
            )}
          </MessageInput>
        </ContainerInput>
      </form>
    );
  }
}

MessageField.defaultProps = {
  value: "",
  loading: false
};

MessageField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onSendFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // Injected by withTheme Hoc
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(withTheme(MessageField));
