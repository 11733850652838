import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Dialog as MuiDialog, Fab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Header from "./components/header";
import Content from "./components/content";
import DialogActions from "./components/dialog_actions";
import imagePhoneMock from "../../../../assets/images/celular-mock.png";

const Dialog = styled(MuiDialog)`
  .travel-course {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    text-align: center;
    background-color: ${({ theme }) => theme.color.primary.main};
    color: white;

    p {
      font-size: 16px;
    }
  }

  .journey-in-progress {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    text-align: center;
    background-color: $aquaGreen;
    color: white;

    p {
      font-size: 16px;
    }
  }

  .dialog-description {
    // iPhone 5 & 5S in portrait & landscape
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
      max-height: 60%;
    }

    // Iphone 6 portrait & landscape
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
      max-height: 69%;
    }

    // Iphone 6 plus portrait & landscape
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
      max-height: 69%;
    }

    img {
      width: 100%;
    }

    .description {
      font-size: 15px;
      padding: 5%;
    }

    .button-extra {
      position: relative;
      height: 40px;
      margin: 0 auto;
      margin-bottom: 10px;
      width: 90%;

      .button {
        position: absolute !important;
        margin-left: auto !important;
        margin-right: auto !important;
        left: 0 !important;
        right: 0 !important;
      }
    }

    .event-detail {
      height: 55px;
      position: relative;

      .date-container {
        width: 20%;
        border-right: solid 1px;

        .date {
          text-align: center;
          margin-bottom: 0;
          font-size: 13px;
          font-weight: bold;
          color: ${({ theme }) => theme.color.primary.dark};
        }

        .month {
          text-align: center;
          margin-top: 0;
          color: ${({ theme }) => theme.color.primary.dark};
        }
      }

      .location-container {
        position: absolute;
        top: -6%;
        left: 24%;
        color: ${({ theme }) => theme.color.primary.dark};

        p {
          position: absolute;
          top: -12px;
          left: 97%;
          width: 150px;
          margin-left: 7px;
          font-size: 12px;
          margin-top: 84%;
        }
      }

      .schedule-container {
        position: absolute;
        top: 35%;
        left: 24%;
        color: ${({ theme }) => theme.color.primary.dark};

        p {
          position: absolute;
          top: -7px;
          left: 100%;
          width: 150px;
          margin-left: 7px;
          font-size: 12px;
          color: ${({ theme }) => theme.color.primary.dark};
        }
      }
    }

    .two-actions-container {
      position: relative;
      margin-bottom: 5%;
      padding-left: 2%;

      .interest {
        width: 50%;
        margin-top: 16px;
        margin-left: 1%;
        color: ${({ theme }) => theme.color.primary.dark};

        p {
          position: absolute;
          margin: 0;
          left: 9%;
          top: 14%;
          font-size: 12px;

          // Iphone 6 portrait & landscape
          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
            left: 10%;
            top: 14%;
            font-size: 13px;
          }

          // Iphone 6 plus portrait & landscape
          @media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
            top: 10%;
            font-size: 14px;
          }
        }
      }

      .presence {
        position: absolute;
        top: 0;
        right: 48%;
        color: ${({ theme }) => theme.color.primary.dark};

        // Iphone 6 portrait & landscape
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
          right: 47%;
        }

        p {
          position: absolute;
          top: 3px;
          margin: 0;
          font-size: 12px;
          left: 20px;
          width: 142px;

          // Iphone 6 portrait & landscape
          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
            top: 2px;
            font-size: 13px;
            width: 155px;
          }

          // Iphone 6 plus portrait & landscape
          @media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
            top: 2px;
            font-size: 14px;
            width: 170px;
          }
        }
      }
    }
  }

  .mbsc-avatar {
    border-radius: 50% !important;
    height: 50px !important;
    width: 50px !important;
  }

  .mbsc-ios.mbsc-card.mbsc-form {
    margin: 10px 10px !important;
  }

  .mbsc-card-title {
    font-size: 1em !important;
  }

  .content {
    p {
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
    }
  }

  .dialog-header {
    width: 100%;
    height: 70px;
    position: relative;

    .school-photo {
      height: 50px;
      width: 50px;
      margin: 0;
      position: absolute;
      z-index: 999;
      border-radius: 50%;
      left: 4%;
      top: 12%;
    }

    img {
      width: 100%;
      margin-top: 22%;
      max-height: 70%;
    }

    .header-title {
      position: relative;
      width: 100%;
      color: $white;
      height: 90%;

      h3 {
        font-size: 15px;
        font-weight: bold;
        position: absolute;
        top: -36%;
        left: 28%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 74%;
      }

      p {
        font-size: 12px;
        position: absolute;
        left: 28%;
      }
    }
  }

  .like {
    position: relative;
    width: 50%;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 5%;
    color: ${({ theme }) => theme.color.primary.dark};

    p {
      position: absolute;
      top: 0;
      margin: 0;
      left: 20%;
    }
  }

  .ack-dialog {
    width: 90%;
    margin: 20px !important;
    overflow: visible !important;

    .ack-dialog-header {
      margin-bottom: 10px;
      color: ${({ theme }) => theme.color.secondary.dark};
      font-size: 17px;
      font-weight: bold;
    }
    .ack-dialog-date {
      margin-top: 2px;
      margin-bottom: 2px;
      color: ${({ theme }) => theme.color.secondary.dark};
      font-size: 14px;
    }
    .ack-dialog-name {
      margin-top: 0;
      color: ${({ theme }) => theme.color.secondary.dark};
      font-size: 14px;
    }
    .ack-dialog-content {
      font-size: 15px;
      color: rgb(153, 153, 153);
      p {
        margin-top: 5px;
      }
    }
    .ack-dialog-footer {
      color: ${({ theme }) => theme.color.secondary.dark};
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    .ack-steps {
      background-color: white !important;
      width: 100%;
    }
    .ack-dialog-event {
      color: ${({ theme }) => theme.color.secondary.dark};
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .container-root-dialog {
    top: -22px !important;
    bottom: -22px !important;
    background-color: #0d0;
    overflow: visible;
  }

  .notification-container-content {
    padding: 0 !important;

    .notification-description {
      overflow-x: hidden;

      &,
      * {
        font-size: 14px !important;
        font-family: Roboto, Helvetica, sans-serif !important;
        margin: 0;
        color: #808080 !important;
      }
      p {
        margin-top: 0;
        margin-left: 0 !important;
        margin-bottom: 8px !important;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
      table {
        min-width: 100%;
      }

      .table-responsive {
        width: 100%;
        box-sizing: border-box;
        overflow-x: scroll;
      }
    }
  }

  .card-dialog {
    width: 360px;
    height: 658px;
    margin: 160px 0 0 !important;
    overflow: visible !important;
    align-self: flex-start;
    vertical-align: top;

    .content-image {
      width: 100%;
    }

    .send-button {
      color: ${({ theme }) => theme.color.primary.dark} !important;
      height: 56px !important;
    }

    .card-dialog-actions {
      height: 56px;
      margin: 0;
      padding: 0 7px;
    }

    .event-details {
      position: relative;
      padding: 4px 0;
      .date-container {
        width: 33%;
        max-width: 130px;
        border-right: solid 1px #cacaca;
        font-size: 16px;
        float: left;
        margin: 7px 0;
        p {
          margin: 0;
          text-align: center;
          color: ${({ theme }) => theme.color.primary.dark};
        }
        .date {
          font-weight: bold;
          font-size: 20px;
        }

        .month {
          font-size: 18px;
        }
      }

      .info-container {
        width: 66%;
        box-sizing: border-box;
        padding: 0 5px;
        float: right;
        color: ${({ theme }) => theme.color.primary.dark};
        margin: 4px 0;
        .location,
        .schedule {
          position: relative;
          display: block;
          height: 22px;
          p {
            width: 100%;
            float: left;
            font-size: 13.3px;
            margin: 4px 0;
          }
          svg {
            margin-bottom: -5px;
            width: 30px;
          }
        }
      }
    }

    .MuiSvgIcon-fontSizeLarge {
      font-size: 27px;
      margin-right: -4px;
    }
  }
`;

const PhoneContainer = styled.div`
  position: absolute;
  background-image: url(${imagePhoneMock});
  background-size: contain;
  background-repeat: no-repeat;
  top: -120px;
  left: -42px;
  width: 440px;
  height: 910px;
  z-index: 0;
`;

const CloseButton = styled(Fab)`
  background-color: #ec0000;
  color: #fff;
  right: -50px;
  top: -20px;
  position: absolute;

  :hover {
    background-color: #bc0000;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
`;

function DialogNotification(props) {
  const {
    open,
    notificationClicked: notification,
    schoolName,
    onClose,
    communicationAttachments,
    survey
  } = props;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="body"
      classes={{
        paper: "card-dialog",
        root: "container-root-dialog"
      }}
    >
      <PhoneContainer>
        <CloseButton size="small" aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </PhoneContainer>

      <ContentContainer>
        <Header
          createdAt={notification.createdAt}
          schoolIcon={notification.icon}
          title={notification.title}
        />
        <Content
          photo={notification.photo}
          bodyMessage={notification.bodyMessage}
          extraButtonText={notification.buttonText}
          extraButtonLinkAddress={notification.linkAddress}
          type={notification.type}
          attachments={communicationAttachments}
          eventDate={notification.eventStart}
          eventLocation={schoolName}
          survey={survey}
        />
        <DialogActions
          type={notification.type}
          hadInterest={notification.hadInterest}
          confirmedPresence={notification.confirmedPresence}
          onClickInterest={() => {}}
          onClickPresence={() => {}}
          onClickMessage={() => {}}
          chatIsEnabled={false}
        />
      </ContentContainer>
    </Dialog>
  );
}

DialogNotification.defaultProps = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  schoolName: "",
  communicationAttachments: [],
  survey: undefined
};

DialogNotification.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  notificationClicked: PropTypes.shape({
    createdAt: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string,
    photo: PropTypes.string,
    bodyMessage: PropTypes.string,
    buttonText: PropTypes.string,
    linkAddress: PropTypes.string,
    type: PropTypes.number,
    eventStart: PropTypes.string,
    hadInterest: PropTypes.bool,
    confirmedPresence: PropTypes.bool
  }).isRequired,
  schoolName: PropTypes.string,
  communicationAttachments: PropTypes.arrayOf(
    PropTypes.shape({
      customName: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.url
    })
  ),
  survey: PropTypes.shape({
    title: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.array
  })
};

export default DialogNotification;
