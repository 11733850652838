import moment from "@moment";
import { api } from "../utils/api";

export const enums = {
  status: {
    DELETED: 0,
    OPEN: 1,
    SENT: 2,
    SCHEDULED: 3
  }
};

const getData = async id => {
  const response = await api.get(`Activities/${id}/detail`).catch(() => {
    throw Error("Atividade não encontrada.");
  });

  return response.data;
};

const getAllData = (skip = 0, searchText = "", initialDate, endDate) => {
  return api
    .get("Activities/list", {
      params: {
        skip,
        searchText,
        initialDate: moment(initialDate).isValid()
          ? new Date(initialDate.setHours(0, 0, 0))
          : new Date(moment().startOf("year")),
        endDate: moment(endDate).isValid()
          ? new Date(endDate.setHours(23, 59, 59))
          : new Date()
      }
    })
    .then(response => {
      return response.data;
    });
};

const getAllDataCount = () => {
  return api.get("Activities/getCount", { params: {} }).then(response => {
    return response.data;
  });
};

// Adapt communication props to suit with api params
const addSubscribers = formValues => {
  const data = { ...formValues };

  data.destinationFilter = {};

  if (data.studentCourses.length) {
    const studentCourses = data.studentCourses.map(course => {
      return {
        CODIGO: course.CODIGO,
        NOME: course.NOME
      };
    });
    const studentClasses = data.studentClasses.map(classItem => {
      return {
        CODIGO: classItem.CODIGO,
        CODIGO_CURSO: classItem.CODIGO_CURSO,
        NOME: classItem.NOME
      };
    });

    data.destinationFilter.students = {
      courses: studentCourses,
      classes: studentClasses
    };
  }

  if (data.employeeCourses.length || data.employeeTypes.length) {
    const employeeCourses = data.employeeCourses.map(course => {
      return {
        CODIGO: course.CODIGO,
        NOME: course.NOME
      };
    });
    const employeeClasses = data.employeeClasses.map(classItem => {
      return {
        CODIGO: classItem.CODIGO,
        CODIGO_CURSO: classItem.CODIGO_CURSO,
        NOME: classItem.NOME
      };
    });
    const employeeTypes = data.employeeTypes.map(course => {
      return {
        CODIGO: course.CODIGO,
        NOME: course.NOME
      };
    });
    data.destinationFilter.employees = {
      courses: employeeCourses,
      classes: employeeClasses,
      types: employeeTypes
    };
  }

  data.allRecipients = data.subscribers.map(e => e.id);

  // Deleting remapped filters above
  delete data.subscribers;
  delete data.courses;
  delete data.classes;

  return data;
};

const getSubjects = async () => {
  const response = await api.get(`/Activities/subjects`).catch(() => {
    throw Error("Disciplinas não encontradas.");
  });

  return response.data;
};

const save = async params => {
  const data = addSubscribers({ ...params });

  try {
    const response = await api.post("/Activities/new", data);

    return response.data;
  } catch (error) {
    let errorMessage;
    if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};

const update = async (id, params) => {
  const data = addSubscribers({ ...params });

  try {
    const response = await api.put(`/Activities/${id}/edit`, data);
    return response.data;
  } catch (error) {
    let errorMessage;
    if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};

const send = async id => {
  try {
    const response = await api.post(`/Activities/${id}/send`);
    return response.data;
  } catch (error) {
    let errorMessage;
    if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};

const remove = id => {
  return api.delete(`Activities/${id}/remove`);
};

const preview = async (title, message, attachments, options) => {
  const params = {
    title,
    message,
    attachments,
    options
  };

  const response = await api.post(`Activities/preview`, params);
  return response.data;
};

const schedule = async (id, schd) => {
  try {
    const response = await api.post(`/Activities/${id}/schedule`, {
      schedule: schd
    });
    return response.data.response;
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data.error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    const responseError = { ...error, ...{ message: errorMessage } };
    throw responseError;
  }
};

const cancelSchedule = id => {
  return api.delete(`Activities/${id}/schedule`);
};

const getStatistics = id => {
  return api.get(`Activities/${id}/statistics`);
};

const getAudits = id => {
  return api.get(`Activities/${id}/getAudits`);
};

export default {
  getData,
  getAllData,
  getAllDataCount,
  send,
  save,
  update,
  remove,
  preview,
  getSubjects,
  schedule,
  cancelSchedule,
  getStatistics,
  getAudits
};
