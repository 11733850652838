import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: transparent;
  background: transparent;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & + div {
    margin-top: 8px;
  }
`;

export const ButtonText = styled.span`
  font-size: 16px;
  text-transform: none;
`;
