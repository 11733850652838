import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box
} from "@material-ui/core";
import moment from "moment";
import { History, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import HeaderInfoTitle from "./HeaderInfoTitle";

const Accordion = styled(MuiAccordion)`
  padding: 0;
  box-shadow: none;
`;
const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0;
`;
const HistoryIcon = styled(History)`
  color: #787486;
  margin-right: 8px;
`;
const HistoryList = styled.div`
  margin-bottom: 5px;
  padding-bottom: 12px;
`;
const HistoryItem = styled.div`
  margin-top: 20px;
  p {
    font-family: Roboto, sans-serif;
  }
`;
const HistoryBadge = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #00303b;
`;
const HistoryTitle = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #0d062d;
`;
const HistoryDate = styled.p`
  margin: 0 0 0 14px;
  color: #787486;
  font-size: 12px;
  font-weight: 500;
`;
const HistoryText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  color: #0d062d;
`;
const TicketDetailHistory = ({ logs }) => {
  return (
    <Box mt={1} borderBottom="2px solid #d9d9d9">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <HistoryIcon />
            <HeaderInfoTitle>Histórico da solicitação</HeaderInfoTitle>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <HistoryList>
            {logs.map(log => (
              <HistoryItem>
                <Box display="flex" alignItems="center">
                  <HistoryBadge />
                  <Box ml={1}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <HistoryTitle>{log.createdBy.name}</HistoryTitle>
                      <HistoryDate>
                        {moment(log.createdAt).format("DD/MM/YYYY HH:mm")}
                      </HistoryDate>
                    </Box>
                    <HistoryText>{log.message}</HistoryText>
                  </Box>
                </Box>
              </HistoryItem>
            ))}
          </HistoryList>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

TicketDetailHistory.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      createdAt: PropTypes.string,
      createdBy: PropTypes.shape({
        name: PropTypes.string
      })
    })
  ).isRequired
};

export default TicketDetailHistory;
