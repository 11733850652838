import React from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import withTheme from "@material-ui/core/styles/withTheme";
import PropTypes from "prop-types";

const Container = styled.div`
  padding: 16px;
`;

const Color = styled.div`
  border: 1px solid #0c0c0c;
  background-color: ${({ color }) => color};
  width: 100%;
  height: 100%;
`;

const PaletteView = ({ theme }) => {
  const { palette } = theme;
  const categories = Object.keys(theme.palette);
  return (
    <Grid>
      <Container>
        <Grid container spacing={2}>
          {categories.map(category =>
            typeof palette[category] === "object" ? (
              <Grid item xs={12} style={{ marginTop: 64 }}>
                <Typography variant="title">{category}</Typography>
                <Grid container spacing={6}>
                  {Object.keys(palette[category]).map(color => (
                    <Grid item xs={3}>
                      <p style={{ margin: 8 }}>{color}</p>
                      <Color color={palette[category][color]} />
                      <p style={{ marginTop: 8 }}>{palette[category][color]}</p>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              <p />
            )
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

PaletteView.propTypes = {
  theme: PropTypes.objectOf().isRequired // Get from withTheme()
};

export default withTheme(PaletteView);
