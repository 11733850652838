import DOMPurify from "dompurify";
import moment from "@moment";
import { api } from "../utils/api";
import UserStore from "../utils/UserStore";

const formatBodyMessage = bodyMessage => {
  const addTableContainer = htmlStr => {
    const containerTable = `<div class="table-responsive">$1</div>`;
    return htmlStr.replace(/((<table(?:.|\n)*?<\/table>))/gm, containerTable);
  };

  const formatImages = htmlStr => {
    return htmlStr.replace(/<img /g, `<img style="max-width: 100%" `);
  };

  let formattedMessage = addTableContainer(bodyMessage);
  formattedMessage = formatImages(formattedMessage);
  return DOMPurify.sanitize(formattedMessage);
};

const getCampaignDetail = async id => {
  const response = await api
    .get(`Campaigns/${id}`, {
      params: {
        filter: {
          include: ["destinataries", "creator"]
        }
      }
    })
    .catch(() => {
      throw Error("Essa Campanha não foi encontrada.");
    });

  const { data } = response;
  data.body = formatBodyMessage(data.body);

  return data;
};

export const enums = {
  status: {
    DELETED: 0,
    OPEN: 1,
    SCHEDULED: 2,
    SENT: 3
  }
};

const getCampaigns = (skip = 0, searchText = "", initialDate, endDate) => {
  const filter = {
    where: {
      createdBy: { inq: [UserStore.getUserId()] },
      status: { neq: enums.status.DELETED },
      and: [
        {
          or: [
            { title: { ilike: `%${searchText}%` } },
            { bodyMessage: { ilike: `%${searchText}%` } }
          ]
        },
        {
          createdAt: {
            gt: moment(initialDate).isValid()
              ? new Date(initialDate.setHours(0, 0, 0))
              : new Date("1900-01-01")
          }
        },
        {
          createdAt: {
            lt: moment(endDate).isValid()
              ? new Date(endDate.setHours(23, 59, 59))
              : new Date()
          }
        }
      ]
    },
    limit: 10,
    order: "createdAt DESC",
    include: ["destinataries"],
    skip,
    scope: {
      skip
    }
  };

  return api
    .get("Campaigns", { params: { filter } })
    .then(response => response.data);
};

const getCampaignsCount = () => {
  const where = {
    createdBy: { inq: [UserStore.getUserId()] },
    status: { neq: enums.status.DELETED }
  };
  return api.get("Campaigns/count", { params: { where } }).then(response => {
    return response.data.count;
  });
};

const saveCampaign = async params => {
  try {
    const response = await api.post("/Campaigns", params);
    return response.data;
  } catch (error) {
    let errorMessage;
    if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};

const updateCampaign = async (id, params) => {
  try {
    const response = await api.put(`/Campaigns/${id}`, params);
    return response.data;
  } catch (error) {
    let errorMessage;
    if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};

const sendCampaign = async campaignId => {
  try {
    const response = await api.post(`/Campaigns/${campaignId}/send`);
    return response.data;
  } catch (error) {
    let errorMessage;
    if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = "Houve um problema de comunicação com o servidor.";
    }
    throw Error(errorMessage);
  }
};

const deleteCampaign = communicationId => {
  return api.delete(`Campaigns/${communicationId}`);
};

const previewCampaign = async (
  title,
  message,
  attachments,
  campaignOptions
) => {
  const params = {
    title,
    message,
    attachments,
    campaignOptions
  };

  const response = await api.post(`Campaigns/preview`, params);
  return response.data;
};

export default {
  getCampaignDetail,
  getCampaigns,
  getCampaignsCount,
  sendCampaign,
  saveCampaign,
  updateCampaign,
  deleteCampaign,
  previewCampaign
};
