import React, { Component } from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { Provider as AlertProvider } from "react-alert";
import { ThemeProvider } from "styled-components";
import { CssBaseline } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import OneSignal from "react-onesignal";
import AppService from "./services/AppService";
import CreateMuiTheme from "./assets/theme/MuiTheme";
import BugsnagService from "./services/BugsnagService";
import AlertTemplate from "./components/AlertTemplate";
import { loadTheme } from "./redux/actions";
import "./index.css";
import "./utils/i18n";
import Loading from "./components/Loading";

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: null
    };
  }

  componentDidMount() {
    this.getTheme();
    this.initializeOneSignal();
  }

  initializeOneSignal = async () => {
    const appId = await AppService.getOneSignalAppId();
    if (!appId) {
      console.error("AppId not found");
      return;
    }
    localStorage.setItem("oneSignalAppId", appId);
    await OneSignal.init({
      appId,
      allowLocalhostAsSecureOrigin: true
    });
    await OneSignal.on("subscriptionChange", async isSubscribed => {
      if (isSubscribed) {
        await AppService.updateInstallationUser();
      }
    });
    await OneSignal.setDefaultNotificationUrl(
      `${window.location.protocol}//${window.location.host}/chat`
    );
  };

  getTheme = async () => {
    const { loadTheme: setTheme, theme } = this.props;
    try {
      const res = await AppService.getTheme();
      setTheme(res);
    } catch (e) {
      console.error(e);
    }
    this.setState({ theme });
  };

  render() {
    const { children } = this.props;
    const { theme } = this.state;

    let MuiTheme;
    if (theme) MuiTheme = CreateMuiTheme(theme);

    return (
      <>
        {theme ? (
          <BugsnagService.ErrorBoundary>
            <StylesProvider injectFirst>
              <MuiThemeProvider theme={MuiTheme}>
                <ThemeProvider theme={theme}>
                  <CssBaseline>
                    <AlertProvider template={AlertTemplate}>
                      {children}
                    </AlertProvider>
                  </CssBaseline>
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>
          </BugsnagService.ErrorBoundary>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

AppProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // injected by mapStateToProps
  children: PropTypes.node.isRequired,
  loadTheme: PropTypes.func.isRequired // injected by mapDispatchToProps
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loadTheme }, dispatch);

const mapStateToProps = state => ({
  theme: state.theme
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppProvider);
