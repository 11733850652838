import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Drawer as MuiDrawer, Hidden } from "@material-ui/core";
import SidebarHeader from "./SidebarHeader";

const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
`;

const Content = styled.div`
  width: ${props => props.size}px;
  height: 100%;
  background-color: #fff;
`;

const Sidebar = ({
  children: list,
  userName,
  userPhoto,
  direction,
  mobileOpen,
  size,
  onClose
}) => {
  const SidebarContent = () => (
    <Content size={size}>
      <SidebarHeader userName={userName} userPhoto={userPhoto} />
      {list}
    </Content>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={direction}
          open={mobileOpen}
          onClose={onClose}
          ModalProps={{
            keepMounted: true
          }}
        >
          <SidebarContent />
        </Drawer>
      </Hidden>

      <Hidden mdDown implementation="css">
        <Drawer variant="permanent" anchor={direction} open>
          <SidebarContent />
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  direction: PropTypes.string,
  size: PropTypes.number,
  mobileOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userPhoto: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired
};

Sidebar.defaultProps = {
  direction: "left",
  size: 280
};

export default Sidebar;
