import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Fab,
  Grid,
  Paper as MuiPaper,
  Popover as MuiPopover
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";
import StudentInfo from "../waybee-ui/StudentInfo/StudentInfo";
import StudentService from "../services/StudentService";

const Paper = styled(MuiPaper)`
  overflow: visible;
  width: 65%;
`;

const Popover = styled(MuiPopover)`
  overflow: visible;
`;

const CloseButton = styled(Fab)`
  background-color: #ec0000;
  color: #fff;
  right: -10px;
  top: -28px;
  position: absolute;

  :hover {
    background-color: #bc0000;
  }
`;

class StudentInfoPopover extends Component {
  state = {
    loading: false,
    student: undefined
  };

  componentDidMount() {
    const { userId } = this.props;
    this.getStudentInfo(userId);
  }

  getStudentInfo = async id => {
    this.setState({ loading: true });
    if (!id) return;
    try {
      const res = await StudentService.getStudentInfo(id);
      this.setState({ student: res.data, loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { student, loading } = this.state;

    const { userId, anchorEl, onClose, invertPosition } = this.props;

    const anchorOrigin = {
      vertical: !invertPosition ? "top" : "bottom",
      horizontal: !invertPosition ? "right" : "left"
    };

    const transformOrigin = {
      vertical: !invertPosition ? "bottom" : "top",
      horizontal: !invertPosition ? "left" : "right"
    };

    return (
      <Popover
        id={userId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        PaperProps={{ component: Paper }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <CloseButton onClick={onClose} size="medium">
          <Close />
        </CloseButton>
        {loading ? (
          <Box margin="30px">
            <CloseButton onClick={onClose} size="medium">
              <Close />
            </CloseButton>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} container spacing={2} alignItems="center">
                <Grid item>
                  <Skeleton variant="circle" width={60} height={60} />
                </Grid>
                <Grid item xs={4} container>
                  <Grid item xs container direction="column">
                    <Grid item xs>
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="text" width="35%" />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={4} container direction="column" justify="center">
                  <Skeleton variant="text" width="100%" />
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Skeleton variant="text" width="100%" />
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Skeleton variant="text" width="100%" />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="text" width="35%" />
              </Grid>
              <Grid item container direction="row" justify="space-between">
                <Skeleton variant="text" width="20%" />
                <Skeleton variant="text" width="20%" />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <StudentInfo student={student} onClose={onClose} />
        )}
      </Popover>
    );
  }
}

StudentInfoPopover.propTypes = {
  userId: PropTypes.number,
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func.isRequired,
  invertPosition: PropTypes.bool
};

StudentInfoPopover.defaultProps = {
  userId: undefined,
  anchorEl: undefined,
  invertPosition: false
};

export default StudentInfoPopover;
