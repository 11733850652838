import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import AclService from "../../services/AclService";
import Button from "../../waybee-ui/Button";

class AclUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      userRoles: [],
      values: {
        user: "",
        roles: []
      }
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Configuração de acessos para usuário de comunicação");

    this.getRoles();
    this.getUserRoles();
  }

  getRoles = async () => {
    const roles = await AclService.getRoles();
    this.setState({ roles });
  };

  getUserRoles = async () => {
    const userRoles = await AclService.getCommUserRoles();
    this.setState({ userRoles });
  };

  onChangeUser = async e => {
    const { userRoles } = this.state;

    const userId = e.target.value;
    const foundUser = userRoles.find(u => u.id === userId);

    this.setState({
      values: {
        roles: foundUser.roles ? foundUser.roles.map(r => r.id) : [],
        user: userId
      }
    });
  };

  onChangeRole = async e => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        roles: e.target.value
      }
    });
  };

  onSave = async () => {
    const { values } = this.state;
    await AclService.updateCommUserRoles(values);
  };

  render() {
    const { roles, userRoles, values } = this.state;

    return (
      <div>
        <Grid container>
          <Grid xs={6}>
            <FormControl fullWidth>
              <InputLabel>Usuários</InputLabel>
              <Select
                value={values.user}
                onChange={this.onChangeUser}
                input={<Input />}
              >
                {userRoles.map(user => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={6}>
            <FormControl fullWidth>
              <InputLabel>Perfis</InputLabel>
              <Select
                value={values.roles}
                multiple
                onChange={this.onChangeRole}
                input={<Input />}
                renderValue={selected => (
                  <div>
                    {selected.map(value => {
                      const role = roles.find(r => {
                        return r.id === value;
                      });
                      return <Chip key={value} label={role.name} />;
                    })}
                  </div>
                )}
              >
                {roles.map(role => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button color="primary" variant="contained" onClick={this.onSave}>
          Salvar
        </Button>
      </div>
    );
  }
}

AclUser.propTypes = {
  setTitle: PropTypes.func.isRequired
};

export default AclUser;
