import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

const Loading = () => {
  return (
    <LoadContainer>
      <CircularProgress />
    </LoadContainer>
  );
};

export default Loading;
