import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import styled from "styled-components";
import PopoverLabel from "../../PopoverLabel";

const ContentLabel = styled.p`
  color: black;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
`;

const NumericColumn = ({ total, popoverValues }) => {
  return (
    <>
      <PopoverLabel
        label={(
          <Label>
            {`${popoverValues ? popoverValues.length : 0}/${total}`}
          </Label>
        )}
        popoverContent={
          popoverValues &&
          popoverValues.length &&
          popoverValues.map(user => (
            <Typography>
              <FormControlLabel
                control={<Checkbox checked color="primary" disableRipple />}
                label={<ContentLabel>{user.name}</ContentLabel>}
              />
            </Typography>
          ))
        }
      />
    </>
  );
};

NumericColumn.propTypes = {
  total: PropTypes.number,
  popoverValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  )
};

NumericColumn.defaultProps = {
  total: 0,
  popoverValues: []
};

export default NumericColumn;
