import React from "react";
import PropTypes from "prop-types";
import LocationIcon from "@material-ui/icons/LocationOn";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Divider } from "@material-ui/core";
import moment from "@moment";
import { theme } from "../../../../../assets/theme/StyledTheme";

const styles = {
  listIcon: {
    height: "20px",
    width: "30px",
    color: theme.color.primary.dark,
    marginBottom: "-5px"
  },
  eventInfo: {
    position: "relative",
    padding: "4px 0"
  },
  dateContainer: {
    width: "33%",
    maxWidth: 130,
    borderRight: "solid 1px #cacaca",
    fontSize: 16,
    float: "left",
    margin: "7px 0",
    lineHeight: "normal"
  },
  dateText: {
    margin: 0,
    textAlign: "center",
    color: theme.color.primary.dark
  },
  dateDay: {
    fontWeight: "bold",
    fontSize: 20
  },
  dateMonth: {
    fontSize: 18
  },
  infoContainer: {
    width: "66%",
    boxSizing: "border-box",
    padding: "0 5px",
    float: "right",
    color: theme.color.primary.dark,
    margin: "4px 0"
  },
  infoItem: {
    position: "relative",
    display: "block",
    height: 22
  },
  infoItemText: {
    width: "100%",
    float: "left",
    fontSize: 13.3,
    margin: "4px 0"
  }
};

function EventInfo(props) {
  const { date, location } = props;

  return (
    <div style={styles.eventInfo}>
      <Divider />
      <div style={styles.dateContainer}>
        <p style={{ ...styles.dateDay, ...styles.dateText }}>
          {moment(date).format("DD")}
        </p>
        <p style={{ ...styles.dateMonth, ...styles.dateText }}>
          {moment(date)
            .format("MMM")
            .toUpperCase()}
        </p>
      </div>
      <div style={styles.infoContainer}>
        <div style={styles.infoItem}>
          <p style={styles.infoItemText}>
            <LocationIcon style={styles.listIcon} />
            {location}
          </p>
        </div>
        <div style={styles.infoItem}>
          <p style={styles.infoItemText}>
            <ScheduleIcon style={styles.listIcon} />
            {moment(date).format("HH:mm")}
          </p>
        </div>
      </div>
    </div>
  );
}

EventInfo.defaultProps = {
  date: "",
  location: ""
};

EventInfo.propTypes = {
  date: PropTypes.string,
  location: PropTypes.string
};

export default EventInfo;
