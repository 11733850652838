import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Avatar from "../Avatar";

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 90px;
  background-color: ${({ theme }) => theme.color.secondary.dark};
  padding: 15px 20px;
  display: flex;
  font-family: inherit;
`;

const TextContaner = styled.div`
  margin-left: 14px;
  width: 100%;
  margin: -10px -10px -14px 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const Text = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  display: box;
  line-clamp: 4;
  box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const SidebarHeader = ({ userPhoto, userName }) => (
  <Container>
    <Avatar size={58} src={userPhoto} />
    <TextContaner>
      <Text variant="body1">{userName}</Text>
    </TextContaner>
  </Container>
);

SidebarHeader.propTypes = {
  userPhoto: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired
};

export default SidebarHeader;
