import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withAlert } from "react-alert";
import _ from "lodash";
import Widget from "./components/Widget";
import DateWidget from "./components/DateWidget";
import Banner from "./components/Banner";
import AccessionWidget from "./components/AccessionWidget";
// import AccessionGraph from "./components/AccessionGraph";
import AccessionService from "../../services/AccessionService";
import Loading from "../../components/Loading";
import UserStore from "../../utils/UserStore";
import { Can } from "../../acl/can";

class AccessionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loadingGraph: false,
      // graphData: [],
      loadingAccessionData: false,
      accessionData: {}
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Painel de Adesão");

    // this.getGraphData();
    this.getAccessionData();
  }

  getAccessionData = async () => {
    this.setState({ loadingAccessionData: true });
    try {
      const accessionData = await AccessionService.getAccessionData();
      this.setState({ accessionData });
    } catch (e) {
      const { alert } = this.props;
      console.error(e);
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao buscar dados de adesão"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingAccessionData: false });
    }
  };

  // getGraphData = async () => {
  //   this.setState({ loadingGraph: true });
  //   try {
  //     const graphData = await AccessionService.getGraphicData();
  //     this.setState({ graphData });
  //   } catch (e) {
  //     const { alert } = this.props;
  //     console.error(e);
  //     const message = _.get(
  //       e,
  //       "response.data.error.message",
  //       "Erro ao buscar dados do gráfico"
  //     );
  //     alert.show(message, { title: "Erro" });
  //   } finally {
  //     this.setState({ loadingGraph: false });
  //   }
  // };

  clickDetails = type => {
    const { history } = this.props;
    history.push(`/accession-panel/${type}`);
  };

  render() {
    const {
      // graphData,
      // loadingGraph,
      accessionData,
      loadingAccessionData
    } = this.state;

    return (
      <Grid container spacing={2}>
        <DateWidget sm={3} />
        <Widget sm={9}>
          <Banner userName={UserStore.getUserName().split(" ")[0]} />
        </Widget>

        {loadingAccessionData ? (
          <Widget sm={12}>
            <Loading />
          </Widget>
        ) : (
          <Can I="use module" a="accession">
            {accessionData.responsibles && (
              <AccessionWidget
                sm={4}
                label="Responsáveis"
                // value={accessionData.responsibles.value}
                // additionalValue={accessionData.responsibles.additionalValue}
                onClick={() => this.clickDetails("responsibles")}
              />
            )}

            {accessionData.students && (
              <AccessionWidget
                sm={4}
                label="Alunos"
                // value={accessionData.students.value}
                // additionalValue={accessionData.students.additionalValue}
                onClick={() => this.clickDetails("students")}
              />
            )}

            {/* {accessionData.family && ( */}
            {/*  <AccessionWidget */}
            {/*    sm={4} */}
            {/*    label="Familiares" */}
            {/*    value={accessionData.family.value} */}
            {/*    additionalValue={accessionData.family.additionalValue} */}
            {/*  /> */}
            {/* )} */}
          </Can>
        )}
        <Can I="use module" a="accession">
          {/* <Widget sm={12}> */}
          {/*  {loadingGraph ? <Loading /> : <AccessionGraph data={graphData} />} */}
          {/* </Widget> */}
        </Can>
      </Grid>
    );
  }
}

AccessionPanel.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired, // inject by withAlert
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired // inject by withRouter
};

export default withAlert()(withRouter(AccessionPanel));
