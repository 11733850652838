import React from "react";
import PropTypes from "prop-types";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import { FileCopy as CopyIcon } from "@material-ui/icons";
import Dialog from "../../../waybee-ui/Dialog";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import Button from "../../../waybee-ui/Button";

const DialogCopy = ({ title, value, open, onClose }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth closeButton>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <TextField
            variant="outlined"
            value={value}
            disabled
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(value);
                    }}
                  >
                    <CopyIcon color="action" />
                  </Button>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DialogCopy.defaultProps = {
  title: "",
  value: ""
};

DialogCopy.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DialogCopy;
