import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
// services
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { AddCircleOutline, History as HistoryIcon } from "@material-ui/icons";
import CampaignsService from "../../services/CampaignsService";
import Group from "../../waybee-ui/Group";
import ButtonBlock from "../../waybee-ui/ButtonBlock";
import Heading from "../../waybee-ui/Heading/Heading";

import EmailList from "./components/EmailList";
import { AbilityContext, Can } from "../../acl/can";

class EmailDashboard extends Component {
  state = {
    campaigns: [],
    filteredCampaigns: [],
    campaignsLoading: false,
    hasAllCampaigns: false,
    campaignsCount: 0,
    filters: {
      searchText: "",
      initialDate: null,
      finalDate: null
    },
    campaignSelected: null
  };

  componentDidMount() {
    const { context } = this;

    if (context.can("list", "campaign")) {
      const { campaigns } = this.state;

      if (!campaigns.length) {
        this.getCampaigns().then(newcampaigns =>
          this.setState(() => ({ campaigns: newcampaigns }))
        );
      }

      window.addEventListener("scroll", this.scrollEvent);

      this.setCampaignsCount();
    }

    const { setTitle } = this.props;
    setTitle("Central de E-mail");
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEvent);
  }

  setFilter(field, value) {
    const { filters } = this.state;
    filters[field] = value;
    this.setState(
      { filters, filteredCampaigns: [], hasAllCampaigns: false },
      () => {
        if (this.hasAnyFilter()) {
          this.getCampaigns().then(newCampaigns =>
            this.setState(() => ({ filteredCampaigns: newCampaigns }))
          );
        }
      }
    );
  }

  getCampaigns = (countToSkip = 0) => {
    const { filters } = this.state;
    this.setState({ campaignsLoading: true });
    return CampaignsService.getCampaigns(
      countToSkip,
      filters.searchText,
      filters.initialDate,
      filters.finalDate
    ).finally(() => this.setState({ campaignsLoading: false }));
  };

  getMoreCampaigns() {
    const {
      campaigns,
      filteredCampaigns,
      hasAllCampaigns,
      campaignsLoading
    } = this.state;
    if (hasAllCampaigns) return;
    if (campaignsLoading) {
      setTimeout(() => {
        this.getMoreCampaigns();
      }, 500);
      return;
    }
    this.getCampaigns(
      this.hasAnyFilter() ? filteredCampaigns.length : campaigns.length
    ).then(newCampaigns => {
      this.setState(prevState => {
        const typeData = this.hasAnyFilter()
          ? "filteredCampaigns"
          : "campaigns";
        return {
          hasAllCampaigns: newCampaigns.length === 0,
          [typeData]: prevState[typeData].concat(newCampaigns)
        };
      });
    });
  }

  setCampaignsCount() {
    CampaignsService.getCampaignsCount().then(count =>
      this.setState({ campaignsCount: count })
    );
  }

  scrollEvent = () => {
    const container = document.querySelector("html");
    if (
      container.scrollTop + window.innerHeight >=
      container.offsetHeight - 100
    ) {
      this.getMoreCampaigns();
    }
  };

  hasAnyFilter() {
    const { filters } = this.state;
    const { searchText, initialDate, finalDate } = filters;
    return !!(searchText.trim() || initialDate || finalDate);
  }

  selectCampaign(campaign) {
    this.setState({ campaignSelected: campaign });
  }

  render() {
    const {
      campaigns,
      filteredCampaigns,
      campaignsLoading,
      filters,
      campaignsCount,
      campaignSelected
    } = this.state;

    if (campaignSelected) {
      return <Redirect to={`/email/${campaignSelected.id}`} />;
    }

    return (
      <Grid container spacing={2}>
        <Can I="create" a="campaign">
          <Grid item xs={12}>
            <Group p={0}>
              <ButtonBlock
                startIcon={<AddCircleOutline />}
                component={Link}
                to="/create-email"
              >
                Novo Disparo de E-mail
              </ButtonBlock>
            </Group>
          </Grid>
        </Can>
        <Can I="list" a="campaign">
          <Grid item xs={12}>
            <Group>
              <Box>
                <Heading level={2} icon={HistoryIcon} gutterBottom>
                  {`Histórico de Disparos (${campaignsCount})`}
                </Heading>

                <EmailList
                  campaigns={
                    this.hasAnyFilter() ? filteredCampaigns : campaigns
                  }
                  filters={filters}
                  setFilter={(field, value) => this.setFilter(field, value)}
                  onSelectCampaign={(...props) => this.selectCampaign(...props)}
                />
                {campaignsLoading && (
                  <Box p={6} textAlign="center">
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Group>
          </Grid>
        </Can>
      </Grid>
    );
  }
}

EmailDashboard.contextType = AbilityContext;

EmailDashboard.propTypes = {
  setTitle: PropTypes.func.isRequired
};

export default EmailDashboard;
