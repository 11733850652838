import { api } from "../utils/api";

const saveCalendar = async data => {
  const response = await api.post("/Calendars", data);
  return response.data;
};

const editCalendar = async (data, id) => {
  const response = await api.put(`/Calendars/${id}`, data);
  return response.data;
};

const deleteCalendar = async id => {
  const response = await api.delete(`/Calendars/${id}`);
  return response.data;
};

const saveEvent = async data => {
  const response = await api.post("/Calendars/event", data);
  return response.data;
};

const editEvent = async (data, id) => {
  const response = await api.put(`/Calendars/events/${id}`, data);
  return response.data;
};

const deleteEvent = async id => {
  const response = await api.delete(`/Calendars/events/${id}`);
  return response.data;
};

const getCalendars = async () => {
  const res = await api.get(`Calendars/`);
  return res.data;
};

const getCalendar = async id => {
  const res = await api.get(`Calendars/${id}`);
  return res.data;
};

const getEvent = async id => {
  const res = await api.get(`Calendars/events/${id}`);
  return res.data;
};

const getEvents = async filter => {
  const res = await api.get(`Calendars/events`, { params: { filter } });
  return res.data;
};

const notifyEventEdit = async eventId => {
  const res = await api.post(`Calendars/events/${eventId}/notification`);
  return res.data;
};

const saveEventNotification = async data => {
  const response = await api.post("/Calendars/events/notification", data);
  return response.data;
};

const editEventNotification = async data => {
  const response = await api.put("/Calendars/events/notification", data);
  return response.data;
};

const cancelEventNotification = async id => {
  const response = await api.put("/Calendars/events/notification/cancel", id);
  return response.data;
};
export default {
  saveCalendar,
  getCalendar,
  saveEvent,
  editEvent,
  deleteEvent,
  getCalendars,
  getEvent,
  getEvents,
  editCalendar,
  deleteCalendar,
  notifyEventEdit,
  saveEventNotification,
  editEventNotification,
  cancelEventNotification
};
