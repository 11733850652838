import React, { Component } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import { theme } from "../../../../../assets/theme/StyledTheme";

class AttachmentsItem extends Component {
  styles = {
    textItem: {
      color: theme.color.primary.main,
      fontSize: "13px",
      verticalAlign: "top",
      marginLeft: "17px",
      display: "inline-block",
      padding: "3px 0"
    }
  };

  render() {
    const { styles } = this;
    const { icon, text, onClick } = this.props;
    return (
      <ListItem
        onClick={onClick}
        innerDivStyle={{ padding: "10px 16px 2px" }}
        component="span"
      >
        {icon}
        <span style={styles.textItem}>{text}</span>
      </ListItem>
    );
  }
}

AttachmentsItem.defaultProps = {
  icon: undefined
};

AttachmentsItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AttachmentsItem;
