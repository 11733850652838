import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { Description as DescriptionIcon } from "@material-ui/icons";
import Group from "../../../waybee-ui/Group";
import Heading from "../../../waybee-ui/Heading/Heading";

const GroupContent = ({ campaign }) => {
  return (
    <Group>
      <Box minHeight={380}>
        <Heading level={2} icon={DescriptionIcon} gutterBottom>
          Conteúdo
        </Heading>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="caption">Título</Typography>
            <Typography variant="body1" gutterBottom>
              {campaign.title}
            </Typography>
          </Grid>
        </Grid>

        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: campaign.body }}
          gutterBottom
        />
      </Box>
    </Group>
  );
};

GroupContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  campaign: PropTypes.object.isRequired
};

export default GroupContent;
