import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
  Details as DetailsIcon,
  People as GroupIcon
} from "@material-ui/icons";
import * as yup from "yup";
import { withAlert } from "react-alert";
import _ from "lodash";
import BackButton from "../../components/BackButton";
import Heading from "../../waybee-ui/Heading";
import Group from "../../waybee-ui/Group";
import DetailForm from "./components/DetailForm";
import PublicSelector from "../../components/PublicSelector";
import CalendarService from "../../services/CalendarService";
import Button from "../../waybee-ui/Button";
import LoadingDialog from "../../waybee-ui/Dialog/LoadingDialog";
import SimpleConfirmDialog from "../../waybee-ui/Dialog/SimpleConfirmDialog";

require("../../utils/yup.locale.pt-br");

class CalendarForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openConfirm: false,
      loading: false,
      resetFormFlag: 0,
      selectedCalendar: null,
      values: {
        publicField: "N",
        color: "#FFFFFF"
      },
      schema: yup.object().shape({
        name: yup.string().required("Nome do calendário é obrigatório"),
        color: yup.string().required("Cor do calendário é obrigatória"),
        publicField: yup
          .string()
          .required("Especificação de público é obrigatório")
      })
    };
  }

  componentDidMount() {
    this.getSelectedCalendar();
  }

  getSelectedCalendar = async () => {
    const {
      setTitle,
      match: {
        params: { calendarId }
      }
    } = this.props;
    const { resetFormFlag } = this.state;

    if (calendarId) {
      try {
        this.setState({ loading: true });
        const selectedCalendar = await CalendarService.getCalendar(calendarId);
        this.setState({
          selectedCalendar,
          values: {
            name: selectedCalendar.name,
            color: selectedCalendar.color,
            description: selectedCalendar.description,
            publicField: selectedCalendar.public ? "Y" : "N",
            destinationFilter: selectedCalendar.destinationFilter
          },
          resetFormFlag: resetFormFlag === 1 ? 0 : 1
        });
        setTitle("Editar Calendário");
      } catch (e) {
        console.error(e);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      setTitle("Novo Calendário");
    }
  };

  saveValues = async newValues => {
    const { values } = this.state;
    let { destinationFilter } = newValues;
    if (newValues.publicField === "Y") destinationFilter = null;

    const valuesObj = {
      ...values,
      ...newValues,
      destinationFilter
    };

    this.setState({
      values: valuesObj
    });
  };

  validate = values => {
    const { schema } = this.state;
    return schema.validate(values, { abortEarly: false });
  };

  saveCalendar = async () => {
    const { alert } = this.props;
    const { values, selectedCalendar } = this.state;

    this.setState({ loading: true });

    try {
      const publicField = values.publicField === "Y";
      const sendValues = {
        ...values,
        public: publicField
      };
      await this.validate(sendValues);

      if (selectedCalendar)
        await CalendarService.editCalendar(sendValues, selectedCalendar.id);
      else {
        await CalendarService.saveCalendar(sendValues);
        this.reset();
      }

      alert.show("Calendário salvo com sucesso", {
        title: "Sucesso"
      });
    } catch (e) {
      let message = "";
      if (e.errors) message = e.errors.join("\n");

      message = _.get(
        e,
        "response.data.error.message",
        "Erro ao salvar calendário"
      );

      alert.show(message, {
        title: "Erro"
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  deleteCalendar = async () => {
    const { alert, history } = this.props;
    const { selectedCalendar } = this.state;

    this.setState({ loading: true });
    try {
      await CalendarService.deleteCalendar(selectedCalendar.id);
      history.push(`/calendar`);
    } catch (e) {
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao deletar calendário"
      );

      alert.show(message, {
        title: "Erro"
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  reset = () => {
    const { resetFormFlag } = this.state;
    this.setState({
      values: {
        name: "",
        color: "#FFFFFF",
        description: "",
        publicField: "N",
        destinationFilter: {
          students: {
            courses: [],
            classes: [],
            excluded: []
          }
        }
      },
      resetFormFlag: resetFormFlag === 1 ? 0 : 1
    });
  };

  render() {
    const {
      values,
      resetFormFlag,
      loading,
      selectedCalendar,
      openConfirm
    } = this.state;

    return (
      <span>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <BackButton to="/calendar" />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Group>
                <Heading level={2} icon={DetailsIcon} gutterBottom>
                  Detalhes do calendário
                </Heading>
                <DetailForm
                  values={values}
                  resetFlag={resetFormFlag}
                  onChange={this.saveValues}
                />
              </Group>
            </Box>
          </Grid>
          {values.publicField === "N" && (
            <Grid item xs={12}>
              <Group>
                <Heading level={2} icon={GroupIcon} gutterBottom>
                  Público do calendário
                </Heading>

                {loading ? (
                  <CircularProgress />
                ) : (
                  <PublicSelector
                    description={`
                    Assinale quem você deseja que participe do calendário:
                    O grupo de pessoas assinaladas será o grupo que receberá notificações quando um evento for criado neste calendário
                  `}
                    onChange={val =>
                      this.saveValues({ destinationFilter: val })
                    }
                    value={values.destinationFilter}
                    resetFlag={resetFormFlag}
                  />
                )}
              </Group>
            </Grid>
          )}

          {selectedCalendar && (
            <Grid item xs={4}>
              <Box mt={4.4}>
                <Button
                  onClick={() => this.setState({ openConfirm: true })}
                  color="error"
                  variant="contained"
                  fullWidth
                >
                  Deletar
                </Button>
              </Box>
            </Grid>
          )}

          <Grid item xs={4}>
            <Box mt={4.4}>
              <Button
                onClick={this.saveCalendar}
                color="primary"
                variant="contained"
                fullWidth
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <LoadingDialog
          onClose={() => this.setState({ loading: false })}
          open={loading}
        />

        <SimpleConfirmDialog
          message="Tem certeza que deseja deletar o calendário? Todos os eventos vinculados também serão apagados."
          onClose={() => this.setState({ openConfirm: false })}
          onConfirm={this.deleteCalendar}
          open={openConfirm}
        />
      </span>
    );
  }
}

CalendarForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      calendarId: PropTypes.any
    })
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired, // Injected by withAlert hoc
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired // inject by withRouter
};

export default withAlert()(CalendarForm);
