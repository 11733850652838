import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import HeaderInfoTitle from "./HeaderInfoTitle";

const DescriptionIcon = styled(DescriptionOutlined)`
  color: #787486;
  margin-right: 8px;
`;
const Description = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #787486;
`;
const TicketDetailDescription = ({ description }) => {
  return (
    <Box mt={1}>
      <Box display="flex" alignItems="center">
        <DescriptionIcon />
        <HeaderInfoTitle>Descrição</HeaderInfoTitle>
      </Box>
      <Description>{description}</Description>
    </Box>
  );
};

TicketDetailDescription.propTypes = {
  description: PropTypes.string.isRequired
};

export default TicketDetailDescription;
