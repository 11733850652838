import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core";
import styled from "styled-components";
import moment from "@moment";
import Widget from "./Widget";

const Container = styled.div`
  padding: 30px;
  text-align: center;
`;
const Header = styled.p`
  margin: 0 0 12px;
  color: black;
  font-size: 35px;
  line-height: 37px;
`;
const Day = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 60px;
  line-height: 62px;
`;
const Month = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 35px;
  line-height: 37px;
`;
const WeekDay = styled.p`
  margin: 12px 0 0;
  color: ${({ color }) => color};
  font-size: 24px;
  line-height: 26px;
`;

const DateWidget = ({ theme, ...other }) => {
  const date = moment();

  const day = date.format("DD");
  const month = date.format("MMM");
  const weekDay = date.format("dddd");

  return (
    <Widget {...other}>
      <Container>
        <Header>Hoje</Header>
        <Day>{day}</Day>
        <Month>{month}</Month>
        <WeekDay color={theme.palette.primary.main}>{weekDay}</WeekDay>
      </Container>
    </Widget>
  );
};

DateWidget.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // inject by withTheme
};

export default withTheme(DateWidget);
