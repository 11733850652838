import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "@moment";
import FullCalendar from "@fullcalendar/react";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import styled from "styled-components";
import _ from "lodash";
import { withTheme } from "@material-ui/core";

const CalendarStyle = styled.span`
  .fc {
    background-color: white;
    font-family: "Roboto", sans-serif;

    .fc-toolbar.fc-header-toolbar {
      padding: 20px 22px;
      margin: 0;

      .fc-toolbar-chunk {
        display: flex;
        align-items: center;

        .fc-toolbar-title {
          display: inline;
          font-weight: 400;
        }

        .fc-prev-button,
        .fc-next-button {
          border: none;
          background-color: transparent;
          color: black;
          font-weight: 600;
        }

        .fc-dayGridMonth-button,
        .fc-timeGridWeek-button,
        .fc-timeGridDay-button,
        .fc-listWeek-button {
          border: none;
          background-color: transparent;
          color: rgba(0, 0, 0, 0.5);

          &:focus {
            box-shadow: none;
          }

          &.fc-button-active {
            color: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            flex-direction: column;

            &:after {
              content: "";
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: ${({ palette }) => palette.primary.main};
              display: block;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }

    .fc-view {
      .fc-list-day .fc-list-day-cushion {
        background-color: transparent;
      }

      .fc-scrollgrid-section-header {
        .fc-scroller {
          overflow: unset !important;
        }
      }

      .fc-timegrid-axis {
        border: none;
      }

      .fc-col-header-day,
      .fc-col-header-week-day {
        margin: 0;

        &.active {
          color: ${({ palette }) => palette.primary.dark};
        }
      }

      .fc-col-header-day {
        font-size: 24px;
        font-weight: 600;
      }

      .fc-col-header-week-day {
        font-size: 18px;
        font-weight: 300;
      }

      .fc-col-header {
        border-bottom: 1px solid #ddd;

        .fc-col-header-cell {
          padding: 8px 0;
          line-height: 26px;
          text-align: center;
          color: ${({ palette }) => palette.secondary.dark};
          border: none;
        }

        .fc-col-header-cell-cushion {
          white-space: pre-wrap;
        }
      }

      .fc-daygrid-body .fc-daygrid-event,
      .fc-timegrid-body .fc-timegrid-event {
        border-radius: 0;
        border-right: none;
        border-top: none;
        border-bottom: none;
        border-left-width: 4px;
        box-shadow: none;
        padding: 6px;

        .fc-event-time {
          font-size: 12px;
          font-weight: 300;
          line-height: 10px;
        }

        .fc-event-title {
          font-size: 13px;
          font-weight: 400;
        }
      }

      .fc-timegrid-body {
        .fc-timegrid-slot {
          height: 60px;
        }
      }

      .fc-timegrid-col .fc-timegrid-col-events {
        margin: 0;
      }
    }
  }
`;

class Calendar extends Component {
  calendarRef = React.createRef();

  shouldComponentUpdate(nextProps) {
    const { selectedDay, options, selectedCalendars } = this.props;

    if (selectedCalendars !== nextProps.selectedCalendars) this.refreshEvents();

    return (
      selectedDay !== nextProps.selectedDay ||
      !_.isEqual(options, nextProps.options)
    );
  }

  componentDidUpdate(prevProps) {
    const { selectedDay } = this.props;
    if (prevProps.selectedDay) this.onSelectDay(selectedDay);
  }

  onSelectDay = selectedDay => {
    const calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(selectedDay.toDate());
  };

  refreshEvents = () => {
    const calendarApi = this.calendarRef.current.getApi();
    calendarApi.refetchEvents();
  };

  render() {
    const { options, theme } = this.props;

    return (
      <CalendarStyle palette={theme.palette}>
        <FullCalendar
          ref={this.calendarRef}
          plugins={[timeGridPlugin, dayGridPlugin, listPlugin]}
          locale={ptBrLocale}
          dayHeaderContent={args => (
            <span>
              {args.view.type !== "dayGridMonth" && (
                <p className={`fc-col-header-day ${args.isToday && "active"}`}>
                  {moment(args.date).format("DD")}
                </p>
              )}
              <p className="fc-col-header-week-day">
                {
                  moment(args.date)
                    .format("dddd")
                    .split("-")[0]
                }
              </p>
            </span>
          )}
          {...options}
        />
      </CalendarStyle>
    );
  }
}

Calendar.defaultProps = {
  options: {},
  selectedCalendars: []
};

Calendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedDay: PropTypes.object.isRequired, // moment object
  selectedCalendars: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.shape({
    ...FullCalendar.propTypes
  }),
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // inject by withTheme
};

export default withTheme(Calendar);
