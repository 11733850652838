import React from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: "10px 8px",
    background: "rgb(229,229,229)",
    fontSize: "0.7em",
    maxWidth: "35%"
  }
}));

const HoverPopover = ({ id, value, externalClasses, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div className={externalClasses || ""}>
      <Typography
        aria-owns={open ? `mouse-over-popover-${id}` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>

      <Popover
        id={`mouse-over-popover-${id}`}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
      >
        <Typography sx={{ p: 2 }}>{value}</Typography>
      </Popover>
    </div>
  );
};

HoverPopover.defaultProps = {
  externalClasses: undefined
};

HoverPopover.propTypes = {
  id: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  externalClasses: PropTypes.string
};

export default HoverPopover;
