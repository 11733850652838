import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import DOMPurify from "dompurify";
import Header from "./header";
import AttachmentList from "../../../CommunicationForm/components/notification/components/AttachmentsList";

import {
  Dialog,
  PhoneContainer,
  CloseButton,
  Container,
  Content,
  Title,
  Body
} from "./styles";

const Preview = ({
  closeDialog,
  openDialog,
  createdAt,
  icon,
  title,
  subject,
  body,
  homeActivity,
  attachments
}) => {
  return (
    <Dialog
      onClose={closeDialog}
      open={openDialog}
      scroll="body"
      classes={{
        paper: "card-dialog",
        root: "container-root-dialog"
      }}
    >
      <PhoneContainer>
        <CloseButton size="small" aria-label="close" onClick={closeDialog}>
          <CloseIcon />
        </CloseButton>
      </PhoneContainer>
      <Container>
        <Header createdAt={createdAt} schoolIcon={icon} title={title} />
        <Content>
          <Title>{subject}</Title>
          <Body
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
          />
          {homeActivity && (
            <>
              <Title>Lição de casa</Title>
              <Body
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(homeActivity)
                }}
              />
            </>
          )}
          {!!attachments.length && <AttachmentList attachments={attachments} />}
        </Content>
      </Container>
    </Dialog>
  );
};

Preview.defaultProps = {
  homeActivity: null,
  attachments: []
};

Preview.propTypes = {
  closeDialog: PropTypes.bool.isRequired,
  openDialog: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  homeActivity: PropTypes.string,
  subject: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      customName: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.url
    })
  )
};

export default Preview;
