import PropTypes from "prop-types";
import MuiButton from "@material-ui/core/Button";
import styled, { css } from "styled-components";

const Button = styled(MuiButton).withConfig({
  shouldForwardProp: prop => !["size"].includes(prop)
})`
  background-color: white;

  ${({ size }) => {
    let height = "";

    let fontSize = "";

    let iconSize = "";

    let iconMargin = "";

    switch (size) {
      case "normal":
        height = "80px";
        fontSize = "18px";
        iconSize = "1.6em";
        iconMargin = "10px";
        break;
      case "small":
        height = "52px";
        fontSize = "14px";
        iconSize = "1.2em";
        iconMargin = "8px";
        break;
      default:
    }
    return css`
      height: ${height};
      font-size: ${fontSize};
      svg {
        margin-right: ${iconMargin};
        width: ${iconSize};
        height: ${iconSize};
      }
    `;
  }};

  width: 100%;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.color.primary.main};
  box-shadow: #dadada 1px 1px 1px;
`;

Button.defaultProps = {
  size: "normal"
};

Button.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ...MuiButton.propTypes,
  size: PropTypes.oneOf(["normal", "small"])
};

export default Button;
